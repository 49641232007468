import { CreateToastFnReturn } from '@chakra-ui/react';
import { correspondencesService, requestService } from '../../../../services';
import { Account, Correspondence } from '../../../../types';
import { genericToast } from '../../../../util';

export class CorrespondenceActionDispatcher {
    async previewCorrespondence(
        values: { account: Account },
        placeholderReplacements: Record<string, any>,
        toast: CreateToastFnReturn
    ) {
        const { account } = values;

        await genericToast(
            toast,
            async () => {
                const response = await requestService.post<{ signedUrl: string }>(
                    `buildings/${account.buildingId}/correspondences/preview`,
                    {
                        body: {
                            accountId: account.id,
                            ...placeholderReplacements
                        }
                    }
                );

                const { signedUrl } = response.data;
                if (signedUrl) window.open(signedUrl);
            },
            {
                duration: 4000,
                title: 'Downloading correspondence',
                description: `Downloading correspondence for ${account.accountName}`,
                onSuccessTitle: 'Successfully downloaded correspondence',
                onErrorTitle: 'Failed to downloaded correspondence'
            }
        );
    }

    async downloadCorrespondence(
        values: { account: Account; correspondence: Correspondence },
        toast: CreateToastFnReturn
    ) {
        const { account, correspondence } = values;

        await genericToast(
            toast,
            async () => {
                const result = await correspondencesService.create<{
                    signedUrl: string;
                }>({
                    pathParams: {
                        buildingId: correspondence.buildingId,
                        id: correspondence.id
                    },
                    pathSuffix: 'download',
                    params: {
                        accountId: account.id
                    }
                });

                if (result) {
                    window.open(result.signedUrl);
                }
            },
            {
                duration: 4000,
                title: 'Downloading correspondence',
                description: `Downloading correspondence for ${account.accountName}`,
                onSuccessTitle: 'Successfully downloaded correspondence',
                onErrorTitle: 'Failed to downloaded correspondence'
            }
        );
    }
}
