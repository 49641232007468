import { Flex, Spacer, Text, UseDisclosureReturn, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StandardModal, TextField, WeFactorActionButton, WeFactorSecondaryButton } from '../../../components';
import { requestService } from '../../../services';
import { Building } from '../../../types';
import { genericToast } from '../../../util';

const schema = Joi.object({
    code: Joi.string().label('Transfer code').trim().min(10).max(10).required()
}).unknown(false);

type AssumeAdminForm = {
    code: string;
};

export const AssumeAdminFormDialog = (props: {
    disclosure: UseDisclosureReturn;
    onClose: (building?: Building) => void;
}) => {
    const { disclosure, onClose } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);

    const toast = useToast();

    const form = useForm<AssumeAdminForm>({
        resolver: joiResolver(schema),
        reValidateMode: 'onSubmit'
    });

    const submit = async (): Promise<void> => {
        setIsSubmitting(true);
        await genericToast(
            toast,
            async () => {
                const response = await requestService.post<Building>('buildings/complete-transfer-request', {
                    body: form.getValues()
                });
                onClose(response.data);
            },
            {
                title: 'Submitting request',
                description: 'Submitting request to assume admin rights'
            }
        );
        setIsSubmitting(false);
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    md: 'sm'
                }
            }}
            onClose={onClose}
            header={'Transfer building/estate'}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton isDisabled={isSubmitting} onClick={disclosure.onClose}>
                        Cancel
                    </WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorActionButton
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                        onClick={form.handleSubmit(submit)}
                    >
                        Complete
                    </WeFactorActionButton>
                </Flex>
            }
        >
            <Text mb={4}>
                Enter the transfer code below and click on "Complete" to complete the transfer of admin rights to your
                account.
            </Text>
            <form id="assume-admin-rights" noValidate autoComplete="off">
                <TextField formLabel="Transfer code" name="code" formHook={form} placeholder="Enter transfer code..." />
            </form>
        </StandardModal>
    );
};
