import { Text } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import { EmptyPageBox, PageLoadingSkeleton, StandardPage } from '../../components';
import {
    accountsService,
    buildingUsersService,
    correspondencesService,
    factorPropertiesService,
    userAccountService
} from '../../services';
import { Accounts, Correspondence, Properties, UserAccount } from '../../types';
import { formatStreetAddress } from '../../util';
import { CorrespondenceTable } from './components';

export const CorrespondencePageOwner = () => {
    const [activeAccount] = useObservable(userAccountService.activeEntity$ as Observable<UserAccount>);

    const [accountsLoaded] = useObservable(accountsService.loaded$ as Observable<boolean>);

    const [correspondences] = useObservable(correspondencesService.entities$ as Observable<Correspondence[]>);
    const [correspondencesLoaded] = useObservable(correspondencesService.loaded$ as Observable<boolean>);

    const [propertiesLoaded] = useObservable(factorPropertiesService.loaded$ as Observable<boolean>);

    const [accounts, setAccounts] = useState<Accounts>();
    const [properties, setProperties] = useState<Properties>();

    useEffect(() => {
        if (!activeAccount) return;
        const { id: accountId, buildingId } = activeAccount;
        accountsService.fetch({ params: { buildingId } }).then((accounts) => setAccounts(new Accounts(accounts)));
        factorPropertiesService
            .fetch({ params: { buildingId } })
            .then((properties) => setProperties(new Properties(properties)));
        correspondencesService.fetch({ params: { buildingId, accountId } });
        buildingUsersService.fetch({ params: { buildingId } });
    }, [activeAccount]);

    if (!accountsLoaded || !correspondencesLoaded || !propertiesLoaded) {
        return <PageLoadingSkeleton />;
    }

    if (!activeAccount || !accounts || !properties) {
        return <PageLoadingSkeleton />;
    }

    if (!correspondences.length) {
        return (
            <EmptyPageBox>
                <Text mt={5} fontWeight={600}>
                    {formatStreetAddress(activeAccount.property)}
                </Text>
                <Text mt={2} textAlign={'center'}>
                    You have not yet received any correspondence
                </Text>
            </EmptyPageBox>
        );
    }

    return (
        <>
            <StandardPage title={`${formatStreetAddress(activeAccount.property)} - Correspondence`}>
                <CorrespondenceTable correspondences={correspondences} />
            </StandardPage>
        </>
    );
};
