import { Radio, RadioGroup, RadioGroupProps, RadioProps, Stack, StackDirection } from '@chakra-ui/react';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';

type RadioGroupFieldProps<T extends FieldValues> = BaseFormControlProps<T> & {
    radioGroupProps?: Omit<RadioGroupProps, 'children' | 'isDisabled' | 'name' | 'onChange' | 'value'>;
    radioProps?: Omit<RadioProps, 'autoFocus' | 'id' | 'key' | 'name' | 'value'>;

    direction?: StackDirection;
    options: { label: string; value: string }[];
};

export const BaseRadioGroupField = <T extends FieldValues>(props: RadioGroupFieldProps<T>) => {
    const { direction, name, options, radioGroupProps, radioProps } = props;

    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        const field = controller.field;

        return (
            <RadioGroup
                name={name}
                isDisabled={field.disabled}
                onChange={field.onChange}
                value={field.value}
                ref={field.ref}
                {...radioGroupProps}
            >
                <Stack direction={direction || 'column'}>
                    {options.map((option, index) => {
                        const id = `${name}.${index}`;

                        return (
                            <Radio
                                id={id}
                                key={id}
                                name={name}
                                aria-label={option.value}
                                size="md"
                                spacing="1rem"
                                value={option.value}
                                {...radioProps}
                            >
                                {option.label}
                            </Radio>
                        );
                    })}
                </Stack>
            </RadioGroup>
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
