import NP from 'number-precision';
import { Account, CommonRepairQuoteShare, Division, DivisionMethod, DivisionVersion } from '../../../../types';

export class ShareCalculator {
    constructor(private accounts: Account[]) {}

    calculateAcceptedByDivisionVersion(
        commonRepairQuoteShares: CommonRepairQuoteShare[],
        divisionVersion: DivisionVersion
    ): string {
        switch (divisionVersion.divisionMethod) {
            case DivisionMethod.EQUAL_SHARE:
                return this.calculateAcceptedByEqualShare(commonRepairQuoteShares);
            case DivisionMethod.FRACTION:
            case DivisionMethod.FLOOR_AREA:
            case DivisionMethod.PERCENTAGE:
            case DivisionMethod.RATEABLE_VALUE:
                return this.calculateAcceptedByRatio(commonRepairQuoteShares, divisionVersion);
            default:
                return '0';
        }
    }

    private calculateAcceptedByEqualShare(commonRepairQuoteShares: CommonRepairQuoteShare[]): string {
        const accepted = commonRepairQuoteShares.filter(
            (commonRepairQuoteShare) => commonRepairQuoteShare.factorResponse || commonRepairQuoteShare.ownerResponse
        );

        return this.convertToPercentageString(accepted.length, commonRepairQuoteShares.length);
    }

    private calculateAcceptedByRatio(
        commonRepairQuoteShares: CommonRepairQuoteShare[],
        divisionVersion: DivisionVersion
    ) {
        const propertyIds = new Set(commonRepairQuoteShares.map((share) => share.propertyId));

        const acceptedPropertyIds = new Set(
            commonRepairQuoteShares
                .filter((share) => share.factorResponse || share.ownerResponse)
                .map((share) => share.propertyId)
        );

        const numeratorSum = divisionVersion.divisions.reduce((sum, division) => {
            if (propertyIds.has(division.propertyId)) {
                return NP.plus(sum, division.value);
            } else {
                return sum;
            }
        }, 0);

        const acceptedNumeratorSum = divisionVersion.divisions.reduce((sum, division) => {
            if (acceptedPropertyIds.has(division.propertyId)) {
                return NP.plus(sum, division.value);
            } else {
                return sum;
            }
        }, 0);

        return this.convertToPercentageString(acceptedNumeratorSum, numeratorSum);
    }

    calculateAmountsByDivisionVersion(
        accountIds: string[],
        amount: number,
        divisionVersion: DivisionVersion
    ): Map<string, number> {
        const accounts = this.accounts.filter((a) => accountIds.includes(a.id));

        switch (divisionVersion.divisionMethod) {
            case DivisionMethod.EQUAL_SHARE:
                return this.calculateAmountsByEqualShare(accountIds, amount);
            case DivisionMethod.FRACTION:
            case DivisionMethod.FLOOR_AREA:
            case DivisionMethod.PERCENTAGE:
            case DivisionMethod.RATEABLE_VALUE:
                return this.calculateAmountsByRatio(accounts, amount, divisionVersion);
            default:
                return new Map();
        }
    }

    private calculateAmountsByEqualShare(accountIds: string[], amount: number): Map<string, number> {
        const accounts = this.accounts.filter((a) => accountIds.includes(a.id));
        const share = this.roundUp(NP.divide(amount, accounts.length));

        return accounts.reduce((map, account) => {
            map.set(account.id, share);
            return map;
        }, new Map<string, number>());
    }

    private calculateAmountsByRatio(accounts: Account[], amount: number, divisionVersion: DivisionVersion) {
        const divisionsMap = divisionVersion.divisions.reduce((map, division) => {
            map.set(division.propertyId, division);
            return map;
        }, new Map<string, Division>());

        const denominator = accounts.reduce((sum, account) => {
            return NP.plus(sum, this.getValue(account, divisionsMap));
        }, 0);

        return accounts.reduce((map, account) => {
            map.set(
                account.id,
                this.roundUp(NP.divide(NP.times(amount, this.getValue(account, divisionsMap)), denominator))
            );
            return map;
        }, new Map<string, number>());
    }

    private getValue(account: Account, divisionsMap: Map<string, Division>) {
        const divison = divisionsMap.get(account.propertyId);
        if (!divison) throw new Error('Missing divison');
        return divison.value;
    }

    private roundUp(amount: number): number {
        const amountTo2Places = NP.round(amount, 2);
        const amountTo6Places = NP.round(amount, 6);
        return amountTo2Places < amountTo6Places ? NP.plus(amountTo2Places, 0.01) : amountTo2Places;
    }

    private convertToPercentageString(numerator: number, denominator: number): string {
        return NP.times(NP.divide(numerator, denominator), 100).toFixed(2);
    }
}
