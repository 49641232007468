import { Config } from './config.model';

export interface ConfigProps {
    config?: Config | Error;
}

export const configService = (() => {
    let config: Config;

    const getConfig = () => {
        return config;
    };

    const fetchConfig = async (): Promise<Config> => {
        const result = await fetch('/config/app-config.json');
        const _config = await result.json();
        config = _config;
        return _config;
    };

    return {
        getConfig,
        fetchConfig
    };
})();
