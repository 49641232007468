import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import { FieldValues } from 'react-hook-form';
import { DivisionMethod, DivisionVersion, ShareMethod } from '../../types';
import { BaseSelect, BaseSelectProps } from './BaseSelect';

type SelectShareMethodFieldProps<T extends FieldValues> = Omit<BaseSelectProps<T>, 'options'> & {
    activeDivisionVersion: DivisionVersion;
    currentDivisionVersion?: DivisionVersion;
};

const DIVISION_METHOD_DESCRIPTIONS: Map<DivisionMethod, string> = new Map([
    [DivisionMethod.EQUAL_SHARE, 'Share equally'],
    [DivisionMethod.FLOOR_AREA, 'Share by ratio of floor areas'],
    [DivisionMethod.FRACTION, 'Share by set fractions'],
    [DivisionMethod.PERCENTAGE, 'Share by set percentages'],
    [DivisionMethod.RATEABLE_VALUE, 'Share by ratio of rateable values']
]);

export const SelectShareMethodField = <T extends FieldValues>(props: SelectShareMethodFieldProps<T>) => {
    const { activeDivisionVersion, currentDivisionVersion } = props;

    const referencesPreviousVersion =
        currentDivisionVersion &&
        currentDivisionVersion.divisionMethod !== DivisionMethod.EQUAL_SHARE &&
        currentDivisionVersion.id !== activeDivisionVersion.id;

    const options: any[] = [];

    if (referencesPreviousVersion) {
        options.push({
            value: ShareMethod.RATEABLE_PREVIOUS,
            label: `${DIVISION_METHOD_DESCRIPTIONS.get(currentDivisionVersion.divisionMethod)} (previous configuration)`
        });
    }

    if (activeDivisionVersion.divisionMethod !== DivisionMethod.EQUAL_SHARE) {
        const newDivisionVersion = DIVISION_METHOD_DESCRIPTIONS.get(activeDivisionVersion.divisionMethod);

        if (referencesPreviousVersion) {
            options.push(
                {
                    value: ShareMethod.RATEABLE,
                    label: `${newDivisionVersion} (updated configuration)`
                },
                {
                    value: ShareMethod.EQUAL_SHARE,
                    label: 'Share equally'
                }
            );
        } else {
            options.push(
                {
                    value: ShareMethod.RATEABLE,
                    label: newDivisionVersion
                },
                {
                    value: ShareMethod.EQUAL_SHARE,
                    label: 'Share equally'
                }
            );
        }
    } else {
        if (referencesPreviousVersion) {
            options.push({
                value: ShareMethod.EQUAL_SHARE,
                label: 'Share equally (updated configuration)'
            });
        } else {
            options.push({
                value: ShareMethod.EQUAL_SHARE,
                label: 'Share equally'
            });
        }
    }

    if (referencesPreviousVersion) {
        return (
            <>
                <BaseSelect options={options} {...props} />
                <Alert status="warning">
                    <AlertIcon />
                    <AlertDescription>
                        How costs are shared between properties has been updated since this invoice was originally
                        created. You can either continue to use the previous configuration to keep the original amounts
                        assigned to each property or select the updated configuration to update the amounts to align to
                        the new configuration.
                    </AlertDescription>
                </Alert>
            </>
        );
    } else {
        return <BaseSelect options={options} {...props} />;
    }
};
