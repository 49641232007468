import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex, FormLabel, Text, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    CheckAddressesField,
    HiddenField,
    StandardModalProps,
    TextField,
    Wizard,
    WizardTabPanel
} from '../../../components';
import { factorDivisionVersionsService, factorPropertiesService, requestService } from '../../../services';
import {
    Accounts,
    Address,
    Building,
    DivisionMethod,
    Properties,
    Property,
    currentOwnerFilter,
    deletedFilter
} from '../../../types';
import { formatStreetAddress } from '../../../util';

type Owner = {
    accountName: string;
    email: string;
    uniqueCode: string;
};

type PropertiesForm = {
    buildingId: string;
    owners: Owner[];
    postcode: string;
    properties: string[];
};

interface PropertiesWizardProps extends StandardModalProps {
    accounts: Accounts;
    building: Building;
    properties: Properties;
    onSaveSuccess?: () => void;
}

enum SearchStatus {
    NEW,
    COMPLETE,
    ERROR,
    SEARCHING
}

export const PropertiesWizard = (props: PropertiesWizardProps) => {
    const { accounts, building, properties, onSaveSuccess, disclosure, onClose } = props;

    const [showWarning, setShowWarning] = useState(false);

    const deletedAccountsMap = useMemo(
        () =>
            accounts.getAccountsMap('propertyId', {
                filters: [currentOwnerFilter(), deletedFilter()]
            }),
        [accounts]
    );

    const deletedPropertiesMap = useMemo(
        () => properties.getPropertiesMap('uniqueCode', { filters: [deletedFilter()] }),
        [properties]
    );

    const existingProperties = useMemo(
        () => properties.getActiveProperties().map((property) => property.uniqueCode.toString()),
        [properties]
    );

    const [addressSearch, setAddressSearch] = useState<{
        postcode?: string;
        results: Address[];
        status: SearchStatus;
    }>({
        results: [],
        status: SearchStatus.NEW
    });
    const [selectedAddresses, setSelectedAddresses] = useState<Address[]>([]);

    const toast = useToast();

    const form = useForm<PropertiesForm>({
        defaultValues: {
            buildingId: building.id,
            postcode: '',
            owners: [],
            properties: []
        },
        resolver: joiResolver(
            Joi.object({
                buildingId: Joi.string().uuid().required(),
                owners: Joi.array()
                    .label('Owners')
                    .items({
                        accountName: Joi.string()
                            .label('Owner full name')
                            .trim()
                            .messages({ 'string.empty': 'Name is required' })
                            .min(3)
                            .max(100)
                            .required(),
                        email: Joi.string()
                            .label('Owner email address')
                            .trim()
                            .messages({ 'string.email': 'Email address is invalid' })
                            .email({ tlds: { allow: false } })
                            .allow(null)
                            .allow('')
                            .empty()
                            .max(255)
                            .optional(),
                        uniqueCode: Joi.string().label('Unique code').trim().required()
                    })
                    .required(),
                postcode: Joi.string()
                    .label('Postcode')
                    .trim()
                    .regex(/^[a-zA-Z]{1,2}[0-9]{1,2}[' ']?[a-zA-Z]?[0-9][a-zA-Z]{2}$/)
                    .messages({
                        'string.pattern.base': 'A full valid postcode must be entered'
                    })
                    .min(3)
                    .max(10)
                    .required(),
                properties: Joi.array()
                    .label('Properties')
                    .items(Joi.string().required())
                    .min(1)
                    .max(20 - existingProperties.length)
                    .required()
            }).unknown(false)
        ),
        reValidateMode: 'onSubmit'
    });

    useEffect(() => {
        factorDivisionVersionsService.fetch({ pathParams: { buildingId: building.id } }).then((divisions) => {
            const activeDivision = divisions.find((div) => div.active);
            setShowWarning(activeDivision?.divisionMethod !== DivisionMethod.EQUAL_SHARE);
        });
    }, [building]);

    const searchAddresses = async (postcode: string) => {
        form.resetField('properties', {
            defaultValue: [],
            keepDirty: false,
            keepError: false,
            keepTouched: false
        });

        setAddressSearch({
            postcode: postcode,
            results: [],
            status: SearchStatus.SEARCHING
        });

        try {
            const response = await requestService.get<Address[]>('address-search', {
                params: {
                    postcode: postcode?.trim()
                }
            });

            setAddressSearch({
                postcode: postcode,
                status: SearchStatus.COMPLETE,
                results: response.data
            });
        } catch (error) {
            setAddressSearch({
                postcode: postcode,
                results: [],
                status: SearchStatus.ERROR
            });

            toast({
                description: 'Address search failed',
                status: 'error',
                position: 'top',
                isClosable: true
            });
        }
    };

    const save = async (formValues: PropertiesForm): Promise<Property[]> => {
        return factorPropertiesService.create({
            pathParams: {
                buildingId: building.id
            },
            body: formValues,
            doNotUpdateEntities: true
        });
    };

    const onTabChange = (exitingTab: number, enteringTab: number) => {
        if (exitingTab === 0) {
            const postcode = form.getValues('postcode').trim();

            if (postcode !== addressSearch.postcode) {
                searchAddresses(postcode);
            }
        }

        if (exitingTab === 1 && enteringTab === 2) {
            const previousSelectedUniqueCodes = selectedAddresses.map((address) => address.uniqueCode.toString());
            const previousSelectedUniqueCodeSet = new Set(previousSelectedUniqueCodes);

            const selectedUniqueCodes = form.getValues('properties');
            const selectedUniqueCodeSet = new Set(selectedUniqueCodes);

            const selectionHasChanged =
                selectedUniqueCodes.some((uniqueCode) => !previousSelectedUniqueCodeSet.has(uniqueCode)) ||
                previousSelectedUniqueCodes.some((uniqueCode) => !selectedUniqueCodeSet.has(uniqueCode));

            if (selectionHasChanged) {
                const addressSet = addressSearch.results.reduce(
                    (map, address) => map.set(address.uniqueCode.toString(), address),
                    new Map()
                );

                const updatedSelectedAddresses = selectedUniqueCodes.map((uniqueCode) => addressSet.get(uniqueCode));

                const previousSelectedOwners = form
                    .getValues('owners')
                    .reduce((map, owner) => map.set(owner.uniqueCode.toString(), owner), new Map<string, Owner>());

                form.resetField('owners', {
                    defaultValue: updatedSelectedAddresses.map((address) => {
                        return {
                            accountName: '',
                            email: '',
                            uniqueCode: address.uniqueCode.toString()
                        };
                    }),
                    keepDirty: false,
                    keepError: false,
                    keepTouched: false
                });

                const getNewValues = (uniqueCode: string) => {
                    let accountName: string | undefined;
                    let email: string | undefined;

                    const previousSelectedOwner = previousSelectedOwners.get(uniqueCode);

                    if (previousSelectedOwner) {
                        accountName = previousSelectedOwner.accountName;
                        email = previousSelectedOwner.email;
                    } else {
                        const deletedProperty = deletedPropertiesMap.get(uniqueCode);

                        if (deletedProperty) {
                            const deletedAccounts = deletedAccountsMap.get(deletedProperty.id) || [];

                            if (deletedAccounts.length > 0) {
                                const deletedAccount = deletedAccounts[0];
                                accountName = deletedAccount.accountName;
                                email = deletedAccount.email;
                            }
                        }
                    }

                    return { accountName, email };
                };

                updatedSelectedAddresses.forEach((address, index) => {
                    const uniqueCode = address.uniqueCode.toString();
                    const { accountName, email } = getNewValues(uniqueCode);
                    form.setValue(`owners.${index}.accountName`, accountName || '');
                    form.setValue(`owners.${index}.email`, email || '');
                    form.setValue(`owners.${index}.uniqueCode`, uniqueCode);
                });

                setSelectedAddresses(updatedSelectedAddresses);
            }
        }
    };

    return (
        <Wizard<PropertiesForm, Property[]>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'lg'
                }
            }}
            modalContentProps={{
                height: '3xl'
            }}
            onClose={onClose}
            form={form}
            heading={`Add more properties`}
            steps={[
                {
                    heading: 'Search properties',
                    fieldNames: ['buildingId', 'postcode']
                },
                {
                    heading: 'Select properties',
                    fieldNames: ['properties']
                },
                {
                    heading: 'Add property owners',
                    fieldNames: ['owners']
                }
            ]}
            onSaveSuccess={onSaveSuccess}
            onStepChange={onTabChange}
            save={save}
        >
            <WizardTabPanel>
                <TextField
                    formHook={form}
                    formLabel="Postcode"
                    name="postcode"
                    inputProps={{ autoFocus: true }}
                    isRequired
                />
                {showWarning && (
                    <Alert mt={5} status="warning">
                        <AlertIcon />
                        <Box>
                            <AlertTitle>Shared costs configuration</AlertTitle>
                            <AlertDescription fontSize={'sm'}>
                                <Text mb={1}>
                                    Adding additional properties will invalidate the current shared costs configuration.
                                </Text>
                                <Text mb={1}>
                                    Your current configuration will be updated so that all costs are shared equally
                                    between existing and additional properties.
                                </Text>
                                <Text>
                                    You will have to configure shared costs again if you are not sharing costs equally.
                                </Text>
                            </AlertDescription>
                        </Box>
                    </Alert>
                )}
            </WizardTabPanel>
            <WizardTabPanel>
                {addressSearch.status === SearchStatus.SEARCHING && <Text mb={5}>Searching - please wait.</Text>}
                {addressSearch.status === SearchStatus.ERROR && <Text mb={5}>Failed to complete search.</Text>}
                {addressSearch.status === SearchStatus.COMPLETE && addressSearch.results.length > 0 && (
                    <CheckAddressesField
                        name="properties"
                        formHook={form}
                        groupLabel="Properties"
                        addresses={addressSearch.results}
                        disabledValues={existingProperties}
                    />
                )}
                {addressSearch.status === SearchStatus.COMPLETE && addressSearch.results.length === 0 && (
                    <Text mb={5}>Failed to find any properties in {addressSearch.postcode} postcode.</Text>
                )}
            </WizardTabPanel>
            <WizardTabPanel>
                {selectedAddresses.map((address, index) => {
                    const isDisabled = deletedPropertiesMap.has(address.uniqueCode.toString());
                    return (
                        <Box key={address.uniqueCode}>
                            <FormLabel
                                color="#84818A"
                                fontSize={'sm'}
                                mb={1}
                                ml={0.5}
                                htmlFor={`owners.${index}.accountName`}
                            >
                                {formatStreetAddress(address, true)}
                            </FormLabel>
                            <Flex direction={['column', 'row']}>
                                <TextField
                                    formHook={form}
                                    name={`owners.${index}.accountName`}
                                    inputProps={{ mr: [0, 2] }}
                                    isDisabled={isDisabled}
                                    isRequired={true}
                                    placeholder="Owner full name *"
                                />
                                <TextField
                                    formHook={form}
                                    name={`owners.${index}.email`}
                                    isDisabled={isDisabled}
                                    placeholder="Owner email address"
                                />
                            </Flex>
                            <HiddenField formHook={form} name={`owners.${index}.uniqueCode`} />
                        </Box>
                    );
                })}
            </WizardTabPanel>
        </Wizard>
    );
};
