import { Flex, Text } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import { EmptyPageBox, PageLoadingSkeleton, StandardPage } from '../../components';
import {
    ownerAccountBalanceService,
    ownerInvoiceService,
    ownerTransactionService,
    userAccountService
} from '../../services';
import { Invoice, Transaction, UserAccount } from '../../types';
import { createMap, formatStreetAddress } from '../../util';
import { AccountBalanceBadge, AccountingTable } from './components';

export const AccountingPageOwner = () => {
    const [activeAccount] = useObservable(userAccountService.activeEntity$ as Observable<UserAccount>);

    const [invoices] = useObservable(ownerInvoiceService.entities$ as Observable<Invoice[]>);
    const [invoicesLoaded] = useObservable(ownerInvoiceService.loaded$ as Observable<boolean>);

    const [transactions] = useObservable(ownerTransactionService.entities$ as Observable<Transaction[]>);
    const [transactionsLoaded] = useObservable(ownerTransactionService.loaded$ as Observable<boolean>);

    const [invoicesMap, setInvoicesMap] = useState<Map<string, Invoice>>();

    useEffect(() => {
        if (!activeAccount) return;
        const { id: accountId, buildingId } = activeAccount;
        ownerTransactionService.fetch({ params: { accountId, buildingId } });
        ownerInvoiceService
            .fetch({ params: { accountId, buildingId } })
            .then((invoices) => setInvoicesMap(createMap(invoices)));
        ownerAccountBalanceService.fetch({
            params: { buildingId, accountIds: [accountId] }
        });
    }, [activeAccount]);

    if (!transactionsLoaded || !invoicesLoaded) {
        return <PageLoadingSkeleton />;
    }

    if (!transactions || !invoices || !invoicesMap) {
        return <PageLoadingSkeleton />;
    }

    if (!transactions.length) {
        return (
            <EmptyPageBox>
                <Text mt={5} fontWeight={600}>
                    {formatStreetAddress(activeAccount.property)}
                </Text>
                <Text mt={2} textAlign={'center'}>
                    There are no invoices or payments on your account
                </Text>
            </EmptyPageBox>
        );
    }

    return (
        <>
            <StandardPage
                title={
                    <Flex alignItems={'center'}>
                        <Text mr={2}>{formatStreetAddress(activeAccount.property)} - Accounting</Text>
                        <AccountBalanceBadge accountId={activeAccount.id} />
                    </Flex>
                }
            >
                <AccountingTable invoicesMap={invoicesMap} transactions={transactions} showForAccount={activeAccount} />
            </StandardPage>
        </>
    );
};
