import { Box, Container, Input, Text, VStack, useBreakpointValue, useDisclosure, useToast } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Observable } from 'rxjs';
import { Heading2, StandardDivider, StandardPage, WeFactorActionButton } from '../../components';
import { buildingsService, userBuildingsService } from '../../services';
import { Building } from '../../types';
import { updateToast } from '../../util';
import { BuildingSubscriptionSettings } from './BuildingSubscriptionSettings';
import { TransferOwnershipDialog } from './dialogs/TransferOwnershipDialog';

export const SettingsPageFactor = () => {
    const { buildingId } = useParams();

    const [building] = useObservable(userBuildingsService.activeEntity$ as Observable<Building>);

    const [buildingName, setBuildingName] = useState(building.name);

    const toast = useToast();

    const canTransfer = useMemo(
        () => !building.subscriptionStatus.includes('ACTIVE') || building.subscriptionCancellationRequested,
        [building]
    );

    const transferOwnershipDisclosure = useDisclosure();

    useEffect(() => {
        if (!buildingName || buildingName === building.name) return;

        const timeOutId = setTimeout(async () => {
            updateBuilding({
                name: buildingName
            });
        }, 1000);

        return () => clearTimeout(timeOutId);
    }, [buildingName]);

    const pageHeader = useBreakpointValue({
        base: 'Settings',
        md: `Settings: ${building?.name}`
    });

    if (!building) return null;

    const updateBuilding = async (body: any) => {
        await updateToast(toast, async () => {
            const building = await buildingsService.update({
                pathParams: {
                    id: buildingId
                },
                body: body
            });
            userBuildingsService.updateEntity(building.id, building);
        });
    };

    return (
        <StandardPage title={pageHeader}>
            <Container>
                <VStack divider={<StandardDivider />} gap={2}>
                    <Box width={'100%'}>
                        <Heading2>Building name</Heading2>
                        <Text marginBottom={2}>
                            This will appear on statements and emails so should make sense to the owners. We suggest
                            using the building name or street address.
                        </Text>
                        <Input
                            maxWidth="500px"
                            onChange={(event) => {
                                setBuildingName(event.target.value);
                            }}
                            defaultValue={building.name}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <Heading2>Subscription</Heading2>
                        <BuildingSubscriptionSettings building={building} />
                    </Box>
                    <Box width={'100%'}>
                        <Heading2>Transfer admin rights</Heading2>
                        {canTransfer ? (
                            <>
                                <Text marginBottom={2}>
                                    You can transfer admin rights to another person if you would like someone else to
                                    take over the management of this building/estate.
                                </Text>
                                <Text>
                                    This action cannot be reversed unless the other person transfers the admin rights
                                    back to you.
                                </Text>
                            </>
                        ) : (
                            <Text marginBottom={2}>
                                The subscription for this building must be cancelled before admin rights can be
                                transferred to another user.
                            </Text>
                        )}
                        <WeFactorActionButton
                            onClick={transferOwnershipDisclosure.onOpen}
                            isDisabled={!canTransfer}
                            mt={2}
                        >
                            Transfer admin rights
                        </WeFactorActionButton>
                    </Box>
                    {transferOwnershipDisclosure.isOpen && (
                        <TransferOwnershipDialog disclosure={transferOwnershipDisclosure} building={building} />
                    )}
                </VStack>
            </Container>
        </StandardPage>
    );
};
