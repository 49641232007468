import { UseDisclosureReturn } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import { PageLoadingModal } from '../../../components';
import {
    correspondenceAccountsService,
    correspondenceDocumentsService,
    factorDivisionVersionsService,
    requestService
} from '../../../services';
import {
    Accounts,
    Building,
    CommonRepairQuote,
    Correspondence,
    CorrespondenceType,
    DivisionVersionWithDivisions,
    Properties,
    SharedCosts
} from '../../../types';
import { CorrespondenceViewForLetter } from './CorrespondenceViewForLetter';
import { CorrespondenceViewForQuote } from './CorrespondenceViewForQuote';
import { CorrespondenceViewForSharedCosts } from './CorrespondenceViewForSharedCosts';

export const CorrespondenceView = (props: {
    accounts: Accounts;
    building: Building;
    correspondenceId: string;
    correspondenceType: string;
    disclosure: UseDisclosureReturn;
    properties: Properties;
}) => {
    const { accounts, building, correspondenceId, correspondenceType, properties, disclosure } = props;

    const [correspondence, setCorrespondence] = useState<Correspondence>();

    const [correspondenceAccounts] = useObservable(correspondenceAccountsService.entities$);
    const [correspondenceAccountsLoaded] = useObservable(correspondenceAccountsService.loaded$);

    const [correspondenceDocuments] = useObservable(correspondenceDocumentsService.entities$);
    const [correspondenceDocumentsLoaded] = useObservable(correspondenceDocumentsService.loaded$);

    const [divisionVersions] = useObservable(
        factorDivisionVersionsService.entities$ as Observable<DivisionVersionWithDivisions[]>
    );
    const [divisionVersionsLoaded] = useObservable(factorDivisionVersionsService.loaded$);

    useEffect(() => {
        requestService
            .get<Correspondence>(
                `buildings/${building.id}/correspondences/${correspondenceId}?correspondenceType=${correspondenceType}`
            )
            .then((response) => setCorrespondence(response.data));

        correspondenceAccountsService.fetch({
            pathParams: {
                buildingId: building.id,
                correspondenceId: correspondenceId
            }
        });

        correspondenceDocumentsService.fetch({
            pathParams: {
                buildingId: building.id,
                correspondenceId: correspondenceId
            }
        });

        factorDivisionVersionsService.fetch({
            pathParams: {
                buildingId: building.id
            }
        });

        return () => {
            setCorrespondence(undefined);
            correspondenceAccountsService.removeAllEntities();
            correspondenceDocumentsService.removeAllEntities();
            factorDivisionVersionsService.removeAllEntities();
        };
    }, [building.id, correspondenceId, correspondenceType]);

    const onClose = () => {
        correspondenceAccountsService.removeAllEntities();
        correspondenceDocumentsService.removeAllEntities();
    };

    if (
        !correspondence ||
        !correspondenceAccounts ||
        !correspondenceAccountsLoaded ||
        !correspondenceDocuments ||
        !correspondenceDocumentsLoaded ||
        !divisionVersions.length ||
        !divisionVersionsLoaded
    ) {
        return <PageLoadingModal />;
    }

    const getViewContents = () => {
        switch (correspondence.type) {
            case CorrespondenceType.COMMON_REPAIR_QUOTE:
                return (
                    <CorrespondenceViewForQuote
                        accounts={accounts}
                        building={building}
                        correspondence={correspondence as CommonRepairQuote}
                        correspondenceAccounts={correspondenceAccounts}
                        divisionVersions={divisionVersions}
                        documents={correspondenceDocuments}
                        properties={properties}
                        standardModalProps={{
                            disclosure: disclosure,
                            onClose: onClose
                        }}
                    />
                );
            case CorrespondenceType.LETTER:
                return (
                    <CorrespondenceViewForLetter
                        accounts={accounts}
                        building={building}
                        correspondence={correspondence}
                        correspondenceAccounts={correspondenceAccounts}
                        documents={correspondenceDocuments}
                        properties={properties}
                        standardModalProps={{
                            disclosure: disclosure,
                            onClose: onClose
                        }}
                    />
                );
            case CorrespondenceType.SHARED_COSTS:
                return (
                    <CorrespondenceViewForSharedCosts
                        accounts={accounts}
                        building={building}
                        correspondence={correspondence as SharedCosts}
                        correspondenceAccounts={correspondenceAccounts}
                        divisionVersions={divisionVersions}
                        documents={correspondenceDocuments}
                        properties={properties}
                        standardModalProps={{
                            disclosure: disclosure,
                            onClose: onClose
                        }}
                    />
                );
            default: {
                return <></>;
            }
        }
    };

    return getViewContents();
};
