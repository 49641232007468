import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface SideBarProps extends PropsWithChildren {
    header: string;
    isOpen: boolean;
    onClose: () => void;
}
export const SideBar = (props: SideBarProps) => {
    const { header, isOpen, onClose, children } = props;

    return (
        <Drawer
            isFullHeight={true}
            isOpen={isOpen}
            onClose={onClose}
            placement={'right'}
            size={['full', 'sm']}
            variant={'contentSideBar'}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton onClick={onClose} />
                <DrawerHeader>{header}</DrawerHeader>
                <DrawerBody padding={0}>{children}</DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
