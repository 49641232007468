export const fileUploadService = (() => {
    const createBufferFromFile = async (file: any): Promise<any> => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = (event: any) => {
                return resolve(event.target.result);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const convertToUploadableBlob = (arrayBuffer: any, type: string): Blob => {
        return new Blob([new Uint8Array(arrayBuffer)], { type });
    };

    const upload = async (signedUrl: string, file: File): Promise<any> => {
        const imageBuffer = await createBufferFromFile(file);
        const imageBlob = convertToUploadableBlob(imageBuffer, file.type);

        return await fetch(signedUrl, {
            method: 'PUT',
            body: imageBlob,
            headers: {
                'Content-Type': file.type
            }
        });
    };

    return {
        upload
    };
})();
