import { useObservable } from '@ngneat/react-rxjs';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userAccountService, userBuildingsService } from '../services';

export const RootUrlGuard = () => {
    const location = useLocation();

    const [buildings] = useObservable(userBuildingsService.entities$);
    const [userAccounts] = useObservable(userAccountService.entities$);

    if (location.pathname === '' || location.pathname === '/') {
        if (buildings.length) {
            const buildingId = buildings[0].id;
            return <Navigate to={`/buildings/${buildingId}/properties`} />;
        }

        if (userAccounts.length) {
            const accountId = userAccounts[0].id;
            return <Navigate to={`/accounts/${accountId}/accounting`} />;
        }
    }

    return <Outlet />;
};
