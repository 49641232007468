import {
    Container,
    Divider,
    Link,
    MenuGroup,
    Text,
    VStack,
    useBreakpointValue,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useRef, useState } from 'react';
import {
    MdOutlineDownload,
    MdOutlineMail,
    MdOutlineReceipt,
    MdOutlineSavings,
    MdOutlineSettings,
    MdOutlineTune,
    MdOutlineUndo
} from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { map } from 'rxjs';
import {
    ActionMenuItem,
    DeleteDialog,
    Heading2,
    Heading3,
    HelpKey,
    PageLoadingSkeleton,
    StandardPage
} from '../../components';
import {
    factorAccountsService,
    factorDivisionVersionsService,
    factorInvoiceService,
    factorPropertiesService,
    factorTransactionService,
    userBuildingsService
} from '../../services';
import { Accounts, Invoice, Properties, Transaction, TransactionType, getTransactionTypeName } from '../../types';
import { createMap, deleteToast } from '../../util';
import { AccountingTable, StatementDueBadge } from './components';
import {
    DivisionVersionWizard,
    DownloadStatementsDialog,
    InvoiceView,
    InvoiceWizard,
    SendStatementsDialog,
    TransactionView,
    TransactionWizard
} from './dialogs';

export const AccountingPageFactor = () => {
    const { buildingId } = useParams();

    const deleteInvoiceDisclosure = useDisclosure();
    const deleteTransactionDisclosure = useDisclosure();
    const downloadStatementDisclosure = useDisclosure();
    const editInvoiceDisclosure = useDisclosure();
    const editSharedCostsDisclosure = useDisclosure();
    const editTransactionDisclosure = useDisclosure();
    const sendStatementDisclosure = useDisclosure();
    const viewInvoiceDisclosure = useDisclosure();
    const viewTransactionDisclosure = useDisclosure();

    const [building] = useObservable(userBuildingsService.activeEntity$);

    const [divisionVersions] = useObservable(factorDivisionVersionsService.entities$);
    const [divisionVersionsLoaded] = useObservable(factorDivisionVersionsService.loaded$);

    const [invoice] = useObservable(factorInvoiceService.activeEntity$);
    const [invoices] = useObservable(factorInvoiceService.entities$);
    const [invoicesLoaded] = useObservable(factorInvoiceService.loaded$);

    const [transaction] = useObservable(factorTransactionService.activeEntity$);
    const [transactions] = useObservable(
        factorTransactionService.entities$.pipe(
            map((transactions) =>
                transactions.sort(
                    (transaction1, transaction2) =>
                        new Date(transaction2.date).getTime() - new Date(transaction1.date).getTime()
                )
            )
        )
    );
    const [transactionsLoaded] = useObservable(factorTransactionService.loaded$);

    const [accounts, setAccounts] = useState<Accounts>();
    const [properties, setProperties] = useState<Properties>();
    const [invoicesMap, setInvoicesMap] = useState<Map<string, Invoice>>();

    const pageHeader = useBreakpointValue({
        base: 'Accounting',
        md: `Accounting: ${building?.name}`
    });

    const addTransactionType = useRef<TransactionType>();

    const toast = useToast();

    useEffect(() => {
        if (!building) return;
        const { id: buildingId } = building;
        factorDivisionVersionsService.fetch({ pathParams: { buildingId } });
        factorInvoiceService.fetch({ pathParams: { buildingId } });
        factorTransactionService.fetch({ pathParams: { buildingId } });
        factorAccountsService.fetch({ pathParams: { buildingId } }).then((accounts) => {
            setAccounts(new Accounts(accounts));
        });
        factorPropertiesService.fetch({ pathParams: { buildingId } }).then((properties) => {
            setProperties(new Properties(properties));
        });
    }, [building]);

    useEffect(() => {
        setInvoicesMap(createMap(invoices));
    }, [invoices]);

    if (
        !building ||
        !accounts ||
        !properties ||
        !buildingId ||
        !divisionVersionsLoaded ||
        !invoicesLoaded ||
        !transactionsLoaded ||
        !divisionVersions ||
        !properties ||
        !transactions ||
        !invoicesMap
    ) {
        return <PageLoadingSkeleton />;
    }

    const transactionDeleted = (transaction: Transaction) => {
        factorTransactionService.removeEntities(transaction.id);
    };

    const deleteInvoice = async (invoiceToDelete: Invoice) => {
        let deleted = false;

        if (invoiceToDelete) {
            await deleteToast(toast, async () => {
                await factorInvoiceService.delete({
                    id: invoiceToDelete.id,
                    pathParams: { buildingId: buildingId, id: invoiceToDelete.id }
                });

                transactionDeleted(invoiceToDelete.transaction);

                deleted = true;
            });
        }

        return deleted;
    };

    const deleteTransaction = async (transactionToDelete: Transaction) => {
        let deleted = false;

        if (transactionToDelete) {
            await deleteToast(toast, async () => {
                await factorTransactionService.delete({
                    id: transactionToDelete.id,
                    pathParams: {
                        buildingId: buildingId,
                        id: transactionToDelete.id
                    }
                });

                transactionDeleted(transactionToDelete);

                deleted = true;
            });
        }

        return deleted;
    };

    const onDeleteInvoice = (invoice: Invoice) => {
        factorInvoiceService.setActiveEntityId(invoice.id);
        deleteInvoiceDisclosure.onOpen();
    };

    const onDeleteTransaction = (transaction: Transaction) => {
        factorTransactionService.setActiveEntityId(transaction.id);
        deleteTransactionDisclosure.onOpen();
    };

    const onEditInvoice = (invoice: Invoice) => {
        factorInvoiceService.setActiveEntityId(invoice.id);
        editInvoiceDisclosure.onOpen();
    };

    const onEditTransaction = (transaction: Transaction) => {
        factorTransactionService.setActiveEntityId(transaction.id);
        editTransactionDisclosure.onOpen();
    };

    const onAddTransaction = (event: any, selectedTransactionType: TransactionType) => {
        event.preventDefault();
        addTransactionType.current = selectedTransactionType;
        editTransactionDisclosure.onOpen();
    };

    return (
        <>
            <StandardPage
                helpKey={HelpKey.ACCOUNTING}
                actions={
                    <>
                        <MenuGroup title="Record transactions">
                            <ActionMenuItem
                                menuItemProps={{
                                    icon: <MdOutlineReceipt />,
                                    onClick: editInvoiceDisclosure.onOpen
                                }}
                            >
                                New charge
                            </ActionMenuItem>
                            <ActionMenuItem
                                menuItemProps={{
                                    icon: <MdOutlineSavings />,
                                    onClick: (event: React.MouseEvent<HTMLElement>) =>
                                        onAddTransaction(event, TransactionType.PAYMENT)
                                }}
                            >
                                New payment
                            </ActionMenuItem>
                            <ActionMenuItem
                                menuItemProps={{
                                    icon: <MdOutlineUndo />,
                                    onClick: (event: React.MouseEvent<HTMLElement>) =>
                                        onAddTransaction(event, TransactionType.REFUND)
                                }}
                            >
                                New refund
                            </ActionMenuItem>
                        </MenuGroup>
                        <Divider />
                        <MenuGroup title="Statements">
                            <ActionMenuItem
                                menuItemProps={{
                                    icon: <MdOutlineDownload />,
                                    onClick: downloadStatementDisclosure.onOpen
                                }}
                            >
                                Download statement
                            </ActionMenuItem>
                            <ActionMenuItem
                                menuItemProps={{
                                    icon: <MdOutlineMail />,
                                    onClick: sendStatementDisclosure.onOpen
                                }}
                            >
                                Send statements
                            </ActionMenuItem>
                        </MenuGroup>
                        <MenuGroup title="Getting started">
                            <ActionMenuItem
                                menuItemProps={{
                                    icon: <MdOutlineTune />,
                                    onClick: (event: React.MouseEvent<HTMLElement>) =>
                                        onAddTransaction(event, TransactionType.ADJUSTMENT)
                                }}
                            >
                                Adjust account balance
                            </ActionMenuItem>
                            <ActionMenuItem
                                menuItemProps={{
                                    icon: <MdOutlineSettings />,
                                    onClick: editSharedCostsDisclosure.onOpen
                                }}
                            >
                                Configure cost sharing
                            </ActionMenuItem>
                        </MenuGroup>
                    </>
                }
                title={pageHeader}
            >
                {transactions.length === 0 ? (
                    <Container>
                        <Heading2>Getting started with accounting</Heading2>
                        <Text mb={2}>
                            Your first steps should be to define how common or mutual costs are shared and (if
                            applicable) set the starting balances for each account.
                        </Text>
                        <Heading3>1. Define how common or mutual costs are shared</Heading3>
                        <Text mb={2}>
                            The rules for how common or mutual costs are shared between properties in Scotland is set
                            out in The Tenements (Scotland) Act.
                        </Text>
                        <Text mb={2}>
                            There are multiple approaches to sharing common or mutual costs and it is incumbent on you
                            to select the appropriate one.
                        </Text>
                        <Text mb={2}>
                            The website{' '}
                            <Link
                                color="blue.500"
                                href="https://underoneroof.scot/articles/1087/Property_management_rules/Sharing_repair_costs"
                                target="_blank"
                            >
                                underoneroof.scot
                            </Link>{' '}
                            is a useful resource that gives detailed information on how to work out which sharing method
                            applies to your building.
                        </Text>
                        <Text mb={2}>
                            When ready select "Configure cost sharing" from the "Actions" button menu to specify how
                            costs should be shared.
                        </Text>
                        <Heading3>2. Setting starting balances</Heading3>
                        <Text mb={2}>
                            If accounts have pre-existing non-zero balances then these will need to be specified so as
                            to reflect their current balances.
                        </Text>
                        <Text>
                            Select "Adjust balance" from the "Actions" button menu to set a positive or negative
                            starting balance for each account.
                        </Text>
                    </Container>
                ) : (
                    <VStack>
                        <StatementDueBadge building={building} />
                        <AccountingTable
                            accounts={accounts}
                            properties={properties}
                            invoicesMap={invoicesMap}
                            transactions={transactions}
                            onEditAccountTransaction={onEditTransaction}
                            onDeleteAccountTransaction={onDeleteTransaction}
                            onViewAccountTransaction={(transaction) => {
                                factorTransactionService.setActiveEntityId(transaction.id);
                                viewTransactionDisclosure.onOpen();
                            }}
                            onEditInvoice={onEditInvoice}
                            onDeleteInvoice={onDeleteInvoice}
                            onViewInvoice={(invoice) => {
                                factorInvoiceService.setActiveEntityId(invoice.id);
                                viewInvoiceDisclosure.onOpen();
                            }}
                        />
                    </VStack>
                )}
            </StandardPage>

            {viewInvoiceDisclosure.isOpen && invoice && (
                <InvoiceView
                    invoice={invoice}
                    disclosure={viewInvoiceDisclosure}
                    divisionVersions={divisionVersions}
                    accounts={accounts}
                    properties={properties}
                    onClose={factorInvoiceService.clearActiveEntity}
                />
            )}

            {editInvoiceDisclosure.isOpen && (
                <InvoiceWizard
                    invoice={invoice}
                    building={building}
                    disclosure={editInvoiceDisclosure}
                    accounts={accounts}
                    properties={properties}
                    divisionVersions={divisionVersions}
                    onClose={factorInvoiceService.clearActiveEntity}
                />
            )}

            {viewTransactionDisclosure.isOpen && transaction && (
                <TransactionView
                    disclosure={viewTransactionDisclosure}
                    accounts={accounts}
                    properties={properties}
                    transaction={transaction}
                    onClose={factorTransactionService.clearActiveEntity}
                />
            )}

            {editTransactionDisclosure.isOpen && (
                <TransactionWizard
                    accounts={accounts}
                    building={building}
                    disclosure={editTransactionDisclosure}
                    properties={properties}
                    transaction={transaction}
                    transactionType={addTransactionType.current}
                    onClose={factorTransactionService.clearActiveEntity}
                />
            )}

            {deleteInvoiceDisclosure.isOpen && invoice && (
                <DeleteDialog
                    disclosure={deleteInvoiceDisclosure}
                    title="Delete charges"
                    onClose={factorInvoiceService.clearActiveEntity}
                    onConfirm={() => deleteInvoice(invoice)}
                />
            )}

            {deleteTransactionDisclosure.isOpen && transaction && transaction.accountId && (
                <DeleteDialog
                    disclosure={deleteTransactionDisclosure}
                    title={`Delete ${getTransactionTypeName(transaction.type).toLowerCase()}`}
                    detail={`Delete ${getTransactionTypeName(transaction.type).toLowerCase()} from ${
                        accounts.getAccount(transaction.accountId)?.accountName
                    }'s account.`}
                    onClose={factorTransactionService.clearActiveEntity}
                    onConfirm={() => deleteTransaction(transaction)}
                />
            )}

            {downloadStatementDisclosure.isOpen && (
                <DownloadStatementsDialog
                    accounts={accounts}
                    building={building}
                    disclosure={downloadStatementDisclosure}
                    properties={properties}
                />
            )}

            {sendStatementDisclosure.isOpen && (
                <SendStatementsDialog building={building} disclosure={sendStatementDisclosure} />
            )}

            {editSharedCostsDisclosure.isOpen && (
                <DivisionVersionWizard
                    building={building}
                    disclosure={editSharedCostsDisclosure}
                    divisionVersions={divisionVersions}
                    properties={properties.getActiveProperties()}
                    onClose={(divisionVersion) => {
                        if (divisionVersion) {
                            factorDivisionVersionsService.fetch({
                                pathParams: {
                                    buildingId: building.id
                                }
                            });
                        }
                    }}
                />
            )}
        </>
    );
};
