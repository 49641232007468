import { BadgeProps, Flex, Icon, Text } from '@chakra-ui/react';
import { MdOutlineEdit, MdOutlineVisibility } from 'react-icons/md';
import { StandardBadge } from '../../../components';
import { ForumBoard } from '../../../types';

export const AccessReadBadge = (props: { forumBoard: ForumBoard; badgeProps?: BadgeProps }) => {
    const { forumBoard, badgeProps } = props;

    const { colorScheme, text } = (() => {
        return forumBoard.buildingId
            ? {
                  colorScheme: 'red',
                  text: 'Owners from building'
              }
            : {
                  colorScheme: 'green',
                  text: 'Public'
              };
    })();

    return (
        <StandardBadge colorScheme={colorScheme} {...badgeProps}>
            <Flex alignItems={'center'}>
                <Icon as={MdOutlineVisibility} />
                <Text marginLeft={1}>{text}</Text>
            </Flex>
        </StandardBadge>
    );
};

export const AccessWriteBadge = (props: { forumBoard: ForumBoard; badgeProps?: BadgeProps }) => {
    const { forumBoard, badgeProps } = props;

    const { colorScheme, text } = (() => {
        switch (forumBoard.accessCreatePost) {
            case 'ADMINS':
                return {
                    colorScheme: 'yellow',
                    text: 'Admin'
                };
            case 'SUBSCRIBERS':
            case 'USERS':
                return forumBoard.buildingId
                    ? {
                          colorScheme: 'red',
                          text: 'Owners from building'
                      }
                    : {
                          colorScheme: 'green',
                          text: 'WeFactor users'
                      };
        }
    })();

    return (
        <StandardBadge colorScheme={colorScheme} {...badgeProps}>
            <Flex alignItems={'center'}>
                <Icon as={MdOutlineEdit} />
                <Text marginLeft={1}>{text}</Text>
            </Flex>
        </StandardBadge>
    );
};
