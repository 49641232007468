import { Flex, Spacer, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextEditor, WeFactorActionButton, WeFactorSecondaryButton } from '../../../components';
import { forumMessageService } from '../../../services';
import { ForumMessage, ForumPost } from '../../../types';
import { createToast } from '../../../util';

type ForumMessageForm = {
    body: string;
};

export const EditForumMessage = (props: {
    buildingId: string;
    forumPost: ForumPost;
    forumMessage: ForumMessage;
    onClose: (updated: boolean) => void;
}) => {
    const { buildingId, forumMessage, forumPost, onClose } = props;

    const [submitting, setIsSubmitting] = useState(false);

    const toast = useToast();

    const form = useForm<ForumMessageForm>({
        defaultValues: { body: forumMessage.body },
        resolver: joiResolver(Joi.object({ body: Joi.string().label('message').trim().min(3).max(20000).required() })),
        reValidateMode: 'onSubmit'
    });

    const { handleSubmit } = form;

    const updateForumMessage = async (formValues: ForumMessageForm) => {
        setIsSubmitting(true);

        await createToast(toast, async () => {
            await forumMessageService.update({
                pathParams: {
                    buildingId: buildingId,
                    forumPostId: forumPost.id,
                    id: forumMessage.id
                },
                body: formValues
            });

            onClose(true);
        });

        setIsSubmitting(false);
    };

    return (
        <>
            <form id="response-form" autoComplete="off">
                <TextEditor name="body" formHook={form} isRequired={true} />
            </form>
            <Flex>
                <WeFactorSecondaryButton isDisabled={submitting} onClick={() => onClose(false)} minWidth={'100px'}>
                    Cancel
                </WeFactorSecondaryButton>
                <Spacer />
                <WeFactorActionButton
                    isDisabled={submitting}
                    onClick={handleSubmit(updateForumMessage)}
                    minWidth={'100px'}
                >
                    Update
                </WeFactorActionButton>
            </Flex>
        </>
    );
};
