import {
    Button,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuButtonProps,
    MenuItem,
    MenuItemProps,
    MenuList,
    MenuProps
} from '@chakra-ui/react';
import { MdOutlineMenu, MdOutlineMenuOpen } from 'react-icons/md';
import { COLOURS } from '../skeletons/constants';

export interface MenuOption {
    menuItemProps?: MenuItemProps;
    text: string;
}

export const ActionMenu = (props: { children: any; menuProps?: MenuProps; menuButtonProps?: MenuButtonProps }) => {
    const { children, menuButtonProps, menuProps } = props;
    return (
        <Menu {...menuProps}>
            <ActionMenuButton {...menuButtonProps} />
            <MenuList>{children}</MenuList>
        </Menu>
    );
};

export const ActionMenuButton = (props: { menuButtonProps?: MenuButtonProps }) => {
    return (
        <MenuButton
            as={Button}
            backgroundColor={COLOURS.BLUE_2}
            color={COLOURS.WHITE}
            _active={{ backgroundColor: COLOURS.BLUE_2 }}
            _hover={{ backgroundColor: COLOURS.GREEN }}
            {...props.menuButtonProps}
        >
            Actions
        </MenuButton>
    );
};

export const ActionMenuItem = (props: { children: any; menuItemProps?: MenuItemProps }) => {
    return (
        <MenuItem paddingX={4} paddingY={2} {...props.menuItemProps}>
            {props.children}
        </MenuItem>
    );
};

export const TableMenu = (props: { children: any; menuProps?: MenuProps; menuButtonProps?: MenuButtonProps }) => {
    return (
        <Menu {...props.menuProps}>
            {(renderProps) => (
                <>
                    <TableMenuButton isOpen={renderProps.isOpen} {...props.menuButtonProps} />
                    <MenuList>{props.children}</MenuList>
                </>
            )}
        </Menu>
    );
};

export const TableMenuButton = (props: { isOpen: boolean; menuButtonProps?: MenuButtonProps }) => {
    return (
        <MenuButton
            as={IconButton}
            aria-label={props['aria-label']}
            icon={<Icon as={props.isOpen ? MdOutlineMenuOpen : MdOutlineMenu} />}
            variant={'outline'}
            {...props.menuButtonProps}
        />
    );
};

export const TableMenuItem = (props: { children: any; menuItemProps?: MenuItemProps }) => {
    return (
        <MenuItem paddingX={4} paddingY={2} {...props.menuItemProps}>
            {props.children}
        </MenuItem>
    );
};
