import { Alert, AlertDescription, AlertIcon, Flex, Spacer, Text, useToast } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { StandardModal, StandardModalProps } from '../../../components';
import { PremiumFeatureAlert, WeFactorActionButton, WeFactorSecondaryButton } from '../../../components/shared';
import { factorStatementService, userBuildingsService } from '../../../services';
import { Building } from '../../../types';
import { convertStringToDateTime, formatMonthYear, genericToast, lastDayOfPreviousMonth } from '../../../util';

interface SendStatementsDialogProps extends StandardModalProps {
    building: Building;
}

export const SendStatementsDialog = (props: SendStatementsDialogProps) => {
    const { building, disclosure, onClose } = props;

    const hasActiveSubscription = useMemo(() => building.subscriptionStatus.includes('ACTIVE'), [building]);

    const toast = useToast();

    const [sending, setIsSending] = useState(false);

    const requestStatement = async () => {
        try {
            await genericToast(
                toast,
                async () => {
                    setIsSending(true);

                    const updatedBuilding = await factorStatementService.create<Building>({
                        pathParams: {
                            buildingId: building.id
                        },
                        pathSuffix: 'send-statements'
                    });

                    userBuildingsService.updateEntity(updatedBuilding.id, updatedBuilding);
                },
                {
                    title: 'Sending statements',
                    onSuccessTitle: 'Complete',
                    onErrorTitle: 'Failed to send statements'
                }
            );

            disclosure.onClose();
        } catch (error) {
            console.error('Failed to send statements', error);
        } finally {
            setIsSending(false);
        }
    };

    const toDateString = formatMonthYear(lastDayOfPreviousMonth());

    const getText = () => {
        if (building.lastStatementDate) {
            const fromDateString = formatMonthYear(
                convertStringToDateTime(building.lastStatementDate).plus({ days: 1 }).toJSDate()
            );

            return toDateString === fromDateString ? (
                <Text>
                    This will send statements to all applicable accounts for the month of
                    {toDateString}.
                </Text>
            ) : (
                <Text>
                    This will send statements to all applicable accounts for the period of
                    {fromDateString} to {toDateString}.
                </Text>
            );
        } else {
            return (
                <Text>
                    This will send statements to all applicable accounts for the period up to and including{' '}
                    {toDateString}.
                </Text>
            );
        }
    };

    const isActionDisabled = !hasActiveSubscription || !building.canSendNextStatement || sending;

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'sm'
                }
            }}
            modalContentProps={{
                height: 'xl'
            }}
            onClose={onClose}
            header={'Send statements'}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton isDisabled={sending} onClick={disclosure.onClose}>
                        Cancel
                    </WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorActionButton isDisabled={isActionDisabled} isLoading={sending} onClick={requestStatement}>
                        Send statements
                    </WeFactorActionButton>
                </Flex>
            }
        >
            {hasActiveSubscription ? (
                <>
                    {building.canSendNextStatement ? (
                        <>
                            {getText()}
                            <Text my={2}>
                                Applicable accounts have valid email addresses and recorded transactions within the date
                                range.
                            </Text>
                            <Text mb={2}>
                                Be sure that all transactions have been recorded and email addresses added before
                                clicking on "Send statements".
                            </Text>
                            <Text>
                                You can send transactions on an individual account basis from the account sidebar on the
                                Properties page.
                            </Text>
                        </>
                    ) : (
                        <>
                            <Alert status="warning" mb={2}>
                                <AlertIcon />
                                <AlertDescription fontSize={'sm'}>
                                    Statements have already been sent for {toDateString}. The next set of statements can
                                    be sent after the start of next calendar month.
                                </AlertDescription>
                            </Alert>
                            <Alert status="info" mb={2}>
                                <AlertIcon />
                                <AlertDescription fontSize={'sm'}>
                                    You can send statements for individual accounts from the sidebar of the Properties
                                    page after selecting a property.
                                </AlertDescription>
                            </Alert>
                        </>
                    )}
                </>
            ) : (
                <PremiumFeatureAlert
                    subtext="Alternatively you can download statements to send from your personal
            email account or print for delivery."
                />
            )}
        </StandardModal>
    );
};
