import { authenticatedUserService } from '../authenticated-user';
import { Config } from '../config/config.model';
import { notificationsService } from '../entities';

export const websocketService = (() => {
    let ws: WebSocket | null;
    let updatesEndpoint: string;
    let interval: any;

    const init = (config: Config) => {
        updatesEndpoint = config.WS_BASE_URL;

        connect()
            .then()
            .catch((err) => {
                console.error('Failed to connect to websocket service');
            });
    };

    const connect = async () => {
        if (!updatesEndpoint) {
            throw new Error('websocket endpoint not configured, please initialize service with config');
        }

        ws = new WebSocket(`${updatesEndpoint}?idToken=${await authenticatedUserService.getIdToken()}`);

        ws.onopen = (e) => {
            interval = setInterval(() => {
                if (ws) {
                    ws.send(JSON.stringify({ action: 'ping' }));
                }
            }, 60000);
        };

        ws.onmessage = (e) => {
            const { type, data } = JSON.parse(e.data);
            if (!type || !data) return;
            if (type === 'NOTIFICATION') notificationsService.upsertEntities(data);
        };

        ws.onclose = (e) => {
            if (interval) {
                clearInterval(interval);
            }

            // attempt to reconnect when the connection is dropped
            setTimeout(() => {
                connect().then();
            }, 5000);
        };
    };

    const disconnect = () => {
        if (ws) {
            ws.onclose = () => {};

            ws.close();

            ws = null;
        }

        if (interval) {
            clearInterval(interval);
        }
    };

    return {
        init,
        connect,
        disconnect
    };
})();
