import { Td, Tr } from '@chakra-ui/react';

interface GlossaryTermProps {
    word: string;
    description: string;
}
export const GlossaryTerm = (props: GlossaryTermProps) => {
    const { word, description } = props;
    return (
        <Tr>
            <Td>{word}</Td>
            <Td>{description}</Td>
        </Tr>
    );
};
