import { Spinner, Text, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlinePreview } from 'react-icons/md';
import {
    CurrencyField,
    DateField,
    DocumentsField,
    SelectAccountsField,
    SelectShareMethodField,
    TextEditor,
    TextField,
    Wizard,
    WizardTabPanel
} from '../../../components';
import { correspondencesService } from '../../../services';
import { Account, CommonRepairQuote, DivisionMethod, DivisionVersionWithDivisions, ShareMethod } from '../../../types';
import { formatCurrency, formatDate } from '../../../util';
import { CorrespondenceWizardProps } from './CorrespondenceWizard';
import { CorrespondenceActionDispatcher } from './components/CorrespondenceActionDispatcher';
import { CorrespondenceForm, correspondenceSchema } from './components/Joi';
import { ShareCalculator } from './components/ShareCalculator';
import { Templates } from './components/Templates';

type CommonRepairQuoteForm = CorrespondenceForm & {
    amount: number;
    divisionVersionId: string | null;
    responseDeadline: string;
    shareMethod: string;
};

const commonRepairQuoteSchema = correspondenceSchema.keys({
    amount: Joi.number().label('Quoted repair costs').precision(2).positive().required(),
    divisionVersionId: Joi.string().uuid().allow(null).optional(),
    responseDeadline: Joi.date()
        .label('Deadline for property owners to respond')
        .messages({
            'date.min': 'Date must be in the future'
        })
        .min(new Date())
        .raw()
        .required(),
    shareMethod: Joi.string()
        .label('How will repair costs be split between property owners?')
        .allow(DivisionMethod)
        .required()
});

interface CorrespondenceWizardForQuoteProps extends CorrespondenceWizardProps {
    divisionVersions: DivisionVersionWithDivisions[];
    onClose?: (correspondence?: CommonRepairQuote) => void;
}

export const CorrespondenceWizardForQuote = (props: CorrespondenceWizardForQuoteProps) => {
    const { accounts, building, disclosure, divisionVersions, properties, onClose } = props;

    const toast = useToast();

    const actionDispatcher = useMemo(() => new CorrespondenceActionDispatcher(), []);

    const { activeDivisionVersion, equalShareDivisionVersion } = useMemo(() => {
        const dvs = divisionVersions;
        return {
            activeDivisionVersion: dvs.find((dv) => dv.active)!,
            equalShareDivisionVersion: dvs.find((dv) => dv.divisionMethod === DivisionMethod.EQUAL_SHARE)!
        };
    }, [divisionVersions]);

    const [shares, setShares] = useState<Map<string, number>>();

    const form = useForm<CommonRepairQuoteForm>({
        defaultValues: {
            accountIds: accounts.getCurrentOwnerAccounts().map((account) => account.id),
            buildingId: building.id,
            body: new Templates().getCommonRepairCostsTemplate(building.name),
            documents: [],
            subject: `Maintenance work at ${building.name}`,
            amount: undefined,
            divisionVersionId: activeDivisionVersion?.id || null,
            responseDeadline: '',
            shareMethod: ''
        },
        resolver: joiResolver(commonRepairQuoteSchema),
        reValidateMode: 'onSubmit'
    });

    const save = async (formValues: any) => {
        return correspondencesService.create<CommonRepairQuote>({
            pathParams: {
                buildingId: building.id
            },
            pathSuffix: 'common-repair-quote',
            body: formValues
        });
    };

    const previewCorrespondence = async (account: Account) => {
        if (shares && shares.has(account.id)) {
            const { amount, body, responseDeadline, subject } = form.getValues();

            actionDispatcher.previewCorrespondence(
                { account },
                {
                    body: body,
                    subject: subject,
                    replacements: [
                        { name: 'deadline', value: formatDate(responseDeadline) },
                        { name: 'owners_share', value: formatCurrency(shares.get(account.id)) },
                        { name: 'total_cost', value: formatCurrency(amount) }
                    ]
                },
                toast
            );
        }
    };

    const accountIds = form.watch('accountIds');
    const amount = form.watch('amount');
    const shareMethod = form.watch('shareMethod');

    useEffect(() => {
        setShares(undefined);

        if (!accountIds || !amount || !shareMethod) {
            return;
        }

        let divisionVersion;

        if (shareMethod === ShareMethod.EQUAL_SHARE) {
            divisionVersion = equalShareDivisionVersion;
        } else if (shareMethod === ShareMethod.RATEABLE) {
            divisionVersion = activeDivisionVersion;
        }

        setShares(
            new ShareCalculator(accounts.getAccounts()).calculateAmountsByDivisionVersion(
                accountIds,
                amount,
                divisionVersion
            )
        );

        form.setValue('divisionVersionId', divisionVersion.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountIds, amount, shareMethod]);

    return (
        <Wizard<CommonRepairQuoteForm, CommonRepairQuote>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: '4xl'
                }
            }}
            modalContentProps={{
                height: '4xl'
            }}
            form={form}
            heading={'Publish maintenance quote letter'}
            isLoading={!divisionVersions}
            save={save}
            saveLabel={'Publish'}
            onSaveSuccess={onClose}
            steps={[
                {
                    heading: 'Quote details',
                    fieldNames: ['subject', 'amount', 'shareMethod', 'responseDeadline', 'buildingId']
                },
                {
                    heading: 'Letter content',
                    fieldNames: ['body']
                },
                {
                    heading: 'Select recipients',
                    fieldNames: ['accountIds']
                },
                {
                    heading: 'Attachments',
                    fieldNames: ['documents']
                }
            ]}
        >
            <WizardTabPanel>
                <TextField formLabel="Letter subject" name="subject" formHook={form} isRequired />
                <CurrencyField name="amount" formHook={form} formLabel="Quoted repair costs" isRequired />
                <SelectShareMethodField
                    name="shareMethod"
                    formHook={form}
                    formLabel="How will repair costs be split between property owners?"
                    isRequired
                    activeDivisionVersion={activeDivisionVersion}
                />
                <DateField
                    name="responseDeadline"
                    formHelperText="It is recommended to give property owners a few weeks to respond to the quote"
                    formHook={form}
                    formLabel="Deadline for property owners to respond"
                    isRequired
                />
            </WizardTabPanel>
            <WizardTabPanel>
                <TextEditor formLabel="Letter content" name="body" formHook={form} isRequired />
            </WizardTabPanel>
            <WizardTabPanel>
                <SelectAccountsField
                    formLabel="Selected recipients"
                    name="accountIds"
                    formHook={form}
                    accounts={accounts}
                    properties={properties}
                    isRequired
                    additionalButton={{
                        buttonIcon: MdOutlinePreview,
                        onClick: (account) => previewCorrespondence(account),
                        tooltip: 'Preview document'
                    }}
                    additionalColumn={{
                        getHeading() {
                            return 'Share';
                        },
                        getContent(account: Account) {
                            const share = shares?.get(account.id);
                            return share ? <Text>{formatCurrency(share)}</Text> : <Spinner size={'xs'} />;
                        }
                    }}
                />
            </WizardTabPanel>
            <WizardTabPanel>
                <DocumentsField building={building} formHook={form} formLabel="Attachments" name="documents" />
            </WizardTabPanel>
            <WizardTabPanel>
                <Text mb={2}>Preview</Text>
            </WizardTabPanel>
        </Wizard>
    );
};
