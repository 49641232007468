import axios, { AxiosResponse } from 'axios';
import { authenticatedUserService } from '../authenticated-user';
import { Config } from '../config';

export const requestServiceFactory = (() => {
    let API_URL: string;

    const init = (config: Config) => {
        API_URL = config.API_BASE_URL + '/';
    };

    const create = () => {
        const resolveResourcePath = (resourcePath: string, params: any) => {
            const replaced = resourcePath.replace(/\{\{(.*?)\}\}/g, (match, token) => {
                return params[token] || null;
            });

            return params.id ? replaced + `/${params.id}` : replaced;
        };

        const get = async <T = any>(
            url: string,
            options?: { body?: any; pathParams?: any; params?: any; auth?: boolean }
        ): Promise<AxiosResponse<T>> => {
            return axios.get<T>(API_URL + resolveResourcePath(url, options?.pathParams || {}), {
                params: options?.params || {},
                data: JSON.stringify(options?.body || {}),
                headers: {
                    Authorization: options?.auth === false ? '' : await authenticatedUserService.getIdToken(),
                    'Content-Type': 'application/json'
                }
            });
        };

        const post = async <T = any>(
            url: string,
            options?: { body?: any; pathParams?: any; params?: any; auth?: boolean }
        ): Promise<AxiosResponse<T>> => {
            return axios.post<T>(
                API_URL + resolveResourcePath(url, options?.pathParams || {}),
                JSON.stringify(options?.body || {}),
                {
                    params: options?.params || {},
                    headers: {
                        Authorization: options?.auth === false ? '' : await authenticatedUserService.getIdToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
        };

        const put = async <T = any>(
            url: string,
            options?: { body?: any; pathParams?: any; params?: any; auth?: boolean }
        ): Promise<AxiosResponse<T>> => {
            return axios.put<T>(
                API_URL + resolveResourcePath(url, options?.pathParams || {}),
                JSON.stringify(options?.body || {}),
                {
                    params: options?.params || {},
                    headers: {
                        Authorization: options?.auth === false ? '' : await authenticatedUserService.getIdToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
        };

        const _delete = async <T = any>(
            url: string,
            options?: { body?: any; pathParams?: any; params?: any; auth?: boolean }
        ): Promise<AxiosResponse<T>> => {
            return axios.delete<T>(API_URL + resolveResourcePath(url, options?.pathParams || {}), {
                params: options?.params || {},
                data: JSON.stringify(options?.body || {}),
                headers: {
                    Authorization: options?.auth === false ? '' : await authenticatedUserService.getIdToken(),
                    'Content-Type': 'application/json'
                }
            });
        };

        return {
            get,
            post,
            put,
            delete: _delete
        };
    };

    return {
        init,
        create
    };
})();

export const requestService = requestServiceFactory.create();
