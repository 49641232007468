import { FieldValues } from 'react-hook-form';
import { Account, Accounts, Properties } from '../../types';
import { formatStreetAddress } from '../../util';
import { BaseSelect, BaseSelectProps } from './BaseSelect';

type SelectAccountFieldProps<T extends FieldValues> = Omit<BaseSelectProps<T>, 'options'> & {
    accounts: Accounts;
    properties?: Properties;
};

export const SelectAccountField = <T extends FieldValues>(props: SelectAccountFieldProps<T>) => {
    const { accounts, properties } = props;

    return (
        <BaseSelect
            options={createAccountOptions(accounts, properties)}
            {...props}
            selectProps={{
                ...props.selectProps,
                closeMenuOnSelect: true,
                isSearchable: true
            }}
        />
    );
};

const createAccountOptions = (accounts: Accounts, properties?: Properties) => {
    const owners = accounts.getActiveAccounts();
    const currentOwners: any[] = [];

    const getLabel = (account: Account) => {
        const property = properties?.getProperty(account.propertyId);
        return property ? `${account.accountName} - ${formatStreetAddress(property)}` : account.accountName;
    };

    for (let i = 0; i < owners.length; i++) {
        const owner: Account = owners[i];
        const ownerOption = {
            label: getLabel(owner),
            value: owner.id
        };
        currentOwners.push(ownerOption);
    }

    return currentOwners;
};
