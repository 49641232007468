import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { User } from '../../types';
import { configService } from '../config';
import { Config } from '../config/config.model';
import { userAccountService, userBuildingsService, usersService } from '../entities';
import { AuthenticatedUser } from './authenticated-user.model';

export const authenticatedUserService = (() => {
    let userPool: CognitoUserPool;

    const store = createStore(
        {
            name: 'auth-service'
        },
        withProps<AuthenticatedUser>({
            authenticatedUser: undefined,
            displayName: undefined,
            email: undefined,
            username: undefined,
            isTrialUsed: false,
            profileImageUrl: undefined
        })
    );

    const user$: Observable<AuthenticatedUser> = store.pipe(
        select((user) => user),
        skipWhile((user) => user === undefined)
    );

    const authenticatedUser$: Observable<CognitoUser | null> = store.pipe(
        select((store) => store.authenticatedUser),
        skipWhile((authenticatedUser) => authenticatedUser === undefined)
    ) as Observable<CognitoUser | null>;

    const init = async (config: Config) => {
        userPool = new CognitoUserPool({
            UserPoolId: config.COGNITO_POOL_ID,
            ClientId: config.COGNITO_CLIENT_ID
        });

        const currentUser = userPool.getCurrentUser();

        if (!currentUser) {
            return await signOut();
        }

        currentUser.getSession(async (err: Error | null, data: CognitoUserSession) => {
            if (err) {
                return await signOut();
            }

            try {
                await refreshSession();
            } catch (error) {
                return await signOut();
            }
        });
    };

    const isLoggedIn = (): boolean => {
        return store.getValue().authenticatedUser != null;
    };

    const signUp = async (displayName: string, password: string, email: string, inviteCode: string): Promise<void> => {
        try {
            const config: Config | Error = (await configService.getConfig()) as any;

            if (!(config instanceof Error)) {
                await axios.post(
                    `${config.API_BASE_URL}/public/cognito/sign-up`,
                    JSON.stringify({ email, displayName, password, inviteCode }),
                    {
                        params: {},
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
            }
        } catch (error: any) {
            if (error.response && error.response.data) {
                throw error.response.data;
            }

            throw new Error();
        }
    };

    const confirmAccount = async (email: string, code: string): Promise<void> => {
        try {
            const config: Config | Error = (await configService.getConfig()) as any;

            if (!(config instanceof Error)) {
                return await axios.post(
                    `${config.API_BASE_URL}/public/cognito/confirm-sign-up`,
                    JSON.stringify({ email, code }),
                    {
                        params: {},
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
            }
        } catch (error: any) {
            console.error(JSON.stringify(error, null, 2));

            if (error.response && error.response.data) {
                console.error(JSON.stringify(error.response.data), null, 2);
                throw error.response.data;
            }

            throw new Error();
        }
    };

    const signIn = async (username: string, password: string): Promise<boolean> => {
        const authenticatedUser = new CognitoUser({
            Username: username,
            Pool: userPool
        });

        return new Promise((res, rej) => {
            authenticatedUser.authenticateUser(new AuthenticationDetails({ Username: username, Password: password }), {
                onSuccess: async (session, confirmRequired) => {
                    if (confirmRequired) {
                        res(true);
                        return;
                    }

                    try {
                        const token = session.getIdToken().getJwtToken();
                        const user = await fetchUserUpdateOrCreate(token);
                        await userAccountService.fetch({ token });
                        await userBuildingsService.fetch({ token });

                        store.update(
                            setProps({
                                authenticatedUser,
                                displayName: user.displayName,
                                username: user.username,
                                email: user.email,
                                profileImageUrl: user.profileImageUrl,
                                isTrialUsed: user.isTrialUsed
                            })
                        );

                        return res(false);
                    } catch (error) {
                        authenticatedUser.signOut();
                        return rej(error);
                    }
                },
                onFailure: (err) => {
                    console.error(err);
                    rej(err);
                }
            });
        });
    };

    const signOut = async (): Promise<void> => {
        const user = userPool.getCurrentUser();

        if (user == null) {
            return store.update(setProp('authenticatedUser', null));
        }

        return new Promise((res) => {
            user.signOut(async () => {
                store.update(setProp('authenticatedUser', null));
                res();
            });
        });
    };

    const resetPassword = async (username: string, verificationCode: string, newPassword: string): Promise<void> => {
        const user = new CognitoUser({
            Username: username,
            Pool: userPool
        });

        return new Promise((res, rej) => {
            user.confirmPassword(verificationCode, newPassword, {
                onSuccess: async () => {
                    await signIn(username, newPassword);
                    res();
                    return;
                },
                onFailure: (err: any) => {
                    rej(err);
                    return;
                }
            });
        });
    };

    const requestPasswordResetCode = async (username: string): Promise<void> => {
        const user = new CognitoUser({
            Username: username,
            Pool: userPool
        });

        return new Promise((res, rej) => {
            user.forgotPassword({
                onSuccess: () => {
                    res();
                    return;
                },
                onFailure: (err: any) => {
                    rej(err);
                    return;
                },
                inputVerificationCode: (data) => {
                    res();
                    return;
                }
            });
        });
    };

    const changeEmail = (newEmail: string) => {
        const user = store.getValue().authenticatedUser;
        if (!user) throw new Error('Invalid User, cannot get token');

        return new Promise((res, rej) => {
            user.updateAttributes(
                [
                    {
                        Name: 'email',
                        Value: newEmail
                    }
                ],
                (err, data) => {
                    if (err) {
                        rej(err);
                        return;
                    }

                    res(true);
                }
            );
        });
    };

    const verifyEmailChange = (code: string) => {
        const user = store.getValue().authenticatedUser;
        if (!user) throw new Error('Invalid User, cannot get token');

        return new Promise((res, rej) => {
            user.verifyAttribute('email', code, {
                onSuccess: () => {
                    res(true);
                    return;
                },
                onFailure: (err) => {
                    rej(err);
                    return;
                }
            });
        });
    };

    const changePassword = (oldPassword: string, newPassword: string) => {
        const user = store.getValue().authenticatedUser;

        if (!user) throw new Error('invalid user, cannot get token');

        return new Promise((res, rej) => {
            user.changePassword(oldPassword, newPassword, (err, data) => {
                if (err) {
                    rej(err);
                    return;
                }

                res(true);
            });
        });
    };

    const getIdToken = async (): Promise<string> => {
        const user = store.getValue().authenticatedUser;

        if (!user) throw new Error('invalid user, cannot get token');

        return new Promise((res) => {
            user.getSession(async (err: Error | null, data: CognitoUserSession) => {
                if (err || !data) {
                    await signOut();
                }

                try {
                    const idToken = data.getIdToken().getJwtToken();
                    res(idToken);
                } catch (error) {
                    await signOut();
                }
            });
        });
    };

    const refreshUser = async () => {
        const authenticatedUser = store.getValue();

        if (!authenticatedUser || !authenticatedUser.username) {
            return await signOut();
        }

        const user: User = await usersService.get({}, authenticatedUser.username);

        store.update(setProps({ ...user }));
    };

    const refreshSession = async () => {
        const authenticatedUser = userPool.getCurrentUser();

        if (!authenticatedUser) {
            return await signOut();
        }

        return new Promise((res, rej) => {
            authenticatedUser.getSession(async (err: Error | null, data: CognitoUserSession) => {
                if (err) {
                    return rej('invalid user session, failed to refresh token');
                }

                const refreshToken = data.getRefreshToken();

                authenticatedUser.refreshSession(refreshToken, async (err, result) => {
                    if (err) {
                        return rej('invalid user session, failed to refresh token');
                    }

                    try {
                        const token = result.idToken.jwtToken;
                        const user = await fetchUserUpdateOrCreate(token);
                        const jwtToken = data.getIdToken().getJwtToken();
                        await userAccountService.fetch({ token: jwtToken });
                        await userBuildingsService.fetch({ token: jwtToken });

                        store.update(
                            setProps({
                                authenticatedUser,
                                displayName: user.displayName,
                                email: user.email,
                                username: user.username,
                                profileImageUrl: user.profileImageUrl,
                                isTrialUsed: user.isTrialUsed
                            })
                        );

                        return res(result);
                    } catch (error) {
                        return rej(error);
                    }
                });
            });
        });
    };

    const getUser = () => {
        return store.getValue() as User;
    };

    const fetchUserUpdateOrCreate = async (token: any): Promise<User> => {
        const decodedToken: any = jwtDecode(token);

        const email = decodedToken['email'];
        const displayName = decodedToken['custom:displayName'];
        const inviteCode = decodedToken['custom:inviteCode'];
        const username = decodedToken['cognito:username'];

        const userResult: User = await usersService.fetch({
            pathParams: { id: username },
            token: token
        });

        let user: User;

        const isUserInSync = (userResult: User): boolean => {
            return email === userResult.email;
        };

        if (userResult.username === null) {
            // if user profile does not exist then create it
            user = await usersService.create({
                token: token,
                body: {
                    email,
                    displayName,
                    username,
                    inviteCode
                }
            });
        } else if (isUserInSync(userResult) === false) {
            // if token does not match user profile then sync it
            user = await usersService.update({
                pathParams: {
                    id: username
                },
                token: token,
                body: {
                    email: email,
                    displayName: userResult.displayName,
                    profileImageUrl: userResult.profileImageUrl
                }
            });
        } else {
            // otherwise simply return userprofile
            user = userResult;
        }

        return user;
    };

    const updateUserDetails = (user: User) => {
        store.update(
            setProps({
                displayName: user.displayName,
                email: user.email,
                isTrialUsed: user.isTrialUsed,
                profileImageUrl: user.profileImageUrl,
                username: user.username
            })
        );
    };

    return {
        user$,
        authenticatedUser$,
        init,
        isLoggedIn,
        signUp,
        signIn,
        signOut,
        confirmAccount,
        resetPassword,
        requestPasswordResetCode,
        changeEmail,
        verifyEmailChange,
        changePassword,
        getIdToken,
        getUser,
        updateUserDetails,
        refreshSession,
        refreshUser
    };
})();
