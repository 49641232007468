import { Link, Text, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { EmailField, PasswordField, TextField, WeFactorSubmitButton } from '../../components';
import { authenticatedUserService } from '../../services';
import { AuthFlex } from './components/AuthFlex';
import { AuthHeader } from './components/AuthHeader';

export const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const passwordMessage =
        'Passwords must be be at least 8 characters and contain a minimum of: 1 lower case ' +
        'letter; 1 upper case letter; 1 numeric character; and 1 special character.';

    const form = useForm({
        defaultValues: {
            email: '',
            code: '',
            password: '',
            confirmPassword: '',
            codeRequested: false
        },
        resolver: joiResolver(
            Joi.object({
                code: Joi.string().min(6).required(),
                email: Joi.string()
                    .email({ tlds: { allow: false } })
                    .required(),
                password: Joi.string().min(6).required(),
                confirmPassword: Joi.string()
                    .valid(Joi.ref('password'))
                    .required()
                    .label('Confirm password')
                    .messages({ 'any.only': '{{#label}} does not match "Password" ' }),
                codeRequested: Joi.boolean().required()
            }).required()
        ),
        reValidateMode: 'onSubmit'
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        formState: { isValid, isSubmitting }
    } = form;

    const codeRequestedValue = getValues('codeRequested');

    const submitCodeRequest = async (event: any) => {
        event.preventDefault();

        const email = getValues('email');

        try {
            await authenticatedUserService.requestPasswordResetCode(email);

            setValue('codeRequested', true, { shouldValidate: true });

            toast({
                title: `Reset verification code has been sent to ${email}`,
                status: 'success',
                duration: 6000,
                isClosable: true,
                position: 'top'
            });
        } catch (error: any) {
            const getErrorMessage = (code) => {
                switch (code) {
                    case 'InvalidParameterException':
                        return 'Please enter a valid email address.';
                    case 'LimitExceededException':
                        return 'Maximum number of attempts reached. Please try again later.';
                    case 'UserNotFoundException':
                        return 'Email address could not be found.';
                    default:
                        console.error(`Unhandled error code: ${code}`);
                        return 'There was an unexpected error. Please contact us if the problem persists.';
                }
            };

            toast({
                description: getErrorMessage(error.code),
                status: 'error',
                duration: 6000,
                isClosable: true,
                position: 'top'
            });
        }
    };

    const submit = async (formValues: any) => {
        const { email, code, password } = formValues;

        try {
            await authenticatedUserService.resetPassword(email, code, password);

            toast({
                title: 'Successfully reset password',
                status: 'success',
                isClosable: true,
                position: 'top'
            });
        } catch (error: any) {
            const getErrorMessage = (code) => {
                switch (code) {
                    case 'CodeMismatchException':
                        return 'Invalid code entered. Please try again or request a new code.';
                    case 'LimitExceededException':
                        return 'Maximum number of attempts reached. Please try again later.';
                    case 'UserNotFoundException':
                        return 'Email address could not be found.';
                    default:
                        console.error(`Unhandled error code: ${code}`);
                        return 'There was an unexpected error. Please contact us if the problem persists.';
                }
            };

            toast({
                description: getErrorMessage(error.code),
                status: 'error',
                duration: 6000,
                isClosable: true,
                position: 'top'
            });
        }
    };

    return (
        <AuthFlex>
            <AuthHeader>Trouble logging in?</AuthHeader>
            <Text mb={4}>Enter your email address and we'll send you a link to reset your password.</Text>
            <form id="sign-in-form" noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
                <EmailField
                    name="email"
                    formHook={form}
                    formLabel="Email address"
                    inputProps={{ autoComplete: 'username' }}
                    isRequired
                />
                <WeFactorSubmitButton
                    disabled={codeRequestedValue === true}
                    onClick={submitCodeRequest}
                    width="100%"
                    mb={2}
                >
                    Send reset link
                </WeFactorSubmitButton>
                {codeRequestedValue && (
                    <div>
                        <TextField
                            name="code"
                            formLabel="Code"
                            formHook={form}
                            inputProps={{ autoComplete: 'one-time-code' }}
                        />
                        <PasswordField
                            name="password"
                            formLabel="New Password"
                            formHook={form}
                            formHelperText={passwordMessage}
                            inputProps={{ autoComplete: 'new-password' }}
                            isRequired
                        />
                        <PasswordField
                            name="confirmPassword"
                            formLabel="Confirm New Password"
                            formHook={form}
                            inputProps={{ autoComplete: 'new-password' }}
                            isRequired
                        />
                        <WeFactorSubmitButton disabled={!isValid || isSubmitting} isLoading={isSubmitting} width="100%">
                            Reset Password
                        </WeFactorSubmitButton>
                    </div>
                )}
            </form>
            <Link
                display={'block'}
                textAlign={'right'}
                fontSize={'sm'}
                onClick={() => {
                    navigate('/sign-up');
                }}
            >
                Sign up now for free
            </Link>
        </AuthFlex>
    );
};
