import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, Skeleton, Spinner, useDisclosure } from '@chakra-ui/react';

export const PageLoadingSkeleton = () => {
    return (
        <Flex padding={0} flexDirection="column" height="100%">
            <Skeleton flex={1} m={2} />
            <Skeleton flex={1} m={2} />
            <Skeleton flex={1} m={2} />
        </Flex>
    );
};

export const PageLoadingModal = () => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent
                background={'transparent'}
                shadow={'none'}
                boxShadow={'none'}
                height={'min-content'}
                width={'min-content'}
            >
                <ModalBody height={'min-content'} width={'min-content'}>
                    <Spinner size={'xl'} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
