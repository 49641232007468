import { Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { StandardModalProps } from '../../../components';
import { Accounts, Building, Correspondence, CorrespondenceAccount, Document, Properties } from '../../../types';
import { CorrespondenceModal } from './components/CorrespondenceModal';
import { Documents } from './components/Documents';
import { PublishedDate } from './components/PublishedDate';
import { RecipientsTable } from './components/RecipientsTable';
import { Subject } from './components/Subject';

export const CorrespondenceViewForLetter = (props: {
    accounts: Accounts;
    building: Building;
    correspondence: Correspondence;
    correspondenceAccounts: CorrespondenceAccount[];
    documents: Document[];
    properties: Properties;
    standardModalProps: StandardModalProps;
}) => {
    const { accounts, building, correspondence, correspondenceAccounts, documents, properties, standardModalProps } =
        props;

    const renderGrid = useBreakpointValue(
        {
            base: () => {
                return (
                    <Grid gap={4} marginBottom={4}>
                        <GridItem>
                            <Subject correspondence={correspondence} />
                        </GridItem>
                        <GridItem>
                            <PublishedDate correspondence={correspondence} />
                        </GridItem>
                        {documents.length > 0 && (
                            <GridItem>
                                <Documents correspondence={correspondence} building={building} documents={documents} />
                            </GridItem>
                        )}
                    </Grid>
                );
            },
            md: () => {
                return (
                    <Grid gap={4} marginBottom={4} templateColumns="repeat(5, 1fr)">
                        <GridItem colSpan={3}>
                            <Subject correspondence={correspondence} />
                        </GridItem>
                        <GridItem colSpan={2} rowSpan={2}>
                            <Documents correspondence={correspondence} building={building} documents={documents} />
                        </GridItem>
                        <GridItem>
                            <PublishedDate correspondence={correspondence} />
                        </GridItem>
                    </Grid>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <>
            <CorrespondenceModal {...standardModalProps}>
                {renderGrid()}
                <RecipientsTable
                    accounts={accounts}
                    building={building}
                    correspondence={correspondence}
                    correspondenceAccounts={correspondenceAccounts}
                    properties={properties}
                />
            </CorrespondenceModal>
        </>
    );
};
