import { Box, BoxProps, Text } from '@chakra-ui/react';
import { Heading3 } from './Headings';

export const NamedValue = (props: { label: string; children: any; boxProps?: BoxProps }) => {
    return (
        <Box {...props.boxProps}>
            <Heading3>{props.label}</Heading3>
            <Text>{props.children}</Text>
        </Box>
    );
};
