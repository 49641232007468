import { FieldValues } from 'react-hook-form';
import { BaseTextArea, BaseTextAreaProps } from './BaseTextArea';

type TextAreaProps<T extends FieldValues> = BaseTextAreaProps<T>;

export const TextArea = <T extends FieldValues>(props: TextAreaProps<T>) => {
    return (
        <BaseTextArea
            {...props}
            textareaProps={{
                rows: 5,
                ...props.textareaProps
            }}
        />
    );
};
