export interface Correspondence {
    id: string;
    buildingId: string;
    body: string;
    createdDate: string;
    sentDate?: string;
    subject: string;
    type: string;
}

export interface ExtendedCorrespondence extends Correspondence {
    correspondenceAccounts: CorrespondenceAccount[];
}

export interface CorrespondenceAccount {
    id: string;
    correspondenceId: string;
    accountId: string;
    read: boolean;
    sentDate?: string;
}

export enum CorrespondenceType {
    COMMON_REPAIR_QUOTE = 'COMMON_REPAIR_QUOTE',
    LETTER = 'LETTER',
    SHARED_COSTS = 'SHARED_COSTS'
}

// COMMON REPAIR QUOTE

export interface CommonRepairQuote extends Correspondence {
    amount: number;
    companyName: string;
    divisionVersionId: string;
    maintenanceDescription: string;
    responseDeadline: string;
    shareMethod: string;
}

export interface CommonRepairQuoteShare {
    id: string;
    correspondenceId: string;
    accountId: string;
    propertyId: string;
    amount: number;
    factorResponse?: boolean;
    factorResponseDate?: string;
    ownerResponse?: boolean;
    ownerResponseDate?: string;
}

// SHARED_COSTS

export interface SharedCosts extends Correspondence {
    divisionVersionId: string;
    propertiesCount: number;
}
