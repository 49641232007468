import { Box, Text } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { Observable } from 'rxjs';
import { StandardPage } from '../../components';
import { userAccountService } from '../../services';
import { UserAccount } from '../../types/user-account';
import { formatStreetAddress } from '../../util';

export const SettingsPageOwner = () => {
    const [activeAccountProperty] = useObservable(userAccountService.activeEntity$ as Observable<UserAccount>);

    if (!activeAccountProperty) {
        return <></>;
    }

    return (
        <StandardPage title={`Account Settings`}>
            <>
                <Text color="#84818A">{`${formatStreetAddress(activeAccountProperty.property)}`}</Text>
                <Text mt={4} color="#84818A">
                    {activeAccountProperty.accountName}
                </Text>
                <Box mt={8} borderBottom="1px solid #EBEAED" />
            </>
        </StandardPage>
    );
};
