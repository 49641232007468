import { Flex, Spacer, Spinner, Text, useToast } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useMemo, useState } from 'react';
import {
    PremiumFeatureAlert,
    StandardModal,
    StandardModalProps,
    WeFactorSecondaryButton,
    WeFactorSubmitButton
} from '../../../../components';
import { correspondenceAccountsService, correspondencesService } from '../../../../services';
import { Account, Building, Correspondence, ExtendedCorrespondence } from '../../../../types';
import { genericToast, isToday } from '../../../../util';

export const SendCorrespondenceModal = (
    props: StandardModalProps & {
        building: Building;
        correspondence: Correspondence;
    }
) => {
    const { building, correspondence, disclosure, onClose } = props;

    const hasActiveSubscription = useMemo(() => building.subscriptionStatus.includes('ACTIVE'), [building]);

    const getMessage = () => {
        if (!hasActiveSubscription) {
            return (
                <PremiumFeatureAlert subtext="Alternatively you can download correspondence to send from your personal email account or print for delivery" />
            );
        } else if (!!correspondence.sentDate) {
            return (
                <>
                    <Text mb={2}>
                        This correspondence has already been sent to all recipients and cannot be sent again.
                    </Text>
                    <Text>
                        You can re-send correspondence to individual recipients from the recipients table when viewing
                        the correspondence.
                    </Text>
                </>
            );
        }

        return (
            <Text>
                This will send the correspondence to all recipients with valid email addresses who have not yet been
                sent it.
            </Text>
        );
    };

    return (
        <CommonSendCorrespondenceModal
            correspondence={correspondence}
            heading={'Send correspondence'}
            isDisabled={!hasActiveSubscription || !!correspondence.sentDate}
            getMessage={getMessage}
            disclosure={disclosure}
            onClose={onClose}
        />
    );
};

export const SendCorrespondanceToAccountModal = (
    props: StandardModalProps & {
        account: Account;
        building: Building;
        correspondence: Correspondence;
    }
) => {
    const { account, building, correspondence, disclosure, onClose } = props;

    const hasActiveSubscription = useMemo(() => building.subscriptionStatus.includes('ACTIVE'), [building]);

    const [isDisabled, setIsDisabled] = useState(true);

    const [correspondenceAccounts] = useObservable(correspondenceAccountsService.entities$);

    const getMessage = () => {
        if (!hasActiveSubscription) {
            return (
                <PremiumFeatureAlert subtext="Alternatively you can download correspondence to send from your personal email account or print for delivery" />
            );
        }

        if (!account.email) {
            return (
                <Text>
                    Cannot send correspondence because the recipient account does not have a valid email address.
                </Text>
            );
        }

        const correspondenceAccount = correspondenceAccounts.find(
            (correspondenceAccount) => correspondenceAccount.accountId === account.id
        );

        if (!correspondenceAccount) {
            return (
                <>
                    <Text mb={2} textAlign={'center'}>
                        <Spinner />
                    </Text>
                    <Text textAlign={'center'}>Fetching sent status...</Text>
                </>
            );
        }

        if (isToday(correspondenceAccount.sentDate)) {
            return (
                <>
                    <Text mb={2}>This recipient has already been sent this correspondence once today.</Text>
                    <Text>We have limited sends to a maximum of one time a day for each recipient.</Text>
                </>
            );
        }

        setIsDisabled(false);

        return <Text>This will send the correspondence to {account.accountName}.</Text>;
    };

    return (
        <CommonSendCorrespondenceModal
            account={account}
            correspondence={correspondence}
            heading={'Send correspondence'}
            isDisabled={isDisabled}
            getMessage={getMessage}
            disclosure={disclosure}
            onClose={onClose}
        />
    );
};

const CommonSendCorrespondenceModal = (
    props: StandardModalProps & {
        account?: Account;
        correspondence: Correspondence;
        heading: string;
        isDisabled: boolean;
        getMessage: () => any;
    }
) => {
    const { account, correspondence, heading, isDisabled, getMessage, disclosure, onClose } = props;

    const [sendingRequest, setIsSendingRequest] = useState(false);

    const toast = useToast();

    const sendCorrespondence = async () => {
        setIsSendingRequest(true);

        if (account) {
            await genericToast(
                toast,
                async () => {
                    const correspondenceAccount = await correspondencesService.update({
                        pathParams: {
                            buildingId: correspondence.buildingId,
                            id: correspondence.id
                        },
                        pathSuffix: 'resend',
                        params: {
                            accountId: account.id
                        },
                        doNotUpdateEntities: true
                    });
                    correspondenceAccountsService.updateEntity(correspondenceAccount.id, correspondenceAccount);
                    disclosure.onClose();
                },
                {
                    title: 'Sending correspondence',
                    onSuccessTitle: 'Successfully sent correspondence',
                    onErrorTitle: 'Failed to send correspondence'
                }
            );
        } else {
            await genericToast(
                toast,
                async () => {
                    const updatedCorrespondence = await correspondencesService.update<ExtendedCorrespondence>({
                        pathParams: {
                            buildingId: correspondence.buildingId,
                            id: correspondence.id
                        },
                        pathSuffix: 'send'
                    });
                    correspondenceAccountsService.upsertEntities(updatedCorrespondence.correspondenceAccounts);
                    disclosure.onClose();
                },
                {
                    title: 'Sending correspondence',
                    onSuccessTitle: 'Successfully sent correspondence',
                    onErrorTitle: 'Failed to send correspondence'
                }
            );
        }

        setIsSendingRequest(false);
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'sm'
                }
            }}
            onClose={onClose}
            header={heading}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton onClick={disclosure.onClose} isDisabled={sendingRequest}>
                        Cancel
                    </WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorSubmitButton onClick={sendCorrespondence} isDisabled={isDisabled || sendingRequest}>
                        {'Send'}
                    </WeFactorSubmitButton>
                </Flex>
            }
        >
            {getMessage()}
        </StandardModal>
    );
};
