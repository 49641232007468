import { Button, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLOURS } from '../../components';
import { layoutService } from '../../services';

interface NavigationButtonProps {
    icon: React.ReactElement;
    isDisabled?: boolean;
    label: string;
    route?: string;
    onClick?: () => void;
}

export const NavigationButton = (props: NavigationButtonProps) => {
    const { icon, isDisabled, label, onClick, route } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const isActiveRoute = !!route && location.pathname.startsWith(route);

    const handleClick = () => {
        if (onClick) {
            onClick();
        }

        if (route) {
            layoutService.closeNavBar();
            navigate(route);
        }
    };

    return (
        <Button
            _active={{ color: COLOURS.GREEN }}
            _hover={isActiveRoute ? {} : { backgroundColor: COLOURS.GREEN, color: COLOURS.BLUE }}
            isActive={isActiveRoute}
            isDisabled={isDisabled}
            color={COLOURS.WHITE}
            justifyContent={'start'}
            leftIcon={icon}
            onClick={handleClick}
            variant={'ghost'}
        >
            <Text marginLeft={2}>{label}</Text>
        </Button>
    );
};
