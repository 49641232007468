import { Box, Flex, Input, InputRightAddon, Spacer, Text, UseDisclosureReturn } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { BaseRadioGroupField, HiddenField, TextField, Wizard, WizardTabPanel } from '../../../components';
import { factorDivisionVersionsService } from '../../../services';
import { Building, DivisionMethod, DivisionVersion, Property, getDivisionMethodDescription } from '../../../types';
import { formatStreetAddress } from '../../../util';

type DivisionVersionForm = {
    buildingId: string;
    denominator: number | '';
    divisionMethod: string;
    divisions: DivisionForm[];
};

type DivisionForm = {
    propertyId: string;
    value: number | '';
};

interface DivisionVersionWizardProps {
    disclosure: UseDisclosureReturn;
    building: Building;
    divisionVersions: DivisionVersion[];
    properties: Property[];
    onClose?: (divisionVersion?: DivisionVersion) => void;
}

export const DivisionVersionWizard = (props: DivisionVersionWizardProps) => {
    const { building, disclosure, divisionVersions, onClose } = props;

    const existingProperties = useMemo(
        () => props.properties.filter((property) => !property.deleted),
        [props.properties]
    );

    const activeDivisionVersion = useMemo(
        () => divisionVersions.find((divisionVersion) => divisionVersion.active)!,
        [divisionVersions]
    );

    const getDivisionValue = (propertyId: string): number | '' => {
        if (activeDivisionVersion.divisionMethod === DivisionMethod.EQUAL_SHARE) {
            return 1;
        } else {
            return activeDivisionVersion.divisions.find((division) => division.propertyId === propertyId)?.value || '';
        }
    };

    const getDefaultDivisions = () => {
        return existingProperties.map((property) => ({
            propertyId: property.id,
            value: getDivisionValue(property.id)
        }));
    };

    const form = useForm<DivisionVersionForm>({
        defaultValues: {
            buildingId: building.id,
            denominator: activeDivisionVersion.denominator || '',
            divisionMethod: activeDivisionVersion.divisionMethod,
            divisions: getDefaultDivisions()
        },
        resolver: joiResolver(
            Joi.object({
                buildingId: Joi.string().uuid().required(),
                denominator: Joi.when('divisionMethod', [
                    {
                        is: DivisionMethod.FRACTION,
                        then: Joi.number()
                            .min(1)
                            .max(9999)
                            .messages({
                                'any.required': 'must have a value',
                                'number.base': 'must be a number',
                                'number.min': 'must be 1 or greater',
                                'number.max': 'must be 9999 or less'
                            })
                            .required(),
                        otherwise: Joi.any()
                    }
                ]),
                divisionMethod: Joi.string().allow(DivisionMethod).required(),
                divisions: Joi.when('divisionMethod', [
                    {
                        is: Joi.equal(DivisionMethod.EQUAL_SHARE),
                        then: Joi.array().items(
                            Joi.object({
                                propertyId: Joi.string().uuid().required(),
                                value: Joi.number().min(1).max(1).required()
                            })
                        )
                    },
                    {
                        is: Joi.equal(DivisionMethod.FLOOR_AREA),
                        then: Joi.array().items(
                            Joi.object({
                                propertyId: Joi.string().uuid().required(),
                                value: Joi.number()
                                    .min(1)
                                    .max(9999)
                                    .messages({
                                        'any.required': 'must have a value',
                                        'number.base': 'must be a number',
                                        'number.min': 'must be 1 or greater',
                                        'number.max': 'must be 9999 or less'
                                    })
                                    .required()
                            })
                        )
                    },
                    {
                        is: Joi.equal(DivisionMethod.FRACTION),
                        then: Joi.array().items(
                            Joi.object({
                                propertyId: Joi.string().uuid().required(),
                                value: Joi.number()
                                    .integer()
                                    .min(1)
                                    .max(9999)
                                    .messages({
                                        'any.required': 'must have a value',
                                        'number.base': 'must be a number',
                                        'number.min': 'must be 1 or greater',
                                        'number.max': 'must be 9999 or less'
                                    })
                                    .messages({
                                        'any.required': 'must have a value',
                                        'number.base': 'must be a number'
                                    })
                                    .required()
                            })
                        )
                    },
                    {
                        is: Joi.equal(DivisionMethod.PERCENTAGE),
                        then: Joi.array().items(
                            Joi.object({
                                propertyId: Joi.string().uuid().required(),
                                value: Joi.number()
                                    .min(1)
                                    .max(99)
                                    .messages({
                                        'any.required': 'must have a value',
                                        'number.base': 'must be a number',
                                        'number.min': 'must be 1 or greater',
                                        'number.max': 'must be 99 or less'
                                    })
                                    .required()
                            })
                        )
                    },
                    {
                        is: Joi.equal(DivisionMethod.RATEABLE_VALUE),
                        then: Joi.array().items(
                            Joi.object({
                                propertyId: Joi.string().uuid().required(),
                                value: Joi.number()
                                    .min(1)
                                    .max(99)
                                    .messages({
                                        'any.required': 'must have a value',
                                        'number.base': 'must be a number',
                                        'number.min': 'must be 1 or greater',
                                        'number.max': 'must be 99 or less'
                                    })
                                    .required()
                            })
                        )
                    }
                ])
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const denominator = form.watch('denominator');
    const divisionMethod = form.watch('divisionMethod');

    useEffect(() => {
        if (!divisionMethod) return;

        if (divisionMethod === activeDivisionVersion.divisionMethod) {
            form.resetField('denominator', { defaultValue: activeDivisionVersion.denominator || '' });
            form.resetField('divisions', { defaultValue: getDefaultDivisions() });
        } else {
            form.resetField('denominator', { defaultValue: '' });
            form.resetField('divisions', {
                defaultValue: existingProperties.map((property) => ({
                    propertyId: property.id,
                    value: divisionMethod === DivisionMethod.EQUAL_SHARE ? 1 : ''
                }))
            });
        }
    }, [divisionMethod]);

    const onStepChange = (exitingTab: number, enteringTab: number) => {
        if (enteringTab === 1) form.clearErrors();
    };

    const save = async (formValues: DivisionVersionForm): Promise<DivisionVersion | undefined> => {
        return factorDivisionVersionsService.create({
            pathParams: {
                buildingId: building.id
            },
            body: { ...formValues }
        });
    };

    const getTab = () => {
        const getTabConents = (index: number) => {
            switch (divisionMethod) {
                case DivisionMethod.EQUAL_SHARE:
                    return (
                        <>
                            <Box>
                                <TextField
                                    formHook={form}
                                    name={`divisions.${index}.value`}
                                    inputProps={{
                                        width: '50px',
                                        isDisabled: true,
                                        isReadOnly: true,
                                        textAlign: 'right'
                                    }}
                                />
                            </Box>
                            <Text marginX={2}>{'/'}</Text>
                            <Input
                                id={`property-count-${index}`}
                                isDisabled={true}
                                isReadOnly={true}
                                textAlign={'left'}
                                width="60px"
                                value={existingProperties.length}
                            />
                        </>
                    );
                case DivisionMethod.FLOOR_AREA:
                    return (
                        <Box>
                            <TextField
                                formHook={form}
                                name={`divisions.${index}.value`}
                                inputElementRight={
                                    <InputRightAddon
                                        children={
                                            <Text>
                                                m<sup>2</sup>
                                            </Text>
                                        }
                                    />
                                }
                                inputProps={{ maxLength: 4, width: '68px' }}
                            />
                        </Box>
                    );
                case DivisionMethod.FRACTION:
                    return (
                        <>
                            <Box>
                                <TextField
                                    formHook={form}
                                    name={`divisions.${index}.value`}
                                    inputProps={{ maxLength: 4, width: '68px' }}
                                />
                            </Box>
                            <Text marginX={2}>{'/'}</Text>
                            <Box>
                                <Input
                                    id={`property-${index}`}
                                    isDisabled={true}
                                    isReadOnly={true}
                                    maxLength={4}
                                    type={'number'}
                                    value={denominator?.toString() || ''}
                                    width="68px"
                                />
                            </Box>
                        </>
                    );
                case DivisionMethod.PERCENTAGE:
                    return (
                        <Box>
                            <TextField
                                formHook={form}
                                name={`divisions.${index}.value`}
                                inputElementRight={<InputRightAddon children={'%'} />}
                                inputProps={{ maxLength: 5, width: '80px' }}
                            />
                        </Box>
                    );
                case DivisionMethod.RATEABLE_VALUE:
                    return (
                        <Box>
                            <TextField
                                formHook={form}
                                name={`divisions.${index}.value`}
                                inputElementRight={<InputRightAddon children={'RV'} />}
                                inputProps={{ maxLength: 4, width: '68px' }}
                            />
                        </Box>
                    );
                default:
                    return <></>;
            }
        };

        return (
            <>
                {divisionMethod === DivisionMethod.FRACTION && (
                    <Flex alignItems={'center'} key={'denominator'} mb={2}>
                        <Text>&nbsp;</Text>
                        <Spacer />
                        <Box>
                            <TextField
                                formHook={form}
                                name="denominator"
                                inputProps={{ maxLength: 4, width: '158px' }}
                                placeholder="denominator"
                            />
                        </Box>
                    </Flex>
                )}
                {existingProperties.map((property, index) => {
                    return (
                        <Flex alignItems={'center'} key={index} mb={2}>
                            <Text>{formatStreetAddress(property, true)}</Text>
                            <Spacer />
                            {getTabConents(index)}
                            <Box>
                                <HiddenField formHook={form} name={`divisions.${index}.propertyId`} />
                            </Box>
                        </Flex>
                    );
                })}
            </>
        );
    };

    return (
        <Wizard<DivisionVersionForm, DivisionVersion>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    md: 'md'
                }
            }}
            form={form}
            heading={'How should costs be shared'}
            onSaveSuccess={onClose}
            onStepChange={onStepChange}
            save={save}
            steps={[
                {
                    heading: 'How should common or mutual costs be shared?',
                    fieldNames: ['buildingId', 'divisionMethod']
                },
                {
                    heading: 'Configure each properties share',
                    fieldNames: ['divisions']
                }
            ]}
        >
            <WizardTabPanel>
                <BaseRadioGroupField
                    formHook={form}
                    name="divisionMethod"
                    direction={'column'}
                    groupLabel="Select applicable option:"
                    options={[
                        {
                            value: DivisionMethod.EQUAL_SHARE,
                            label: getDivisionMethodDescription(DivisionMethod.EQUAL_SHARE)
                        },
                        {
                            value: DivisionMethod.FLOOR_AREA,
                            label: getDivisionMethodDescription(DivisionMethod.FLOOR_AREA)
                        },
                        {
                            value: DivisionMethod.FRACTION,
                            label: getDivisionMethodDescription(DivisionMethod.FRACTION)
                        },
                        {
                            value: DivisionMethod.PERCENTAGE,
                            label: getDivisionMethodDescription(DivisionMethod.PERCENTAGE)
                        },
                        {
                            value: DivisionMethod.RATEABLE_VALUE,
                            label: getDivisionMethodDescription(DivisionMethod.RATEABLE_VALUE)
                        }
                    ]}
                />
                <HiddenField name="buildingId" formHook={form} />
            </WizardTabPanel>
            <WizardTabPanel>
                <>{getTab()}</>
            </WizardTabPanel>
        </Wizard>
    );
};
