import { Address } from './address';
import { notDeletedFilter } from './common';

export interface Property extends Address {
    id: string;
    buildingId: string;

    deleted: boolean;
    deletedDate: string | undefined;
}

export class Properties {
    private properties: Property[];
    private activeProperties: Property[];
    private propertiesMap: Map<string, Property>;

    constructor(properties: Property[]) {
        this.properties = [...properties].sort(addressSorter());
        this.activeProperties = this.properties.filter(notDeletedFilter());
        this.propertiesMap = new Map(this.properties.map((property) => [property.id, property]));
    }

    getProperty(accountId: string): Property | undefined {
        return this.propertiesMap.get(accountId);
    }

    getProperties(options?: PropertiesOptions): Property[] {
        return this.transformList(this.properties, options);
    }

    getActiveProperties(options?: PropertiesOptions): Property[] {
        return this.transformList(this.activeProperties, options);
    }

    getPropertiesMap(key: string = 'id', options?: PropertiesOptions): Map<string, Property> {
        return new Map(
            this.transformList(this.properties, options).map((property) => [property[key].toString(), property])
        );
    }

    private transformList(properties: Property[], options?: PropertiesOptions): Property[] {
        if (options?.filters) {
            properties = options.filters.reduce((properties, filter) => properties.filter(filter), properties);
        }

        if (options?.sorters) {
            properties = options.sorters.reduce((properties, filter) => properties.sort(filter), properties);
        }

        return properties;
    }
}

type PropertiesOptions = {
    filters?: PropertyFilter[];
    sorters?: PropertySorter[];
};

type PropertyFilter = (value: Property, index: number, array: Property[]) => unknown;

type PropertySorter = (p1: Property, p2: Property) => number;

// Property sorts

export const addressSorter = () => (p1: Property, p2: Property) => {
    return (
        (p1.streetName || '').localeCompare(p2.streetName || '') ||
        (p1.buildingNumber || '')?.toString().localeCompare(p2.buildingNumber?.toString() || '') ||
        (p1.buildingName || '')?.toString().localeCompare(p2.buildingName?.toString() || '') ||
        (p1.subBuilding || '')?.toString().localeCompare(p2.subBuilding?.toString() || '') ||
        0
    );
};
