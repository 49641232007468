import { Table, Tbody, Td, Text, Th, Tr, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { TdButton, ThTableMenu, WeFactorThead } from '../../../../components';
import {
    Accounts,
    Building,
    Correspondence,
    CorrespondenceAccount,
    Properties,
    addressSorter
} from '../../../../types';
import { formatDateShort, formatDateShortMonth, formatStreetAddress } from '../../../../util';
import { CorrespondenceActionDispatcher } from './CorrespondenceActionDispatcher';
import { CorrespondenceTableMenu } from './CorrespondenceTableMenu';
import { CorrespondenceRowValues } from './CorrespondenceTypes';
import { SendCorrespondanceToAccountModal } from './SendCorrespondanceModals';

interface RepientsTableProps {
    accounts: Accounts;
    building: Building;
    correspondence: Correspondence;
    correspondenceAccounts: CorrespondenceAccount[];
    properties: Properties;
    renderHeaderRow?: () => React.ReactElement | React.ReactElement[];
    renderCorrespondenceRow?: (values: CorrespondenceRowValues) => React.ReactElement | React.ReactElement[];
}

export const RecipientsTable = (props: RepientsTableProps) => {
    const {
        accounts,
        building,
        correspondence,
        correspondenceAccounts,
        properties,
        renderHeaderRow,
        renderCorrespondenceRow
    } = props;

    const sendCorrespondenceDisclosure = useDisclosure();

    const [correspondenceAccount, setCorrespondenceAccount] = useState<CorrespondenceAccount>();

    const actionDispatcher = useMemo(() => new CorrespondenceActionDispatcher(), []);

    const recipients: CorrespondenceRowValues[] = useMemo(
        () =>
            correspondenceAccounts
                .map((correspondenceAccount) => {
                    const account = accounts.getAccount(correspondenceAccount.accountId)!;
                    const property = properties.getProperty(account.propertyId)!;
                    return { account, correspondence, correspondenceAccount, property };
                })
                .sort((acp1, acp2) => addressSorter()(acp1.property, acp2.property)),
        [accounts, correspondence, correspondenceAccounts, properties]
    );

    const renderHeaderRowCommon = useBreakpointValue(
        {
            base: () => (
                <>
                    <Th key={'account-address'}>Account</Th>
                    {renderHeaderRow && renderHeaderRow()}
                    <Th key={'sent-date'} textAlign={'center'}>
                        Sent
                    </Th>
                    <ThTableMenu />
                </>
            ),
            md: () => (
                <>
                    <Th key={'account'}>Account</Th>
                    <Th key={'address'}>Property</Th>
                    {renderHeaderRow && renderHeaderRow()}
                    <Th key={'sent-date'} textAlign={'center'}>
                        Sent
                    </Th>
                    <ThTableMenu />
                </>
            )
        },
        { fallback: 'md', ssr: false }
    )!;

    const getBaseValues = (values: CorrespondenceRowValues) => {
        const { correspondenceAccount, property } = values;

        return {
            address: formatStreetAddress(property, true),
            date: correspondenceAccount.sentDate ? formatDateShort(correspondenceAccount.sentDate) : '-'
        };
    };

    const getMediumValues = (values: CorrespondenceRowValues) => {
        const { correspondenceAccount } = values;

        return {
            ...getBaseValues(values),
            date: correspondenceAccount.sentDate ? formatDateShortMonth(correspondenceAccount.sentDate) : '-'
        };
    };

    const resendCorrespondence = (values: CorrespondenceRowValues) => {
        setCorrespondenceAccount(values.correspondenceAccount);
        sendCorrespondenceDisclosure.onOpen();
    };

    const renderCorrespondenceRowCommon = useBreakpointValue(
        {
            base: (values: CorrespondenceRowValues) => {
                const { account } = values;
                const { address, date } = getBaseValues(values);
                return (
                    <>
                        <Td key={'account-address'}>
                            <Text mb={1.5}>{account.accountName}</Text>
                            <Text fontWeight={300}>{address}</Text>
                        </Td>
                        {renderCorrespondenceRow && renderCorrespondenceRow(values)}
                        <Td key={'sent-date'} textAlign={'center'}>
                            {date}
                        </Td>
                        <TdButton>
                            <CorrespondenceTableMenu
                                actionDispatcher={actionDispatcher}
                                values={values}
                                resendCorrespondence={() => resendCorrespondence(values)}
                            />
                        </TdButton>
                    </>
                );
            },
            md: (values: CorrespondenceRowValues) => {
                const { account } = values;
                const { address, date } = getMediumValues(values);
                return (
                    <>
                        <Td key={'account'}>{account.accountName}</Td>
                        <Td key={'address'}>{address}</Td>
                        {renderCorrespondenceRow && renderCorrespondenceRow(values)}
                        <Td key={'sent-date'} textAlign={'center'}>
                            {date}
                        </Td>
                        <TdButton>
                            <CorrespondenceTableMenu
                                actionDispatcher={actionDispatcher}
                                values={values}
                                resendCorrespondence={() => resendCorrespondence(values)}
                            />
                        </TdButton>
                    </>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <>
            <Table size={'sm'}>
                <WeFactorThead>
                    <Tr key={'header'}>{renderHeaderRowCommon()}</Tr>
                </WeFactorThead>
                <Tbody>
                    {recipients.map((values) => {
                        return <Tr key={values.account.id}>{renderCorrespondenceRowCommon(values)}</Tr>;
                    })}
                </Tbody>
            </Table>
            {sendCorrespondenceDisclosure.isOpen && correspondenceAccount && (
                <SendCorrespondanceToAccountModal
                    account={accounts.getAccount(correspondenceAccount.accountId)!}
                    building={building}
                    correspondence={correspondence}
                    disclosure={sendCorrespondenceDisclosure}
                />
            )}
        </>
    );
};
