import { Table, Tbody, Td, Text, Th, Tr, useBreakpointValue } from '@chakra-ui/react';
import { CurrencyBadge, TableMenu, TableMenuItem, TdButton, ThTableMenu, WeFactorThead } from '../../../components';
import { Accounts, Properties, Property, currentOwnerFilter, propertyFilter } from '../../../types';
import { formatStreetAddress } from '../../../util';
import { AccountStatusBadge } from './AccountStatusBadge';

interface PropertiesTableProps {
    accounts: Accounts;
    balanceMap: Map<string, number>;
    properties: Properties;
    onAddAccount?: (property: Property) => void;
    onDeleteProperty?: (property: Property) => void;
    onViewProperty: (property: Property) => void;
}

export const PropertiesTable = (props: PropertiesTableProps) => {
    const { accounts, balanceMap, properties, onAddAccount, onDeleteProperty, onViewProperty } = props;

    const onAddAccountClick = (property: Property) => {
        if (onAddAccount) {
            onAddAccount(property);
        }
    };

    const onDeletePropertyClick = (property: Property) => {
        if (onDeleteProperty) {
            onDeleteProperty(property);
        }
    };

    const renderHeaderRow = useBreakpointValue(
        {
            base: () => (
                <>
                    <Th key={'address'}>Property</Th>
                    <Th key="balance" paddingX={0} textAlign={'center'} width={'80px'}>
                        Balance
                    </Th>
                </>
            ),
            md: () => (
                <>
                    <Th key={'address'}>Property</Th>
                    <Th key={'account'}>Owner</Th>
                    <Th key={'status'} textAlign={'center'}>
                        Status
                    </Th>
                    <Th key="balance" paddingX={0} textAlign={'center'} width={'80px'}>
                        Balance
                    </Th>
                </>
            )
        },
        { fallback: 'md', ssr: false }
    )!;

    const getBaseValues = (property: Property) => {
        const account = accounts.getAccounts({ filters: [currentOwnerFilter(), propertyFilter(property.id)] })[0];

        return {
            clickProps: {
                _hover: { cursor: 'pointer' },
                onClick: () => onViewProperty(property)
            },
            account: account,
            accountName: account ? account.accountName : '',
            balance: account ? balanceMap.get(account.id) || 0 : 0,
            address: formatStreetAddress(property, true)
        };
    };

    const getMediumValues = (property: Property) => {
        return {
            ...getBaseValues(property)
        };
    };

    const renderProperiesRow = useBreakpointValue(
        {
            base: (property: Property) => {
                const { accountName, address, balance, clickProps } = getBaseValues(property);
                return (
                    <>
                        <Td {...clickProps} key="address">
                            <Text mb={1.5}>{address}</Text>
                            <Text fontWeight={300}>{accountName}</Text>
                        </Td>
                        <Td {...clickProps} key="balance" textAlign={'center'}>
                            <CurrencyBadge amount={balance} />
                        </Td>
                    </>
                );
            },
            md: (property: Property) => {
                const { account, accountName, address, balance, clickProps } = getMediumValues(property);
                return (
                    <>
                        <Td {...clickProps} key="address">
                            {address}
                        </Td>
                        <Td {...clickProps} key="account">
                            {accountName}
                        </Td>
                        <Td {...clickProps} key="status" textAlign={'center'}>
                            <AccountStatusBadge account={account} />
                        </Td>
                        <Td {...clickProps} key="balance" textAlign={'center'}>
                            <CurrencyBadge amount={balance} />
                        </Td>
                    </>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <Table size={'sm'}>
            <WeFactorThead>
                <Tr>
                    {renderHeaderRow()}
                    <ThTableMenu>&nbsp;</ThTableMenu>
                </Tr>
            </WeFactorThead>
            <Tbody>
                {properties.getActiveProperties().map((property) => {
                    return (
                        <Tr key={property.id}>
                            {renderProperiesRow(property)}
                            <TdButton key={'buttons'}>
                                <TableMenu>
                                    <TableMenuItem
                                        key={'add-new-account'}
                                        menuItemProps={{
                                            'aria-label': 'Add new account',
                                            onClick: () => onAddAccountClick(property)
                                        }}
                                    >
                                        Add new account
                                    </TableMenuItem>
                                    <TableMenuItem
                                        key={'delete-property'}
                                        menuItemProps={{
                                            'aria-label': 'Delete property',
                                            onClick: () => onDeletePropertyClick(property)
                                        }}
                                    >
                                        Delete property
                                    </TableMenuItem>
                                </TableMenu>
                            </TdButton>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};
