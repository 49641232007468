import { Box, Flex, Icon, Link, Spacer, Text, Tooltip } from '@chakra-ui/react';
import { MdOutlineChat, MdOutlineMarkUnreadChatAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { stripHtml } from 'string-strip-html';
import { Heading3, LINK_BLUE } from '../../../components';
import { ForumBoard, ForumPost } from '../../../types';
import { formatDateShortMonth, formatTime } from '../../../util';

export const ForumPostBox = (props: {
    buildingId: string;
    forumBoard: ForumBoard;
    forumPost: ForumPost;
    forumPostViews: Map<string, string>;
}) => {
    const { buildingId, forumBoard, forumPost, forumPostViews } = props;
    const { message } = forumPost;
    const { user } = message;

    const navigate = useNavigate();

    const getIcon = () => {
        const lastViewedTimestamp = forumPostViews.get(forumPost.id);

        if (lastViewedTimestamp) {
            if (new Date(lastViewedTimestamp) < new Date(forumPost.latestMessageTimestamp)) {
                return {
                    color: 'black',
                    icon: MdOutlineMarkUnreadChatAlt,
                    tooltipLabel: 'New replies'
                };
            }
        } else {
            return {
                color: 'black',
                icon: MdOutlineMarkUnreadChatAlt,
                tooltipLabel: 'New post'
            };
        }

        return {
            color: 'RGBA(0, 0, 0, 0.50)',
            icon: MdOutlineChat,
            tooltipLabel: 'No new replies'
        };
    };

    const goTo = () => {
        navigate(`/buildings/${buildingId}/forums/${forumBoard.id}/posts/${forumPost.id}`);
    };

    const { icon, color, tooltipLabel } = getIcon();

    return (
        <Box key={forumPost.id}>
            <Box key={'post-name'}>
                <Heading3>
                    <Link color={LINK_BLUE} href="#" onClick={goTo}>
                        {forumPost.subject}
                    </Link>
                </Heading3>
                <Box
                    marginBottom={2}
                    maxHeight={'100px'}
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {stripHtml(message.body).result}
                </Box>
                <Flex alignItems={'center'}>
                    <Box key={'author-details'}>
                        <Text fontSize={'sm'}>{user.displayName}</Text>
                        <Text fontSize={'sm'}>
                            {formatDateShortMonth(message.createdDate)} @ {formatTime(message.createdDate)}
                        </Text>
                    </Box>
                    <Spacer />
                    <Tooltip label={tooltipLabel}>
                        <Flex color={color} alignItems={'center'} direction={'row'}>
                            <Text fontSize={'sm'} marginRight={'3px'} paddingBottom={'3px'}>
                                {forumPost.messageCount}
                            </Text>
                            <Icon color={color} height={4} width={4} as={icon} />
                        </Flex>
                    </Tooltip>
                </Flex>
            </Box>
        </Box>
    );
};
