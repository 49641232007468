import { Text, TextProps, forwardRef } from '@chakra-ui/react';

interface AuthHeaderProps extends TextProps {
    children: any;
}

export const AuthHeader = forwardRef<AuthHeaderProps, 'p'>((props, ref) => {
    const { children } = props;

    return (
        <Text fontWeight={600} fontSize="2xl" mb={4} textAlign={'center'} {...props} ref={ref}>
            {children}
        </Text>
    );
});
