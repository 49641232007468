import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { BaseSelectProps } from './BaseSelect';

type Option = {
    label: string;
    value: string;
};

const MONTH_OPTIONS: Option[] = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
];

const YEAR_OPTIONS: Option[] = [
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' }
];

type SelectYearMonthProps<T extends FieldValues> = Omit<BaseSelectProps<T>, 'options'> & {
    allowCurrentMonth: boolean;
    autoFocus?: boolean;
};

export const SelectYearMonth = <T extends FieldValues>(props: SelectYearMonthProps<T>) => {
    const { autoFocus, name, formHelperText, formHook, formLabel, isDisabled, isRequired } = props;

    const getYear = () => {
        const defaultValue = formHook.getValues(name);
        return defaultValue?.length === 7 ? defaultValue.substring(0, 4) : '';
    };

    const getMonth = () => {
        const defaultValue = formHook.getValues(name);
        return defaultValue?.length === 7 ? defaultValue.substring(5, 7) : '';
    };

    const [year, setYear] = useState<string>(getYear());
    const [month, setMonth] = useState<string>(getMonth());

    useEffect(() => {
        if (year?.length === 4 && month?.length === 2) {
            formHook.setValue(name, `${year}-${month}` as any);
        } else {
            formHook.setValue(name, '' as any);
        }
    }, [year, month]);

    const controller = useController<T, FieldPath<T>>({
        control: formHook.control,
        name: name,
        rules: { required: isRequired }
    });

    const {
        formState: { isSubmitting }
    } = formHook;

    const {
        fieldState: { error }
    } = controller;

    return (
        <FormControl
            aria-errormessage={error?.message}
            isDisabled={isDisabled || isSubmitting}
            isInvalid={!!error}
            isRequired={isRequired}
            flexDirection="column"
            justifyContent="center"
            mb={2}
        >
            {formLabel && (
                <FormLabel color="#84818A" fontSize="md" mb={1} ml={0.5}>
                    {formLabel}
                </FormLabel>
            )}
            <Flex>
                <Box flex={3} mr={2}>
                    <Select
                        autoFocus={autoFocus}
                        id={`month-field`}
                        aria-autocomplete={'none'}
                        aria-label={'Statement month'}
                        aria-required={isRequired}
                        size={'md'}
                        onChange={(e: any) => setMonth(e.value)}
                        options={MONTH_OPTIONS}
                        value={
                            MONTH_OPTIONS.find((option) => option.value === month) || {
                                label: 'Select',
                                value: ''
                            }
                        }
                    />
                </Box>
                <Box flex={2}>
                    <Select
                        id={`year-field`}
                        aria-autocomplete={'none'}
                        aria-label={'Statement '}
                        aria-required={isRequired}
                        size={'md'}
                        onChange={(e: any) => setYear(e.value)}
                        options={YEAR_OPTIONS}
                        value={
                            YEAR_OPTIONS.find((option) => option.value === year) || {
                                label: 'Select',
                                value: ''
                            }
                        }
                    />
                </Box>
            </Flex>
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
            {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
        </FormControl>
    );
};
