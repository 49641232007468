import {
    Modal,
    ModalBody,
    ModalBodyProps,
    ModalCloseButton,
    ModalContent,
    ModalContentProps,
    ModalFooter,
    ModalFooterProps,
    ModalHeader,
    ModalHeaderProps,
    ModalOverlay,
    ModalProps,
    Text,
    UseDisclosureReturn
} from '@chakra-ui/react';

export interface StandardModalProps {
    disclosure: UseDisclosureReturn;
    modalBodyProps?: Partial<ModalBodyProps>;
    modalContentProps?: Partial<ModalContentProps>;
    modalFooterProps?: Partial<ModalFooterProps>;
    modalHeaderProps?: Partial<ModalHeaderProps>;
    modalProps?: Partial<ModalProps>;
    onClose?: () => void;
}

const PADDING_X = [2, 4];
const PADDING_Y = [2, 4];

export const StandardModal = (
    props: StandardModalProps & {
        header?: string;
        children: any;
        footer?: any;
    }
) => {
    const {
        header,
        children,
        footer,
        disclosure,
        modalBodyProps,
        modalContentProps,
        modalFooterProps,
        modalHeaderProps,
        modalProps,
        onClose
    } = props;

    const closeView = () => {
        if (onClose) onClose();
        disclosure.onClose();
    };

    return (
        <Modal
            closeOnEsc={true}
            closeOnOverlayClick={false}
            isCentered
            isOpen={disclosure.isOpen}
            onClose={closeView}
            scrollBehavior={'inside'}
            {...modalProps}
        >
            <ModalOverlay />
            <ModalContent {...modalContentProps}>
                {header && (
                    <ModalHeader paddingX={PADDING_X} {...modalHeaderProps}>
                        <Text>{header}</Text>
                    </ModalHeader>
                )}
                <ModalCloseButton />
                <ModalBody overflow={'auto'} paddingX={PADDING_X} {...modalBodyProps}>
                    {children}
                </ModalBody>
                {footer && (
                    <ModalFooter paddingX={PADDING_X} paddingY={PADDING_Y} {...modalFooterProps}>
                        {footer}
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    );
};
