import { UseDisclosureReturn } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { DateField, EmailField, HiddenField, TextField, Wizard, WizardTabPanel } from '../../../components';
import { factorAccountsService } from '../../../services';
import { Account, Building } from '../../../types';

type AccountForm = {
    accountName: string;
    contactAddressLine1: string;
    contactAddressLine2: string;
    contactAddressLine3?: string;
    contactAddressLine4?: string;
    contactPostcode: string;
    buildingId: string;
    email?: string;
    propertyId: string;
    startDate: string;
};

interface AccountWizardProps {
    account: Account;
    building: Building;
    disclosure: UseDisclosureReturn;
    onClose?: () => void;
    onSaveSuccess?: (account: Account) => void;
}

export const EditAccountWizard = (props: AccountWizardProps) => {
    const { account, building, disclosure, onClose, onSaveSuccess } = props;

    const form = useForm<AccountForm>({
        defaultValues: {
            buildingId: building.id,
            propertyId: account.propertyId,
            accountName: account.accountName,
            contactAddressLine1: account.contactAddressLine1 || '',
            contactAddressLine2: account.contactAddressLine2 || '',
            contactAddressLine3: account.contactAddressLine3 || '',
            contactAddressLine4: account.contactAddressLine4 || '',
            contactPostcode: account.contactPostcode || '',
            email: account.email || '',
            startDate: account.startDate
        },
        resolver: joiResolver(
            Joi.object({
                buildingId: Joi.string().uuid().required(),
                propertyId: Joi.string().uuid().required(),
                accountName: Joi.string().label('Full name').trim().min(3).max(100).required(),
                contactAddressLine1: Joi.string().label('Address line 1').trim().min(1).max(50).required(),
                contactAddressLine2: Joi.string().label('Address line 2').trim().min(1).max(50).required(),
                contactAddressLine3: Joi.string()
                    .label('Address line 3')
                    .trim()
                    .allow(null)
                    .allow('')
                    .empty()
                    .optional(),
                contactAddressLine4: Joi.string()
                    .label('Address line 4')
                    .trim()
                    .allow(null)
                    .allow('')
                    .empty()
                    .optional(),
                contactPostcode: Joi.string().label('Postcode').trim().max(10).required(),
                email: Joi.string()
                    .label('Email address')
                    .trim()
                    .email({ tlds: { allow: false } })
                    .allow(null, '')
                    .empty()
                    .max(250)
                    .optional(),
                startDate: Joi.date()
                    .label('Start date')
                    .messages({
                        'date.max': '"Start Date" cannot be in the future'
                    })
                    .max('now')
                    .raw()
                    .required()
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const save = async (formValues: AccountForm) => {
        return factorAccountsService.update({
            pathParams: {
                buildingId: building.id,
                id: account.id
            },
            body: formValues
        });
    };

    return (
        <Wizard<AccountForm, Account>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    md: 'md'
                }
            }}
            form={form}
            heading={'Edit account details'}
            onClose={onClose}
            onSaveSuccess={onSaveSuccess}
            save={save}
            steps={[
                {
                    heading: 'Account details',
                    fieldNames: ['accountName', 'email', 'startDate', 'buildingId', 'propertyId']
                },
                {
                    heading: 'Contact address',
                    fieldNames: [
                        'contactAddressLine1',
                        'contactAddressLine2',
                        'contactAddressLine3',
                        'contactAddressLine4',
                        'contactPostcode'
                    ]
                }
            ]}
        >
            <WizardTabPanel>
                <TextField name="accountName" formLabel="Full name" formHook={form} isRequired />
                <EmailField name="email" formLabel="Email address" formHook={form} />
                <DateField name="startDate" formLabel="Start date" formHook={form} isRequired />
                <HiddenField name="buildingId" formHook={form} />
                <HiddenField name="propertyId" formHook={form} />
            </WizardTabPanel>
            <WizardTabPanel>
                <TextField name="contactAddressLine1" formLabel="Address line 1" formHook={form} />
                <TextField name="contactAddressLine2" formLabel="Address line 2" formHook={form} />
                <TextField name="contactAddressLine3" formLabel="Address line 3" formHook={form} />
                <TextField name="contactAddressLine4" formLabel="Address line 4" formHook={form} />
                <TextField name="contactPostcode" formLabel="Postcode" formHook={form} />
            </WizardTabPanel>
        </Wizard>
    );
};
