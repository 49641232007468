import { InputLeftAddon } from '@chakra-ui/react';
import { FieldValues } from 'react-hook-form';
import { BaseInput, BaseInputProps } from './BaseInput';

export const CurrencyField = <T extends FieldValues>(props: BaseInputProps<T>) => {
    return (
        <BaseInput
            inputElementLeft={<InputLeftAddon children="£" />}
            {...props}
            inputProps={{
                ...props.inputProps,
                type: 'number'
            }}
        />
    );
};
