import { StandardModal, StandardModalProps } from '../../modals';
import { AccountingHelp } from './AccountingHelp';
import { CorrespondenceHelp } from './CorrespondenceHelp';
import { PropertiesHelp } from './PropertiesHelp';

export enum HelpKey {
    ACCOUNTING = 'ACCOUNTING',
    CORRESPONDENCE = 'CORRESPONDENCE',
    FORUMS = 'FORUMS',
    PROPERTIES = 'PROPERTIES'
}

interface HelpModalProps extends StandardModalProps {
    helpKey: HelpKey;
}

export const HelpModal = (props: HelpModalProps) => {
    const { helpKey, disclosure, onClose } = props;

    const getHelpHeading = () => {
        switch (helpKey) {
            case HelpKey.ACCOUNTING:
                return 'Accounting Help';
            case HelpKey.CORRESPONDENCE:
                return 'Correspondence Help';
            case HelpKey.FORUMS:
                return 'Forums';
            case HelpKey.PROPERTIES:
                return 'Properties Help';
            default:
                return '';
        }
    };

    const getHelpContent = () => {
        switch (helpKey) {
            case HelpKey.ACCOUNTING:
                return <AccountingHelp />;
            case HelpKey.CORRESPONDENCE:
                return <CorrespondenceHelp />;
            case HelpKey.PROPERTIES:
                return <PropertiesHelp />;
            default:
                return <></>;
        }
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full'
                }
            }}
            onClose={onClose}
            header={getHelpHeading()}
        >
            {getHelpContent()}
        </StandardModal>
    );
};
