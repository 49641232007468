import { Container, TabPanel, TabPanels, Tabs, Text, useDisclosure } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BaseRadioGroupField, Heading1, WeFactorSecondaryButton, WeFactorSubmitButton } from '../../components';
import { layoutService, userBuildingsService } from '../../services';
import { Building } from '../../types';
import { AssumeAdminFormDialog, BuildingWizard } from '../buildings';

type FormOptions = {
    accountType: 'FACTOR' | 'OWNER' | 'TRANSFER';
};

export const NoBuildingPage = () => {
    const navigate = useNavigate();

    const disclosureAddBuilding = useDisclosure();
    const disclosureAssumeAdmin = useDisclosure();

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        layoutService.hideGlobalSearch();

        return () => {
            layoutService.showGlobalSearch();
        };
    }, []);

    const onAddBuildingClose = async (building?: Building) => {
        if (building) {
            userBuildingsService.fetch().then(() => navigate(`/buildings/${building.id}/properties`));
        }
    };

    const onAssumeAdminClose = async (building?: Building) => {
        if (building) {
            userBuildingsService.fetch().then(() => navigate(`/buildings/${building.id}/properties`));
        }
    };

    const form = useForm<FormOptions>({
        resolver: joiResolver(
            Joi.object({
                accountType: Joi.string()
                    .valid('FACTOR', 'OWNER', 'TRANSFER')
                    .messages({ 'any.required': 'Please select a statement' })
                    .required()
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const accountType = form.watch('accountType');

    const next = () => {
        if (currentStep === 0) {
            if (accountType === 'FACTOR') {
                disclosureAddBuilding.onOpen();
            } else if (accountType === 'OWNER') {
                setCurrentStep(1);
            } else if (accountType === 'TRANSFER') {
                disclosureAssumeAdmin.onOpen();
            }
        }
    };

    const back = () => {
        setCurrentStep(0);
    };

    return (
        <Container
            height="100%"
            width="100%"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <>
                <Heading1>Welcome</Heading1>
                <Tabs index={currentStep}>
                    <TabPanels>
                        <TabPanel>
                            <Text mb={2}>Select the applicable option:</Text>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <BaseRadioGroupField
                                    name="accountType"
                                    formHook={form}
                                    direction={'column'}
                                    options={[
                                        {
                                            value: 'FACTOR',
                                            label: "I'm an account manager for my building/estate and I want to establish accounts for its properties and start managing them"
                                        },
                                        {
                                            value: 'OWNER',
                                            label: "I'm a property owner wanting to access the account established for me by my building/estate account manager"
                                        },
                                        {
                                            value: 'TRANSFER',
                                            label: 'Administration rights of an existing building/estate are being transferred to me'
                                        }
                                    ]}
                                />
                                <WeFactorSubmitButton onClick={next} mt={5}>
                                    Next
                                </WeFactorSubmitButton>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <Text mb={2}>
                                We are currently extending the WeFactor application to enable owners to have a read only
                                view of their account(s) on WeFactor.
                            </Text>
                            <Text mb={5}>We expect this feature to be available by summer 2024.</Text>
                            <WeFactorSecondaryButton onClick={back} mt={5} isDisabled={!accountType}>
                                Back
                            </WeFactorSecondaryButton>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </>
            {disclosureAddBuilding.isOpen && (
                <BuildingWizard disclosure={disclosureAddBuilding} onClose={onAddBuildingClose} />
            )}
            {disclosureAssumeAdmin.isOpen && (
                <AssumeAdminFormDialog disclosure={disclosureAssumeAdmin} onClose={onAssumeAdminClose} />
            )}
        </Container>
    );
};
