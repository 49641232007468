import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { PageLoadingSkeleton } from '../components';
import { userAccountService, userBuildingsService } from '../services';

export const AccountGuard = () => {
    const navigate = useNavigate();

    const { accountId } = useParams();

    const [account] = useObservable(userAccountService.activeEntity$);
    const [accounts] = useObservable(userAccountService.entities$);
    const [accountsLoaded] = useObservable(userAccountService.loaded$);

    useEffect(() => {
        if (!accountId || !accounts || !accountsLoaded || accountId === account?.id) {
            return;
        }

        const id = accounts.find((account) => account.id === accountId)?.id;

        if (id) {
            userAccountService.setActiveEntityId(id);
            userBuildingsService.clearActiveEntity();
        } else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, account, accounts, accountsLoaded]);

    if (account) {
        return <Outlet />;
    } else {
        return <PageLoadingSkeleton />;
    }
};
