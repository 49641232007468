import { Account, Accounts, Properties } from '../../../types';
import { formatStreetAddress } from '../../../util';

export interface AccountOptionGroup {
    label: string;
    options: AccountOption[];
}

export interface AccountOption {
    label: string;
    value: string;
}

export const createAccountOptions = (accounts: Accounts, properties?: Properties): AccountOptionGroup[] => {
    const owners = accounts.getActiveAccounts();
    const currentOwners: any[] = [];
    const previousOwners: any[] = [];

    const getLabel = (account: Account) => {
        if (properties) {
            return `${account.accountName} - (${formatStreetAddress(properties.getProperty(account.propertyId)!)})`;
        } else {
            return account.accountName;
        }
    };

    for (let i = 0; i < owners.length; i++) {
        const owner: Account = owners[i];
        const ownerOption = {
            label: getLabel(owner),
            value: owner.id
        };
        if (owner.isCurrentOwner) {
            currentOwners.push(ownerOption);
        } else {
            previousOwners.push(ownerOption);
        }
    }

    const groups: any[] = [];

    if (currentOwners.length > 0) {
        groups.push({
            label: 'Current owners',
            options: currentOwners
        });
    }

    if (previousOwners.length > 0) {
        groups.push({
            label: 'Previous owners',
            options: previousOwners
        });
    }

    return groups;
};
