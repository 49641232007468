import { Spinner, Text } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    CurrencyField,
    DateField,
    DocumentsField,
    SelectAccountsField,
    SelectShareMethodField,
    TextField,
    Wizard,
    WizardProps,
    WizardTabPanel
} from '../../../components';
import {
    factorInvoiceService,
    factorTransactionService,
    maintenanceAccountTransactionsService
} from '../../../services';
import {
    Account,
    Accounts,
    Building,
    DivisionMethod,
    DivisionVersion,
    Invoice,
    Properties,
    ShareMethod,
    currentOwnerFilter
} from '../../../types';
import { formatCurrency } from '../../../util';
import { ShareCalculator } from '../../correspondence/dialogs/components/ShareCalculator';

type InvoiceForm = {
    buildingId: string;
    maintenanceAccountTransactionId: string | null;

    accountIds: string[];
    amount: number | '';
    date: string;
    documents: { id: string }[];
    shareMethod: string;
    subject: string;
};

interface InvoiceWizardProps extends WizardProps<Invoice> {
    accounts: Accounts;
    building: Building;
    divisionVersions: DivisionVersion[];
    invoice?: Partial<Invoice>;
    maintenanceAccountTransactionId?: string;
    properties: Properties;
}

export const InvoiceWizard = (props: InvoiceWizardProps) => {
    const {
        disclosure,
        onClose,
        onSaveSuccess,
        accounts,
        building,
        divisionVersions,
        invoice,
        maintenanceAccountTransactionId,
        properties
    } = props;

    const { id: buildingId } = building;

    const { activeDivisionVersion, equalShareDivisionVersion, invoiceDivisionVersion } = useMemo(() => {
        const dvs = divisionVersions;
        return {
            activeDivisionVersion: dvs.find((dv) => dv.active)!,
            equalShareDivisionVersion: dvs.find((dv) => dv.divisionMethod === DivisionMethod.EQUAL_SHARE)!,
            invoiceDivisionVersion: invoice ? dvs.find((dv) => dv.id === invoice.divisionVersionId) : undefined
        };
    }, [divisionVersions, invoice]);

    const [shares, setShares] = useState<Map<string, number> | undefined>();

    const getDefaultShareMethod = () => {
        if (invoice) {
            if (invoice.shareMethod === ShareMethod.EQUAL_SHARE) {
                return ShareMethod.EQUAL_SHARE;
            } else if (invoice.divisionVersionId === activeDivisionVersion.id) {
                return ShareMethod.RATEABLE;
            } else {
                return ShareMethod.RATEABLE_PREVIOUS;
            }
        }

        return undefined;
    };

    const form = useForm<InvoiceForm>({
        defaultValues: {
            buildingId: buildingId,
            accountIds:
                invoice && invoice.id
                    ? invoice.invoiceShares?.map((invoiceShare) => invoiceShare.accountId)
                    : accounts.getActiveAccounts({ filters: [currentOwnerFilter()] }).map((account) => account.id),
            amount: invoice?.amount || '',
            date: invoice?.date || '',
            documents: invoice?.documents || [],
            shareMethod: getDefaultShareMethod(),
            subject: invoice?.subject || ''
        },
        resolver: joiResolver(
            Joi.object({
                accountIds: Joi.array()
                    .label('Select accounts sharing the charges')
                    .messages({ 'array.min': 'You must select at least 1 account' })
                    .items(Joi.string().uuid().required())
                    .min(1)
                    .max(100)
                    .required(),
                amount: Joi.number().label('Charged amount').precision(2).positive().required(),
                buildingId: Joi.string().uuid().required(),
                date: Joi.date()
                    .label('Date of charges')
                    .messages({
                        'date.max': '"Date of charges" cannot be set in the future'
                    })
                    .max(new Date())
                    .raw()
                    .required(),
                documents: Joi.array()
                    .label('Documents')
                    .items(
                        Joi.object({
                            id: Joi.string().required()
                        }).unknown(true)
                    )
                    .min(0)
                    .max(10)
                    .required(),
                maintenanceAccountTransactionId: Joi.string().uuid().allow(null).optional(),
                shareMethod: Joi.string().label('How should the charges be shared?').allow(ShareMethod).required(),
                subject: Joi.string().label('Reason for charges').trim().min(3).max(100).required()
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const save = async (formValues: InvoiceForm) => {
        if (invoice?.id) {
            const updatedInvoice = await factorInvoiceService.update({
                pathParams: {
                    buildingId: buildingId,
                    id: invoice.id
                },
                body: formValues
            });

            factorTransactionService.updateEntity(updatedInvoice.transaction.id, updatedInvoice.transaction);

            return updatedInvoice;
        } else {
            const createdInvoice = await factorInvoiceService.create({
                pathParams: {
                    buildingId: buildingId
                },
                body: {
                    ...formValues,
                    maintenanceAccountTransactionId
                }
            });

            factorTransactionService.upsertEntities(createdInvoice.transaction);

            if (maintenanceAccountTransactionId) {
                maintenanceAccountTransactionsService.updateEntity(maintenanceAccountTransactionId, {
                    dismissed: true,
                    invoiceId: createdInvoice?.id
                });
            }

            return createdInvoice;
        }
    };

    const accountIds = form.watch('accountIds');
    const amount = form.watch('amount');
    const shareMethod = form.watch('shareMethod');

    useEffect(() => {
        setShares(undefined);

        if (!accountIds || !amount || !shareMethod) {
            return;
        }

        let divisionVersion;

        if (shareMethod === ShareMethod.EQUAL_SHARE) {
            divisionVersion = equalShareDivisionVersion;
        } else if (shareMethod === ShareMethod.RATEABLE) {
            divisionVersion = activeDivisionVersion;
        } else if (shareMethod === ShareMethod.RATEABLE_PREVIOUS) {
            divisionVersion = invoiceDivisionVersion;
        }

        setShares(
            new ShareCalculator(accounts.getAccounts()).calculateAmountsByDivisionVersion(
                accountIds,
                amount,
                divisionVersion
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountIds, amount, shareMethod]);

    return (
        <Wizard<InvoiceForm, Invoice>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: '3xl'
                }
            }}
            modalContentProps={{
                height: '2xl'
            }}
            onClose={onClose}
            onSaveSuccess={onSaveSuccess}
            form={form}
            heading={`${invoice ? 'Modify' : 'Record new'} charge`}
            save={save}
            steps={[
                {
                    heading: 'Details of charges',
                    fieldNames: ['subject', 'date', 'amount', 'shareMethod']
                },
                {
                    heading: 'Select accounts sharing the charges',
                    fieldNames: ['accountIds']
                },
                {
                    heading: 'Upload attachments',
                    fieldNames: ['documents']
                }
            ]}
        >
            <WizardTabPanel>
                <TextField name="subject" formLabel="Reason for charges" formHook={form} isRequired />
                <DateField name="date" formLabel="Date of charges" formHook={form} isRequired />
                <CurrencyField name="amount" formLabel="Charged amount" formHook={form} isRequired />
                <SelectShareMethodField
                    name="shareMethod"
                    formLabel="How should the charges be shared?"
                    formHook={form}
                    isRequired
                    activeDivisionVersion={activeDivisionVersion}
                    currentDivisionVersion={invoiceDivisionVersion}
                />
            </WizardTabPanel>
            <WizardTabPanel>
                <SelectAccountsField
                    name="accountIds"
                    formHook={form}
                    accounts={accounts}
                    properties={properties}
                    isRequired
                    additionalColumn={{
                        getHeading() {
                            return 'Share';
                        },
                        getContent(account: Account) {
                            const share = shares?.get(account.id);
                            return share ? <Text>{formatCurrency(share)}</Text> : <Spinner size={'xs'} />;
                        }
                    }}
                />
            </WizardTabPanel>
            <WizardTabPanel>
                <DocumentsField name="documents" formHook={form} building={building} />
            </WizardTabPanel>
        </Wizard>
    );
};
