import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FieldPath, FieldValues, UseControllerReturn, UseFormReturn, useController } from 'react-hook-form';

export interface BaseFormControlProps<T extends FieldValues> {
    name: FieldPath<T>;
    formHook: UseFormReturn<T>;
    formHelperText?: string | React.ReactElement;
    formLabel?: string;
    groupLabel?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    onValueChanged?: (value: any) => void;
}

type InternalBaseFormControlProps<T extends FieldValues> = BaseFormControlProps<T> & {
    render: (controller: UseControllerReturn<T, FieldPath<T>>) => React.ReactElement;
};

export const BaseFormControl = <T extends FieldValues>(props: InternalBaseFormControlProps<T>) => {
    const { formHelperText, formHook, formLabel, groupLabel, isDisabled, isRequired, name, onValueChanged, render } =
        props;

    const controller = useController<T, FieldPath<T>>({
        control: formHook.control,
        name: name,
        rules: { required: isRequired }
    });

    const {
        formState: { isSubmitting }
    } = formHook;

    const {
        fieldState: { error }
    } = controller;

    const value = formHook.watch(name);

    useEffect(() => {
        if (onValueChanged) onValueChanged(value);
    }, [onValueChanged, value]);

    return (
        <FormControl
            aria-errormessage={error?.message}
            isDisabled={isDisabled || isSubmitting}
            isInvalid={!!error}
            isRequired={isRequired}
            flexDirection="column"
            justifyContent="center"
            mb={2}
        >
            {formLabel && (
                <FormLabel color="#84818A" fontSize="md" mb={1} ml={0.5} htmlFor={name}>
                    {formLabel}
                </FormLabel>
            )}
            {groupLabel && <FormLabel as="legend">{groupLabel}</FormLabel>}
            {render(controller)}
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
            {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
        </FormControl>
    );
};
