import { Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { useMemo } from 'react';
import { NamedValue, StandardModal, StandardModalProps } from '../../../components';
import { Accounts, Properties, Transaction, getTransactionTypeName } from '../../../types';
import { formatCurrency, formatDate, formatStreetAddress } from '../../../util';

interface TransactionViewProps extends StandardModalProps {
    accounts: Accounts;
    properties: Properties;
    transaction: Transaction;
}

export const TransactionView = (props: TransactionViewProps) => {
    const { accounts, disclosure, properties, transaction, onClose } = props;

    const header = useMemo(() => `${getTransactionTypeName(transaction.type)} details`, [transaction.type]);

    const renderAccountName = () => {
        if (!transaction.accountId) return;
        const account = accounts.getAccount(transaction.accountId);
        if (!account) return;
        return account.accountName;
    };

    const renderAddress = () => {
        if (!transaction.accountId) return;
        const account = accounts.getAccount(transaction.accountId);
        if (!account) return;
        const property = properties.getProperty(account.propertyId);
        if (!property) return;
        return formatStreetAddress(property);
    };

    const templateColumns = useBreakpointValue(
        {
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)'
        },
        { fallback: 'md', ssr: false }
    );

    return (
        <StandardModal
            modalProps={{
                size: {
                    base: 'full',
                    md: 'lg'
                }
            }}
            disclosure={disclosure}
            onClose={onClose}
            header={header}
        >
            <Grid gap={4} marginBottom={4} templateColumns={templateColumns}>
                <GridItem>
                    <NamedValue label="Amount">{formatCurrency(transaction.amount)}</NamedValue>
                </GridItem>
                <GridItem>
                    <NamedValue label="Date">{formatDate(transaction.date)}</NamedValue>
                </GridItem>
                <GridItem>
                    <NamedValue label="Account">{renderAccountName()}</NamedValue>
                </GridItem>
                <GridItem>
                    <NamedValue label="Property">{renderAddress()}</NamedValue>
                </GridItem>
            </Grid>
        </StandardModal>
    );
};
