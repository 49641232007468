import { Icon } from '@chakra-ui/react';

export const EmptyBoxIcon = () => (
    <Icon h={85} w={85}>
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.5 48.9734H16.5V74.4274C16.5 75.6415 17.2961 76.7126 18.4594 77.0619C23.9855 78.7187 39.5257 83.3814 43.2094 84.4869C43.725 84.6409 44.275 84.6409 44.7906 84.4869C48.4743 83.3814 64.0145 78.7187 69.5406 77.0619C70.7039 76.7126 71.5 75.6415 71.5 74.4274C71.5 68.0529 71.5 48.9734 71.5 48.9734Z"
                fill="#CADCF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.5 48.9734H44C44 48.9734 43.7332 84.6024 44 84.6024C44.2667 84.6024 44.5321 84.5639 44.7906 84.4869C48.4742 83.3814 64.0145 78.7187 69.5406 77.0619C70.7039 76.7126 71.5 75.6415 71.5 74.4274C71.5 68.0529 71.5 48.9734 71.5 48.9734Z"
                fill="#A4BBDB"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44 57.8957L16.5 48.9734C16.5 48.9734 11.6779 56.1234 9.52187 59.3189C9.27987 59.6777 9.22075 60.1274 9.361 60.5357C9.50262 60.9441 9.82575 61.2617 10.2369 61.3965C15.4605 63.0932 30.9237 68.1175 34.98 69.4347C35.5616 69.6245 36.1996 69.4059 36.5434 68.8985C38.3872 66.1774 44 57.8957 44 57.8957Z"
                fill="#E9F3FC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.5 48.9734L44 57.8957C44 57.8957 49.6128 66.1774 51.4566 68.8985C51.8004 69.4059 52.4384 69.6245 53.02 69.4347C57.0763 68.1175 72.5395 63.0932 77.7631 61.3965C78.1743 61.2617 78.4974 60.9441 78.639 60.5357C78.7793 60.1274 78.7201 59.6777 78.4781 59.3189C76.3221 56.1234 71.5 48.9734 71.5 48.9734Z"
                fill="#E9F3FC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.3436 30.2308C36.9999 29.7261 36.3632 29.5075 35.7816 29.6973C31.7281 31.0131 16.2607 36.0388 11.0371 37.7369C10.626 37.8703 10.3015 38.1879 10.1612 38.5963C10.021 39.0046 10.0801 39.4556 10.3221 39.8131C12.3076 42.7584 16.5 48.9734 16.5 48.9734L44 40.0208C44 40.0208 39.061 32.7566 37.3436 30.2308Z"
                fill="#CADCF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.6779 39.8131C77.9199 39.4556 77.979 39.0046 77.8388 38.5963C77.6985 38.1879 77.374 37.8703 76.9629 37.7369C71.7393 36.0388 56.2719 31.0131 52.2184 29.6973C51.6368 29.5075 51.0001 29.7261 50.6564 30.2308C48.939 32.7566 44 40.0208 44 40.0208L71.5 48.9734C71.5 48.9734 75.6924 42.7584 77.6779 39.8131Z"
                fill="#E9F3FC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.5 48.9734L44 40.0208L16.5 48.9734L44 57.8957L71.5 48.9734Z"
                fill="#CADCF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44 57.8957V40.0208L16.5 48.9734L44 57.8957Z"
                fill="#A4BBDB"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.4752 43.9698C37.9032 43.5958 37.3876 43.1956 36.9297 42.7763C36.3715 42.2634 35.5011 42.3005 34.9869 42.8601C34.474 43.4184 34.5111 44.2888 35.0707 44.8016C35.6331 45.3186 36.267 45.8109 36.971 46.2715C37.6062 46.6868 38.4587 46.508 38.874 45.8728C39.2892 45.2375 39.1105 44.385 38.4752 43.9698Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.1907 39.7458C33.7824 39.1916 33.4372 38.6348 33.154 38.0779C32.8102 37.4014 31.9811 37.1319 31.3046 37.4756C30.6281 37.8194 30.3586 38.6485 30.7024 39.325C31.0516 40.0098 31.4751 40.6959 31.977 41.3765C32.4266 41.987 33.2874 42.1176 33.8992 41.668C34.5097 41.217 34.6404 40.3563 34.1907 39.7458Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.1805 34.1344C32.1942 33.5803 32.2754 33.0426 32.4239 32.527C32.6329 31.7983 32.2107 31.0351 31.4806 30.8261C30.7519 30.6171 29.9887 31.0393 29.7797 31.7694C29.5694 32.5064 29.4511 33.275 29.4305 34.0656C29.4126 34.8246 30.0121 35.4558 30.7711 35.4736C31.5301 35.4929 32.1612 34.8934 32.1805 34.1344Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.2952 29.5419C34.6486 29.2146 35.0446 28.9121 35.4777 28.6385C36.1199 28.2329 36.3124 27.3831 35.9081 26.7424C35.5025 26.1003 34.6527 25.9078 34.0106 26.312C33.429 26.6791 32.901 27.0848 32.4266 27.5248C31.8697 28.0404 31.8367 28.9108 32.3524 29.4676C32.868 30.0245 33.7384 30.0575 34.2952 29.5419Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.4089 27.17C40.1005 27.0421 40.8334 26.9596 41.6061 26.928C42.3651 26.895 42.9536 26.2529 42.922 25.4953C42.8904 24.7376 42.2482 24.1478 41.4892 24.1794C40.5817 24.2179 39.721 24.3155 38.9084 24.4654C38.1631 24.6043 37.6681 25.322 37.807 26.0673C37.9445 26.8139 38.6622 27.3075 39.4089 27.17Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.5754 27.0889C47.7483 27.1026 48.84 27.0614 49.8534 26.972C50.6096 26.9046 51.1693 26.2364 51.1019 25.4801C51.0345 24.7253 50.3663 24.1656 49.61 24.233C48.6819 24.3141 47.6823 24.3526 46.607 24.3389C45.848 24.3306 45.2251 24.9398 45.2169 25.6974C45.2073 26.4564 45.8164 27.0806 46.5754 27.0889Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.373 26.1291C55.7342 25.7125 56.8837 25.179 57.8462 24.5671C58.4856 24.1588 58.6754 23.309 58.267 22.6683C57.86 22.0289 57.0089 21.8391 56.3681 22.2475C55.594 22.7398 54.6659 23.1633 53.5686 23.4988C52.844 23.7215 52.4342 24.4901 52.657 25.2161C52.8784 25.9421 53.6484 26.3505 54.373 26.1291Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.3718 20.7185C62.0208 19.3765 62.2696 17.9575 62.2064 16.5894C62.1706 15.8304 61.5258 15.2446 60.7681 15.2804C60.0105 15.3161 59.4234 15.9596 59.4591 16.7173C59.5031 17.6468 59.3354 18.6106 58.8954 19.5223C58.5654 20.2056 58.8514 21.0293 59.5348 21.3593C60.2181 21.6893 61.0418 21.4019 61.3718 20.7185Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.4959 11.7274C59.4935 10.373 58.1336 9.42976 56.6514 9.12588C55.9075 8.97463 55.1801 9.45313 55.0275 10.197C54.8762 10.9409 55.3561 11.6683 56.0986 11.8209C56.9539 11.9955 57.7074 12.5826 58.2862 13.365C58.7386 13.9741 59.6007 14.102 60.2099 13.651C60.8204 13.1986 60.9482 12.3365 60.4959 11.7274Z"
                fill="#1E78C0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5329 9.39263C44.0206 0.968002 56.914 1.5125 49.4079 9.39263H42.5329Z"
                fill="#CADCF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5329 11.5266C44.0206 19.9499 56.914 19.4068 49.4079 11.5266H42.5329Z"
                fill="#CADCF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.4091 11.8484H51.6161C52.3751 11.8484 52.9911 11.2324 52.9911 10.4734C52.9911 9.71437 52.3751 9.09837 51.6161 9.09837H42.4091C41.6515 9.09837 41.0341 9.71437 41.0341 10.4734C41.0341 11.2324 41.6515 11.8484 42.4091 11.8484Z"
                fill="#1E78C0"
            />
        </svg>
    </Icon>
);
