import { DivisionVersion } from '../../../types';
import { entityServiceFactory } from '../entity-service-factory';

export const ownerDivisionVersionsService = entityServiceFactory.create<DivisionVersion>(
    'accounts/{{accountId}}/divisions'
);

export const factorDivisionVersionsService = entityServiceFactory.create<DivisionVersion>(
    'buildings/{{buildingId}}/divisions'
);
