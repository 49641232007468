import { Stack } from '@chakra-ui/react';
import { MdOutlineEmail, MdOutlineSettings, MdOutlineTrendingUp } from 'react-icons/md';
import { ICON_SIZES } from '../../components';
import { UserAccount } from '../../types';
import { NavigationButton } from './NavigationButton';

export const OwnerNavigationList = (props: { selectedUserAccount: UserAccount }) => {
    const { id: accountId } = props.selectedUserAccount;

    return (
        <Stack spacing={3} width={'100%'}>
            <NavigationButton
                icon={<MdOutlineTrendingUp size={ICON_SIZES.LARGE} />}
                label={'Accounting'}
                route={`/accounts/${accountId}/accounting`}
            />
            <NavigationButton
                icon={<MdOutlineEmail size={ICON_SIZES.LARGE} />}
                label={'Correspondence'}
                route={`/accounts/${accountId}/correspondence`}
            />
            <NavigationButton
                icon={<MdOutlineSettings size={ICON_SIZES.LARGE} />}
                label={'Settings'}
                route={`/accounts/${accountId}/settings`}
            />
        </Stack>
    );
};
