import { Container, Skeleton, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { map } from 'rxjs';
import { Heading2, HelpKey, StandardDivider, StandardPage } from '../../components';
import { forumBoardService, forumBoardViewService, userBuildingsService } from '../../services';
import { ForumBoardBox } from './components';

export const ForumsPage = () => {
    const { buildingId } = useParams();

    const [building] = useObservable(userBuildingsService.activeEntity$);

    const [forumBoards] = useObservable(forumBoardService.entities$);

    const [forumBoardViews] = useObservable(
        forumBoardViewService.entities$.pipe(
            map((forumBoardViews) =>
                forumBoardViews.reduce(
                    (timestampMap, { forumBoardId, updatedDate }) => timestampMap.set(forumBoardId, updatedDate),
                    new Map()
                )
            )
        )
    );

    useEffect(() => {
        if (!buildingId) return;
        forumBoardService.fetch({ pathParams: { buildingId: buildingId }, retainEntities: true });
        forumBoardViewService.fetch({ pathParams: { buildingId: buildingId }, retainEntities: true });
    }, [buildingId]);

    const pageHeader = useBreakpointValue({
        base: 'Forums',
        md: `Forums: ${building?.name}`
    });

    return (
        <StandardPage helpKey={HelpKey.FORUMS} title={pageHeader}>
            <Container height={'100%'}>
                <Heading2>Forums</Heading2>
                <Skeleton isLoaded={forumBoards.length > 0} height={'100%'}>
                    <Stack divider={<StandardDivider />} gap={1}>
                        {forumBoards.length === 0 && <Text>There are no forums.</Text>}
                        {forumBoards.map((forumBoard) => (
                            <ForumBoardBox
                                buildingId={buildingId!}
                                forumBoard={forumBoard}
                                forumBoardViews={forumBoardViews}
                            />
                        ))}
                    </Stack>
                </Skeleton>
            </Container>
        </StandardPage>
    );
};
