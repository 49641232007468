import { Box, Icon, Table, Tbody, Td, Text, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { MdOutlineDownload, MdOutlineEmail } from 'react-icons/md';
import { CurrencyBadge, SideBarSkeleton, WeFactorIconButton } from '../../../components';
import { ownerInvoiceService, ownerTransactionService } from '../../../services';
import {
    Account,
    Building,
    Transaction,
    TransactionType,
    getTransactionTypeIcon,
    getTransactionTypeName
} from '../../../types';
import { formatDateShortMonth } from '../../../util';
import { DownloadStatementDialog, SendStatementDialog } from '../../accounting';

interface AccountingSectionProps {
    account: Account;
    building: Building;
}

export const AccountingSection = (props: AccountingSectionProps) => {
    const { account, building } = props;

    const downloadStatementDisclosure = useDisclosure();
    const sendStatementDisclosure = useDisclosure();

    const [invoices] = useObservable(ownerInvoiceService.entities$);
    const [invoicesLoaded] = useObservable(ownerInvoiceService.loaded$);
    const [transactions] = useObservable(ownerTransactionService.entities$);
    const [transactionsLoaded] = useObservable(ownerTransactionService.loaded$);

    useEffect(() => {
        ownerInvoiceService.fetch({
            pathParams: {
                buildingId: account.buildingId,
                accountId: account.id
            }
        });

        ownerTransactionService.fetch({
            pathParams: {
                buildingId: account.buildingId,
                accountId: account.id
            }
        });

        return () => {
            ownerInvoiceService.removeAllEntities();
            ownerTransactionService.removeAllEntities();
        };
    }, [account.buildingId, account.id]);

    if (!account || !invoices || !invoicesLoaded || !transactions || !transactionsLoaded) {
        return <SideBarSkeleton />;
    }

    const getDescription = (transaction: Transaction, transactionTypeName: string) => {
        return transaction.type === TransactionType.INVOICE_SHARE
            ? invoices.find((invoice) =>
                  invoice.invoiceShares.find((invoiceShare) => invoiceShare.id === transaction.id)
              )?.subject
            : transactionTypeName;
    };

    return transactions.length > 0 ? (
        <>
            <Box display={'flex'} justifyContent={'center'}>
                <WeFactorIconButton
                    buttonIcon={MdOutlineEmail}
                    iconButtonProps={{
                        'aria-label': 'Send statement',
                        mr: 2,
                        onClick: sendStatementDisclosure.onOpen
                    }}
                    tooltipText="Send statement"
                />
                <WeFactorIconButton
                    buttonIcon={MdOutlineDownload}
                    iconButtonProps={{
                        'aria-label': 'Download statement',
                        onClick: downloadStatementDisclosure.onOpen
                    }}
                    tooltipText="Download statement"
                />
            </Box>

            <Table size={'sm'}>
                <Tbody>
                    {transactions.map((transaction) => {
                        const icon = getTransactionTypeIcon(transaction.type);
                        const name = getTransactionTypeName(transaction.type);
                        const date = formatDateShortMonth(transaction.date);
                        const description = getDescription(transaction, name);

                        return (
                            <Tr key={transaction.id}>
                                <Td width={'14px'} margin={0} padding={0}>
                                    <Tooltip label={name} placement="right">
                                        <span>
                                            <Icon as={icon} size={2} />
                                        </span>
                                    </Tooltip>
                                </Td>
                                <Td>
                                    <Text mb={1}>{description}</Text>
                                    <Text fontSize={'sm'} fontWeight={200}>
                                        {date}
                                    </Text>
                                </Td>
                                <Td width={'80px'} margin={0} padding={0} textAlign={'right'}>
                                    <CurrencyBadge amount={transaction.amount} />
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            {downloadStatementDisclosure.isOpen && (
                <DownloadStatementDialog
                    account={account}
                    building={building}
                    disclosure={downloadStatementDisclosure}
                />
            )}

            {sendStatementDisclosure.isOpen && (
                <SendStatementDialog account={account} building={building} disclosure={sendStatementDisclosure} />
            )}
        </>
    ) : (
        <Text fontSize={'sm'}>No transactions recorded for this account yet</Text>
    );
};
