import { useBreakpointValue, useDisclosure, useToast } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { ActionMenuItem, DeleteDialog, HelpKey, PageLoadingSkeleton, StandardPage } from '../../components';
import {
    factorAccountsService,
    factorActiveAccountBalancesService,
    factorPropertiesService,
    userBuildingsService
} from '../../services';
import { Accounts, Properties, Property, currentOwnerFilter, propertyFilter } from '../../types';
import { deleteToast, formatStreetAddress } from '../../util';
import { PropertiesTable } from './components';
import { AddAccountWizard } from './dialogs/AddAccountWizard';
import { PropertiesWizard } from './dialogs/PropertiesWizard';
import { PropertiesSideBar } from './sidebars/PropertiesSideBar';

export const PropertiesPage = () => {
    const addAccountDisclosure = useDisclosure();
    const addPropertiesDisclosure = useDisclosure();
    const deletePropertyDisclosure = useDisclosure();

    const [account] = useObservable(factorAccountsService.activeEntity$);
    const [building] = useObservable(userBuildingsService.activeEntity$);
    const [property] = useObservable(factorPropertiesService.activeEntity$);

    const [observedAccounts] = useObservable(factorAccountsService.entities$);
    const [observedAccountsLoaded] = useObservable(factorAccountsService.loaded$);
    const [observedProperties] = useObservable(factorPropertiesService.entities$);
    const [observedPropertiesLoaded] = useObservable(factorPropertiesService.loaded$);

    const [accounts, setAccounts] = useState<Accounts>();
    const [properties, setProperties] = useState<Properties>();
    const [balanceMap, setBalanceMap] = useState<Map<string, number>>();

    const pageHeader = useBreakpointValue({
        base: 'Properties',
        md: `Properties: ${building?.name}`
    });

    const toast = useToast();

    useEffect(() => {
        if (observedAccountsLoaded) {
            setAccounts(new Accounts(observedAccounts));
        }
    }, [observedAccounts, observedAccountsLoaded]);

    useEffect(() => {
        if (observedPropertiesLoaded) {
            setProperties(new Properties(observedProperties));
        }
    }, [observedProperties, observedPropertiesLoaded]);

    useEffect(() => {
        if (!building) return;
        const { id: buildingId } = building;
        loadAccounts(buildingId);
        loadActiveBalances(buildingId);
        loadProperties(buildingId);
    }, [building]);

    const loadAccounts = async (buildingId: string) => {
        factorAccountsService.fetch({ pathParams: { buildingId } });
    };

    const loadActiveBalances = (buildingId: string) => {
        factorActiveAccountBalancesService.fetch({ pathParams: { buildingId } }).then((data) => {
            setBalanceMap(
                data.reduce((previousValue, currentValue) => {
                    previousValue.set(currentValue.id, currentValue.balance);
                    return previousValue;
                }, new Map<string, number>())
            );
        });
    };

    const loadProperties = async (buildingId: string) => {
        factorPropertiesService.fetch({ pathParams: { buildingId } });
    };

    if (!observedAccountsLoaded || !observedPropertiesLoaded) {
        return <PageLoadingSkeleton />;
    }

    if (!accounts || !balanceMap || !building || !properties) {
        return <PageLoadingSkeleton />;
    }

    const deleteProperty = async (property: Property) => {
        let deleted = false;
        await deleteToast(toast, async () => {
            await factorPropertiesService.delete({
                id: property.id,
                pathParams: {
                    buildingId: property.buildingId
                },
                updateEntities: true
            });
            deleted = true;
            loadAccounts(property.buildingId);
        });
        return deleted;
    };

    const addAccountClicked = (property: Property) => {
        factorPropertiesService.setActiveEntityId(property.id);
        addAccountDisclosure.onOpen();
    };

    const deletePropertyClicked = (property: Property) => {
        factorPropertiesService.setActiveEntityId(property.id);
        deletePropertyDisclosure.onOpen();
    };

    const viewPropertyClicked = (property: Property) => {
        const account = accounts
            .getAccounts({
                filters: [currentOwnerFilter(), propertyFilter(property.id)]
            })
            .shift();

        if (account) {
            factorAccountsService.setActiveEntityId(account.id);
            factorPropertiesService.setActiveEntityId(property.id);
        }
    };

    return (
        <>
            <StandardPage
                helpKey={HelpKey.PROPERTIES}
                actions={
                    <>
                        <ActionMenuItem
                            menuItemProps={{
                                onClick: () => {
                                    factorPropertiesService.clearActiveEntity();
                                    addAccountDisclosure.onOpen();
                                }
                            }}
                        >
                            Add new account
                        </ActionMenuItem>
                        <ActionMenuItem
                            menuItemProps={{
                                onClick: addPropertiesDisclosure.onOpen
                            }}
                        >
                            Add more properties
                        </ActionMenuItem>
                    </>
                }
                title={pageHeader}
            >
                <PropertiesTable
                    accounts={accounts}
                    properties={properties}
                    balanceMap={balanceMap}
                    onAddAccount={addAccountClicked}
                    onDeleteProperty={deletePropertyClicked}
                    onViewProperty={viewPropertyClicked}
                />
            </StandardPage>

            {addAccountDisclosure.isOpen && (
                <AddAccountWizard
                    building={building}
                    defaultPropertyId={property?.id}
                    disclosure={addAccountDisclosure}
                    properties={properties}
                />
            )}

            {addPropertiesDisclosure.isOpen && (
                <PropertiesWizard
                    accounts={accounts}
                    building={building}
                    disclosure={addPropertiesDisclosure}
                    properties={properties}
                    onSaveSuccess={() => {
                        const { id: buildingId } = building;
                        loadAccounts(buildingId);
                        loadProperties(buildingId);
                    }}
                />
            )}

            {deletePropertyDisclosure.isOpen && property && (
                <DeleteDialog
                    disclosure={deletePropertyDisclosure}
                    detail={[
                        `This will delete "${formatStreetAddress(property)}".`,
                        'You will need to reconfigure how common costs are shared if this was previously specified to take into account the different number of properties.'
                    ]}
                    title="Delete property"
                    onConfirm={() => deleteProperty(property)}
                />
            )}

            {account && property && (
                <PropertiesSideBar
                    account={account}
                    accounts={accounts}
                    building={building}
                    properties={properties}
                    property={property}
                />
            )}
        </>
    );
};
