import {
    Button,
    ButtonProps,
    Icon,
    IconButton,
    IconButtonProps,
    PlacementWithLogical,
    Tooltip
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { COLOURS } from '../skeletons/constants';

export interface WeFactorIconButtonProps {
    buttonIcon: IconType;
    tooltipPlacement?: PlacementWithLogical;
    tooltipText?: string;
    iconButtonProps: IconButtonProps;
}

export const WeFactorIconButton = (props: WeFactorIconButtonProps) => {
    const { buttonIcon, tooltipPlacement, tooltipText } = props;

    const icon = <IconButton icon={<Icon as={buttonIcon} />} variant={'outline'} {...props.iconButtonProps} />;

    if (tooltipText) {
        return (
            <Tooltip label={tooltipText} placement={tooltipPlacement || 'auto'}>
                {icon}
            </Tooltip>
        );
    } else {
        return icon;
    }
};

export const WeFactorActionButton = (props: ButtonProps) => {
    return (
        <Button
            _hover={{ backgroundColor: COLOURS.GREEN }}
            color={COLOURS.WHITE}
            backgroundColor={COLOURS.BLUE_2}
            variant={'contained'}
            {...props}
        >
            {props.children}
        </Button>
    );
};

export const WeFactorSubmitButton = (props: ButtonProps) => {
    return (
        <WeFactorActionButton type={'submit'} {...props}>
            {props.children}
        </WeFactorActionButton>
    );
};

export const WeFactorSecondaryButton = (props: ButtonProps) => {
    return (
        <Button color={'secondary'} variant={'outline'} {...props}>
            {props.children}
        </Button>
    );
};
