import { CreateToastFnReturn, ToastId } from '@chakra-ui/react';

const DEFAULT_ERROR_TIMEOUT = 8000;
const DEFAULT_SUCCESS_TIMEOUT = 2000;

const handleError = (error: any, loadingToast: ToastId, toast: CreateToastFnReturn, message: string) => {
    const is400Err = error?.response?.status === 400;

    if (is400Err) {
        toast.update(loadingToast, {
            title: error?.response?.data?.message,
            status: 'error',
            duration: 9000,
            isClosable: true
        });
    } else {
        toast.update(loadingToast, {
            title: message,
            status: 'error',
            duration: 2000
        });
    }
};

export const saveToast = async (toast: CreateToastFnReturn, action: () => void | Promise<void>) => {
    const actionToast = toast({
        title: 'Saving',
        status: 'loading',
        position: 'top'
    });

    try {
        await action();

        toast.update(actionToast, {
            title: 'Save complete',
            status: 'success',
            duration: DEFAULT_SUCCESS_TIMEOUT
        });
    } catch (error: any) {
        handleError(error, actionToast, toast, 'Failed to save');
    }
};

export const createToast = async (toast: CreateToastFnReturn, action: () => void | Promise<void>) => {
    const actionToast = toast({
        title: 'Creating',
        status: 'loading',
        position: 'top'
    });

    try {
        await action();

        toast.update(actionToast, {
            title: 'Successfully created',
            status: 'success',
            duration: DEFAULT_SUCCESS_TIMEOUT
        });
    } catch (error: any) {
        handleError(error, actionToast, toast, 'Failed to create');
    }
};

export const updateToast = async (toast: CreateToastFnReturn, action: () => void | Promise<void>) => {
    const actionToast = toast({
        title: 'Updating',
        status: 'loading',
        position: 'top'
    });

    try {
        await action();

        toast.update(actionToast, {
            title: 'Succesfully updated',
            status: 'success',
            duration: DEFAULT_SUCCESS_TIMEOUT
        });
    } catch (error: any) {
        handleError(error, actionToast, toast, 'Failed to update');
    }
};

export const deleteToast = async (toast: CreateToastFnReturn, action: () => void | Promise<void>) => {
    const actionToast = toast({
        title: 'Deleting',
        status: 'loading',
        position: 'top'
    });

    try {
        await action();

        toast.update(actionToast, {
            title: 'Succesfully deleted',
            status: 'success',
            duration: DEFAULT_SUCCESS_TIMEOUT
        });
    } catch (error: any) {
        handleError(error, actionToast, toast, 'Failed to delete');
    }
};

export const genericToast = async (
    toast: CreateToastFnReturn,
    action: () => void | Promise<void>,
    options?: {
        duration?: number;
        title?: string;
        description?: string;
        onSuccessTitle?: string;
        onSuccess?: () => void;
        onErrorTitle?: string;
        onError?: () => void;
    }
) => {
    const actionToast = toast({
        title: options?.title ? options.title : 'Loading',
        description: options?.description,
        duration: options?.duration,
        status: 'loading',
        position: 'top'
    });

    try {
        await action();

        toast.update(actionToast, {
            title: options?.onSuccessTitle ? options.onSuccessTitle : 'Success',
            status: 'success',
            duration: options?.duration || 2000
        });

        if (options?.onSuccess) {
            options.onSuccess();
        }
    } catch (error: any) {
        const is400Err = [400, 403].includes(error?.response?.status);

        if (is400Err) {
            toast.update(actionToast, {
                title: error?.response?.data?.message,
                status: 'error',
                duration: DEFAULT_ERROR_TIMEOUT,
                isClosable: true
            });
        } else {
            toast.update(actionToast, {
                title: options?.onErrorTitle ? options.onErrorTitle : `Something went wrong`,
                status: 'error',
                duration: DEFAULT_ERROR_TIMEOUT
            });
        }

        if (options?.onError) {
            options.onError();
        }
    }
};
