import { StandardModal, StandardModalProps } from '../../../../components';

export const CorrespondenceModal = (props: { children: any } & StandardModalProps) => {
    const { children, disclosure, onClose } = props;

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: '3xl'
                }
            }}
            onClose={onClose}
            header={'Correspondence'}
        >
            {children}
        </StandardModal>
    );
};
