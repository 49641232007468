import {
    Box,
    Skeleton,
    Spacer,
    Step,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    Stepper,
    TabPanels,
    Tabs,
    Text,
    useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { StandardModal, StandardModalProps, WeFactorActionButton, WeFactorSecondaryButton } from '..';
import { saveToast } from '../../util';

export type WizardProps<Entity> = StandardModalProps & {
    onSaveSuccess?: (entity: Entity) => void;
};

interface ExtendedWizardProps<WizardForm extends FieldValues, ReturnEntity> extends WizardProps<ReturnEntity> {
    children: React.ReactElement | React.ReactElement[];
    form: UseFormReturn<WizardForm>;
    heading: string;
    isLoading?: boolean;
    save: (data: WizardForm) => Promise<ReturnEntity | undefined>;
    saveLabel?: string;
    steps: {
        fieldNames: FieldPath<WizardForm>[];
        heading: string;
    }[];
    onStepChange?: (exitingTab: number, enteringTab: number) => void;
}

export const Wizard = <WizardForm extends FieldValues, Entity, ReturnEntity = Entity>(
    props: ExtendedWizardProps<WizardForm, ReturnEntity>
) => {
    const {
        children,
        form,
        heading,
        isLoading,
        steps,
        save,
        saveLabel,
        onSaveSuccess,
        onStepChange,
        disclosure,
        onClose,
        modalContentProps,
        modalProps
    } = props;

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isFinalStep, setIsFinalStep] = useState<boolean>(steps.length === 1);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const toast = useToast();

    const { handleSubmit } = form;

    const closeWizard = () => {
        if (onClose) onClose();
        disclosure.onClose();
    };

    const submit = async (formValues: WizardForm) => {
        setIsSubmitting(true);

        try {
            await saveToast(toast, async () => {
                const responseContent = await save(formValues);
                if (onSaveSuccess && responseContent) onSaveSuccess(responseContent);
                closeWizard();
            });
        } catch (error: any) {
            console.error('Error while saving entity', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleNextClick = async () => {
        if (await isCurrentTabValid()) {
            const newStepIndex = currentStep + 1;
            setCurrentStep(newStepIndex);
            setIsFinalStep(newStepIndex === steps.length - 1);
            if (onStepChange) onStepChange(currentStep, newStepIndex);
        }
    };

    const handlePreviousClick = async () => {
        const newStepIndex = currentStep - 1;
        setCurrentStep(newStepIndex);
        setIsFinalStep(false);
        if (onStepChange) onStepChange(currentStep, newStepIndex);
    };

    const isCurrentTabValid = async () => {
        const fieldNames: FieldPath<WizardForm>[] = steps[currentStep].fieldNames;
        await form.trigger(fieldNames, { shouldFocus: true });
        return !fieldNames.some((fieldName) => form.getFieldState(fieldName).error);
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalContentProps={modalContentProps}
            modalProps={modalProps}
            onClose={onClose}
            header={heading}
            footer={
                <>
                    <WeFactorSecondaryButton onClick={disclosure.onClose} minWidth={'100px'}>
                        Cancel
                    </WeFactorSecondaryButton>

                    <Spacer />

                    {steps.length > 1 && (
                        <WeFactorActionButton
                            isDisabled={isLoading || isSubmitting || currentStep === 0}
                            onClick={handlePreviousClick}
                            marginRight={[2, 4]}
                            minWidth={'100px'}
                        >
                            Previous
                        </WeFactorActionButton>
                    )}

                    {isFinalStep ? (
                        <WeFactorActionButton
                            isDisabled={isLoading || isSubmitting}
                            onClick={handleSubmit(submit)}
                            minWidth={'100px'}
                        >
                            {saveLabel || 'Save'}
                        </WeFactorActionButton>
                    ) : (
                        <WeFactorActionButton
                            isDisabled={isLoading || isSubmitting}
                            onClick={handleNextClick}
                            minWidth={'100px'}
                        >
                            Next
                        </WeFactorActionButton>
                    )}
                </>
            }
        >
            <form
                id="wizard-form"
                autoComplete="off"
                noValidate
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'auto'
                }}
            >
                {steps.length > 1 && (
                    <>
                        <Box paddingX={0} marginBottom={3}>
                            <Stepper index={currentStep} size={'sm'} marginBottom={3}>
                                {steps.map((step, index) => (
                                    <Step key={step.heading + index}>
                                        <StepIndicator>
                                            <StepStatus
                                                active={<StepNumber />}
                                                complete={<StepIcon />}
                                                incomplete={<StepNumber />}
                                            />
                                        </StepIndicator>
                                        <StepSeparator />
                                    </Step>
                                ))}
                            </Stepper>
                            <Text>{`${currentStep + 1}. ${steps[currentStep].heading}`}</Text>
                        </Box>
                        <hr />
                    </>
                )}
                <Skeleton isLoaded={!isLoading}>
                    <Tabs isFitted index={currentStep} variant={'soft-rounded'}>
                        <TabPanels paddingX={2} paddingY={[2, 4]}>
                            {children}
                        </TabPanels>
                    </Tabs>
                </Skeleton>
            </form>
        </StandardModal>
    );
};
