import { Box, Flex, Icon, Spinner, Table, Tbody, Td, Text, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useMemo } from 'react';
import {
    MdOutlineEdit,
    MdOutlineGroups,
    MdOutlinePerson,
    MdOutlinePersonAdd,
    MdOutlinePersonRemove,
    MdOutlineWarningAmber
} from 'react-icons/md';
import {
    Currency,
    DeleteDialog,
    ExpandableMenu,
    InformationDialog,
    StandardBadge,
    WeFactorIconButton
} from '../../../components';
import {
    factorAccountsService,
    factorActiveAccountBalancesService,
    ownerAccountBalanceService
} from '../../../services';
import {
    Account,
    Accounts,
    Balance,
    Building,
    Properties,
    Property,
    notDeletedFilter,
    propertyFilter
} from '../../../types';
import { deleteToast, formatDate } from '../../../util';
import { AddAccountWizard } from '../dialogs/AddAccountWizard';
import { EditAccountWizard } from '../dialogs/EditAccountWizard';

interface DetailsSectionProps {
    account: Account;
    accounts: Accounts;
    building: Building;
    property: Property;
    properties: Properties;
}

export const DetailsSection = (props: DetailsSectionProps) => {
    const { account, accounts, building, properties, property } = props;

    const propertyAccounts = useMemo(
        () =>
            accounts.getAccounts({
                filters: [notDeletedFilter(), propertyFilter(property.id)]
            }),
        [accounts, property]
    );

    const [balance] = useObservable(ownerAccountBalanceService.activeEntity$);

    const addAccountDisclosure = useDisclosure();
    const editAccountDisclosure = useDisclosure();
    const deleteAccountDisclosure = useDisclosure();

    const toast = useToast();

    useEffect(() => {
        ownerAccountBalanceService.upsertEntities(factorActiveAccountBalancesService.getEntities());
        return () => ownerAccountBalanceService.removeAllEntities();
    }, []);

    useEffect(() => {
        const accountId = account.id;

        if (ownerAccountBalanceService.hasEntityId(accountId)) {
            ownerAccountBalanceService.setActiveEntityId(accountId);
        } else {
            ownerAccountBalanceService.clearActiveEntity();
            ownerAccountBalanceService
                .fetch<Balance>({ pathParams: { accountId }, retainEntities: true })
                .then(() => ownerAccountBalanceService.setActiveEntityId(accountId));
        }
    }, [account.id]);

    const deleteAccount = async () => {
        let deleted = false;

        await deleteToast(toast, async () => {
            const remainingAccounts = await factorAccountsService.delete<Account[]>({
                id: account.id,
                pathParams: {
                    buildingId: building.id
                },
                updateEntities: true
            });

            const activeAccount = remainingAccounts.find((account) => account.isCurrentOwner);

            if (activeAccount) {
                factorAccountsService.setActiveEntityId(activeAccount.id);
            }

            deleted = true;
        });

        return deleted;
    };

    const selectAccount = (id: string) => {
        factorAccountsService.setActiveEntityId(id);
    };

    const getAccountBalance = () => {
        if (balance) {
            return <Currency amount={balance.balance} />;
        } else {
            return <Spinner size="xs" />;
        }
    };

    const getMenuGroupsForOwners = () => {
        return propertyAccounts.map((theAccount) => ({
            text: theAccount.accountName,
            onClick: () => selectAccount(theAccount.id),
            disabled: theAccount.id === account.id,
            icon: <MdOutlinePerson />
        }));
    };

    const TdLabel = (props: { children: any }) => {
        return (
            <Td textAlign={'right'} width={'120px'}>
                {props.children}
            </Td>
        );
    };

    return (
        <>
            <Box>
                {account && (
                    <>
                        <Box display={'flex'} justifyContent={'center'}>
                            <WeFactorIconButton
                                buttonIcon={MdOutlineEdit}
                                tooltipText="Edit account details"
                                iconButtonProps={{
                                    'aria-label': 'Edit account details',
                                    mr: 2,
                                    onClick: editAccountDisclosure.onOpen
                                }}
                            />
                            <ExpandableMenu
                                menuButtonProps={{
                                    'aria-label': 'Select other account',
                                    icon: <MdOutlineGroups />,
                                    isDisabled: propertyAccounts.length < 2,
                                    mr: 2
                                }}
                                menuItems={getMenuGroupsForOwners()}
                                tooltipText={'Select other account'}
                            />
                            <WeFactorIconButton
                                buttonIcon={MdOutlinePersonAdd}
                                tooltipText="Add new account"
                                iconButtonProps={{
                                    'aria-label': 'Add new account',
                                    mr: 2,
                                    onClick: addAccountDisclosure.onOpen
                                }}
                            />
                            <WeFactorIconButton
                                buttonIcon={MdOutlinePersonRemove}
                                tooltipText="Delete account"
                                iconButtonProps={{
                                    'aria-label': 'Delete account',
                                    onClick: deleteAccountDisclosure.onOpen
                                }}
                            />
                        </Box>
                        <Table size={'sm'}>
                            <Tbody>
                                <Tr key={'name'}>
                                    <TdLabel>Full name</TdLabel>
                                    <Td>
                                        <Flex alignItems={'center'}>
                                            <Text>{account.accountName}</Text>
                                        </Flex>
                                    </Td>
                                </Tr>
                                <Tr key={'email-address'}>
                                    <TdLabel>Email address</TdLabel>
                                    <Td>
                                        {account.email ? (
                                            account.email
                                        ) : (
                                            <StandardBadge
                                                backgroundColor={'#F6E05E'}
                                                onClick={editAccountDisclosure.onOpen}
                                            >
                                                <Flex alignItems={'center'}>
                                                    <Icon as={MdOutlineWarningAmber} mr={1} /> Email not set
                                                </Flex>
                                            </StandardBadge>
                                        )}
                                    </Td>
                                </Tr>
                                <Tr key={'start-date'}>
                                    <TdLabel>Start date</TdLabel>
                                    <Td>{formatDate(account.startDate)}</Td>
                                </Tr>
                                {account.endDate && (
                                    <Tr key={'end-date'}>
                                        <TdLabel>End date</TdLabel>
                                        <Td>{formatDate(account.endDate)}</Td>
                                    </Tr>
                                )}
                                <Tr key={'balance'}>
                                    <TdLabel>Balance</TdLabel>
                                    <Td>{getAccountBalance()}</Td>
                                </Tr>
                                <Tr key={'postal-address'}>
                                    <TdLabel>Contact address</TdLabel>
                                    <Td>
                                        {[
                                            account.contactAddressLine1,
                                            account.contactAddressLine2,
                                            account.contactAddressLine3,
                                            account.contactAddressLine4,
                                            account.contactPostcode
                                        ]
                                            .filter((value) => value)
                                            .map((value, index) => (
                                                <Text key={index}>{value}</Text>
                                            ))}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </>
                )}
            </Box>

            {addAccountDisclosure.isOpen && (
                <AddAccountWizard
                    disclosure={addAccountDisclosure}
                    building={building}
                    defaultPropertyId={property.id}
                    properties={properties}
                    onSaveSuccess={(accounts: Account[]) => {
                        const sortedAccounts = [...accounts].sort((a1: Account, a2: Account) =>
                            a2.createdDate.localeCompare(a1.createdDate)
                        );
                        factorAccountsService.setActiveEntityId(sortedAccounts[0].id);
                    }}
                />
            )}

            {account && editAccountDisclosure.isOpen && (
                <EditAccountWizard disclosure={editAccountDisclosure} account={account} building={building} />
            )}

            {account && deleteAccountDisclosure.isOpen && (
                <>
                    {propertyAccounts.filter((account) => !account.deleted).length === 1 ? (
                        <InformationDialog
                            disclosure={deleteAccountDisclosure}
                            content={{
                                title: 'Cannot delete account',
                                messages: [
                                    'A property must have a minimum of one account and because this is the only account for this property, it cannot be deleted.',
                                    'If a new owner has purchased the property then you should create a new account for them.',
                                    'If this property was created by mistake then you can delete the property from the properties list.'
                                ]
                            }}
                        />
                    ) : (
                        <DeleteDialog
                            disclosure={deleteAccountDisclosure}
                            detail={[
                                `This will delete the account "${account.accountName}".`,
                                `Deleted accounts will no longer be selectable from the accounts lists.`
                            ]}
                            title="Delete account"
                            onConfirm={deleteAccount}
                        />
                    )}
                </>
            )}
        </>
    );
};
