import { Flex, Spacer, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextEditor, WeFactorActionButton, WeFactorSecondaryButton } from '../../../components';
import { forumMessageService, forumPostViewService } from '../../../services';
import { ForumBoard, ForumPost } from '../../../types';
import { createToast } from '../../../util';

type ForumMessageForm = {
    body: string;
};

export const CreateForumMessage = (props: {
    buildingId: string;
    forumBoard: ForumBoard;
    forumPost: ForumPost;
    onClose: (created: boolean) => void;
}) => {
    const { buildingId, forumBoard, forumPost, onClose } = props;

    const [submitting, setIsSubmitting] = useState(false);

    const toast = useToast();

    const form = useForm<ForumMessageForm>({
        defaultValues: {
            body: ''
        },
        resolver: joiResolver(
            Joi.object({
                body: Joi.string().label('Body').trim().min(3).max(20000).required()
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const { handleSubmit } = form;

    const reply = async (formValues: ForumMessageForm) => {
        if (!forumPost) return;

        setIsSubmitting(true);

        await createToast(toast, async () => {
            await forumMessageService.create({
                pathParams: {
                    buildingId: buildingId,
                    forumPostId: forumPost.id
                },
                body: formValues
            });

            forumPostViewService.update({
                pathParams: {
                    buildingId: buildingId,
                    forumBoardId: forumBoard.id
                },
                params: {
                    forumPostId: forumPost.id
                }
            });

            onClose(true);
        });

        setIsSubmitting(false);
    };

    return (
        <>
            <form id="response-form" autoComplete="off">
                <TextEditor name="body" formHook={form} isRequired={true} />
            </form>
            <Flex>
                <WeFactorSecondaryButton isDisabled={submitting} onClick={() => onClose(false)} minWidth={'100px'}>
                    Cancel
                </WeFactorSecondaryButton>
                <Spacer />
                <WeFactorActionButton isDisabled={submitting} onClick={handleSubmit(reply)} minWidth={'100px'}>
                    Reply
                </WeFactorActionButton>
            </Flex>
        </>
    );
};
