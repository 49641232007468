import {
    Button,
    IconButton,
    IconButtonProps,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuListProps,
    MenuProps,
    Tooltip
} from '@chakra-ui/react';
import { useState } from 'react';

export interface ExpandableMenuItem {
    disabled?: boolean;
    hide?: boolean;
    text: string;
    icon?: React.ReactElement;
    onClick: () => void;
}

export interface ExpandableMenuProps {
    menuItems: ExpandableMenuItem[];
    menuButtonProps?: IconButtonProps;
    menuListProps?: MenuListProps;
    menuProps?: MenuProps;
    tooltipText: string;
}

export const ExpandableMenu = (props: ExpandableMenuProps) => {
    const { menuButtonProps, menuListProps, menuProps, menuItems, tooltipText } = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const visibleMenuItems = menuItems.filter((menuItem) => !menuItem.hide);

    if (!visibleMenuItems.length) {
        return <></>;
    }

    return (
        <Menu {...menuProps}>
            <Tooltip isOpen={tooltipOpen && !!tooltipText} label={tooltipText}>
                <MenuButton
                    as={IconButton}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    variant={'outline'}
                    {...menuButtonProps}
                />
            </Tooltip>
            <MenuList {...menuListProps}>
                {visibleMenuItems.map((menuItem, menuItemIndex) => {
                    return (
                        <MenuItem
                            aria-label={menuItem.text}
                            as={Button}
                            key={`menu-item-${menuItemIndex}`}
                            onClick={menuItem.onClick}
                            icon={menuItem.icon}
                            isDisabled={menuItem.disabled}
                        >
                            {menuItem.text}
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    );
};
