import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { debounceTime } from 'rxjs';

export interface LayoutProps {
    navBar: boolean;
    contentDialog?: OpenContentDialogOptions;
    contentSideBar?: OpenContentSideBarOptions;
    globalSearch?: string;
    isGlobalSearchVisible: boolean;
}

interface OpenContentSideBarOptions {
    header: string;
    onEdit?: () => Promise<void> | void;
    content: any;
}

interface OpenContentDialogOptions {
    content: any;
}

export const layoutService = (() => {
    const store = createStore(
        { name: 'layout-store' },
        withProps<LayoutProps>({
            navBar: false,
            contentSideBar: undefined,
            contentDialog: undefined,
            globalSearch: undefined,
            isGlobalSearchVisible: true
        })
    );

    const navBar$ = store.pipe(select((state) => state.navBar));
    const contentSideBar$ = store.pipe(select((state) => state.contentSideBar));
    const contentDialog$ = store.pipe(select((state) => state.contentDialog));

    const globalSearch$ = store.pipe(
        select((state) => state.globalSearch),
        debounceTime(500)
    );

    const isGlobalSearchVisible$ = store.pipe(select((state) => state.isGlobalSearchVisible));

    const openNavBar = () => {
        store.update(setProp('navBar', true));
    };

    const closeNavBar = () => {
        store.update(setProp('navBar', false));
    };

    const openContentDialog = (options: OpenContentDialogOptions) => {
        store.update(setProp('contentDialog', options));
    };

    const closeContentDialog = () => {
        store.update(setProp('contentDialog', undefined));
    };

    const openContentSideBar = (options: OpenContentSideBarOptions) => {
        store.update(setProp('contentSideBar', options));
    };

    const closeContentSideBar = () => {
        store.update(setProp('contentSideBar', undefined));
    };

    const showGlobalSearch = () => {
        store.update(setProp('isGlobalSearchVisible', true));
    };

    const hideGlobalSearch = () => {
        store.update(setProp('isGlobalSearchVisible', false));
    };

    const updateGlobalSearch = (searchString: string) => {
        store.update(setProp('globalSearch', searchString));
    };

    const clearGlobalSearch = () => {
        store.update(setProp('globalSearch', undefined));
    };

    return {
        contentDialog$,
        contentSideBar$,
        globalSearch$,
        navBar$,
        isGlobalSearchVisible$,
        openContentDialog,
        openContentSideBar,
        openNavBar,
        clearGlobalSearch,
        closeContentDialog,
        closeContentSideBar,
        closeNavBar,
        hideGlobalSearch,
        showGlobalSearch,
        updateGlobalSearch
    };
})();
