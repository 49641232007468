import { Container, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { map } from 'rxjs';
import { ActionMenuItem, Heading2, HelpKey, PageLoadingSkeleton, StandardPage } from '../../components';
import {
    correspondencesService,
    factorAccountsService,
    factorPropertiesService,
    userBuildingsService
} from '../../services';
import { Accounts, Correspondence, CorrespondenceType, Properties } from '../../types';
import { formatCorrespondenceType } from '../../util';
import { CorrespondenceTable } from './components';
import { CorrespondenceWizard } from './dialogs';
import { CorrespondenceView } from './dialogs/CorrespondenceView';
import { SendCorrespondenceModal } from './dialogs/components/SendCorrespondanceModals';
import { CorrespondenceTemplate } from './dialogs/components/Templates';

export const CorrespondencePageFactor = () => {
    const addCorrespondenceDisclosure = useDisclosure();
    const sendCorrespondenceDisclosure = useDisclosure();
    const viewCorrespondenceDisclosure = useDisclosure();

    const [correspondenceType, setCorrespondenceType] = useState<{
        correspondenceTemplate?: CorrespondenceTemplate;
        correspondenceType: CorrespondenceType;
    }>();

    const [building] = useObservable(userBuildingsService.activeEntity$);

    const [correspondence] = useObservable(correspondencesService.activeEntity$);
    const [correspondences] = useObservable(
        correspondencesService.entities$.pipe(
            map((correspondences) =>
                correspondences.sort(
                    (correspondence1, correspondence2) =>
                        new Date(correspondence2.createdDate).getTime() -
                        new Date(correspondence1.createdDate).getTime()
                )
            )
        )
    );
    const [correspondencesLoaded] = useObservable(correspondencesService.loaded$);

    const [accounts, setAccounts] = useState<Accounts>();
    const [properties, setProperties] = useState<Properties>();

    const pageHeader = useBreakpointValue({
        base: 'Correspondence',
        md: `Correspondence: ${building?.name}`
    });

    useEffect(() => {
        if (!building) return;

        const { id: buildingId } = building;

        factorAccountsService
            .fetch({
                pathParams: {
                    buildingId: buildingId
                }
            })
            .then((accounts) => setAccounts(new Accounts(accounts)));

        factorPropertiesService
            .fetch({
                pathParams: {
                    buildingId: buildingId
                }
            })
            .then((properties) => setProperties(new Properties(properties)));

        correspondencesService.fetch({
            pathParams: {
                buildingId: buildingId
            }
        });
    }, [building]);

    if (!correspondencesLoaded) {
        return <PageLoadingSkeleton />;
    }

    if (!building || !accounts || !correspondences || !properties) {
        return <PageLoadingSkeleton />;
    }

    const addCorrespondence = (
        correspondenceType: CorrespondenceType,
        correspondenceTemplate?: CorrespondenceTemplate
    ) => {
        setCorrespondenceType({
            correspondenceTemplate: correspondenceTemplate,
            correspondenceType: correspondenceType
        });
        addCorrespondenceDisclosure.onOpen();
    };

    const sendCorrespondence = (correspondence: Correspondence) => {
        correspondencesService.setActiveEntityId(correspondence.id);
        sendCorrespondenceDisclosure.onOpen();
    };

    const viewCorrespondence = (correspondence: Correspondence) => {
        correspondencesService.setActiveEntityId(correspondence.id);
        viewCorrespondenceDisclosure.onOpen();
    };

    const getActions = () => {
        return (
            <>
                <ActionMenuItem
                    menuItemProps={{
                        onClick: () => addCorrespondence(CorrespondenceType.COMMON_REPAIR_QUOTE)
                    }}
                >
                    {formatCorrespondenceType(CorrespondenceType.COMMON_REPAIR_QUOTE)}
                </ActionMenuItem>
                <ActionMenuItem
                    menuItemProps={{
                        onClick: () => addCorrespondence(CorrespondenceType.LETTER)
                    }}
                >
                    Letter (empty template)
                </ActionMenuItem>
                <ActionMenuItem
                    menuItemProps={{
                        onClick: () => addCorrespondence(CorrespondenceType.SHARED_COSTS)
                    }}
                >
                    {formatCorrespondenceType(CorrespondenceType.SHARED_COSTS)}
                </ActionMenuItem>
            </>
        );
    };

    return (
        <>
            <StandardPage actions={getActions()} helpKey={HelpKey.CORRESPONDENCE} title={pageHeader}>
                {correspondences.length === 0 ? (
                    <Container>
                        <Heading2>Getting started with correspondence</Heading2>
                        <Text mb={2}>
                            This page is where you will draft and publish correspondence to the various property owners
                            in your building.
                        </Text>
                        <Text mb={2}>
                            We provide a selection of templates for common types of correspondence to make it easier.
                        </Text>
                        <Text mb={2}>
                            One of the first tasks is to inform owners of how future common or mutual costs will be
                            shared between them. First, configure how sharing is done via the Accounting tab, then
                            select 'Shared costs information' from the Actions menu to draft such a letter.
                        </Text>
                    </Container>
                ) : (
                    <CorrespondenceTable
                        correspondences={correspondences}
                        onSendCorrespondence={sendCorrespondence}
                        onViewCorrespondence={viewCorrespondence}
                    />
                )}
            </StandardPage>
            {viewCorrespondenceDisclosure.isOpen && correspondence && (
                <CorrespondenceView
                    accounts={accounts}
                    building={building}
                    correspondenceId={correspondence.id}
                    correspondenceType={correspondence.type}
                    disclosure={viewCorrespondenceDisclosure}
                    properties={properties}
                />
            )}
            {addCorrespondenceDisclosure.isOpen && correspondenceType && (
                <CorrespondenceWizard
                    accounts={accounts}
                    building={building}
                    correspondenceTemplate={correspondenceType.correspondenceTemplate}
                    correspondenceType={correspondenceType.correspondenceType}
                    disclosure={addCorrespondenceDisclosure}
                    properties={properties}
                />
            )}
            {sendCorrespondenceDisclosure.isOpen && correspondence && (
                <SendCorrespondenceModal
                    building={building}
                    correspondence={correspondence}
                    disclosure={sendCorrespondenceDisclosure}
                />
            )}
        </>
    );
};
