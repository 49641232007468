import { FieldValues } from 'react-hook-form';
import { Address } from '../../types';
import { formatStreetAddress } from '../../util';
import { BaseCheckboxGroupField, BaseCheckboxGroupFieldProps } from './BaseCheckboxGroupField';

type CheckAddressesFieldProps<T extends FieldValues> = Omit<BaseCheckboxGroupFieldProps<T>, 'options'> & {
    addresses: Address[];
};

export const CheckAddressesField = <T extends FieldValues>(props: CheckAddressesFieldProps<T>) => {
    return <BaseCheckboxGroupField {...props} options={createAddressOptions(props.addresses)} />;
};

const createAddressOptions = (addresses: Address[]) => {
    return addresses.map((address) => {
        return {
            value: address.uniqueCode.toString(),
            label: formatStreetAddress(address, true)
        };
    });
};
