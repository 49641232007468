import { Box, Flex, FormLabel, Text, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    CheckAddressesField,
    HiddenField,
    StandardModalProps,
    TextField,
    Wizard,
    WizardTabPanel
} from '../../../components';
import { buildingsService, requestService } from '../../../services';
import { Address, Building } from '../../../types';
import { formatStreetAddress } from '../../../util';

type Owner = {
    accountName: string;
    email: string;
    uniqueCode: string;
};

type BuildingForm = {
    name: string;
    owners: Owner[];
    postcode: string;
    properties: string[];
};

enum SearchStatus {
    NEW,
    COMPLETE,
    ERROR,
    SEARCHING
}

export const BuildingWizard = (props: StandardModalProps) => {
    const { disclosure, onClose } = props;

    const [addressSearch, setAddressSearch] = useState<{
        postcode?: string;
        results: Address[];
        status: SearchStatus;
    }>({
        results: [],
        status: SearchStatus.NEW
    });
    const [selectedAddresses, setSelectedAddresses] = useState<Address[]>([]);

    const toast = useToast();

    const form = useForm<BuildingForm>({
        defaultValues: {
            name: '',
            owners: [],
            postcode: ''
        },
        resolver: joiResolver(
            Joi.object({
                name: Joi.string().label('Name for group of properties').trim().min(5).max(50).required(),
                owners: Joi.array()
                    .label('Owners')
                    .items({
                        accountName: Joi.string()
                            .label('Owner full name')
                            .trim()
                            .messages({ 'string.empty': 'Name is required' })
                            .min(3)
                            .max(100)
                            .required(),
                        email: Joi.string()
                            .label('Owner email address')
                            .trim()
                            .messages({ 'string.email': 'Email address is invalid' })
                            .email({ tlds: { allow: false } })
                            .allow(null, '')
                            .empty()
                            .max(255)
                            .optional(),
                        uniqueCode: Joi.string().label('Unique code').trim().required()
                    })
                    .required(),
                postcode: Joi.string()
                    .label('Postcode')
                    .trim()
                    .regex(/^[a-zA-Z]{1,2}[0-9]{1,2}[' ']?[a-zA-Z]?[0-9][a-zA-Z]{2}$/)
                    .messages({
                        'string.pattern.base': 'A full valid postcode must be entered'
                    })
                    .min(3)
                    .max(10)
                    .required(),
                properties: Joi.array().label('Properties').items(Joi.string().required()).min(1).max(20).required()
            }).unknown(false)
        )
    });

    const generateBuildingName = (addresses: Address[]) => {
        const generateNameForStreet = (streetName: string) => {
            const propertiesOnStreet = addresses.filter(
                (property) => property.streetName.toUpperCase() === streetName.toUpperCase()
            );

            if (propertiesOnStreet.some((property) => !property.buildingNumber)) {
                form.setValue('name', streetName);
            } else {
                const uniqueBuildingNumbers = new Set(propertiesOnStreet.map((property) => property.buildingNumber));

                if (uniqueBuildingNumbers.size === 1) {
                    form.setValue('name', `${uniqueBuildingNumbers.values().next().value} ${streetName}`);
                } else {
                    const sortedNumbers = [...uniqueBuildingNumbers].sort();
                    form.setValue(
                        'name',
                        `${sortedNumbers[0]}-${sortedNumbers[sortedNumbers.length - 1]} ${streetName}`
                    );
                }
            }
        };

        const uniqueStreetNames = new Set(addresses.map((property) => property.streetName));
        return [...uniqueStreetNames].map((streetName) => generateNameForStreet(streetName)).join('/');
    };

    const searchAddresses = async (postcode: string) => {
        form.resetField('properties', {
            defaultValue: [],
            keepDirty: false,
            keepError: false,
            keepTouched: false
        });

        setAddressSearch({
            postcode: postcode,
            results: [],
            status: SearchStatus.SEARCHING
        });

        try {
            const response = await requestService.get<Address[]>('address-search', {
                params: {
                    postcode: postcode.trim()
                }
            });

            setAddressSearch({
                postcode: postcode,
                status: SearchStatus.COMPLETE,
                results: response.data
            });
        } catch (error) {
            setAddressSearch({
                postcode: postcode,
                results: [],
                status: SearchStatus.ERROR
            });

            toast({
                description: 'Address search failed',
                status: 'error',
                position: 'top',
                isClosable: true
            });
        }
    };

    const save = async (formValues: BuildingForm): Promise<Building> => {
        return await buildingsService.create({ body: formValues });
    };

    const onTabChange = (exitingTab: number, enteringTab: number) => {
        if (exitingTab === 0) {
            const postcode = form.getValues('postcode').trim();
            if (postcode !== addressSearch.postcode) {
                searchAddresses(postcode);
            }
        }

        if (exitingTab === 1 && enteringTab === 2) {
            const previousSelectedUniqueCodes = selectedAddresses.map((address) => address.uniqueCode.toString());
            const previousSelectedUniqueCodeSet = new Set(previousSelectedUniqueCodes);

            const selectedUniqueCodes = form.getValues('properties');
            const selectedUniqueCodeSet = new Set(selectedUniqueCodes);

            const selectionHasChanged =
                selectedUniqueCodes.some((uniqueCode) => !previousSelectedUniqueCodeSet.has(uniqueCode)) ||
                previousSelectedUniqueCodes.some((uniqueCode) => !selectedUniqueCodeSet.has(uniqueCode));

            if (selectionHasChanged) {
                const addressSet = addressSearch.results.reduce(
                    (map, address) => map.set(address.uniqueCode.toString(), address),
                    new Map()
                );

                const updatedSelectedAddresses = selectedUniqueCodes.map((uniqueCode) => addressSet.get(uniqueCode));

                const previousSelectedOwners = form
                    .getValues('owners')
                    .reduce((map, owner) => map.set(owner.uniqueCode.toString(), owner), new Map<string, Owner>());

                form.resetField('owners', {
                    defaultValue: updatedSelectedAddresses.map((address) => {
                        return {
                            accountName: '',
                            email: '',
                            uniqueCode: address.uniqueCode.toString()
                        };
                    }),
                    keepDirty: false,
                    keepError: false,
                    keepTouched: false
                });

                updatedSelectedAddresses.forEach((address, index) => {
                    const uniqueCode = address.uniqueCode.toString();
                    const previousOwner = previousSelectedOwners.get(uniqueCode);
                    form.setValue(`owners.${index}.accountName`, previousOwner?.accountName || '');
                    form.setValue(`owners.${index}.email`, previousOwner?.email || '');
                    form.setValue(`owners.${index}.uniqueCode`, uniqueCode);
                });

                setSelectedAddresses(updatedSelectedAddresses);
                generateBuildingName(updatedSelectedAddresses);
            }
        }
    };

    const getSearchStatusMessage = () => {
        switch (addressSearch.status) {
            case SearchStatus.SEARCHING:
                return 'Searching... please wait';
            case SearchStatus.COMPLETE: {
                if (addressSearch.results.length === 0) {
                    return `Failed to find any properties in ${addressSearch.postcode} postcode.`;
                }
                return undefined;
            }
            case SearchStatus.ERROR:
                return 'Failed to complete search';
            default:
                return undefined;
        }
    };

    const searchStatusMessage = getSearchStatusMessage();

    return (
        <Wizard<BuildingForm, Building>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: 'xl'
                }
            }}
            modalContentProps={{
                height: '3xl'
            }}
            onClose={onClose}
            form={form}
            heading={`Add new building/estate`}
            steps={[
                {
                    heading: 'Search for properties in building/estate',
                    fieldNames: ['postcode']
                },
                {
                    heading: 'Select properties in building/estate',
                    fieldNames: ['properties']
                },
                {
                    heading: 'Enter details of building/estate',
                    fieldNames: ['name']
                },
                {
                    heading: 'Enter details of property owners',
                    fieldNames: ['owners']
                }
            ]}
            onSaveSuccess={onClose}
            onStepChange={onTabChange}
            save={save}
        >
            <WizardTabPanel>
                <TextField formHook={form} formLabel="Postcode" name="postcode" isRequired />
            </WizardTabPanel>
            <WizardTabPanel>
                <CheckAddressesField
                    name="properties"
                    formHook={form}
                    groupLabel="Properties"
                    addresses={addressSearch.results || []}
                />
                {searchStatusMessage && <Text>{searchStatusMessage}</Text>}
            </WizardTabPanel>
            <WizardTabPanel>
                <TextField formLabel="Name of building/estate" name="name" formHook={form} isRequired />
            </WizardTabPanel>
            <WizardTabPanel>
                {selectedAddresses.map((address, index) => {
                    return (
                        <Box key={index}>
                            <FormLabel
                                color="#84818A"
                                fontSize="md"
                                mb={1}
                                ml={0.5}
                                htmlFor={`owners.${index}.accountName`}
                            >
                                {formatStreetAddress(address, true)}
                            </FormLabel>
                            <Flex direction={['column', 'row']}>
                                <TextField
                                    formHook={form}
                                    name={`owners.${index}.accountName`}
                                    inputProps={{ mr: [0, 2] }}
                                    isRequired={true}
                                    placeholder="Owner full name"
                                />
                                <TextField
                                    formHook={form}
                                    name={`owners.${index}.email`}
                                    placeholder="Owner email address"
                                />
                            </Flex>
                            <HiddenField formHook={form} name={`owners.${index}.uniqueCode`} />
                        </Box>
                    );
                })}
            </WizardTabPanel>
        </Wizard>
    );
};
