import { Editor } from '@tinymce/tinymce-react';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';

export interface TextEditorProps<T extends FieldValues> extends BaseFormControlProps<T> {
    height?: number;
}

export const TextEditor = <T extends FieldValues>(props: TextEditorProps<T>) => {
    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        const {
            field: { onChange, ...field }
        } = controller;

        return (
            <Editor
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                init={{
                    content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',
                    menubar: false,
                    plugins: [
                        'advlist',
                        'anchor',
                        'autolink',
                        'charmap',
                        'code',
                        'fullscreen',
                        'image',
                        'insertdatetime',
                        'link',
                        'lists',
                        'media',
                        'preview',
                        'searchreplace',
                        'table',
                        'visualblocks',
                        'wordcount'
                    ],
                    toolbar:
                        'fullscreen | ' +
                        'blocks | ' +
                        'bold italic forecolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist outdent indent ',
                    ui_mode: 'split' // Fix issue of menu's not showing properly
                }}
                onEditorChange={controller.field.onChange}
                value={field.value}
            />
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
