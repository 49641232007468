import { Avatar, Box, Flex, HStack, Link, Spacer, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteDialog, LINK_BLUE } from '../../../components';
import { forumMessageService } from '../../../services';
import { ForumBoard, ForumMessage, ForumPost } from '../../../types';
import { deleteToast, formatDate, formatTime } from '../../../util';
import { EditForumMessage } from './';

export const ForumMessageBox = (props: {
    buildingId: string;
    forumBoard: ForumBoard;
    forumMessage: ForumMessage;
    forumPost: ForumPost;
}) => {
    const { buildingId, forumBoard, forumMessage, forumPost } = props;
    const { user } = forumMessage;

    const deleteDisclosure = useDisclosure();

    const navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);

    const toast = useToast();

    const deleteMessage = async () => {
        let deleted = false;

        await deleteToast(toast, async () => {
            const remainingMessages = await forumMessageService.delete<ForumMessage[]>({
                id: forumMessage.id,
                pathParams: {
                    buildingId: buildingId,
                    forumPostId: forumPost.id
                },
                updateEntities: true
            });

            if (remainingMessages.length === 0) {
                navigate(`/buildings/${buildingId}/forums/${forumBoard.id}`);
            } else {
                deleted = true;
            }

            deleted = true;
        });

        return deleted;
    };

    return (
        <>
            {isEditing ? (
                <EditForumMessage
                    buildingId={buildingId}
                    forumMessage={forumMessage}
                    forumPost={forumPost}
                    onClose={() => setIsEditing(false)}
                />
            ) : (
                <HStack align="flex-start" key={`${forumMessage.id}-${isEditing ? 'editing' : 'non-editing'}`} gap={4}>
                    <Box paddingTop={1}>
                        <Avatar size={'sm'} src={user.profileImageUrl} name={user.displayName || 'N/A'} />
                    </Box>
                    <Stack spacing={1} width={'100%'}>
                        <Stack spacing={2}>
                            <Box key={'response-body'} color={'fg'} lineHeight={'1.5.rem'}>
                                <div dangerouslySetInnerHTML={{ __html: forumMessage.body }} />
                            </Box>
                            <Box key={'response-metadata'} color={'fg.muted'} lineHeight={'1.0rem'}>
                                <Flex marginTop={2}>
                                    <Text fontSize={'sm'} marginRight={2}>
                                        {user.displayName || 'N/A'}
                                    </Text>
                                    {forumMessage.hasEditPermission && (
                                        <Flex marginRight={2}>
                                            <Link
                                                color={LINK_BLUE}
                                                fontSize={'sm'}
                                                href="#"
                                                onClick={() => setIsEditing(true)}
                                            >
                                                (edit)
                                            </Link>
                                        </Flex>
                                    )}
                                    {forumMessage.hasDeletePermission && (
                                        <Flex marginRight={2}>
                                            <Link
                                                color={LINK_BLUE}
                                                fontSize={'sm'}
                                                href="#"
                                                onClick={deleteDisclosure.onOpen}
                                            >
                                                (delete)
                                            </Link>
                                        </Flex>
                                    )}
                                    <Spacer />
                                    <Text fontSize={'sm'} marginRight={2}>
                                        {formatDate(forumMessage.createdDate)}
                                    </Text>
                                    <Text fontSize={'sm'}>{formatTime(forumMessage.createdDate)}</Text>
                                </Flex>
                            </Box>
                        </Stack>
                    </Stack>
                </HStack>
            )}
            {deleteDisclosure.isOpen && (
                <DeleteDialog
                    disclosure={deleteDisclosure}
                    detail={[`This will delete this message.`]}
                    title="Delete property"
                    onConfirm={deleteMessage}
                />
            )}
        </>
    );
};
