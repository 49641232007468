import { IconType } from 'react-icons/lib';
import { MdErrorOutline, MdOutlineReceipt, MdOutlineSavings, MdOutlineTune, MdOutlineUndo } from 'react-icons/md';
import { MaintenanceAccountTransaction } from './maintenance-account-transaction';

export enum TransactionType {
    ADJUSTMENT = 'ADJUSTMENT',
    INVOICE = 'INVOICE',
    INVOICE_SHARE = 'INVOICE_SHARE',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND'
}

const TRANSACTION_TYPE_NAMES: Map<TransactionType, string> = new Map([
    [TransactionType.ADJUSTMENT, 'Balance adjustment'],
    [TransactionType.INVOICE, 'Charges'],
    [TransactionType.INVOICE_SHARE, 'Charges'],
    [TransactionType.PAYMENT, 'Payment'],
    [TransactionType.REFUND, 'Refund']
]);

export const getTransactionTypeName = (type: TransactionType): string => {
    return TRANSACTION_TYPE_NAMES.get(type) || '';
};

const TRANSACTION_TYPE_ICONS: Map<TransactionType, IconType> = new Map([
    [TransactionType.ADJUSTMENT, MdOutlineTune],
    [TransactionType.INVOICE, MdOutlineReceipt],
    [TransactionType.INVOICE_SHARE, MdOutlineReceipt],
    [TransactionType.PAYMENT, MdOutlineSavings],
    [TransactionType.REFUND, MdOutlineUndo]
]);

export const getTransactionTypeIcon = (type: TransactionType): IconType => {
    return TRANSACTION_TYPE_ICONS.get(type) || MdErrorOutline;
};

export interface Transaction {
    id: string;
    accountId?: string;
    buildingId: string;

    amount: number;
    date: string;
    type: TransactionType;

    maintenanceAccountTransaction?: MaintenanceAccountTransaction;
}
