import { ChakraProvider, extendTheme, Skeleton } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import NP from 'number-precision';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { distinctUntilChanged } from 'rxjs';
import { AccountGuard, BuildingGuard, RootUrlGuard } from './guards';
import { Layout } from './layout';
import {
    AccountingPageFactor,
    AccountingPageOwner,
    ConfirmAccountPage,
    CorrespondenceOfflineResponse,
    CorrespondencePageFactor,
    CorrespondencePageOwner,
    ForumBoardPage,
    ForumPostPage,
    ForumsPage,
    NoBuildingPage,
    PropertiesPage,
    ResetPasswordPage,
    SettingsPageFactor,
    SettingsPageOwner,
    SignInPage,
    SignUpPage,
    TechnicalHelpPage
} from './pages';
import { authenticatedUserService, userAccountService, userBuildingsService } from './services';

NP.enableBoundaryChecking(false);

const customTheme = extendTheme({
    components: {
        Button: {
            variants: {
                active: {
                    fontWeight: 100
                }
            }
        },
        Divider: {
            variants: {
                borderColor: 'orange.500'
            }
        }
    }
});

export const App = () => {
    const getRoutes = () => {
        const isLoggedIn = authenticatedUserService.isLoggedIn();

        if (isLoggedIn) {
            return <AuthorizedRoutes />;
        }

        return <UnauthorizedRoutes />;
    };

    return (
        <ChakraProvider theme={customTheme}>
            <BrowserRouter>{getRoutes()}</BrowserRouter>
        </ChakraProvider>
    );
};

const UnauthorizedRoutes = () => (
    <Routes>
        <Route path={'/'} element={<SignInPage />} />
        <Route path={'/sign-in'} element={<SignInPage />} />
        <Route path={'/sign-up'} element={<SignUpPage />} />
        <Route path={'/confirm-account'} element={<ConfirmAccountPage />} />
        <Route path={'/reset-password'} element={<ResetPasswordPage />} />
        <Route path={'/correspondence/respond'} element={<CorrespondenceOfflineResponse />} />
        <Route path="*" element={<Navigate to="/sign-in" replace />} />
    </Routes>
);

const AuthorizedRoutes = () => {
    const [accountsCount] = useObservable(userAccountService.entitiesCount$.pipe(distinctUntilChanged()));

    const [buildingsCount] = useObservable(userBuildingsService.entitiesCount$.pipe(distinctUntilChanged()));

    if (accountsCount === undefined || buildingsCount === undefined) {
        return <Skeleton height="100vh"></Skeleton>;
    }

    const BuildingsRoutes = () => {
        return (
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<RootUrlGuard />}>
                        <Route path="/" element={<NoBuildingPage />} />
                        <Route path="buildings">
                            <Route path=":buildingId" element={<BuildingGuard />}>
                                <Route path="accounting" element={<AccountingPageFactor />} />
                                <Route path="correspondence" element={<CorrespondencePageFactor />} />
                                <Route path="forums">
                                    <Route path=":forumBoardId">
                                        <Route path="posts">
                                            <Route path=":forumPostId" element={<ForumPostPage />} />
                                        </Route>
                                        <Route index element={<ForumBoardPage />} />
                                    </Route>
                                    <Route index element={<ForumsPage />} />
                                </Route>
                                <Route path="properties" element={<PropertiesPage />} />
                                <Route path="settings" element={<SettingsPageFactor />} />
                            </Route>
                        </Route>
                        <Route path="accounts/:accountId" element={<AccountGuard />}>
                            <Route path="accounting" element={<AccountingPageOwner />} />
                            <Route path="correspondence" element={<CorrespondencePageOwner />} />
                            <Route path="settings" element={<SettingsPageOwner />} />
                        </Route>
                    </Route>
                    <Route path="technical-help" element={<TechnicalHelpPage />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
                <Route path="correspondence/respond" element={<CorrespondenceOfflineResponse />} />
            </Routes>
        );
    };

    const NoBuildingsRoutes = () => (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<NoBuildingPage />} />
                <Route path="technicalhelp" element={<TechnicalHelpPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );

    if (accountsCount || buildingsCount) {
        return <BuildingsRoutes />;
    }

    return <NoBuildingsRoutes />;
};
