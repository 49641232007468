import { Flex, Spacer, Text, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StandardModal, StandardModalProps } from '../../../components';
import { HiddenField, SelectYearMonth } from '../../../components/form';
import { PremiumFeatureAlert, WeFactorSecondaryButton, WeFactorSubmitButton } from '../../../components/shared';
import { factorStatementService } from '../../../services';
import { Account, Building } from '../../../types';
import { formatMonthYearForDatePicker, genericToast } from '../../../util';

interface SendStatementDialogProps extends StandardModalProps {
    account: Account;
    building: Building;
}

type MonthRangeForm = {
    accountId: string;
    month: string;
};

export const SendStatementDialog = (props: SendStatementDialogProps) => {
    const { account, building, disclosure, onClose } = props;

    const hasActiveSubscription = useMemo(() => building.subscriptionStatus.includes('ACTIVE'), [building]);

    const toast = useToast();

    const [isSending, setIsSending] = useState(false);

    const getDefaultMonth = () => {
        if (building.lastStatementDate) {
            return formatMonthYearForDatePicker(new Date(building.lastStatementDate));
        } else {
            return formatMonthYearForDatePicker(
                DateTime.fromJSDate(new Date()).minus({ months: 1 }).endOf('month').startOf('day').toJSDate()
            );
        }
    };

    const form = useForm<MonthRangeForm>({
        resolver: joiResolver(
            Joi.object({
                accountId: Joi.string().uuid().required().label('Statement account'),
                month: Joi.string()
                    .label('Statement month')
                    .messages({
                        'date.past': 'Statement month must be in the past'
                    })
                    .custom((value, helpers) => {
                        const currentMonth = DateTime.fromJSDate(new Date()).startOf('month').toJSDate();

                        const selectedMonth = DateTime.fromISO(value).toJSDate();

                        if (selectedMonth >= currentMonth) {
                            return helpers.error('date.past');
                        }

                        if (building.lastStatementDate) {
                            if (selectedMonth >= new Date(building.lastStatementDate)) {
                                return helpers.error('date.before-accounts');
                            }
                        }

                        return value;
                    })
                    .required()
            })
        ),
        defaultValues: {
            accountId: account.id,
            month: getDefaultMonth()
        },
        reValidateMode: 'onSubmit'
    });

    const { getValues, handleSubmit } = form;

    const submit = async () => {
        setIsSending(true);

        try {
            await genericToast(
                toast,
                async () => {
                    await factorStatementService.create({
                        pathParams: {
                            buildingId: building.id
                        },
                        pathSuffix: 'send',
                        body: {
                            statementMonth: getValues('month')
                        },
                        params: {
                            accountId: getValues('accountId')
                        }
                    });
                    disclosure.onClose();
                },
                {
                    title: 'Sending statement',
                    onSuccessTitle: 'Statement sent',
                    onErrorTitle: 'Failed to send statement'
                }
            );
        } catch (error) {
            console.error('Failed to send statement', error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'sm'
                }
            }}
            onClose={onClose}
            header={'Send statement'}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton isDisabled={isSending} onClick={disclosure.onClose}>
                        Cancel
                    </WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorSubmitButton
                        isDisabled={!account.email || !hasActiveSubscription}
                        isLoading={isSending}
                        onClick={handleSubmit(submit)}
                    >
                        Send
                    </WeFactorSubmitButton>
                </Flex>
            }
        >
            <form id="send-statement-form" noValidate autoComplete="off">
                {hasActiveSubscription ? (
                    <>
                        {account.email?.trim() ? (
                            <>
                                <SelectYearMonth
                                    name="month"
                                    formHook={form}
                                    formLabel="Statement month"
                                    isRequired={true}
                                    allowCurrentMonth={false}
                                />
                                <HiddenField name="accountId" formHook={form} isRequired={true} />
                            </>
                        ) : (
                            <>
                                <Text mb={2}>
                                    Cannot send statement because the recipient account does not have a valid email
                                    address.
                                </Text>
                                <Text>
                                    You can set the email address by editing the account details from the sidebar of the
                                    Properties page after selecting a property.
                                </Text>
                            </>
                        )}
                    </>
                ) : (
                    <PremiumFeatureAlert
                        subtext="Alternatively you can download statements to send from your personal
              email account or print for delivery."
                    />
                )}
            </form>
        </StandardModal>
    );
};
