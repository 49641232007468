import { Link, Stack, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TextField, WeFactorSubmitButton } from '../../components';
import { authenticatedUserService } from '../../services';
import { AuthFlex } from './components/AuthFlex';
import { AuthHeader } from './components/AuthHeader';
import { AuthText } from './components/AuthText';

export const ConfirmAccountPage = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const toast = useToast();

    const form = useForm({
        defaultValues: {
            code: params.get('code'),
            email: params.get('email')
        },
        resolver: joiResolver(
            Joi.object({
                code: Joi.string().label('Confirmation code').trim().min(6).required(),
                email: Joi.string()
                    .label('Email address')
                    .trim()
                    .email({ tlds: { allow: false } })
                    .required()
            }).required()
        ),
        reValidateMode: 'onSubmit'
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting }
    } = form;

    const submit = async (formValues: any) => {
        const { email, code } = formValues;

        try {
            await authenticatedUserService.confirmAccount(email, code);

            navigate('/sign-in');

            toast({
                title: 'Successfully confirmed account',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        } catch (error: any) {
            const getErrorMessage = (code) => {
                switch (code) {
                    case 'CodeMismatchException':
                        return 'Invalid code entered. Please try again or request a new code.';
                    case 'InvalidParameterException':
                        return 'Please enter a valid email address.';
                    case 'LimitExceededException':
                        return 'Maximum number of attempts reached. Please try again later.';
                    case 'UserNotFoundException':
                        return 'Email address could not be found.';
                    default:
                        return undefined;
                }
            };

            let errorMessage = getErrorMessage(error.code);

            if (!errorMessage && error.statusCode === 400 && error.message) {
                errorMessage = error.message;
            }

            toast({
                description: errorMessage || 'An unexpected error occurred - please contact us if the issue persists',
                status: 'error',
                duration: 10000,
                isClosable: true,
                position: 'top'
            });
        }
    };

    const getText = () => {
        return params.get('code')
            ? 'Click on "Confirm Account" to activate your account.'
            : 'You should have received an email containing a confirmation code. Enter it here to activate your account.';
    };

    return (
        <AuthFlex>
            <AuthHeader>Confirm Account</AuthHeader>
            <AuthText>{getText()}</AuthText>
            <form id="sign-in-form" noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
                <TextField formLabel="Email address" name="email" formHook={form} />
                <TextField formLabel="Confirmation code" name="code" formHook={form} />
                <WeFactorSubmitButton disabled={!isValid || isSubmitting} isLoading={isSubmitting} width="100%">
                    Confirm Account
                </WeFactorSubmitButton>
                <Stack mt={2}>
                    <Link
                        textAlign={'right'}
                        fontSize={'sm'}
                        onClick={() => {
                            navigate('/sign-in');
                        }}
                    >
                        Sign in
                    </Link>
                </Stack>
            </form>
        </AuthFlex>
    );
};
