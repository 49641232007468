import { Checkbox, CheckboxGroup, CheckboxProps, Stack } from '@chakra-ui/react';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';

export type BaseCheckboxGroupFieldProps<T extends FieldValues> = BaseFormControlProps<T> & {
    checkboxProps?: CheckboxProps;

    disabledValues?: string[];
    options: { label: string; value: string }[];
};

export const BaseCheckboxGroupField = <T extends FieldValues>(props: BaseCheckboxGroupFieldProps<T>) => {
    const { isRequired, name, checkboxProps, disabledValues, options } = props;

    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        const field = controller.field;

        const attachRefToIndex = options.findIndex((option) => !disabledValues?.includes(option.value));

        return (
            <Stack justifyContent="space-between" padding={1} spacing={1.5}>
                <CheckboxGroup isDisabled={field.disabled} onChange={field.onChange} value={field.value}>
                    {options.map((option, index) => {
                        const id = `${name}.${index}`;
                        const isDisabled = disabledValues?.includes(option.value);

                        return (
                            <Checkbox
                                id={id}
                                key={id}
                                name={name}
                                aria-autocomplete="none"
                                aria-label={option.value}
                                aria-required={isRequired}
                                errorBorderColor="crimson"
                                isDisabled={isDisabled}
                                ref={index === attachRefToIndex ? field.ref : null}
                                size="md"
                                spacing="1rem"
                                value={option.value}
                                {...checkboxProps}
                            >
                                {option.label}
                            </Checkbox>
                        );
                    })}
                </CheckboxGroup>
            </Stack>
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
