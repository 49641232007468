import { Spinner } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { CurrencyBadge } from '../../../components';
import { ownerAccountBalanceService } from '../../../services';

interface AccountBalanceBadgeProps {
    accountId: string;
}

export const AccountBalanceBadge = (props: AccountBalanceBadgeProps) => {
    const { accountId } = props;

    const [accountBalances] = useObservable(ownerAccountBalanceService.entities$);
    const [accountBalancesLoaded] = useObservable(ownerAccountBalanceService.loaded$);

    useEffect(() => {
        ownerAccountBalanceService.fetch({ params: { accountId } });
    }, [accountId]);

    if (!accountBalancesLoaded) {
        return <Spinner size={'sm'} colorScheme={'blue'} />;
    }

    return <CurrencyBadge amount={accountBalances.find((bb) => bb.id === accountId)?.balance || 0} />;
};
