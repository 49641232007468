export const OFF_WHITE = '#FBFAFC';

export const MAIN_BLUE = '#2299F9';
export const BLUE_1 = '#1F89DF';
export const BLUE_2 = '#1E78C0';

export const DARK = '#2E2C34';
export const DARK_1 = '#504F54';
export const DARK_2 = '#84818A';
export const DARK_3 = '#B6B4BA';
export const DARK_4 = '#EBEAED';
export const DARK_5 = '#FBFAFC';

export const DARK_HOVER = '#434246';

export const WARNING_RED = '#FF0000';

export const LINK_BLUE = 'teal.500';

export enum COLOURS {
    BLUE = '#000025',
    BLUE_2 = '#008DF6',
    BLUE_3 = '#000065',
    GREEN = '#11EBB1',
    GREEN_2 = '#065E5D',
    WHITE = '#FFF',
    DARK_GREY = '2E2C34'
}

export enum ICON_SIZES {
    LARGE = '1.5em',
    MEDIUM = '1.25em',
    SMALL = '1.0em'
}
