import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { CurrencyField, DateField, SelectAccountField, Wizard, WizardProps, WizardTabPanel } from '../../../components';
import { factorTransactionService } from '../../../services';
import { Accounts, Building, Properties, Transaction, TransactionType, getTransactionTypeName } from '../../../types';

type TransactionForm = {
    accountId: string;
    buildingId: string;
    maintenanceAccountTransactionId: string | null;

    amount: number | '';
    date: string;
    type: string;
};

interface TransactionWizardProps extends WizardProps<Transaction> {
    accounts: Accounts;
    building: Building;
    maintenanceAccountTransactionId?: string;
    properties: Properties;
    transaction?: Transaction;
    transactionType?: TransactionType;
}

export const TransactionWizard = (props: TransactionWizardProps) => {
    const {
        accounts,
        transaction,
        building,
        disclosure,
        maintenanceAccountTransactionId,
        properties,
        transactionType,
        onClose,
        onSaveSuccess
    } = props;

    const { id: buildingId } = building;

    const getAmount = () => {
        switch (transaction?.type) {
            case TransactionType.ADJUSTMENT:
                return transaction.amount;
            case TransactionType.PAYMENT:
                return transaction.amount;
            case TransactionType.REFUND:
                return 0 - transaction.amount;
            default:
                return '';
        }
    };

    const getDateLabel = () => {
        switch (transaction?.type || transactionType) {
            case TransactionType.ADJUSTMENT:
                return 'Adjustment date';
            case TransactionType.PAYMENT:
                return 'Payment date';
            case TransactionType.REFUND:
                return 'Refund date';
            default:
                return 'Date';
        }
    };

    const getJoiAmount = () => {
        if (transaction?.type || transactionType === TransactionType.ADJUSTMENT) {
            return Joi.number().label('Amount').required();
        } else {
            return Joi.number().label('Amount').positive().required();
        }
    };

    const form = useForm<TransactionForm>({
        defaultValues: {
            buildingId: buildingId,
            maintenanceAccountTransactionId: maintenanceAccountTransactionId,
            accountId: transaction?.accountId,
            amount: getAmount(),
            date: transaction?.date || '',
            type: transaction?.type || transactionType
        },
        resolver: joiResolver(
            Joi.object({
                accountId: Joi.string().label('Account').uuid().required(),
                buildingId: Joi.string().uuid().required(),
                amount: getJoiAmount(),
                date: Joi.date()
                    .label(getDateLabel())
                    .messages({
                        'date.max': `"${getDateLabel()}" cannot be set in the future`
                    })
                    .max(new Date())
                    .raw()
                    .required(),
                maintenanceAccountTransactionId: Joi.string().uuid().optional(),
                type: Joi.string().allow('ADJUSTMENT', 'PAYMENT', 'REFUND')
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const save = async (formValues: TransactionForm) => {
        if (transaction) {
            return factorTransactionService.update({
                pathParams: { buildingId, id: transaction.id },
                body: formValues
            });
        } else {
            return factorTransactionService.create({
                pathParams: { buildingId },
                body: formValues
            });
        }
    };

    const getHeading = () => {
        if (transaction) {
            return `Modify ${getTransactionTypeName(transaction.type).toLowerCase()}`;
        } else {
            switch (transactionType) {
                case TransactionType.ADJUSTMENT:
                    return 'Adjust account balance';
                case TransactionType.PAYMENT:
                    return 'Record new payment';
                case TransactionType.REFUND:
                    return 'Record new refund';
                default:
                    return '';
            }
        }
    };

    return (
        <Wizard<TransactionForm, Transaction>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    md: 'md'
                }
            }}
            modalContentProps={{
                height: '2xl'
            }}
            onClose={onClose}
            onSaveSuccess={onSaveSuccess}
            form={form}
            heading={getHeading()}
            save={save}
            steps={[
                {
                    heading: 'Payment Details',
                    fieldNames: ['accountId', 'amount', 'type']
                }
            ]}
        >
            <WizardTabPanel>
                <SelectAccountField
                    name="accountId"
                    formHook={form}
                    formLabel="Account"
                    isRequired
                    accounts={accounts}
                    properties={properties}
                />
                <DateField name="date" formHook={form} formLabel={getDateLabel()} isRequired />
                <CurrencyField name="amount" formHook={form} formLabel="Amount" isRequired />
            </WizardTabPanel>
        </Wizard>
    );
};
