import { Box, Button, Container, Flex, Spacer, Stack, useDisclosure } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import { ActionMenu, Heading1 } from '../shared';
import { HelpKey, HelpModal } from './help/HelpModal';

interface PageProps extends PropsWithChildren {
    action?: React.ReactElement;
    actions?: React.ReactElement;
    helpKey?: HelpKey;
    title: string | any;
}

const PADDING_Y = 2;

export const StandardPage = (props: PageProps) => {
    const { action, actions, children, helpKey, title } = props;

    const disclosureHelp = useDisclosure();

    return (
        <Box paddingRight={{ lg: 2 }}>
            <Box as={'section'} bg={'bg.surface'} paddingY={PADDING_Y}>
                <Container maxWidth={'inherit'}>
                    <Flex alignItems={'center'}>
                        <Heading1 headingProps={{ margin: 0 }}>{title}</Heading1>
                        <Spacer />
                        <Stack direction="row">
                            {helpKey && (
                                <Button variant="secondary" onClick={disclosureHelp.onOpen}>
                                    Help
                                </Button>
                            )}
                            {action && action}
                            {actions && <ActionMenu>{actions}</ActionMenu>}
                        </Stack>
                    </Flex>
                </Container>
            </Box>
            <Box paddingX={0} paddingY={PADDING_Y}>
                {children}
            </Box>
            {helpKey && <HelpModal disclosure={disclosureHelp} helpKey={helpKey} />}
        </Box>
    );
};
