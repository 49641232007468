import { HamburgerIcon } from '@chakra-ui/icons';
import {
    Avatar,
    Badge,
    Box,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { MdOutlineExpandMore, MdOutlineNotifications } from 'react-icons/md';
import { Observable } from 'rxjs';
import { DARK_2, EditProfileDialog, Notifications, SideBar } from '../components';
import { authenticatedUserService, layoutService, notificationsService } from '../services';
import { Notification } from '../types';
import { getContentUrl } from '../util';

export const ToolBar = (props: { isMaxView: boolean }) => {
    const { isMaxView } = props;

    const disclosureEditProfile = useDisclosure();
    const disclosureNotifications = useDisclosure();

    const [authenticatedUser] = useObservable(authenticatedUserService.user$);
    const [notifications] = useObservable(notificationsService.entities$ as Observable<Notification[]>);

    if (!authenticatedUser || !notifications) return null;

    return (
        <Flex
            padding={4}
            backgroundColor={'#FBFAFC'}
            height={'70px'}
            width={'100%'}
            borderBottom={'1px solid #EBEAED'}
            alignItems={'center'}
        >
            {!isMaxView && (
                <IconButton
                    onClick={layoutService.openNavBar}
                    variant={'ghost'}
                    aria-label="open navigation bar"
                    as={HamburgerIcon}
                />
            )}
            {/* {isGlobalSearchVisible && (
                <InputGroup mr={4} display="flex" alignItems="center" height="100%">
                    <InputLeftElement children={<MdOutlineSearch size={ICON_SIZES.TEXT_FIELD} />} />
                    <Input variant="unstyled" placeholder="Search" onChange={updateGlobalSearch} />
                </InputGroup>
            )} */}
            <IconButton
                variant={'ghost'}
                aria-label={'open notifications'}
                ml={'auto'}
                onClick={disclosureNotifications.onOpen}
            >
                <Flex paddingX={3}>
                    <MdOutlineNotifications />
                    <Badge marginLeft={1} colorScheme="red">
                        {notifications.filter((notification) => !notification.read).length}
                    </Badge>
                </Flex>
            </IconButton>
            <Menu>
                <MenuButton _hover={{ bg: 'gray.100' }} lineHeight={'20px'} borderRadius={'md'} padding={2}>
                    <Flex alignItems={'center'}>
                        <Box marginLeft={3}>
                            <Avatar
                                name={authenticatedUser.displayName}
                                src={getContentUrl(authenticatedUser.profileImageUrl)}
                                w={8}
                                h={8}
                            />
                        </Box>
                        <Flex flexDirection={'column'} marginLeft={2} textAlign={'start'}>
                            <Text fontWeight={600}>{authenticatedUser.displayName}</Text>
                            <Text color={DARK_2} fontSize={'sm'}>
                                {authenticatedUser.email}
                            </Text>
                        </Flex>
                        <Box marginLeft={3}>
                            <MdOutlineExpandMore />
                        </Box>
                    </Flex>
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={disclosureEditProfile.onOpen}>Edit Profile</MenuItem>
                    <MenuItem onClick={authenticatedUserService.signOut}>Sign Out</MenuItem>
                </MenuList>
            </Menu>
            <SideBar
                header={'Notifications'}
                isOpen={disclosureNotifications.isOpen}
                onClose={disclosureNotifications.onClose}
            >
                <Notifications notifications={notifications} onNotificationClick={disclosureNotifications.onClose} />
            </SideBar>
            {disclosureEditProfile.isOpen && (
                <EditProfileDialog disclosure={disclosureEditProfile} authenticatedUser={authenticatedUser} />
            )}
        </Flex>
    );
};
