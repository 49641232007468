import { Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WeFactorActionButton } from '../../components';
import { authenticatedUserService, requestService, userBuildingsService } from '../../services';
import { Building } from '../../types';
import { formatNiceDate, genericToast } from '../../util';

interface BuildingSubscriptionSettingsProps {
    building: Building;
}

export const BuildingSubscriptionSettings = (props: BuildingSubscriptionSettingsProps) => {
    const { building } = props;

    const [searchParams] = useSearchParams();
    const [callback, setCallback] = useState<string | null>(() => searchParams.get('callback'));
    const [refreshAttempts, setRefreshAttempts] = useState(0);
    const [successAttempts, setSuccessAttempts] = useState(0);
    const [isPolling, setIsPolling] = useState(false);
    const toast = useToast();

    useEffect(() => {
        const checkIsActive = async (): Promise<boolean> => {
            const response = await requestService.get<Building>(`buildings/${building.id}`);

            const updatedBuilding = response.data;

            if (
                updatedBuilding.subscriptionStatus.includes('ACTIVE') &&
                !updatedBuilding.subscriptionCancellationRequested
            ) {
                userBuildingsService.updateEntity(updatedBuilding.id, updatedBuilding);
                authenticatedUserService.updateUserDetails({
                    ...authenticatedUserService.getUser(),
                    isTrialUsed: true
                });
                return true;
            } else {
                return false;
            }
        };

        const pollForCreate = async () => {
            setIsPolling(true);

            let isActive = await checkIsActive();

            if (successAttempts === 0) {
                if (!isActive) {
                    toast({
                        duration: 60000,
                        id: 'waiting-confirmation',
                        isClosable: false,
                        position: 'top',
                        status: 'loading',
                        description: 'Waiting for confirmation of payment from billing provider...',
                        title: 'Confirming payment'
                    });
                }
            }

            if (isActive) {
                setIsPolling(false);
            } else {
                if (successAttempts < 5) {
                    setTimeout(async () => {
                        setSuccessAttempts(successAttempts + 1);

                        isActive = await checkIsActive();

                        if (isActive) {
                            toast.close('waiting-confirmation');
                            setIsPolling(false);
                            setCallback(null);
                        }
                    }, 5000);
                } else {
                    toast.close('waiting-confirmation');

                    toast({
                        duration: 60000,
                        isClosable: true,
                        position: 'top',
                        status: 'warning',
                        description: 'Please get in touch with Technical Support for help',
                        title: 'Payment confirmation failed'
                    });

                    setIsPolling(false);
                }
            }
        };

        if (callback === 'success') {
            pollForCreate();
        }
    }, [successAttempts, callback]);

    useEffect(() => {
        const checkIsUpdated = async (): Promise<boolean> => {
            const response = await requestService.get<Building>(`buildings/${building.id}`);

            const updatedBuilding = response.data;

            if (updatedBuilding.updatedDate !== building.updatedDate) {
                userBuildingsService.updateEntity(updatedBuilding.id, updatedBuilding);
                return true;
            } else {
                return false;
            }
        };

        const pollForUpdate = async () => {
            setIsPolling(true);

            let isUpdated = await checkIsUpdated();

            if (isUpdated) {
                setIsPolling(false);
            } else {
                if (refreshAttempts < 5) {
                    setTimeout(async () => {
                        const { data: buildingOnServer } = await requestService.get<Building>(
                            `buildings/${building.id}`
                        );

                        if (buildingOnServer.updatedDate !== building.updatedDate) {
                            userBuildingsService.updateEntity(buildingOnServer.id, buildingOnServer);
                            setRefreshAttempts(6);
                        } else {
                            setRefreshAttempts(refreshAttempts + 1);
                        }
                    }, 1000);
                } else {
                    setIsPolling(false);
                }
            }
        };

        if (callback === 'refresh') {
            pollForUpdate();
        }
    }, [refreshAttempts, callback]);

    const openStripeSession = async () => {
        await genericToast(
            toast,
            async () => {
                const response = await requestService.post(
                    `buildings/${building.id}/subscriptions/initiate-stripe-session`
                );
                window.open(response.data.sessionUrl);
                window.close();
            },
            {
                title: 'Subscription portal will open shortly',
                onSuccessTitle: 'Successfully opened subscription portal',
                onErrorTitle: 'Failed to open subscription portal'
            }
        );
    };

    const getSubscriptionDescription = () => {
        switch (building.subscriptionStatus) {
            case 'NEW': {
                if (!authenticatedUserService.getUser().isTrialUsed) {
                    return ['Your free 90 day trial subscription is available!'];
                } else {
                    return ['This building does not not have an active subscription.'];
                }
            }
            case 'ACTIVE': {
                if (building.subscriptionCancellationRequested) {
                    return [
                        `The subscription has been cancelled and will end on ${formatNiceDate(
                            building.subscriptionCancellationDate
                        )}.`,
                        'You will no longer be billed for the subscription.'
                    ];
                } else {
                    return [
                        `The subscription will next be billed on ${formatNiceDate(building.subscriptionPeriodEndDate)}.`
                    ];
                }
            }
            case 'ACTIVE_TRIAL': {
                if (building.subscriptionCancellationRequested) {
                    return [
                        `The free trial subscription has been cancelled and will end on ${formatNiceDate(
                            building.subscriptionCancellationDate
                        )}.`,
                        'You will not be billed for the subscription.'
                    ];
                } else {
                    return [
                        `The free trial subscription will end on ${formatNiceDate(building.subscriptionPeriodEndDate)}.`,
                        'You will be billed on this date and then again on the same date every month until you cancel the subscription.'
                    ];
                }
            }
            case 'CANCELLED':
            case 'ENDED':
                return [`The subscription ended on ${formatNiceDate(building.subscriptionEndedDate)}.`];
            default:
                return [];
        }
    };

    const getButtonLabel = () => {
        switch (building.subscriptionStatus) {
            case 'ACTIVE':
            case 'ACTIVE_TRIAL':
                return 'Manage subscription';
            default:
                return 'Start subscription';
        }
    };

    return (
        <>
            {getSubscriptionDescription().map((text) => (
                <Text key={text} marginBottom={2}>
                    {text}
                </Text>
            ))}
            <WeFactorActionButton onClick={openStripeSession} isDisabled={isPolling} isLoading={isPolling}>
                {getButtonLabel()}
            </WeFactorActionButton>
        </>
    );
};
