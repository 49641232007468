import { Flex, Icon } from '@chakra-ui/react';
import { MdOutlineWarningAmber } from 'react-icons/md';
import { StandardBadge } from '../../../components';
import { Account } from '../../../types';

interface AccountStatusBadgeProps {
    account: Account;
}

export const AccountStatusBadge = (props: AccountStatusBadgeProps) => {
    if (!props.account) {
        return <></>;
    }

    const { email } = props.account;

    if (!email) {
        return (
            <StandardBadge backgroundColor={'#F6E05E'}>
                <Flex alignItems={'center'}>
                    <Icon as={MdOutlineWarningAmber} mr={1} /> Email not set
                </Flex>
            </StandardBadge>
        );
    } else {
        return <></>;
    }
};
