import { ForumBoard, ForumBoardView, ForumMessage, ForumPost, ForumPostView } from '../../../types';
import { entityServiceFactory } from '../entity-service-factory';

export const forumBoardService = entityServiceFactory.create<ForumBoard>('buildings/{{buildingId}}/forums');

export const forumBoardViewService = entityServiceFactory.create<ForumBoardView>(
    'buildings/{{buildingId}}/forums/views'
);

export const forumPostService = entityServiceFactory.create<ForumPost>(
    'buildings/{{buildingId}}/forums/{{forumBoardId}}/posts'
);

export const forumPostViewService = entityServiceFactory.create<ForumPostView>(
    'buildings/{{buildingId}}/forums/{{forumBoardId}}/posts/views'
);

export const forumMessageService = entityServiceFactory.create<ForumMessage>(
    'buildings/{{buildingId}}/posts/{{forumPostId}}/messages'
);
