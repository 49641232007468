import { Flex, Spacer, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import { StandardModal, StandardModalProps } from '../modals';
import { WeFactorActionButton, WeFactorSecondaryButton } from '../shared';

export interface ConfirmDialogProps extends StandardModalProps {
    detail?: string | string[];
    title: string;
    onConfirm: () => Promise<boolean> | boolean;
}

export const ConfirmDialog = (
    props: ConfirmDialogProps & {
        confirmIcon?: React.ReactElement;
        confirmText?: string;
    }
) => {
    const { confirmIcon, confirmText, detail, disclosure, title, onClose, onConfirm } = props;

    const [isConfirming, setIsConfirming] = useState(false);

    const handleOnClose = async () => {
        if (onClose) onClose();
        disclosure.onClose();
    };

    const handleOnConfirm = async () => {
        try {
            setIsConfirming(true);
            const success = await onConfirm();
            if (success) handleOnClose();
        } finally {
            setIsConfirming(false);
        }
    };

    const getChildren = () => {
        if (detail) {
            return Array.isArray(detail) ? (
                detail.map((text, index) => (
                    <Text mb={4} key={index.toString()}>
                        {text}
                    </Text>
                ))
            ) : (
                <Text mb={4}>{detail}</Text>
            );
        }
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'sm'
                }
            }}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton onClick={handleOnClose}>Cancel</WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorActionButton onClick={handleOnConfirm} isLoading={isConfirming} leftIcon={confirmIcon}>
                        {confirmText || 'Confirm'}
                    </WeFactorActionButton>
                </Flex>
            }
            header={title}
            onClose={onClose}
        >
            {getChildren()}
            <Text key={'sure'}>Are you sure?</Text>
        </StandardModal>
    );
};
