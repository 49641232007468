import { Flex, Spacer, Text } from '@chakra-ui/react';
import { StandardModal, StandardModalProps } from '../modals';
import { WeFactorSecondaryButton } from '../shared';

interface InformationDialogProps extends StandardModalProps {
    content: {
        messages: string | string[];
        title: string;
    };
}

export const InformationDialog = (props: InformationDialogProps) => {
    const { disclosure, onClose } = props;
    const { messages, title } = props.content;

    const handleOnClose = () => {
        if (onClose) onClose();
        disclosure.onClose();
    };

    const messagesList = typeof messages === 'string' ? [messages] : messages;

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    md: 'sm'
                }
            }}
            footer={
                <Flex width={'full'}>
                    <Spacer />
                    <WeFactorSecondaryButton onClick={handleOnClose}>Close</WeFactorSecondaryButton>
                    <Spacer />
                </Flex>
            }
            header={title}
            onClose={onClose}
        >
            {messagesList.map((message, index) => (
                <Text key={index.toString()} mb={index === messagesList.length - 1 ? 0 : 2}>
                    {message}
                </Text>
            ))}
        </StandardModal>
    );
};
