import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Container,
    ListItem,
    OrderedList,
    Stack,
    Table,
    Tbody,
    Text,
    UnorderedList
} from '@chakra-ui/react';
import { Heading2, StandardPage } from '../../components';
import { GlossaryTerm } from './GlossaryTerm';

export const TechnicalHelpPage = () => {
    return (
        <StandardPage title={'Technical Help'}>
            <Container>
                <Heading2 headingProps={{ textAlign: 'center' }}>Technical Help</Heading2>
                <Accordion>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as={'span'} flex={1} textAlign={'left'}>
                                    What should my first steps be?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <OrderedList>
                                <ListItem>Add your building/estate</ListItem>
                                <ListItem>Add all properties and corresponding owner details</ListItem>
                                <ListItem>Set owner contact addresses (if different from property)</ListItem>
                            </OrderedList>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as={'span'} flex="1" textAlign="left">
                                    What are my ongoing responsibilities?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <UnorderedList>
                                <ListItem>Record transactions to keep accounts up to date</ListItem>
                                <ListItem>Send account statements once a month to owners</ListItem>
                                <ListItem>Send correspondence to inform owners of developments</ListItem>
                                <ListItem>Create accounts for new owners when property is sold</ListItem>
                            </UnorderedList>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as={'span'} flex="1" textAlign="left">
                                    Accounting
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <Stack gap={2}>
                                <Text>
                                    Accounting involves recording transactions related to the upkeep of the
                                    building/estate such as charges for repairs or payments received.
                                </Text>
                                <Text>
                                    At the start of each new month account statements for the previous month should be
                                    sent to all property owners.
                                </Text>
                                <Text>More information can be found in the "Help" section of the Accounting page.</Text>
                            </Stack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as={'span'} flex="1" textAlign="left">
                                    Correspondence
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <Stack gap={2}>
                                <Text>
                                    It is important to send correspondence to keep owners informed about issues and
                                    developments related to their property.
                                </Text>
                                <Text>
                                    We provide a set of letter templates for common subjects to simplify this process.
                                    In addition there is an empty template for sending custom letters.
                                </Text>
                                <Text>
                                    More information can be found in the "Help" section of the Correspondence page.
                                </Text>
                            </Stack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as={'span'} flex="1" textAlign="left">
                                    Forums
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <Stack gap={2}>
                                <Text>
                                    The forums are where you can raise questions and concerns with fellow account
                                    managers.
                                </Text>
                                <Text>
                                    Most of the forum boards are public facing and anyone can respond. Each
                                    building/estate also has a private forum for account managers and owners who have
                                    signed to have private conversations.
                                </Text>
                                <Text>More information can be found in the "Help" section of the Forums page.</Text>
                            </Stack>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Glossary
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <Table size={'sm'}>
                                <Tbody>
                                    <GlossaryTerm
                                        word={'Account Manager'}
                                        description={
                                            'The account manager is the person who manages the accounts and arranges for common maintenance and services.'
                                        }
                                    />
                                    <GlossaryTerm
                                        word={'Building/estate'}
                                        description={
                                            'We use the term building to mean a shared block of apartments/flats and the word estate to mean a group of distinct properties.'
                                        }
                                    />
                                    <GlossaryTerm
                                        word={'Property'}
                                        description={'A property is an individual dwelling in the building or estate.'}
                                    />
                                    <GlossaryTerm
                                        word={'Owner'}
                                        description={
                                            'An owner is the person, company or trust who owns the property and is therefore responsible for a share of the common maintenance costs.'
                                        }
                                    />
                                    <GlossaryTerm
                                        word={'Account'}
                                        description={
                                            'An account is the register of transactions recorded against each owner. It includes payments received and charges taken.'
                                        }
                                    />
                                </Tbody>
                            </Table>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Container>
        </StandardPage>
    );
};
