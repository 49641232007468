import { Tab, TabList } from '@chakra-ui/react';
import { SideBar, SideBarTabPanel, SideBarTabPanels, SideBarTabs } from '../../../components';
import { factorAccountsService, factorPropertiesService } from '../../../services';
import { Account, Accounts, Building, Properties, Property } from '../../../types';
import { formatStreetAddress } from '../../../util';
import { AccountingSection } from './AccountingSection';
import { CorrespondenceSection } from './CorrespondenceSection';
import { DetailsSection } from './DetailsSection';

interface PropertyDetailsProps {
    account: Account;
    accounts: Accounts;
    building: Building;
    properties: Properties;
    property: Property;
}

export const PropertiesSideBar = (props: PropertyDetailsProps) => {
    const { account, accounts, building, properties, property } = props;

    return (
        <SideBar
            isOpen={true}
            header={formatStreetAddress(property)}
            onClose={() => {
                factorAccountsService.clearActiveEntity();
                factorPropertiesService.clearActiveEntity();
            }}
        >
            <SideBarTabs>
                <TabList>
                    <Tab>Account Details</Tab>
                    <Tab>Accounting</Tab>
                    <Tab>Correspondence</Tab>
                </TabList>
                <SideBarTabPanels>
                    <SideBarTabPanel>
                        <DetailsSection
                            account={account}
                            accounts={accounts}
                            building={building}
                            properties={properties}
                            property={property}
                        />
                    </SideBarTabPanel>
                    <SideBarTabPanel>
                        <AccountingSection account={account} building={building} />
                    </SideBarTabPanel>
                    <SideBarTabPanel>
                        <CorrespondenceSection account={account} building={building} />
                    </SideBarTabPanel>
                </SideBarTabPanels>
            </SideBarTabs>
        </SideBar>
    );
};
