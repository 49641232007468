import { Input, InputGroup, InputProps } from '@chakra-ui/react';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';

export type BaseInputProps<T extends FieldValues> = BaseFormControlProps<T> & {
    inputProps?: InputProps;

    inputElementLeft?: React.ReactElement;
    inputElementRight?: React.ReactElement;
    placeholder?: string;
};

export const BaseInput = <T extends FieldValues>(props: BaseInputProps<T>) => {
    const { formLabel, isRequired, name, inputElementLeft, inputElementRight, inputProps, placeholder } = props;

    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        return (
            <InputGroup>
                {inputElementLeft}
                <Input
                    id={name}
                    aria-label={formLabel || name}
                    aria-required={isRequired}
                    errorBorderColor="crimson"
                    placeholder={placeholder}
                    variant="outline"
                    {...controller.field}
                    {...inputProps}
                />
                {inputElementRight}
            </InputGroup>
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
