import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { TextEditor, TextField, Wizard, WizardProps, WizardTabPanel } from '../../../components';
import { forumPostService } from '../../../services';
import { ForumBoard, ForumPost } from '../../../types';

type ForumPostForm = {
    body: string;
    subject: string;
};

export const ForumPostWizard = (props: { buildingId: string; forumBoard: ForumBoard } & WizardProps<ForumPost>) => {
    const { buildingId, disclosure, forumBoard, onClose, onSaveSuccess } = props;

    const form = useForm<ForumPostForm>({
        defaultValues: {
            body: '',
            subject: ''
        },
        resolver: joiResolver(
            Joi.object({
                body: Joi.string().label('Body').trim().min(3).max(20000).required(),
                subject: Joi.string().label('Subject').trim().min(5).max(200).required()
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const save = async (formValues: ForumPostForm) => {
        return await forumPostService.create({
            pathParams: {
                buildingId: buildingId,
                forumBoardId: forumBoard.id
            },
            body: formValues
        });
    };

    return (
        <Wizard<ForumPostForm, ForumPost>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: '3xl'
                }
            }}
            modalContentProps={{
                height: '2xl'
            }}
            onClose={onClose}
            onSaveSuccess={onSaveSuccess}
            form={form}
            heading={'New post'}
            save={save}
            steps={[
                {
                    heading: 'Message content',
                    fieldNames: ['subject', 'body']
                }
            ]}
        >
            <WizardTabPanel>
                <TextField
                    name="subject"
                    formLabel="Subject"
                    formHook={form}
                    inputProps={{ maxLength: 200 }}
                    isRequired
                />
                <TextEditor name="body" formLabel="Body" formHook={form} isRequired />
            </WizardTabPanel>
        </Wizard>
    );
};
