import { MdOutlineArticle, MdOutlineHandyman, MdOutlinePieChart } from 'react-icons/md';
import { Address, Correspondence, CorrespondenceType, Property } from '../types';
import { convertDateOrStringToDateTime } from './dates';

// Numbers

export const fixDecimalPlaces = (number: number, decimalPlaces: number): string => {
    return (Math.round(number * 100) / 100).toFixed(decimalPlaces);
};

export const formatCurrency = (amount?: number): string => {
    if (amount || amount === 0) {
        return amount < 0 ? `-£${fixDecimalPlaces(0 - amount, 2)}` : `£${fixDecimalPlaces(amount, 2)}`;
    } else {
        return '';
    }
};

// Dates & Times

export const formatShortDate = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('dd/LL/yyyy') || '';
};

export const formatDate = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('d LLLL yyyy') || '';
};

export const formatTime = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('H:mm a').toLocaleLowerCase() || '';
};

const nthNumber = (number) => {
    if (number > 3 && number < 21) return 'th';

    switch (number % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const formatNiceDate = (date?: Date | string): string => {
    const dateTime = convertDateOrStringToDateTime(date);

    if (dateTime) {
        const ordinal = nthNumber(dateTime.day);
        return `${dateTime.day}${ordinal} ${dateTime.toFormat('LLLL yyyy')}`; // e.g. 4th December 2023
    } else {
        return '';
    }
};

export const formatDateShort = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('d/L/yy') || ''; // e.g. 4/12/23
};

export const formatDateShortMonth = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('d LLL yyyy') || ''; // e.g. 4 Dec 2023
};

export const formatMonth = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('LLLL') || ''; // e.g. December
};

export const formatMonthYear = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('LLLL yyyy') || ''; // e.g. 2023
};

export const formatMonthYearForDatePicker = (date?: Date | string): string => {
    return convertDateOrStringToDateTime(date)?.toFormat('yyyy-LL') || ''; // e.g. 2023-12
};

// Addresses

export const formatAddress = (address: Address | Property): string => {
    return [
        address.subBuilding,
        address.buildingName,
        `${address.buildingNumber || ''} ${address.streetName}`.trim(),
        address.town,
        address.postcode
    ]
        .filter((value) => value && value.trim().length > 0)
        .join(', ');
};

export const formatStreetAddress = (address: Address | Property, reverse?: boolean): string => {
    if (reverse) {
        return [
            `${address.buildingNumber || ''} ${address.streetName}`.trim(),
            address.buildingName,
            address.subBuilding
        ]
            .filter((value) => value && value.trim().length > 0)
            .join(', ');
    } else {
        return [
            address.subBuilding,
            address.buildingName,
            `${address.buildingNumber || ''} ${address.streetName}`.trim()
        ]
            .filter((value) => value && value.trim().length > 0)
            .join(', ');
    }
};

export const formatStreetOnlyAddress = (address: Address | Property): string => {
    return [address.buildingName, `${address.buildingNumber || ''} ${address.streetName}`.trim()]
        .filter((value) => value && value.trim().length > 0)
        .join(', ');
};

// Correspondence

export const formatCorrespondenceType = (correspondenceType: CorrespondenceType | string) => {
    switch (correspondenceType) {
        case CorrespondenceType.COMMON_REPAIR_QUOTE:
            return 'Maintenance quote';
        case CorrespondenceType.LETTER:
            return 'Letter';
        case CorrespondenceType.SHARED_COSTS:
            return 'Shared costs information';
        default:
            return '';
    }
};

export const getCorrespondenceIcon = (correspondence: Correspondence) => {
    switch (correspondence.type) {
        case CorrespondenceType.COMMON_REPAIR_QUOTE:
            return MdOutlineHandyman;
        case CorrespondenceType.LETTER:
            return MdOutlineArticle;
        case CorrespondenceType.SHARED_COSTS:
            return MdOutlinePieChart;
        default:
            return undefined;
    }
};

// Other

export const formatPaymentType = (paymentType?: string) => {
    switch (paymentType) {
        case 'BANK_TRANSFER':
            return 'Bank Transfer';
        case 'CASH':
            return 'Cash Payment';
        case 'STARTING_BALANCE':
            return 'Initial Balance';
        default:
            return paymentType || '';
    }
};
