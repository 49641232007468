import { Select, Props as SelectSelect } from 'chakra-react-select';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';

export type BaseSelectProps<T extends FieldValues> = BaseFormControlProps<T> & {
    selectProps?: SelectSelect;

    options: { label: string; value: string }[];
};

export const BaseSelect = <T extends FieldValues>(props: BaseSelectProps<T>) => {
    const { formLabel, isRequired, name, options, selectProps } = props;

    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        const { onBlur, onChange, ref, value } = controller.field;

        const getDefaultValues = () => {
            const optionsMap = new Map();

            for (const option of options) {
                if ((option as any).options) {
                    for (const nestedOptions of (option as any).options) {
                        optionsMap.set(nestedOptions.value, nestedOptions);
                    }
                } else if ((option as any).value) {
                    optionsMap.set((option as any).value, option);
                }
            }

            const defaultOptions: any = [];

            if (Array.isArray(value)) {
                value.forEach((nestedValue) => {
                    const option = optionsMap.get(nestedValue);
                    if (option) defaultOptions.push(option);
                });
            } else {
                const option = optionsMap.get(value);
                if (option) defaultOptions.push(option);
            }

            return defaultOptions;
        };

        return (
            <Select
                inputId={name}
                name={name}
                aria-autocomplete="none"
                aria-label={formLabel || name}
                aria-required={isRequired}
                errorBorderColor="crimson"
                noOptionsMessage={() => 'No options available'}
                selectedOptionStyle="check"
                hideSelectedOptions={false}
                defaultValue={getDefaultValues()}
                onBlur={onBlur}
                onChange={(option: any) => {
                    if (Array.isArray(option)) {
                        return onChange(option.map((nestedOption) => nestedOption.value));
                    } else {
                        return onChange(option.value);
                    }
                }}
                options={options}
                ref={ref}
                {...selectProps}
            />
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
