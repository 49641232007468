import { Balance, Invoice, Transaction } from '../../../types';
import { entityServiceFactory } from '../entity-service-factory';

// Owners

export const ownerInvoiceService = entityServiceFactory.create<Invoice>('accounts/{{accountId}}/invoices');

export const ownerTransactionService = entityServiceFactory.create<Transaction>('accounts/{{accountId}}/transactions');

export const ownerAccountBalanceService = entityServiceFactory.create<Balance>(
    'accounts/{{accountId}}/transactions/balance'
);

// Factors

export const factorInvoiceService = entityServiceFactory.create<Invoice>('buildings/{{buildingId}}/invoices');

export const factorTransactionService = entityServiceFactory.create<Transaction>(
    'buildings/{{buildingId}}/transactions'
);

export const factorAccountBalancesService = entityServiceFactory.create<Balance>(
    'buildings/{{buildingId}}/transactions/balances'
);

export const factorActiveAccountBalancesService = entityServiceFactory.create<Balance>(
    'buildings/{{buildingId}}/transactions/active-balances'
);

// Actions

export const factorStatementService = entityServiceFactory.create<{
    id: string;
    signedUrl: string;
}>('buildings/{{buildingId}}/statements');

export const ownerStatementService = entityServiceFactory.create<{
    id: string;
    signedUrl: string;
}>('accounts/{{accountId}}/statements');
