import { Icon, IconButton, Menu, MenuButton, MenuList, useToast } from '@chakra-ui/react';
import { MdOutlineCheck, MdOutlineClose, MdOutlineQuestionMark } from 'react-icons/md';
import { TableMenu, TableMenuItem } from '../../../../components';
import { Account, CommonRepairQuoteShare, CorrespondenceAccount } from '../../../../types';
import { CorrespondenceActionDispatcher } from './CorrespondenceActionDispatcher';
import { CorrespondenceRowValues } from './CorrespondenceTypes';

export const CorrespondenceTableMenu = (props: {
    actionDispatcher: CorrespondenceActionDispatcher;
    values: CorrespondenceRowValues;
    resendCorrespondence: (correspondenceAccount: CorrespondenceAccount) => void;
}) => {
    const { actionDispatcher, values, resendCorrespondence } = props;
    const { correspondenceAccount } = values;

    const toast = useToast();

    return (
        <TableMenu>
            <TableMenuItem
                menuItemProps={{
                    onClick: () => resendCorrespondence(correspondenceAccount)
                }}
            >
                Send correspondence
            </TableMenuItem>
            <TableMenuItem
                menuItemProps={{
                    onClick: () => actionDispatcher.downloadCorrespondence(values, toast)
                }}
            >
                Download correspondence
            </TableMenuItem>
        </TableMenu>
    );
};

export const CorrespondenceTableMenuForResponse = (props: {
    share: CommonRepairQuoteShare | undefined;
    values: CorrespondenceRowValues;
    updateResponse: (account: Account, response: boolean | null) => void;
}) => {
    if (!props.share) {
        return <></>;
    }

    const { share, values, updateResponse } = props;
    const { factorResponse, ownerResponse } = share;
    const { account } = values;

    const getIcon = () => {
        if (factorResponse !== null) return factorResponse ? MdOutlineCheck : MdOutlineClose;
        if (ownerResponse !== null) return ownerResponse ? MdOutlineCheck : MdOutlineClose;
        return MdOutlineQuestionMark;
    };

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label={'set owner response'}
                icon={<Icon as={getIcon()} />}
                variant={'outline'}
            />
            <MenuList>
                <TableMenuItem
                    key={'set-response-to-true'}
                    menuItemProps={{
                        onClick: () => updateResponse(account, true)
                    }}
                >
                    Set as accepted
                </TableMenuItem>
                <TableMenuItem
                    key={'set-response-to-false'}
                    menuItemProps={{
                        onClick: () => updateResponse(account, false)
                    }}
                >
                    Set as rejected
                </TableMenuItem>
                {factorResponse !== null && ownerResponse !== null && (
                    <TableMenuItem
                        key={'set-response-to-null'}
                        menuItemProps={{
                            onClick: () => updateResponse(account, null)
                        }}
                    >
                        Revert to owner's response
                    </TableMenuItem>
                )}
                {factorResponse !== null && ownerResponse === null && (
                    <TableMenuItem
                        key={'set-response-to-null'}
                        menuItemProps={{
                            onClick: () => updateResponse(account, null)
                        }}
                    >
                        Clear response
                    </TableMenuItem>
                )}
            </MenuList>
        </Menu>
    );
};
