import { notDeletedFilter } from '.';
import { convertStringToDate } from '../util';
import { DbRecord } from './db-record';

export interface Account extends DbRecord {
    id: string;
    buildingId: string;
    propertyId: string;

    accountName: string;
    contactAddressLine1?: string;
    contactAddressLine2?: string;
    contactAddressLine3?: string;
    contactAddressLine4?: string;
    contactPostcode?: string;
    deleted: boolean;
    deletedDate: string | undefined;
    email?: string;
    endDate?: string;
    isCurrentOwner: boolean;
    startDate: string;
    username: string;
}

export interface Balance {
    id: string;
    balance: number;
}

export class Accounts {
    private accounts: Account[];
    private activeAccounts: Account[];
    private accountMap: Map<string, Account>;

    constructor(accounts: Account[]) {
        this.accounts = [...accounts].sort(startDateSorter());
        this.activeAccounts = this.accounts.filter(notDeletedFilter());
        this.accountMap = new Map(this.accounts.map((account) => [account.id, account]));
    }

    getAccount(accountId: string): Account | undefined {
        return this.accountMap.get(accountId);
    }

    getAccounts(options?: AccountOptions): Account[] {
        return this.transformList(this.accounts, options);
    }

    getActiveAccounts(options?: AccountOptions): Account[] {
        return this.transformList(this.activeAccounts, options);
    }

    getAccountMap(key: string = 'id', options?: AccountOptions): Map<string, Account> {
        return new Map(this.transformList(this.accounts, options).map((account) => [account[key].toString(), account]));
    }

    getAccountsMap(key: string = 'id', options?: AccountOptions): Map<string, Account[]> {
        return this.transformList(this.accounts, options).reduce(
            (map, account) => map.set(account[key], [...(map.get(account[key]) || []), account]),
            new Map()
        );
    }

    getCurrentOwnerAccounts(): Account[] {
        return this.transformList(this.activeAccounts, { filters: [currentOwnerFilter] });
    }

    private transformList(accounts: Account[], options?: AccountOptions): Account[] {
        let transformedAccounts = [...accounts];

        if (options?.filters) {
            transformedAccounts = options.filters.reduce((accounts, filter) => accounts.filter(filter), accounts);
        }

        if (options?.sorters) {
            transformedAccounts = options.sorters.reduce((accounts, filter) => accounts.sort(filter), accounts);
        }

        return transformedAccounts;
    }
}

type AccountOptions = {
    filters?: AccountFilter[];
    sorters?: AccountSorter[];
};

type AccountFilter = (value: Account, index: number, array: Account[]) => unknown;

type AccountSorter = (a: Account, b: Account) => number;

// Address filters

export const propertyFilter = (id: string) => (account: Account) => account.propertyId === id;

export const currentOwnerFilter = () => (account: Account) => account.isCurrentOwner;

// Address sorts

export const startDateSorter = () => (a, b) =>
    convertStringToDate(b.startDate).getTime() - convertStringToDate(a.startDate).getTime();
