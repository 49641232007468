import { DivisionMethod } from '../../../../types';

export enum CorrespondenceTemplate {
    WELCOME_LETTER
}

export class Templates {
    getLetterTemplate(correspondenceTemplate?: CorrespondenceTemplate) {
        switch (correspondenceTemplate) {
            case CorrespondenceTemplate.WELCOME_LETTER:
                return `<p>{$current_date}</p>
<p><underline>Re: {$address}</underline></p>
<p>Dear {$name}</p>
<p><strong>{$subject}</strong></p>
<p>Welcome to WeFactor!</p>
<p>Yours sincerely</p>
<p>{$sender_name}</p>`;
            default:
                return `<p>{$current_date}</p>
<p><underline>Re: {$address}</underline></p>
<p>Dear {$name}</p>
<p><strong>{$subject}</strong></p>
<p>Replace this paragraph with the desired contents of your letter. You can preview the letter for each owner on the next page.</p>`;
        }
    }

    getCommonRepairCostsTemplate(buildingName: string) {
        return `<p>{$current_date}</p>
<p><underline>Re: {$address}</underline></p>
<p>Dear {$name}</p>
<p><strong>{$subject}</strong></p>
<p>We have received a quote for maintenance work at ${buildingName} which is outlined below.</p>
<p>Replace this paragraph with a brief outline of the required maintenance work.</p>
<p>The total estimated cost for this work is {$total_cost}. Your share has been calculated to be {$owners_share} for the aforementioned property.</p>
<p>Please respond by {$deadline} to confirm that you are happy for this work to proceed.</p>`;
    }

    getSharedCostsTemplate(divisionMethod: string) {
        const getInner = () => {
            switch (divisionMethod) {
                case DivisionMethod.EQUAL_SHARE: {
                    return `
<p>Common maintenance costs will be shared equally between all {$total_properties} properties in the building.</p>
<p>Therefore your share of costs will be 1/{$total_properties} ({$percentage}).</p>`;
                }
                case DivisionMethod.FLOOR_AREA: {
                    return `
<p>Common maintenance costs will be shared according to the ratio of each property's floor area to the total of all properties' floor areas.</p>
<p>Your floor area has been measured as {$floor_area}.</p>
<p>The total floor area of all properties has been measured as {$total_floor_area}.</p>
<p>Therefore your share of costs will be {$floor_area}/{$total_floor_area} ({$percentage}).</p>`;
                }
                case DivisionMethod.FRACTION: {
                    return `
<p>Common maintenance costs will be shared by assigning a fixed fraction value to each property.</p>
<p>The fraction value assigned to your property is {$numerator}/{$denominator}.</p>
<p>Therefore your share of costs will be {$numerator}/{$denominator} ({$percentage}).</p>`;
                }
                case DivisionMethod.PERCENTAGE: {
                    return `
<p>Common maintenance costs will be shared by assigning a fixed percentage value to each property.</p>
<p>The percentage value assigned to your property is {$percentage}.</p>
<p>Therefore your share of costs will be {$percentage}.</p>`;
                }
                case DivisionMethod.RATEABLE_VALUE: {
                    return `
<p>Common maintenance costs will be shared according to the ratio of each property's Rateable Value to the sum total of all properties' Rateable Values.</p>
<p>The Rateable Value assigned to your property is {$rateable_value}.</p>
<p>The sum total total of all Rateable Values is {$total_rateable_value}.</p>
<p>Therefore your share of costs will be {$rateable_value}/{$total_rateable_value} ({$percentage}).</p>`;
                }
                default:
                    return '';
            }
        };

        return `
<p>{$current_date}</p>
<p>Dear {$name}</p>
<p><strong>{$subject}</strong></p>
<p>This letter details what your share of any future common maintenance costs will be for {$address}.</p>
${getInner()}
<p>If you have any questions or concerns please get in touch.</p>`;
    }
}
