import {
    Box,
    Flex,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Stack,
    Text,
    useDisclosure,
    useToast,
    VStack
} from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { MdHelpOutline, MdOutlineApartment, MdOutlineExpandMore, MdOutlineStarBorder } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Observable } from 'rxjs';
import { COLOURS, DARK, DARK_4, FeedbackDialog, ICON_SIZES, LogoIcon, MAIN_BLUE } from '../../components';
import { AssumeAdminFormDialog, BuildingWizard } from '../../pages/buildings';
import { userAccountService, userBuildingsService } from '../../services';
import { Building } from '../../types';
import { UserAccount } from '../../types/user-account';
import { formatStreetAddress } from '../../util';
import { FactorNavigationList } from './FactorNavigationList';
import { NavigationButton } from './NavigationButton';
import { OwnerNavigationList } from './OwnerNavigationList';

const NAV_PADDING = 4;
const NAV_SPACING = 3;

export const NavigationBar = () => {
    const disclosureHelp = useDisclosure();

    const [account] = useObservable(userAccountService.activeEntity$ as Observable<UserAccount>);
    const [building] = useObservable(userBuildingsService.activeEntity$ as Observable<Building>);

    const [accounts] = useObservable(userAccountService.entities$ as Observable<UserAccount[]>);
    const [accountsLoaded] = useObservable(userAccountService.loaded$ as Observable<boolean>);

    const [buildings] = useObservable(userBuildingsService.entities$ as Observable<Building[]>);
    const [buildingsLoaded] = useObservable(userBuildingsService.loaded$ as Observable<boolean>);

    if (!accountsLoaded || !buildingsLoaded) return null;

    return (
        <Flex
            backgroundColor={COLOURS.BLUE}
            flexDirection={'column'}
            height={'100%'}
            minWidth={'260px'}
            overflow={'auto'}
        >
            <VStack padding={NAV_PADDING} spacing={NAV_SPACING}>
                <LogoIcon />
                <SelectBuildingMenu account={account} accounts={accounts} building={building} buildings={buildings} />
                {account && <OwnerNavigationList selectedUserAccount={account} />}
                {building && <FactorNavigationList selectedBuilding={building} />}
            </VStack>
            <Box padding={NAV_PADDING} marginTop={'auto'}>
                <Stack spacing={NAV_SPACING}>
                    <NavigationButton
                        icon={<MdHelpOutline size={ICON_SIZES.LARGE} />}
                        label={'Technical Help'}
                        route={'/technical-help'}
                    />
                    <NavigationButton
                        icon={<MdOutlineStarBorder size={ICON_SIZES.LARGE} />}
                        label={'Give us feedback'}
                        onClick={disclosureHelp.onOpen}
                    />
                    {disclosureHelp.isOpen && <FeedbackDialog disclosure={disclosureHelp} />}
                </Stack>
            </Box>
        </Flex>
    );
};

interface SelectBuildingMenuProps {
    account: UserAccount;
    accounts: UserAccount[];
    building: Building;
    buildings: Building[];
}

export const SelectBuildingMenu = (props: SelectBuildingMenuProps) => {
    const navigate = useNavigate();

    const { account, building, buildings } = props;

    const disclosureAddBuilding = useDisclosure();
    const disclosureAssumeAdmin = useDisclosure();
    const toast = useToast();

    const onAddBuilding = () => {
        if (buildings.length > 1) {
            showWarnig(
                'You have added the maximum permittable number of buildings. Delete an existing building or transfer its admin rights to someone else before creating a new building.'
            );
        } else {
            disclosureAddBuilding.onOpen();
        }
    };

    const onAssumeAdmin = () => {
        if (buildings.length > 1) {
            showWarnig(
                'You have added the maximum permittable number of buildings. Delete an existing building or transfer its admin rights to someone else before assuming admin rights over a new building.'
            );
        } else {
            disclosureAssumeAdmin.onOpen();
        }
    };

    const showWarnig = (warningMessage: string) => {
        toast({
            duration: 12000,
            position: 'top',
            status: 'warning',
            title: 'Cannot add new building',
            description: warningMessage
        });
    };

    const fetchAndNavigateToBuilding = async (building?: Building) => {
        if (building) {
            userBuildingsService.fetch().then(() => navigateToBuilding(building));
        }
    };

    const navigateToBuilding = (building: Building) => {
        navigate(`/buildings/${building.id}/properties`);
    };

    const getSelectedText = () => {
        if (building) {
            return building.name;
        } else if (account) {
            return formatStreetAddress(account.property);
        } else {
            return 'Select account...';
        }
    };

    const borderBottom = `1px solid ${DARK_4}`;

    return (
        <Menu matchWidth={true}>
            <MenuButton
                _active={{ backgroundColor: COLOURS.BLUE_2 }}
                _hover={{ backgroundColor: COLOURS.GREEN }}
                bgColor={COLOURS.BLUE_2}
                borderRadius={'md'}
                padding={2}
                width={'100%'}
            >
                <Flex alignItems={'center'} color={COLOURS.WHITE} lineHeight={'20px'}>
                    <MdOutlineApartment size={ICON_SIZES.LARGE} />
                    <Text marginLeft={'auto'}>{getSelectedText()}</Text>
                    <Box marginLeft={'auto'}>
                        <MdOutlineExpandMore size={ICON_SIZES.LARGE} />
                    </Box>
                </Flex>
            </MenuButton>
            <MenuList>
                <MenuGroup title={'Buildings/estates'} color={DARK}>
                    {buildings.map((building: Building) => (
                        <MenuItem
                            borderBottom={borderBottom}
                            key={building.name}
                            onClick={() => {
                                navigateToBuilding(building);
                            }}
                        >
                            {building.name}
                        </MenuItem>
                    ))}
                    <MenuItem paddingX={3} paddingY={2} onClick={onAddBuilding} borderBottom={borderBottom}>
                        <Text color={MAIN_BLUE}>+ Add new building/estate</Text>
                    </MenuItem>
                    <MenuItem paddingX={3} paddingY={2} onClick={onAssumeAdmin}>
                        <Text color={MAIN_BLUE}>+ Transfer building/estate</Text>
                    </MenuItem>
                </MenuGroup>
            </MenuList>
            {disclosureAssumeAdmin.isOpen && (
                <AssumeAdminFormDialog disclosure={disclosureAssumeAdmin} onClose={fetchAndNavigateToBuilding} />
            )}
            {disclosureAddBuilding.isOpen && (
                <BuildingWizard disclosure={disclosureAddBuilding} onClose={fetchAndNavigateToBuilding} />
            )}
        </Menu>
    );
};
