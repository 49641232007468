import { Flex, Spacer, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StandardModal, StandardModalProps } from '../../../components';
import { SelectAccountField, SelectYearMonth } from '../../../components/form';
import { WeFactorSecondaryButton, WeFactorSubmitButton } from '../../../components/shared';
import { factorStatementService } from '../../../services';
import { Accounts, Building, Properties } from '../../../types';
import { formatMonthYearForDatePicker, genericToast } from '../../../util';

interface DownloadStatementDialogProps extends StandardModalProps {
    accounts: Accounts;
    building: Building;
    properties: Properties;
}

type MonthRangeForm = {
    accountId: string;
    month: string;
};

export const DownloadStatementsDialog = (props: DownloadStatementDialogProps) => {
    const { accounts, building, properties, disclosure, onClose } = props;

    const toast = useToast();

    const [isDownloading, setIsDownloading] = useState(false);

    const getDefaultMonth = () => {
        if (building.lastStatementDate) {
            return formatMonthYearForDatePicker(new Date(building.lastStatementDate));
        } else {
            return formatMonthYearForDatePicker(
                DateTime.fromJSDate(new Date()).minus({ months: 1 }).endOf('month').startOf('day').toJSDate()
            );
        }
    };

    const form = useForm<MonthRangeForm>({
        resolver: joiResolver(
            Joi.object({
                accountId: Joi.string().uuid().required().label('Statement account'),
                month: Joi.string()
                    .label('Statement month')
                    .messages({
                        'date.past': 'Statement month must be in the past'
                    })
                    .custom((value, helpers) => {
                        const currentMonth = DateTime.fromJSDate(new Date()).startOf('month').toJSDate();

                        const selectedMonth = DateTime.fromISO(value).toJSDate();

                        if (selectedMonth >= currentMonth) {
                            return helpers.error('date.past');
                        }

                        if (building.lastStatementDate) {
                            if (selectedMonth >= new Date(building.lastStatementDate)) {
                                return helpers.error('date.before-accounts');
                            }
                        }

                        return value;
                    })
                    .required()
            })
        ),
        defaultValues: {
            month: getDefaultMonth()
        },
        reValidateMode: 'onSubmit'
    });

    const { getValues, handleSubmit } = form;

    const submit = async () => {
        setIsDownloading(true);

        try {
            await genericToast(
                toast,
                async () => {
                    const { signedUrl } = await factorStatementService.create({
                        pathParams: {
                            buildingId: building.id
                        },
                        pathSuffix: 'download',
                        body: {
                            statementMonth: getValues('month')
                        },
                        params: {
                            accountId: getValues('accountId')
                        }
                    });

                    if (signedUrl) {
                        window.open(signedUrl);
                    }
                },
                {
                    title: 'Downloading statement',
                    onSuccessTitle: 'Statement downloaded',
                    onErrorTitle: 'Failed to  downloaded statement'
                }
            );
        } catch (error) {
            console.error('Failed to download statement', error);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'sm'
                }
            }}
            modalContentProps={{
                height: 'xl'
            }}
            onClose={onClose}
            header={'Download statement'}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton isDisabled={isDownloading} onClick={disclosure.onClose}>
                        Cancel
                    </WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorSubmitButton onClick={handleSubmit(submit)} isLoading={isDownloading}>
                        Download
                    </WeFactorSubmitButton>
                </Flex>
            }
        >
            <form id="download-statements-form" noValidate autoComplete="off">
                <SelectYearMonth
                    name="month"
                    formHook={form}
                    formLabel="Statement month"
                    isRequired={true}
                    allowCurrentMonth={false}
                />
                <SelectAccountField
                    name="accountId"
                    formHook={form}
                    formLabel="Statement account"
                    isRequired={true}
                    accounts={accounts}
                    properties={properties}
                    selectProps={{
                        placeholder: 'Select an account...'
                    }}
                />
            </form>
        </StandardModal>
    );
};
