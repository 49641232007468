import { Box, Container, Link, ListItem, OrderedList, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Heading2, PremiumFeatureCard } from '../../shared';

enum Section {
    ATTACHMENTS,
    DRAFTING,
    PLACEHOLDERS,
    RECIPIENTS,
    SENDING,
    DOWNLOADING,
    EMAILING,
    TEMPLATES,
    LETTER,
    MAINTENANCE_QUOTE
}

export const CorrespondenceHelp = () => {
    const [section, setSection] = useState<Section>(Section.DRAFTING);

    const getActiveSection = () => {
        switch (section) {
            case Section.ATTACHMENTS:
                return <Attachments setSection={setSection} />;
            case Section.DOWNLOADING:
                return <Downloading setSection={setSection} />;
            case Section.DRAFTING:
                return <Drafting setSection={setSection} />;
            case Section.EMAILING:
                return <Emailing setSection={setSection} />;
            case Section.LETTER:
                return <Letter setSection={setSection} />;
            case Section.MAINTENANCE_QUOTE:
                return <MaintenanceQuote setSection={setSection} />;
            case Section.PLACEHOLDERS:
                return <Placeholders setSection={setSection} />;
            case Section.RECIPIENTS:
                return <Recipients setSection={setSection} />;
            case Section.SENDING:
                return <Sending setSection={setSection} />;
            case Section.TEMPLATES:
                return <Templates setSection={setSection} />;
            default:
                return <></>;
        }
    };

    const indent = [4, 6];

    return (
        <Stack paddingX={[2, 0]} direction={['column', 'row']}>
            <Box borderRightWidth={[0, '1px']} paddingRight={indent} width={'fit-content'}>
                <Heading2>Help Contents</Heading2>
                <VStack alignItems={'normal'}>
                    <Link color="teal" onClick={() => setSection(Section.DRAFTING)}>
                        Drafting
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.PLACEHOLDERS)} marginLeft={indent}>
                        Placeholders
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.RECIPIENTS)} marginLeft={indent}>
                        Recipients
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.ATTACHMENTS)} marginLeft={indent}>
                        Attachments
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.SENDING)}>
                        Sending
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.DOWNLOADING)} marginLeft={indent}>
                        Downloading
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.EMAILING)} marginLeft={indent}>
                        Emailing
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.TEMPLATES)}>
                        Templates
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.LETTER)} marginLeft={indent}>
                        Letter
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.MAINTENANCE_QUOTE)} marginLeft={indent}>
                        Maintenance Quote
                    </Link>
                </VStack>
            </Box>
            <Container paddingX={0}>
                <Stack gap={2}>{getActiveSection()}</Stack>
            </Container>
        </Stack>
    );
};

type SectionProps = { setSection: (section: Section) => void };

const Templates = (props: SectionProps) => {
    return (
        <>
            <Heading2>Correspondence Templates</Heading2>
            <Text>We provide a selection of templates for common types of correspondence to make it easier.</Text>
            <Text>
                Placeholder in the template are replaced with proper values when the letter is saved. For example "
                {'{$name}'}" is replaced with the owner's name.
            </Text>
            <Text>
                The full list of supported placeholders can be found in the "Placeholders" section of this help page.
            </Text>
        </>
    );
};

const Drafting = (props: SectionProps) => {
    return (
        <>
            <Heading2>Drafting correspondence</Heading2>
            <Text>
                To draft new correspondence click on the "Actions" button and select the desired type of correspondence
                to create.
            </Text>
        </>
    );
};

const Letter = (props: SectionProps) => {
    return (
        <>
            <Heading2>Letter</Heading2>
            <Text>The Letter template is for sending informational letters.</Text>
        </>
    );
};

const MaintenanceQuote = (props: SectionProps) => {
    return (
        <>
            <Heading2>Maintenance Quote</Heading2>
            <Text>
                The Maintenenace Quote template is used to inform owners of common maintenance work. The letter details
                each owner's share of the total cost and asks them to confirm that they are happy for the repairs to
                proceed.
            </Text>
            <Text>
                Majority consent is required for repair work to proceed. Note that for buildings where properties are
                responsible for different shares of the costs, then the weight of consent is not equal - for example if
                an owner is responsible for 20% of the costs then their vote is worth 20%.
            </Text>
            <Text>
                The app works out each owner's share of costs and populates the template automatically. It also works
                out whether or not majority consent has been reached once each owner's response has been set.
            </Text>
        </>
    );
};

const Placeholders = (props: SectionProps) => {
    return (
        <>
            <Heading2>Placeholders</Heading2>
            <Text>
                When drafting correspondence you can insert placeholders in the letter body. These are special values
                that will be replaced with proper values when the correspondence is downloaded or emailed. For example
                the placeholder {'{$name}'} will be replaced with the recipient's name.
            </Text>
            <Text>All placeholders are of the format {'{$placeholder_name}'}.</Text>
            <Text>Here is the list of supporteed placeholder names and what they will be replaced with:</Text>
            <UnorderedList mt={2}>
                <ListItem>{`{$current_date}`}: The date on which the letter is saved</ListItem>
                <ListItem>{`{$address}`}: The owners property address</ListItem>
                <ListItem>{`{$name}`}: The owner's name</ListItem>
                <ListItem>{`{$subject}`}: The letter subject</ListItem>
            </UnorderedList>
        </>
    );
};

const Recipients = (props: SectionProps) => {
    return (
        <>
            <Heading2>Recipients</Heading2>
            <Text>
                By default we'll select all active accounts as recipients. You are free to remove or add recipients as
                desired.
            </Text>
            <Text>
                Selected recipients will have immediate access to the correspondence when it is published if they have
                signed up to WeFactor.
            </Text>
        </>
    );
};

const Attachments = (props: SectionProps) => {
    return (
        <>
            <Heading2>Attachments</Heading2>
            <Text>You can upload files to correspondence as attachments.</Text>
            <Text>
                When we send correspondence any attachments will be included in the email in addition to the
                correspondence letter itself.
            </Text>
            <Text>
                These attachments will also be downloadable by selected recipients if if they have signed up to
                WeFactor.
            </Text>
        </>
    );
};

const Sending = (props: SectionProps) => {
    return (
        <>
            <Heading2>Sending</Heading2>
            <Text>After correspondence has been published it should be sent to all recipients.</Text>
            <Text>
                {' '}
                There are three methods to send correspondence:
                <OrderedList mt={2} spacing={1}>
                    <ListItem ml={2}>Send via the WeFactor app</ListItem>
                    <ListItem ml={2}>Download and send from a personal email address</ListItem>
                    <ListItem ml={2}>Download and print out for posting</ListItem>
                </OrderedList>
            </Text>
        </>
    );
};

const Emailing = (props: SectionProps) => {
    return (
        <>
            <Heading2>Emailing</Heading2>
            <Text>
                After correspondence has been published it can be automatically emailed out to recipients whose accounts
                have been set up with valid email addresses.
            </Text>
            <Text>
                Any attachments uploaded when the correspondence was published will be included automatically in the
                email in addition to the correspondence itself.
            </Text>
            <Text>
                You can still download correspondence for accounts that do not have email addresses for printing and
                posting.
            </Text>
            <PremiumFeatureCard />
        </>
    );
};

const Downloading = (props: SectionProps) => {
    return (
        <>
            <Heading2>Downloading</Heading2>
            <Text>
                Items of correspondence can be downloaded and printed out for posting, delivery or sending out from your
                personal email address.
            </Text>
        </>
    );
};
