import { Box, Container, Link, Stack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Heading2, Heading3, PremiumFeatureCard } from '../../shared';

enum Section {
    CHARGES,
    DOWNLOAD,
    LOGGING_TRANSACTIONS,
    PAYMENTS,
    REFUNDS,
    SEND,
    STATEMENTS
}

export const AccountingHelp = () => {
    const [section, setSection] = useState<Section>(Section.LOGGING_TRANSACTIONS);

    const getActiveSection = () => {
        switch (section) {
            case Section.CHARGES:
                return <Charges setSection={setSection} />;
            case Section.PAYMENTS:
                return <Payments setSection={setSection} />;
            case Section.REFUNDS:
                return <Refunds setSection={setSection} />;
            case Section.LOGGING_TRANSACTIONS:
                return <LoggingTransactions setSection={setSection} />;
            case Section.STATEMENTS:
                return <Statement setSection={setSection} />;
            case Section.DOWNLOAD:
                return <Download setSection={setSection} />;
            case Section.SEND:
                return <Send setSection={setSection} />;
            default:
                return <></>;
        }
    };

    const indent = [4, 6];

    return (
        <Stack paddingX={[2, 0]} direction={['column', 'row']}>
            <Box borderRightWidth={[0, '1px']} paddingRight={indent} width={'fit-content'}>
                <Heading2>Help Contents</Heading2>
                <VStack alignItems={'normal'}>
                    <Link color="teal" onClick={() => setSection(Section.LOGGING_TRANSACTIONS)}>
                        Recording transactions
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.CHARGES)} marginLeft={indent}>
                        Charges
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.PAYMENTS)} marginLeft={indent}>
                        Payments
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.REFUNDS)} marginLeft={indent}>
                        Refunds
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.STATEMENTS)}>
                        Account statements
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.DOWNLOAD)} marginLeft={indent}>
                        Download statements
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.SEND)} marginLeft={indent}>
                        Send statements
                    </Link>
                </VStack>
            </Box>
            <Container paddingX={0}>
                <Stack gap={2}>{getActiveSection()}</Stack>
            </Container>
        </Stack>
    );
};

type SectionProps = { setSection: (section: Section) => void };

const LoggingTransactions = (props: SectionProps) => {
    return (
        <>
            <Heading2>Recording transactions</Heading2>
            <Text>
                Transactions paid into or out of the maintenance bank account should be recorded as charges, payments or
                refunds.
            </Text>
            <Text>These actions can be selected from the "Actions" button menu on the Accounting page.</Text>
            <Heading3>Charges</Heading3>
            <Text>
                Charges represent funds paid out of the maintenance bank account for common or mutual costs such as
                repairs or services.
            </Text>
            <Text>These can be shared between one or more properties depending on the situation.</Text>
            <Heading3>Payments</Heading3>
            <Text>
                Payments represent funds paid into the maintenance bank account by a property owner into their account
                to cover their share of charges.
            </Text>
            <Text>Recording a payment against an account will increase its balance by the specified amount.</Text>
            <Heading3>Refunds</Heading3>
            <Text>
                Refunds represent funds paid out of the maintenance bank account back to an owner's bank account.
            </Text>
            <Text>Recording a refund against an account will decrease its balance by the specified amount.</Text>
        </>
    );
};

const Statement = (props: SectionProps) => {
    return (
        <>
            <Heading2>Account statements</Heading2>
            <Text>
                Account statements are similar in concept to bank statements and detail payments made into and charge
                transactions taken out of accounts.
            </Text>
            <Text>
                Statements can only be issued for previous months. For example accounts for August can only be issued on
                or after the 1st of September.
            </Text>
            <Text>
                Statements can be downloaded to your personal device for printing or emailing from a personal email
                account; or sent out automatically via our email system to the various account owners.
            </Text>
        </>
    );
};

const Download = (props: SectionProps) => {
    return (
        <>
            <Heading2>Download statements</Heading2>
            <Text>
                Statements can be downloaded as PDF files for printing out or for sending out from a personal email
                address.
            </Text>
            <Text>
                Select "Download statement" from the "Actions" button menu to to open the Download Statement dialog.
                From here you can select the month for which you want to download a statement.
            </Text>
        </>
    );
};

const Send = (props: SectionProps) => {
    return (
        <>
            <Heading2>Send statements</Heading2>
            <Text>Statements can be automatically emailed out to accounts with email addresses.</Text>
            <Text>This will email out a statement for the requested month to each owner.</Text>
            <Text>Select "Send statements" from the "Actions" button menu to to open the Send Statement dialog.</Text>
            <PremiumFeatureCard />
        </>
    );
};

const Charges = (props: SectionProps) => {
    return (
        <>
            <Heading2>Charges</Heading2>
            <Text>
                Charges represent funds paid out of the maintenance bank account for common or mutual costs such as
                repairs or services.
            </Text>
            <Text>
                When recording a charge select one or more accounts to share its cost. By default the accounts active on
                the charge date are selected - one from each property.
            </Text>
            <Text>
                When a property has more than one account due to it having been sold then the account's Start Date is
                used to determine which is active on the charge date.
            </Text>
            <Text>Cost shares are calculated automatically using the selected sharing method.</Text>
            <Text>
                After a charge has been recorded it will appear on the selected accounts' statements and decrease their
                account balances by their share of the cost.
            </Text>
            <Text>
                You can upload attachments when recording a charge. It is recommend to upload a copy of the original
                invoice to provide maximum transparency to owners as to what they are being charged for.
            </Text>
        </>
    );
};

const Payments = (props: SectionProps) => {
    return (
        <>
            <Heading2>Payments</Heading2>
            <Text>
                Payments represent funds paid into the maintenance bank account by a property owner into their account
                to cover their share of charges.
            </Text>
            <Text>
                After a payment has been recorded against an account it will appear on the account's statement and
                increase the account's balance by the specified amount.
            </Text>
        </>
    );
};

const Refunds = (props: SectionProps) => {
    return (
        <>
            <Heading2>Refunds</Heading2>
            <Text>Refunds represent funds paid out of the maintenance bank account back to an owner.</Text>
            <Text>
                Refunds are usually given after an owner has moved out and has a positive balance after all charges have
                been settled.
            </Text>
            <Text>
                After a refund has been recorded against an account it will appear on the account's statement and
                decrease the account's balance by the specified amount.
            </Text>
        </>
    );
};
