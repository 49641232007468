import Joi from 'joi';

export type CorrespondenceForm = {
    accountIds: string[];
    body: string;
    buildingId: string;
    documents: { id: string }[];
    subject: string;
};

export const correspondenceSchema = Joi.object({
    accountIds: Joi.array()
        .items(Joi.string().uuid())
        .messages({
            'array.min': 'Select a minimum of 1 accounts'
        })
        .min(1)
        .max(100)
        .unique()
        .required(),
    buildingId: Joi.string().uuid().required(),
    body: Joi.string().label('Letter content').trim().max(10000).required(),
    documents: Joi.array()
        .label('Attachments')
        .items(
            Joi.object({
                id: Joi.string().required()
            }).unknown(true)
        )
        .min(0)
        .max(10)
        .required(),
    subject: Joi.string().label('Letter subject').trim().max(200).required()
});
