import { Box, Button, Container, Flex, Text, useToast, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COLOURS, LogoIcon } from '../../components';
import { requestService } from '../../services';
import { CommonRepairQuoteShare } from '../../types';
import { genericToast } from '../../util';

export const CorrespondenceOfflineResponse = () => {
    const navigate = useNavigate();

    const [params] = useSearchParams();

    const toast = useToast();

    const [responseResult, setResponseResult] = useState<boolean | undefined>();

    const submit = async (response: boolean) => {
        const correspondenceId = params.get('correspondenceId');
        const uniqueCode = params.get('uniqueCode');

        await genericToast(
            toast,
            async () => {
                await requestService.post<CommonRepairQuoteShare>(`public/correspondences/${correspondenceId}/shares`, {
                    auth: false,
                    body: {
                        response,
                        uniqueCode
                    }
                });

                setResponseResult(response);
            },
            {
                title: 'Sending response..'
            }
        );
    };

    return (
        <Flex alignItems={'center'} backgroundColor={COLOURS.BLUE} height={'100vh'} overflow={'auto'}>
            <Container padding={6} display="flex" flexDirection={'column'} alignContent="center" minWidth={'200px'}>
                <LogoIcon
                    onClick={() => {
                        navigate('/');
                    }}
                />

                <Box backgroundColor={COLOURS.WHITE} borderRadius={40} padding={8} mt={10}>
                    <form id="sign-in-form" noValidate autoComplete="off">
                        {responseResult !== undefined ? (
                            <>
                                <Text fontWeight={600} fontSize="2xl" mb={4} textAlign={'center'}>
                                    Correspondence has been {responseResult === true ? 'accepted' : 'rejected'}
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text fontWeight={600} fontSize="xl" mb={4} textAlign={'center'}>
                                    Do you accept the maintenance quote?
                                </Text>

                                <VStack>
                                    <Button
                                        backgroundColor={COLOURS.BLUE_2}
                                        _hover={{ backgroundColor: COLOURS.GREEN }}
                                        color={COLOURS.WHITE}
                                        onClick={async () => {
                                            await submit(true);
                                        }}
                                        width="100%"
                                    >
                                        Accept
                                    </Button>

                                    <Button
                                        colorScheme={'red'}
                                        color={COLOURS.WHITE}
                                        width="100%"
                                        onClick={async () => {
                                            await submit(false);
                                        }}
                                    >
                                        Reject
                                    </Button>
                                </VStack>
                            </>
                        )}
                    </form>
                </Box>
            </Container>
        </Flex>
    );
};
