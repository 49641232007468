import {
    Box,
    Grid,
    GridItem,
    Stat,
    StatHelpText,
    StatLabel,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Tr,
    useBreakpointValue
} from '@chakra-ui/react';
import { useMemo } from 'react';
import {
    DownloadDocumentLink,
    Heading3,
    NamedValue,
    StandardModal,
    StandardModalProps,
    TransactionDetails,
    WeFactorThead
} from '../../../components';
import {
    Accounts,
    DivisionMethod,
    DivisionVersion,
    Invoice,
    InvoiceShare,
    Properties,
    Property,
    getDivisionMethodDescription,
    getDivisionMethodName
} from '../../../types';
import { fixDecimalPlaces, formatCurrency, formatDate, formatStreetAddress } from '../../../util';

interface InvoiceViewProps extends StandardModalProps {
    invoice: Invoice;
    accounts: Accounts;
    divisionVersions: DivisionVersion[];
    properties: Properties;
}

export const InvoiceView = (props: InvoiceViewProps) => {
    const { accounts, disclosure, divisionVersions, invoice, properties, onClose } = props;

    const divisionVersion = useMemo(
        () => divisionVersions.find((divisionVersion) => divisionVersion.id === invoice.divisionVersionId)!,
        [divisionVersions, invoice]
    );

    const renderAttachments = () => {
        if (invoice.documents.length > 0) {
            return invoice.documents.map((document) => (
                <StatLabel key={`document-${document.id}`}>
                    <DownloadDocumentLink document={document} />
                </StatLabel>
            ));
        } else {
            return <StatHelpText>There are no additional documents attached to this invoice.</StatHelpText>;
        }
    };

    const getShareMethod = (property: Property) => {
        const { divisionMethod, divisions } = divisionVersion;

        if (divisionMethod === DivisionMethod.EQUAL_SHARE) {
            return `1 / ${invoice.invoiceShares.length}`;
        }

        const division = divisions.find((div) => div.propertyId === property.id)!;
        const divisionSum = divisions.reduce((sum, div) => sum + div.value, 0);

        switch (divisionVersion.divisionMethod) {
            case DivisionMethod.FLOOR_AREA:
                return `${fixDecimalPlaces(division.value, 2)} / ${fixDecimalPlaces(divisionSum, 2)}`;
            case DivisionMethod.FRACTION:
                return `${division.value} / ${divisionSum}`;
            case DivisionMethod.PERCENTAGE:
                return `${fixDecimalPlaces(division.value, 2)}%`;
            case DivisionMethod.RATEABLE_VALUE:
                return `${division.value} / ${divisionSum}`;
            default:
                return 'ERror';
        }
    };

    const renderHeaderRowCommon = useBreakpointValue(
        {
            base: () => (
                <>
                    <Th key={'account'}>Account</Th>
                    <Th key={'share'} textAlign="center">
                        Share
                    </Th>
                    <Th key={'amount'} textAlign="center" width="120px">
                        Amount
                    </Th>
                </>
            ),
            md: () => (
                <>
                    <Th key={'account'}>Account</Th>
                    <Th key={'address'}>Property</Th>
                    <Th key={'share'} textAlign="center">
                        Share
                    </Th>
                    <Th key={'amount'} textAlign="center" width="120px">
                        Amount
                    </Th>
                </>
            )
        },
        { fallback: 'md', ssr: false }
    )!;

    const getValues = (invoiceShare: InvoiceShare) => {
        const account = accounts.getAccount(invoiceShare.accountId);
        const property = account && properties.getProperty(account.propertyId);

        return {
            accountName: account?.accountName || '',
            address: property ? formatStreetAddress(property, true) : '',
            amount: formatCurrency(0 - invoiceShare.amount),
            shareMethod: property ? getShareMethod(property) : ''
        };
    };

    const renderInvoiceRow = useBreakpointValue(
        {
            base: (invoiceShare: InvoiceShare) => {
                const { accountName, address, amount, shareMethod } = getValues(invoiceShare);

                return (
                    <>
                        <Td key={'account-address'}>
                            <Text mb={1.5}>{accountName}</Text>
                            <Text fontWeight={300}>{address}</Text>
                        </Td>
                        <Td key={'share'} textAlign="center">
                            {shareMethod}
                        </Td>
                        <Td key={'amount'} textAlign="center">
                            {amount}
                        </Td>
                    </>
                );
            },
            md: (invoiceShare: InvoiceShare) => {
                const { accountName, address, amount, shareMethod } = getValues(invoiceShare);

                return (
                    <>
                        <Td key={'account'}>
                            <Text>{accountName}</Text>
                        </Td>
                        <Td key={'address'}>{address}</Td>
                        <Td key={'share'} textAlign="center">
                            {shareMethod}
                        </Td>
                        <Td key={'amount'} textAlign="center">
                            {amount}
                        </Td>
                    </>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    const renderGrid = useBreakpointValue(
        {
            base: () => {
                return (
                    <Grid gap={4} marginBottom={4}>
                        <GridItem>
                            <NamedValue label={'Reason for charges'}>{invoice.subject}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Date of charges'}>{formatDate(invoice.date)}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Charged amount'}>{formatCurrency(invoice.amount)}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'How should the charges be shared?'}>
                                {getDivisionMethodName(divisionVersion.divisionMethod)}
                            </NamedValue>
                        </GridItem>
                        <GridItem>
                            <Stat>
                                <StatLabel>Attached documents</StatLabel>
                                {renderAttachments()}
                            </Stat>
                        </GridItem>
                    </Grid>
                );
            },
            md: () => {
                return (
                    <Grid gap={4} marginBottom={4} templateColumns="repeat(3, 1fr)">
                        <GridItem colSpan={2}>
                            <NamedValue label={'Reason for charges'}>{invoice.subject}</NamedValue>
                        </GridItem>
                        <GridItem rowSpan={2}>
                            <Stat>
                                <StatLabel>Attached documents</StatLabel>
                                {renderAttachments()}
                            </Stat>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Charged amount'}>{formatCurrency(invoice.amount)}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Date of charges'}>{formatDate(invoice.date)}</NamedValue>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <NamedValue label={'How are the charges shared?'}>
                                {getDivisionMethodDescription(divisionVersion.divisionMethod)}
                            </NamedValue>
                        </GridItem>
                    </Grid>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: '3xl'
                }
            }}
            onClose={onClose}
            header={invoice.subject}
        >
            {invoice && (
                <Box mb={5}>
                    {renderGrid()}
                    <Heading3>Invoice recipients</Heading3>
                    <Table size={'sm'}>
                        <WeFactorThead>
                            <Tr>{renderHeaderRowCommon()}</Tr>
                        </WeFactorThead>
                        <Tbody>
                            {invoice.invoiceShares.map((invoiceShare) => (
                                <Tr key={invoiceShare.id}>{renderInvoiceRow(invoiceShare)}</Tr>
                            ))}
                        </Tbody>
                    </Table>
                    {invoice.transaction.maintenanceAccountTransaction && (
                        <TransactionDetails
                            spacing={{ mt: 2 }}
                            maintenanceAccountTransaction={invoice.transaction.maintenanceAccountTransaction}
                        />
                    )}
                </Box>
            )}
        </StandardModal>
    );
};
