import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { DownloadDocumentLink } from '../../../../components';
import { Building, Correspondence, Document } from '../../../../types';
import { SendCorrespondenceModal } from './SendCorrespondanceModals';

export const Documents = (props: { building: Building; correspondence: Correspondence; documents: Document[] }) => {
    const { building, correspondence, documents } = props;

    const sendCorrespondenceDisclosure = useDisclosure();

    const renderAttachments = () => {
        if (!documents.length) {
            return <Text>There are no additional documents attached to this item of correspondence.</Text>;
        }

        return documents.map((document) => <DownloadDocumentLink document={document} />);
    };

    return (
        <>
            <Box>
                <Text fontWeight={600} marginBottom={1} key={'documents'}>
                    Attached documents
                </Text>
                {renderAttachments()}
            </Box>
            {sendCorrespondenceDisclosure.isOpen && correspondence && (
                <SendCorrespondenceModal
                    building={building}
                    correspondence={correspondence}
                    disclosure={sendCorrespondenceDisclosure}
                />
            )}
        </>
    );
};
