import { Box, Flex, Icon, Link, Stack, Text, Tooltip } from '@chakra-ui/react';
import { MdOutlineChat, MdOutlineMarkUnreadChatAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Heading3, LINK_BLUE } from '../../../components';
import { ForumBoard } from '../../../types';

export const ForumBoardBox = (props: {
    buildingId: string;
    forumBoard: ForumBoard;
    forumBoardViews: Map<string, string>;
}) => {
    const { buildingId, forumBoard, forumBoardViews } = props;

    const navigate = useNavigate();

    const getIcon = () => {
        if (forumBoard.latestPostTimestamp) {
            const lastViewedTimestamp = forumBoardViews.get(forumBoard.id);

            if (!lastViewedTimestamp || new Date(lastViewedTimestamp) < new Date(forumBoard.latestPostTimestamp)) {
                return {
                    color: 'black',
                    icon: MdOutlineMarkUnreadChatAlt,
                    tooltipLabel: 'New posts'
                };
            }
        }

        return {
            color: 'RGBA(0, 0, 0, 0.50)',
            icon: MdOutlineChat,
            tooltipLabel: 'No new posts'
        };
    };

    const { icon, color, tooltipLabel } = getIcon();

    return (
        <Stack>
            <Stack direction={'row'} key={forumBoard.id} gap={4}>
                <Box key={'board-name'}>
                    <Heading3>
                        <Link
                            color={LINK_BLUE}
                            href="#"
                            onClick={() => navigate(`/buildings/${buildingId}/forums/${forumBoard.id}`)}
                        >
                            {forumBoard.name}
                        </Link>
                    </Heading3>
                    <Text>{forumBoard.description}</Text>
                </Box>
                <Tooltip label={tooltipLabel}>
                    <Flex alignItems={'center'} direction={'row'}>
                        <Text color={color} fontSize={'sm'} marginRight={'3px'} paddingBottom={'3px'}>
                            {forumBoard.postCount}
                        </Text>
                        <Icon color={color} height={4} width={4} as={icon} />
                    </Flex>
                </Tooltip>
            </Stack>
        </Stack>
    );
};
