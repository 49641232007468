import { Badge, BadgeProps, Spinner, Text } from '@chakra-ui/react';
import { formatCurrency } from '../../util';

export const StandardBadge = (props: BadgeProps) => (
    <Badge paddingX={2} paddingY={1} textTransform={'none'} {...props}>
        {props.children}
    </Badge>
);

export const CurrencyBadge = (props: { amount: number | null | undefined; badgeProps?: BadgeProps }) => {
    const { amount } = props;

    if (amount === null || amount === undefined) {
        return <Spinner size={'sm'} colorScheme={'blue'} />;
    }

    return (
        <Badge colorScheme={amount < 0 ? 'red' : 'green'} paddingX={2} paddingY={1} {...props.badgeProps}>
            <Text whiteSpace={'nowrap'}>{formatCurrency(amount)}</Text>
        </Badge>
    );
};
