import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { PageLoadingSkeleton } from '../components';
import { userAccountService, userBuildingsService } from '../services';

export const BuildingGuard = () => {
    const navigate = useNavigate();

    const { buildingId } = useParams();

    const [building] = useObservable(userBuildingsService.activeEntity$);
    const [buildings] = useObservable(userBuildingsService.entities$);
    const [buildingsLoaded] = useObservable(userBuildingsService.loaded$);

    useEffect(() => {
        if (!buildingId || !buildings || !buildingsLoaded || buildingId === building?.id) {
            return;
        }

        const id = buildings.find((building) => building.id === buildingId)?.id;

        if (id) {
            userAccountService.clearActiveEntity();
            userBuildingsService.setActiveEntityId(id);
        } else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingId, building, buildings, buildingsLoaded]);

    if (building) {
        return <Outlet />;
    } else {
        return <PageLoadingSkeleton />;
    }
};
