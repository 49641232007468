import { DateTime } from 'luxon';

export const DATE_ZONE_OPTS = { zone: 'Europe/London' };

export const currentDate = (): Date => {
    return currentDateTime().toJSDate();
};

export const currentDateTime = (): DateTime => {
    return DateTime.local(DATE_ZONE_OPTS).startOf('day');
};

export const startOfToday = (): Date => {
    return currentDateTime().startOf('day').toJSDate();
};

export const lastDayOfPreviousMonth = (): Date => {
    return currentDateTime().minus({ month: 1 }).endOf('month').startOf('day').toJSDate();
};

export const convertDateOrStringToDateTime = (date?: string | Date): DateTime | undefined => {
    if (typeof date === 'string') {
        return convertStringToDateTime(date);
    } else if (typeof date === 'object' && date.constructor.name === 'Date') {
        return convertDateToDateTime(date);
    }

    return undefined;
};

export const convertStringToDate = (date: string): Date => {
    return convertStringToDateTime(date).toJSDate();
};

export const convertStringToDateTime = (date: string): DateTime => {
    return DateTime.fromISO(date.trim(), DATE_ZONE_OPTS);
};

export const convertDateToDateTime = (date: Date): DateTime => {
    return DateTime.fromJSDate(date, DATE_ZONE_OPTS);
};

export const isToday = (date?: string): boolean => {
    if (date) {
        return differenceInDays(date, DateTime.now().startOf('day').toJSDate()) === 0;
    } else {
        return false;
    }
};

export const differenceInDays = (date1: string | Date, date2: string | Date): number => {
    const dateTime1 = convertDateOrStringToDateTime(date1)!;
    const dateTime2 = convertDateOrStringToDateTime(date2)!;
    return dateTime1.diff(dateTime2, 'days').days;
};
