import { Flex, Spacer, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StandardModal, StandardModalProps } from '../../../components';
import { HiddenField, SelectYearMonth } from '../../../components/form';
import { WeFactorSecondaryButton, WeFactorSubmitButton } from '../../../components/shared';
import { factorStatementService } from '../../../services';
import { Account, Building } from '../../../types';
import { formatMonthYearForDatePicker, genericToast } from '../../../util';

interface DownloadStatementDialogProps extends StandardModalProps {
    account: Account;
    building: Building;
}

type MonthRangeForm = {
    accountId: string;
    month: string;
};

export const DownloadStatementDialog = (props: DownloadStatementDialogProps) => {
    const { account, building, disclosure, onClose } = props;

    const toast = useToast();

    const [isSending, setIsSending] = useState(false);

    const getDefaultMonth = () => {
        if (building.lastStatementDate) {
            return formatMonthYearForDatePicker(new Date(building.lastStatementDate));
        } else {
            return formatMonthYearForDatePicker(
                DateTime.fromJSDate(new Date()).minus({ months: 1 }).endOf('month').startOf('day').toJSDate()
            );
        }
    };

    const form = useForm<MonthRangeForm>({
        resolver: joiResolver(
            Joi.object({
                accountId: Joi.string().uuid().required().label('Statement account'),
                month: Joi.string()
                    .label('Statement month')
                    .messages({
                        'date.past': 'Statement month must be in the past'
                    })
                    .custom((value, helpers) => {
                        const currentMonth = DateTime.fromJSDate(new Date()).startOf('month').toJSDate();

                        const selectedMonth = DateTime.fromISO(value).toJSDate();

                        if (selectedMonth >= currentMonth) {
                            return helpers.error('date.past');
                        }

                        if (building.lastStatementDate) {
                            if (selectedMonth >= new Date(building.lastStatementDate)) {
                                return helpers.error('date.before-accounts');
                            }
                        }

                        return value;
                    })
                    .required()
            })
        ),
        defaultValues: {
            accountId: account.id,
            month: getDefaultMonth()
        },
        reValidateMode: 'onSubmit'
    });

    const { getValues, handleSubmit } = form;

    const submit = async () => {
        setIsSending(true);

        try {
            await genericToast(
                toast,
                async () => {
                    const { signedUrl } = await factorStatementService.create({
                        pathParams: {
                            buildingId: building.id
                        },
                        pathSuffix: 'download',
                        body: {
                            statementMonth: getValues('month')
                        },
                        params: {
                            accountId: getValues('accountId')
                        }
                    });

                    window.open(signedUrl);

                    disclosure.onClose();
                },
                {
                    title: 'Downloading statement',
                    onSuccessTitle: 'Statement downloaded',
                    onErrorTitle: 'Failed to download statement'
                }
            );
        } catch (error) {
            console.error('Failed to download statement', error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'sm'
                }
            }}
            modalContentProps={{
                height: 'xl'
            }}
            onClose={onClose}
            header={'Download statement'}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton isDisabled={isSending} onClick={disclosure.onClose}>
                        Cancel
                    </WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorSubmitButton onClick={handleSubmit(submit)} isLoading={isSending}>
                        Download
                    </WeFactorSubmitButton>
                </Flex>
            }
        >
            <form id="send-statement-form" noValidate autoComplete="off">
                <>
                    <SelectYearMonth
                        name="month"
                        formHook={form}
                        formLabel="Statement month"
                        isRequired={true}
                        allowCurrentMonth={false}
                    />
                    <HiddenField name="accountId" formHook={form} isRequired={true} />
                </>
            </form>
        </StandardModal>
    );
};
