import { Flex, Icon, Link } from '@chakra-ui/react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { factorDocumentsService } from '../../services';
import { Document, SignedDocument } from '../../types';
import { LINK_BLUE } from '../skeletons/constants';

const viewDocument = async (document: Document) => {
    const result = await factorDocumentsService.get<SignedDocument>(
        {
            pathParams: {
                buildingId: document.buildingId
            }
        },
        document.id
    );

    const { signedUrl } = result;

    window.open(signedUrl);
};

export const DownloadDocumentLink = (props: { document: Document }) => {
    const { document } = props;

    return (
        <Flex alignItems={'center'} key={document.id}>
            <Icon as={MdOutlineFileDownload} marginRight={1} />
            <Link color={LINK_BLUE} href="#" onClick={() => viewDocument(document)}>
                {document.fileName}
            </Link>
        </Flex>
    );
};
