import { FieldValues } from 'react-hook-form';
import { BaseInput, BaseInputProps } from './BaseInput';

export const HiddenField = <T extends FieldValues>(props: BaseInputProps<T>) => {
    return (
        <BaseInput
            {...props}
            inputProps={{
                ...props.inputProps,
                type: 'hidden'
            }}
        />
    );
};
