import { Badge } from '@chakra-ui/react';
import { Building } from '../../../types';
import { convertStringToDateTime, currentDate, differenceInDays } from '../../../util';

interface StatementDueBadgeProps {
    building: Building;
}

export const StatementDueBadge = (props: StatementDueBadgeProps) => {
    if (!props.building.lastStatementDate) {
        return <Badge colorScheme={'orange'}>The first statements are due now</Badge>;
    }

    const days = calculateStatementDueDays(props.building.lastStatementDate);

    if (days === 0) {
        return <Badge colorScheme={'red'}>Statements are due today</Badge>;
    } else {
        const daysWord = days < -1 || days > 1 ? 'days' : 'day';

        if (days < 0) {
            return (
                <Badge colorScheme={'red'}>
                    The next statements are overdue for sending by {-days} {daysWord}
                </Badge>
            );
        } else {
            return (
                <Badge colorScheme={'green'}>
                    The next statements are due for sending in {days} {daysWord}
                </Badge>
            );
        }
    }
};

export const calculateStatementDueDays = (date: string) => {
    return differenceInDays(
        convertStringToDateTime(date).plus({ months: 2 }).startOf('month').toJSDate(),
        currentDate()
    );
};
