import { Config, configService } from '../services/config';

export const getContentUrl = (contentId: string | undefined): string | undefined => {
    const { CONTENT_URL } = configService.getConfig() as Config;
    return contentId ? `${CONTENT_URL}/${contentId}` : undefined;
};

export const createMap = <T extends { id: string }>(items: T[]): Map<string, T> => {
    return items.reduce((map: Map<string, T>, next: T) => {
        map.set(next.id, next);
        return map;
    }, new Map<string, any>());
};
