import { Box, Center, Container, FlexProps } from '@chakra-ui/react';
import { COLOURS, LogoIcon } from '../../../components';

interface AuthFlexProps extends FlexProps {
    children: any;
}

export const AuthFlex = (props: AuthFlexProps) => {
    const { children } = props;

    return (
        <Center backgroundColor={COLOURS.BLUE} minHeight={'100vh'} padding={[4, 6]}>
            <Container padding={0} centerContent>
                <LogoIcon />
                <Box backgroundColor={COLOURS.WHITE} borderRadius={20} marginTop={4} padding={[4, 6]} width={'100%'}>
                    {children}
                </Box>
            </Container>
        </Center>
    );
};
