import { forwardRef, TabPanel, TabPanels, TabPanelsProps, Tabs, TabsProps } from '@chakra-ui/react';

export const SideBarTabs = forwardRef<TabsProps, 'div'>((props, ref) => (
    <Tabs height="100%" display={'flex'} flexDirection={'column'} ref={ref} {...props} />
));

export const SideBarTabPanels = forwardRef<TabPanelsProps, 'div'>((props, ref) => (
    <TabPanels flex={1} display={'flex'} flexDirection={'column'} ref={ref} {...props} />
));

interface SideBarTabPanelProps extends TabPanelsProps {
    children: React.ReactElement | React.ReactElement[];
}

export const SideBarTabPanel = (props: SideBarTabPanelProps) => {
    return <TabPanel {...props}>{props.children}</TabPanel>;
};
