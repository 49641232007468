import { Box, Link, List, ListIcon, ListItem, Stack, Text, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineCancel, MdOutlineCheck } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COLOURS, EmailField, HiddenField, PasswordField, TextField, WeFactorSubmitButton } from '../../components';
import { authenticatedUserService } from '../../services';
import { AuthFlex } from './components/AuthFlex';
import { AuthHeader } from './components/AuthHeader';

const schema = Joi.object({
    displayName: Joi.string().label('Full name').trim().min(3).max(50).required(),
    email: Joi.string()
        .label('Email address')
        .trim()
        .email({ tlds: { allow: false } })
        .required(),
    password: Joi.string()
        .label('Password')
        .messages({ 'string.pattern.base': 'Password does not meet one or more of the requirements' })
        .trim()
        .min(8)
        .max(20)
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/),
    confirmPassword: Joi.string()
        .label('Confirm password')
        .valid(Joi.ref('password'))
        .required()
        .messages({ 'any.only': '{{#label}} does not match "Password" ' }),
    inviteCode: Joi.string().optional()
}).required();

export const SignUpPage = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const toast = useToast();

    const [passwordState, setPasswordState] = useState({
        correctLength: false,
        hasLowerCase: false,
        hasNumber: false,
        hasUpperCase: false
    });

    const form = useForm({
        defaultValues: {
            displayName: params.get('display-name') || '',
            email: params.get('email-address') || '',
            password: '',
            confirmPassword: '',
            inviteCode: params.get('invite-code') || 'N/A'
        },
        resolver: joiResolver(schema),
        reValidateMode: 'onSubmit'
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
        watch
    } = form;

    const password = watch('password');

    useEffect(() => {
        setPasswordState({
            correctLength: password.length >= 8 && password.length <= 20,
            hasLowerCase: /[a-z]+/.test(password),
            hasNumber: /[0-9]+/.test(password),
            hasUpperCase: /[A-Z]+/.test(password)
        });
    }, [password]);

    const submit = async (formValues: any) => {
        const { displayName, password, email, inviteCode } = formValues;

        try {
            await authenticatedUserService.signUp(displayName, password, email, inviteCode);

            navigate(`/confirm-account?email=${email?.toLowerCase()}`);

            toast({
                title: `Sent account verification code to ${email}`,
                status: 'success',
                isClosable: true,
                position: 'top'
            });
        } catch (error: any) {
            const getErrorMessage = (code) => {
                switch (code) {
                    case 'ConfirmedAccountExists':
                        return 'An account with this email address already exists.';
                    case 'LimitExceededException':
                        return 'Maximum number of attempts reached. Please try again later.';
                    case 'UnconfirmedAccountExists':
                        return 'An account with this email address already exists. We have sent another confirmation email to this email address.';
                    default:
                        return undefined;
                }
            };

            let errorMessage = getErrorMessage(error.code);

            if (!errorMessage && error.statusCode === 400 && error.message) {
                errorMessage = error.message;
            }

            toast({
                description: errorMessage || 'An unexpected error occurred - please contact us if the issue persists',
                status: 'error',
                duration: 10000,
                isClosable: true,
                position: 'top'
            });

            if (error.code === 'UnconfirmedAccountExists') {
                navigate(`/confirm-account?email=${email?.toLowerCase()}`);
            }
        }
    };

    const getWebsiteUrl = () => {
        let url;

        switch (window.location.host) {
            case 'localhost:3000':
            case 'app.dev.wefactor.co.uk':
                url = 'https://dev.wefactor.co.uk';
                break;
            case 'app.test.wefactor.co.uk':
                url = 'https://test.wefactor.co.uk';
                break;
            case 'app.staging.wefactor.co.uk':
                url = 'https://staging.wefactor.co.uk';
                break;
            case 'app.wefactor.co.uk':
                url = 'https://wefactor.co.uk';
                break;
        }

        return url;
    };

    const getIcon = (isValid: boolean) => {
        return isValid ? (
            <ListIcon as={MdOutlineCheck} color="green.500" />
        ) : (
            <ListIcon as={MdOutlineCancel} color="red.500" />
        );
    };

    return (
        <AuthFlex>
            <AuthHeader>Sign up for free</AuthHeader>
            <form id="sign-in-form" noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
                <HiddenField name="inviteCode" formHook={form} />
                <TextField formLabel="Full name" name="displayName" formHook={form} />
                <EmailField
                    formLabel="Email address"
                    name="email"
                    formHook={form}
                    inputProps={{ autoComplete: 'username' }}
                />
                <PasswordField
                    name="password"
                    formLabel="Password"
                    formHook={form}
                    formHelperText={
                        <Box mb={2}>
                            <Text mb={1}>Passwords must contain:</Text>
                            <List spacing={1}>
                                <ListItem>
                                    {getIcon(passwordState.correctLength)} be between 8 and 20 characters
                                </ListItem>
                                <ListItem>
                                    {getIcon(passwordState.hasLowerCase)} one or more lower case letters
                                </ListItem>
                                <ListItem>
                                    {getIcon(passwordState.hasUpperCase)} one or more upper case letters
                                </ListItem>
                                <ListItem>{getIcon(passwordState.hasNumber)} one or more numbers</ListItem>
                            </List>
                        </Box>
                    }
                    inputProps={{ autoComplete: 'current-password' }}
                />
                <PasswordField
                    name="confirmPassword"
                    formLabel="Confirm password"
                    formHook={form}
                    inputProps={{ autoComplete: 'current-password' }}
                />
                <WeFactorSubmitButton disabled={!isValid || isSubmitting} isLoading={isSubmitting} width="100%">
                    Create account
                </WeFactorSubmitButton>
                <Text fontSize={'sm'} color={COLOURS.DARK_GREY} mt={4}>
                    By clicking Create account, you agree to our{' '}
                    <Link
                        textAlign={'right'}
                        fontSize={'sm'}
                        color={COLOURS.BLUE_2}
                        onClick={() => {
                            window.open(`${getWebsiteUrl()}/terms-and-conditions.html`, '_blank');
                        }}
                    >
                        Terms & Conditions
                    </Link>
                    . Learn how we collect, use and share your data in our{' '}
                    <Link
                        textAlign={'right'}
                        fontSize={'sm'}
                        color={COLOURS.BLUE_2}
                        onClick={() => {
                            window.open(`${getWebsiteUrl()}/privacy-policy.html`, '_blank');
                        }}
                    >
                        Privacy Policy
                    </Link>
                </Text>
                <Stack mt={2}>
                    <Link
                        textAlign={'right'}
                        fontSize={'sm'}
                        onClick={() => {
                            navigate('/sign-in');
                        }}
                    >
                        Already have an account?
                    </Link>
                </Stack>
                <Stack mt={2}>
                    <Link
                        textAlign={'right'}
                        fontSize={'sm'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/reset-password');
                        }}
                    >
                        Reset my password
                    </Link>
                </Stack>
            </form>
        </AuthFlex>
    );
};
