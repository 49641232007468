import { Box, Drawer, DrawerContent, DrawerOverlay, Stack, useMediaQuery, VStack } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { layoutService } from '../services';
import { NavigationBar } from './navigation';
import { ToolBar } from './ToolBar';

export const Layout = () => {
    const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
    const [isNavBarOpen] = useObservable(layoutService.navBar$);

    useEffect(() => {
        layoutService.closeContentSideBar();
    }, [isLargerThan1024]);

    return (
        <Stack height={'100vh'} direction={'row'}>
            {isLargerThan1024 ? (
                <NavigationBar />
            ) : (
                <Drawer
                    isFullHeight={true}
                    isOpen={isNavBarOpen}
                    onClose={layoutService.closeNavBar}
                    placement={'left'}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <NavigationBar />
                    </DrawerContent>
                </Drawer>
            )}
            <VStack height={'100vh'} width={'100%'} overflow={'auto'}>
                <ToolBar isMaxView={isLargerThan1024} />
                <Box height={'100%'} width={'100%'}>
                    <Outlet />
                </Box>
            </VStack>
        </Stack>
    );
};
