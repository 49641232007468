import { Grid, GridItem, Td, Th, useBreakpointValue } from '@chakra-ui/react';
import { useMemo } from 'react';
import { NamedValue, StandardModalProps } from '../../../components';
import {
    Accounts,
    Building,
    CorrespondenceAccount,
    Division,
    DivisionMethod,
    DivisionVersion,
    Document,
    Properties,
    SharedCosts,
    getDivisionMethodDescription
} from '../../../types';
import { CorrespondenceModal } from './components/CorrespondenceModal';
import { CorrespondenceRowValues } from './components/CorrespondenceTypes';
import { Documents } from './components/Documents';
import { PublishedDate } from './components/PublishedDate';
import { RecipientsTable } from './components/RecipientsTable';
import { Subject } from './components/Subject';

export const CorrespondenceViewForSharedCosts = (props: {
    accounts: Accounts;
    building: Building;
    correspondence: SharedCosts;
    correspondenceAccounts: CorrespondenceAccount[];
    divisionVersions: DivisionVersion[];
    documents: Document[];
    properties: Properties;
    standardModalProps: StandardModalProps;
}) => {
    const {
        accounts,
        building,
        correspondence,
        correspondenceAccounts,
        divisionVersions,
        documents,
        properties,
        standardModalProps
    } = props;

    const { divisionMap, divisionMethod, divisionSum, divisionVersion } = useMemo(() => {
        const divisionVersion = divisionVersions.find((div) => div.id === correspondence.divisionVersionId)!;

        if (!divisionVersion) {
            console.log(`Unable to find division version for ${JSON.stringify(correspondence, null, 2)}`);

            if (divisionVersions) {
                divisionVersions.forEach((dv) =>
                    console.log(`Avaialble division version: ${JSON.stringify(divisionVersions, null, 2)}`)
                );
            } else {
                console.log('No division versions');
            }

            return {
                divisionMap: new Map(),
                divisionMethod: '',
                divisionSum: 0,
                divisionVersion: undefined
            };
        }

        const divisionMap = divisionVersion.divisions.reduce((map, div) => map.set(div.propertyId, div), new Map());
        const divisionSum = divisionVersion.divisions.reduce((sum, div) => sum + div.value, 0);
        const divisionMethod = getDivisionMethodDescription(divisionVersion.divisionMethod);
        return { divisionMap, divisionMethod, divisionSum, divisionVersion };
    }, [correspondence.divisionVersionId, divisionVersions]);

    const getShare = (values: CorrespondenceRowValues) => {
        const { property } = values;

        const division: Division = divisionMap.get(property.id)!;

        switch (divisionVersion?.divisionMethod) {
            case DivisionMethod.EQUAL_SHARE:
                return `1 / ${correspondence.propertiesCount}`;
            case DivisionMethod.FLOOR_AREA:
                return `${division.value} / ${divisionSum}`;
            case DivisionMethod.FRACTION:
                return `${division.value} / ${divisionSum}`;
            case DivisionMethod.PERCENTAGE:
                return `${division.value}%`;
            case DivisionMethod.RATEABLE_VALUE:
                return `${division.value} / ${divisionSum}`;
            default:
                return '';
        }
    };

    const renderHeaderRow = () => (
        <Th key={'share'} textAlign={'center'}>
            Share
        </Th>
    );

    const renderCorrespondenceRow = (values: CorrespondenceRowValues) => (
        <Td key={'share'} textAlign={'center'}>
            {getShare(values)}
        </Td>
    );

    const renderGrid = useBreakpointValue(
        {
            base: () => {
                return (
                    <Grid gap={4} marginBottom={4}>
                        <GridItem>
                            <Subject correspondence={correspondence} />
                        </GridItem>
                        <GridItem>
                            <PublishedDate correspondence={correspondence} />
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'How are costs shared?'}>{divisionMethod}</NamedValue>
                        </GridItem>
                        {documents.length > 0 && (
                            <GridItem>
                                <Documents correspondence={correspondence} building={building} documents={documents} />
                            </GridItem>
                        )}
                    </Grid>
                );
            },
            md: () => {
                return (
                    <Grid gap={4} marginBottom={4} templateColumns="repeat(3, 1fr)">
                        <GridItem colSpan={2}>
                            <Subject correspondence={correspondence} />
                        </GridItem>
                        <GridItem rowSpan={2}>
                            <Documents correspondence={correspondence} building={building} documents={documents} />
                        </GridItem>
                        <GridItem>
                            <PublishedDate correspondence={correspondence} />
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'How are costs shared?'}>{divisionMethod}</NamedValue>
                        </GridItem>
                    </Grid>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <>
            <CorrespondenceModal {...standardModalProps}>
                {renderGrid()}
                <RecipientsTable
                    accounts={accounts}
                    building={building}
                    correspondence={correspondence}
                    correspondenceAccounts={correspondenceAccounts}
                    properties={properties}
                    renderHeaderRow={renderHeaderRow}
                    renderCorrespondenceRow={renderCorrespondenceRow}
                />
            </CorrespondenceModal>
        </>
    );
};
