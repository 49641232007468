import { Heading, HeadingProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const Heading1 = (props: { headingProps?: HeadingProps } & PropsWithChildren) => {
    const { children, headingProps } = props;

    return (
        <Heading as={'h1'} fontSize={'2xl'} fontWeight={600} marginBottom={2} {...headingProps}>
            {children}
        </Heading>
    );
};

export const Heading2 = (props: { headingProps?: HeadingProps } & PropsWithChildren) => {
    const { children, headingProps } = props;

    return (
        <Heading as={'h2'} fontSize={'xl'} fontWeight={600} marginBottom={2} {...headingProps}>
            {children}
        </Heading>
    );
};

export const Heading3 = (props: { headingProps?: HeadingProps } & PropsWithChildren) => {
    const { children, headingProps } = props;

    return (
        <Heading as={'h3'} fontSize={'md'} fontWeight={600} marginBottom={2} {...headingProps}>
            {children}
        </Heading>
    );
};
