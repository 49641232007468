import { DownloadIcon } from '@chakra-ui/icons';
import { Flex, forwardRef, List, ListItem, TabPanel, TabPanelProps, Text } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { Observable } from 'rxjs';
import { factorDocumentsService, userAccountService, userBuildingsService } from '../../services';
import { Building, Document, SignedDocument, UserAccount } from '../../types';

interface SideBarDocumentTabPanelProps extends TabPanelProps {
    documents: Document[];
    parent: {
        id: string;
        type: 'CORRESPONDENCE' | 'DISCUSSION' | 'INVOICE';
    };
}

export const SideBarDocumentTabPanel = forwardRef<SideBarDocumentTabPanelProps, 'div'>((props, ref) => {
    const [account] = useObservable(userAccountService.activeEntity$ as Observable<UserAccount>);

    const [building] = useObservable(userBuildingsService.activeEntity$ as Observable<Building>);

    const viewDocument = async (document: Document) => {
        const result = await factorDocumentsService.fetch<SignedDocument>({
            pathParams: {
                id: document.id
            },
            params: {
                accountId: account?.id,
                buildingId: account?.buildingId || building?.id,
                parentId: props.parent.id,
                parentType: props.parent.type
            }
        });

        const { signedUrl } = result;

        window.open(signedUrl);
    };

    const { documents } = props;

    if (documents.length === 0) {
        return (
            <TabPanel ref={ref} {...props}>
                <Text>No attachments</Text>
            </TabPanel>
        );
    }

    return (
        <TabPanel ref={ref} {...props}>
            <List spacing={1}>
                {documents.map((document) => (
                    <ListItem key={document.id}>
                        <Flex alignItems={'center'}>
                            <Text
                                textDecoration={'underline'}
                                _hover={{ cursor: 'pointer' }}
                                onClick={() => viewDocument(document)}
                            >
                                <DownloadIcon mr={2} />
                                {document.fileName}
                            </Text>
                        </Flex>
                    </ListItem>
                ))}
            </List>
        </TabPanel>
    );
});
