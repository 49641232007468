import { BoxProps } from '@chakra-ui/react';
import { NamedValue } from '../../../../components';
import { Correspondence } from '../../../../types';

export const Subject = (props: { correspondence: Correspondence; boxProps?: BoxProps }) => {
    const { boxProps, correspondence } = props;

    return (
        <NamedValue label={'Subject'} boxProps={boxProps}>
            {correspondence.subject}
        </NamedValue>
    );
};
