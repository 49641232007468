import { Flex, Spacer, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import { ObjectSchema } from 'joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { UseFormReturn, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { createToast, updateToast } from '../../util';
import { StandardModal, StandardModalProps } from '../modals';
import { WeFactorSecondaryButton, WeFactorSubmitButton } from '../shared';

interface AddEditDialogProps<T> extends StandardModalProps {
    children?: any;
    header: string;
    subHeader?: string;
    entity?: T;
    fields: (form: UseFormReturn, disabled: boolean) => JSX.Element;
    onCreate?: (formValues: any) => Promise<any>;
    onUpdate?: (formValues: any, entity: T) => Promise<any>;
    schema: ObjectSchema<T>;
    setValues: (setValue: UseFormSetValue<FieldValues>) => void;
    saveButtonLabel?: string;
}

export const AddEditDialog = <T extends unknown>(props: AddEditDialogProps<T>) => {
    const toast = useToast();

    const {
        header,
        entity,
        onCreate,
        onUpdate,
        schema,
        setValues,
        disclosure,
        modalContentProps,
        modalProps,
        onClose
    } = props;

    const form: UseFormReturn = useForm({
        resolver: joiResolver(schema),
        reValidateMode: 'onSubmit'
    });

    const {
        reset,
        formState: { isValid, isSubmitting },
        handleSubmit,
        setValue
    } = form;

    useEffect(() => {
        reset();
        setValues(setValue);
    }, []);

    const handleOnClose = () => {
        if (onClose) onClose();
        disclosure.onClose();
    };

    const submit = async (formValues: any) => {
        try {
            if (entity && (entity as any).id) {
                if (onUpdate) {
                    await updateToast(toast, async () => {
                        await onUpdate(formValues, entity);
                        handleOnClose();
                    });
                }
            } else {
                if (onCreate) {
                    await createToast(toast, async () => {
                        await onCreate(formValues);
                        handleOnClose();
                    });
                }
            }
        } catch (e) {
            console.error('Could not save entity', e);
        }
    };

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: 'md'
                },
                ...modalProps
            }}
            modalContentProps={{
                height: 'min-content',
                maxHeight: 'max',
                ...modalContentProps
            }}
            header={header}
            footer={
                <Flex width={'full'}>
                    <WeFactorSecondaryButton onClick={handleOnClose} disabled={isSubmitting}>
                        Close
                    </WeFactorSecondaryButton>
                    <Spacer />
                    <WeFactorSubmitButton
                        disabled={isSubmitting || !isValid}
                        isLoading={isSubmitting}
                        onClick={handleSubmit(submit)}
                    >
                        {props?.saveButtonLabel || 'Save'}
                    </WeFactorSubmitButton>
                </Flex>
            }
        >
            <form
                id="add-edit-form"
                noValidate
                autoComplete="off"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '400px',
                    height: '100%',
                    overflow: 'auto'
                }}
            >
                {props.fields(form, isSubmitting)}
                {props.children}
            </form>
        </StandardModal>
    );
};
