import { InputGroup, Textarea, TextareaProps } from '@chakra-ui/react';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';

export type BaseTextAreaProps<T extends FieldValues> = BaseFormControlProps<T> & {
    textareaProps?: TextareaProps;

    inputElementLeft?: React.ReactElement;
    inputElementRight?: React.ReactElement;
    placeholder?: string;
};

export const BaseTextArea = <T extends FieldValues>(props: BaseTextAreaProps<T>) => {
    const { formLabel, isRequired, name, inputElementLeft, inputElementRight, placeholder, textareaProps } = props;

    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        return (
            <InputGroup>
                {inputElementLeft}
                <Textarea
                    id={name}
                    aria-autocomplete="none"
                    aria-label={formLabel || name}
                    aria-required={isRequired}
                    errorBorderColor="crimson"
                    placeholder={placeholder}
                    variant="outline"
                    {...controller.field}
                    {...textareaProps}
                />
                {inputElementRight}
            </InputGroup>
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
