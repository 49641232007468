import { Stack } from '@chakra-ui/react';
import {
    MdOutlineApartment,
    MdOutlineEmail,
    MdOutlineForum,
    MdOutlineSettings,
    MdOutlineTrendingUp
} from 'react-icons/md';
import { ICON_SIZES } from '../../components';
import { Building } from '../../types';
import { NavigationButton } from './NavigationButton';

export const FactorNavigationList = (props: { selectedBuilding: Building }) => {
    const { id: buildingId } = props.selectedBuilding;

    return (
        <Stack spacing={3} width={'100%'}>
            <NavigationButton
                icon={<MdOutlineApartment size={ICON_SIZES.LARGE} />}
                label={'Properties'}
                route={`/buildings/${buildingId}/properties`}
            />
            <NavigationButton
                icon={<MdOutlineTrendingUp size={ICON_SIZES.LARGE} />}
                label={'Accounting'}
                route={`/buildings/${buildingId}/accounting`}
            />
            <NavigationButton
                icon={<MdOutlineEmail size={ICON_SIZES.LARGE} />}
                label={'Correspondence'}
                route={`/buildings/${buildingId}/correspondence`}
            />
            <NavigationButton
                icon={<MdOutlineForum size={ICON_SIZES.LARGE} />}
                label={'Forums'}
                route={`/buildings/${buildingId}/forums`}
            />
            <NavigationButton
                icon={<MdOutlineSettings size={ICON_SIZES.LARGE} />}
                label={'Settings'}
                route={`/buildings/${buildingId}/settings`}
            />
        </Stack>
    );
};
