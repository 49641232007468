import { Box, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlinePreview } from 'react-icons/md';
import {
    DocumentsField,
    SelectAccountsField,
    TextEditor,
    TextField,
    Wizard,
    WizardTabPanel
} from '../../../components';
import { correspondencesService } from '../../../services';
import { Account, Correspondence } from '../../../types';
import { CorrespondenceWizardProps } from './CorrespondenceWizard';
import { CorrespondenceActionDispatcher } from './components/CorrespondenceActionDispatcher';
import { CorrespondenceForm, correspondenceSchema } from './components/Joi';
import { CorrespondenceTemplate, Templates } from './components/Templates';

interface CorrespondenceWizardForLetterProps extends CorrespondenceWizardProps {
    onClose?: (correspondence?: Correspondence) => void;
    correspondenceTemplate?: CorrespondenceTemplate;
}

export const CorrespondenceWizardForLetter = (props: CorrespondenceWizardForLetterProps) => {
    const { accounts, building, disclosure, correspondenceTemplate, properties, onClose } = props;

    const toast = useToast();

    const actionDispatcher = useMemo(() => new CorrespondenceActionDispatcher(), []);

    const form = useForm<CorrespondenceForm>({
        defaultValues: {
            accountIds: accounts.getCurrentOwnerAccounts().map((account) => account.id),
            buildingId: building.id,
            body: new Templates().getLetterTemplate(correspondenceTemplate),
            documents: [],
            subject: ''
        },
        resolver: joiResolver(correspondenceSchema),
        reValidateMode: 'onSubmit'
    });

    const save = async (formValues: any) => {
        return correspondencesService.create<Correspondence>({
            pathParams: {
                buildingId: building.id
            },
            pathSuffix: 'letter',
            body: formValues
        });
    };

    const previewCorrespondence = async (account: Account) => {
        const { body, subject } = form.getValues();

        actionDispatcher.previewCorrespondence(
            { account },
            {
                body: body,
                subject: subject
            },
            toast
        );
    };

    return (
        <Wizard<CorrespondenceForm, Correspondence>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: '4xl'
                }
            }}
            modalContentProps={{
                height: '4xl'
            }}
            form={form}
            heading="Publish letter"
            save={save}
            saveLabel={'Publish'}
            onSaveSuccess={onClose}
            steps={[
                {
                    heading: 'Letter subject / content',
                    fieldNames: ['subject', 'body']
                },
                {
                    heading: 'Select recipients',
                    fieldNames: ['accountIds']
                },
                {
                    heading: 'Attachments',
                    fieldNames: ['documents']
                }
            ]}
        >
            <WizardTabPanel flexDirection={'column'}>
                <TextField formLabel="Letter subject" name="subject" formHook={form} isRequired />
                <Box flex={1}>
                    <TextEditor formLabel="Letter content" name="body" formHook={form} isRequired />
                </Box>
            </WizardTabPanel>
            <WizardTabPanel>
                <SelectAccountsField
                    formLabel="Selected recipients"
                    name="accountIds"
                    formHook={form}
                    accounts={accounts}
                    properties={properties}
                    isRequired
                    additionalButton={{
                        buttonIcon: MdOutlinePreview,
                        onClick: (account) => previewCorrespondence(account),
                        tooltip: 'Preview document'
                    }}
                />
            </WizardTabPanel>
            <WizardTabPanel>
                <DocumentsField building={building} formHook={form} groupLabel="Attachments list" name="documents" />
            </WizardTabPanel>
        </Wizard>
    );
};
