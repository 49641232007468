import { Alert } from '@chakra-ui/react';
import Joi from 'joi';
import { feedbackService } from '../../services';
import { TextArea } from '../form';
import { StandardModalProps } from '../modals';
import { AddEditDialog } from './AddEditDialog';

export const FeedbackDialog = (props: StandardModalProps) => {
    const { disclosure, onClose } = props;

    return (
        <AddEditDialog
            disclosure={disclosure}
            onClose={onClose}
            header={`Provide feedback`}
            fields={(form, disabled) => {
                return (
                    <>
                        <Alert status="info" mb={4}>
                            Feel free to provide both positive and any negative feedback you may have. Feature requests
                            are also more than welcomed.
                        </Alert>
                        <TextArea name="feedback" formHook={form} formLabel="Feedback" isRequired />
                    </>
                );
            }}
            onCreate={async (formValues) => {
                await feedbackService.create({
                    body: formValues
                });
            }}
            schema={Joi.object({
                feedback: Joi.string().min(10).max(500).required()
            })}
            saveButtonLabel={'Send and close'}
            setValues={() => {}}
        />
    );
};
