export type DivisionVersionWithDivisions = DivisionVersion & { divisions: Division };

export interface DivisionVersion {
    id: string;
    active: boolean;
    buildingId: string;
    createdDate: string;
    denominator?: number;
    divisionMethod: DivisionMethod;
    divisions: Division[];
    isInUse: boolean;
}

export enum DivisionMethod {
    EQUAL_SHARE = 'EQUAL_SHARE',
    FLOOR_AREA = 'FLOOR_AREA',
    FRACTION = 'FRACTION',
    PERCENTAGE = 'PERCENTAGE',
    RATEABLE_VALUE = 'RATEABLE_VALUE'
}

const DIVISION_METHOD_NAMES: Map<DivisionMethod, string> = new Map([
    [DivisionMethod.EQUAL_SHARE, 'Equal share'],
    [DivisionMethod.FLOOR_AREA, 'Floor area'],
    [DivisionMethod.FRACTION, 'Fraction'],
    [DivisionMethod.PERCENTAGE, 'Percentage'],
    [DivisionMethod.RATEABLE_VALUE, 'Rateable value']
]);

export const getDivisionMethodName = (divisionMethod: DivisionMethod): string => {
    return DIVISION_METHOD_NAMES.get(divisionMethod) || '';
};

const DIVISION_METHOD_DESCRIPTIONS: Map<DivisionMethod, string> = new Map([
    [DivisionMethod.EQUAL_SHARE, 'Equally'],
    [DivisionMethod.FLOOR_AREA, 'By ratio of floor areas'],
    [DivisionMethod.FRACTION, 'By set fractions'],
    [DivisionMethod.PERCENTAGE, 'By set percentages'],
    [DivisionMethod.RATEABLE_VALUE, 'By ratio of rateable values']
]);

export const getDivisionMethodDescription = (divisionMethod: DivisionMethod): string => {
    return DIVISION_METHOD_DESCRIPTIONS.get(divisionMethod) || '';
};

export interface Division {
    id: string;
    propertyId: string;
    value: number;
}
