import { Icon, Table, Tbody, Td, Text, Th, Tooltip, Tr, useBreakpointValue } from '@chakra-ui/react';
import { TableMenu, TableMenuItem, TdButton, ThTableMenu, WeFactorThead } from '../../../components';
import {
    Account,
    Accounts,
    Invoice,
    Properties,
    Transaction,
    TransactionType,
    getTransactionTypeIcon,
    getTransactionTypeName
} from '../../../types';
import { formatCurrency, formatDateShortMonth } from '../../../util';

interface AccountingTableProps {
    accounts?: Accounts;
    properties?: Properties;
    invoicesMap: Map<string, Invoice>;
    showForAccount?: Account;
    transactions: Transaction[];
    onDeleteAccountTransaction?: (accountTransaction: Transaction) => void;
    onDeleteInvoice?: (invoice: Invoice) => void;
    onEditAccountTransaction?: (accountTransaction: Transaction) => void;
    onEditInvoice?: (invoice: Invoice) => void;
    onViewAccountTransaction?: (accountTransaction: Transaction) => void;
    onViewInvoice?: (invoice: Invoice) => void;
}

export const AccountingTable = (props: AccountingTableProps) => {
    const {
        accounts,
        invoicesMap,
        transactions,
        onDeleteInvoice,
        onDeleteAccountTransaction,
        onEditInvoice,
        onEditAccountTransaction,
        onViewInvoice,
        onViewAccountTransaction
    } = props;

    const onDeleteItemClick = (transaction: Transaction) => {
        if (transaction.type === TransactionType.INVOICE && onDeleteInvoice) {
            const invoice = invoicesMap.get(transaction.id);
            if (invoice) onDeleteInvoice(invoice);
        } else if (onDeleteAccountTransaction) {
            onDeleteAccountTransaction(transaction);
        }
    };

    const onEditItemClick = (transaction: Transaction) => {
        if (transaction.type === TransactionType.INVOICE && onEditInvoice) {
            const invoice = invoicesMap.get(transaction.id);
            if (invoice) onEditInvoice(invoice);
        } else if (onEditAccountTransaction) {
            onEditAccountTransaction(transaction);
        }
    };

    const onViewItemClick = (transaction: Transaction) => {
        if (transaction.type === TransactionType.INVOICE && onViewInvoice) {
            const invoice = invoicesMap.get(transaction.id);
            if (invoice) onViewInvoice(invoice);
        } else if (onViewAccountTransaction) {
            onViewAccountTransaction(transaction);
        }
    };

    const getDescription = (transaction: Transaction) => {
        switch (transaction.type) {
            case TransactionType.INVOICE:
                return invoicesMap.get(transaction.id)?.subject || 'N/A';
            default:
                return accounts?.getAccount(transaction.accountId!)?.accountName;
        }
    };

    const renderHeaderRow = useBreakpointValue(
        {
            base: () => (
                <>
                    <Th>Description</Th>
                    <Th textAlign={'center'} width="80px">
                        In/Out
                    </Th>
                </>
            ),
            md: () => (
                <>
                    <Th width="110px">Date</Th>
                    <Th textAlign={'center'} width="65px">
                        Type
                    </Th>
                    <Th>Description</Th>
                    <Th textAlign={'center'} width="80px">
                        In
                    </Th>
                    <Th textAlign={'center'} width="80px">
                        Out
                    </Th>
                </>
            )
        },
        { fallback: 'md', ssr: false }
    )!;

    const getBaseValues = (transaction: Transaction) => {
        return {
            amount: formatCurrency(transaction.amount),
            date: formatDateShortMonth(transaction.date),
            description: getDescription(transaction),
            clickProps: {
                _hover: { cursor: 'pointer' },
                onClick: () => onViewItemClick(transaction)
            }
        };
    };

    const getMediumValues = (transaction: Transaction) => {
        return {
            ...getBaseValues(transaction),
            icon: getTransactionTypeIcon(transaction.type),
            name: getTransactionTypeName(transaction.type)
        };
    };

    const renderTransactionRow = useBreakpointValue(
        {
            base: (transaction: Transaction) => {
                const { amount, clickProps, date, description } = getBaseValues(transaction);
                return (
                    <>
                        <Td {...clickProps}>
                            <Text mb={1.5}>{description}</Text>
                            <Text fontWeight={'300'}>{date}</Text>
                        </Td>
                        <Td {...clickProps} textAlign={'center'}>
                            <Text whiteSpace={'nowrap'}>{amount}</Text>
                        </Td>
                    </>
                );
            },
            md: (transaction: Transaction) => {
                const { amount, clickProps, date, description, icon, name } = getMediumValues(transaction);
                const isPositive = transaction.amount > 0;
                return (
                    <>
                        <Td {...clickProps}>{date}</Td>
                        <Td {...clickProps} textAlign={'center'}>
                            <Tooltip label={name}>
                                <span>
                                    <Icon as={icon} />
                                </span>
                            </Tooltip>
                        </Td>
                        <Td {...clickProps}>{description}</Td>
                        <Td {...clickProps} textAlign={'center'}>
                            <Text whiteSpace={'nowrap'}>{isPositive ? amount : <>&nbsp;</>}</Text>
                        </Td>
                        <Td {...clickProps} textAlign={'center'}>
                            <Text whiteSpace={'nowrap'}>{!isPositive ? amount : <>&nbsp;</>}</Text>
                        </Td>
                    </>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <Table size={'sm'}>
            <WeFactorThead>
                <Tr>
                    {renderHeaderRow()}
                    <ThTableMenu>&nbsp;</ThTableMenu>
                </Tr>
            </WeFactorThead>
            <Tbody>
                {transactions.map((transaction) => {
                    return (
                        <Tr key={transaction.id}>
                            {renderTransactionRow(transaction)}
                            <TdButton>
                                <TableMenu>
                                    <TableMenuItem
                                        menuItemProps={{
                                            'aria-label': 'Edit Item',
                                            onClick: () => onEditItemClick(transaction)
                                        }}
                                    >
                                        Edit
                                    </TableMenuItem>
                                    <TableMenuItem
                                        menuItemProps={{
                                            'aria-label': 'Delete Item',
                                            onClick: () => onDeleteItemClick(transaction)
                                        }}
                                    >
                                        Delete
                                    </TableMenuItem>
                                </TableMenu>
                            </TdButton>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};
