import { Document, MaintenanceAccountTransaction, Transaction } from '.';

export enum ShareMethod {
    EQUAL_SHARE = 'EQUAL_SHARE', // use equal share division version no matter what is active
    RATEABLE = 'RATEABLE', // means to use active division verson
    RATEABLE_PREVIOUS = 'RATEABLE_PREVIOUS' // means to keep the division version that invoice was created against
}

export interface Invoice {
    id: string;
    buildingId: string;
    divisionVersionId: string;
    amount: number;
    documents: Document[];
    date: string;
    shareMethod: ShareMethod;
    subject: string;
    type: string;

    invoiceShares: InvoiceShare[];
    transaction: Transaction & {
        maintenanceAccountTransaction?: MaintenanceAccountTransaction;
    };
}

export interface InvoiceShare {
    id: string;
    invoiceId: string;
    accountId: string;
    amount: number;

    transaction: Transaction;
}

export interface DownloadInvoice {
    signedUrl: string;
}
