import { createStore, setProp, withProps } from '@ngneat/elf';
import {
    deleteAllEntities,
    deleteEntities,
    deleteEntitiesByPredicate,
    getAllEntities,
    hasEntity,
    resetActiveId,
    selectActiveEntity,
    selectAllEntities,
    selectEntitiesCount,
    setActiveId,
    updateEntities,
    upsertEntities,
    withActiveId,
    withEntities
} from '@ngneat/elf-entities';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';
import { authenticatedUserService } from '../authenticated-user/authenticated-user.service';
import { Config } from '../config/config.model';

interface ContentRequest extends BaseRequest {
    body?: any;
    doNotUpdateEntities?: boolean;
}

interface BaseRequest {
    retainEntities?: boolean;
    pathParams?: any;
    pathPrefix?: string;
    pathSuffix?: string;
    params?: any;
    token?: string;
}

interface StoreProps {
    fetchId: string | undefined;
    fetchOptions: BaseRequest | undefined;
}

const TOKEN_REGEX = /\{\{(.*?)\}\}/g;

export const entityServiceFactory = (() => {
    let API_URL: string;

    const init = (config: Config) => {
        API_URL = config.API_BASE_URL + '/';
    };

    const create = <T extends { id: PropertyKey }>(resourcePath: string) => {
        return (() => {
            const store = createStore(
                { name: resourcePath },
                withActiveId(),
                withEntities<T>({ initialValue: [] }),
                withProps<StoreProps>({
                    fetchId: undefined,
                    fetchOptions: undefined
                })
            );

            const activeEntity$ = store.pipe(selectActiveEntity());

            const entities$ = store.pipe(selectAllEntities());
            const entitiesCount$ = store.pipe(selectEntitiesCount());

            const loaded$ = new BehaviorSubject<boolean>(false);
            const loading$ = new BehaviorSubject<boolean>(false);

            const fetch = async <FetchReturn = T[]>(options: BaseRequest = {}): Promise<FetchReturn> => {
                if (!options.retainEntities) store.update(deleteAllEntities());

                const thisFetchId = v4();
                store.update(setProp('fetchId', thisFetchId));
                store.update(setProp('fetchOptions', options));

                loaded$.next(false);
                loading$.next(true);

                try {
                    const { data } = await axios.get(getUrl(options), await getHeader(options));
                    if (store.getValue().fetchId !== thisFetchId) return [] as FetchReturn;
                    store.update(upsertEntities(data.entities ? data.entities : data));
                    loaded$.next(true);
                    return data;
                } finally {
                    loading$.next(false);
                }
            };

            const get = async <FetchReturn = T>(options: BaseRequest = {}, id: PropertyKey): Promise<FetchReturn> => {
                try {
                    const { data } = await axios.get(getUrl(options, id), await getHeader(options));
                    store.update(upsertEntities(data));
                    return data;
                } catch (error) {
                    throw error;
                }
            };

            const create = async <CreateReturn = T>(options: ContentRequest): Promise<CreateReturn> => {
                const { data } = await axios.post(getUrl(options), getBody(options), await getHeader(options));
                if (options.doNotUpdateEntities !== true) store.update(upsertEntities(data));
                return data;
            };

            const update = async <UpdateReturn = T>(options: ContentRequest): Promise<UpdateReturn> => {
                const { data } = await axios.put(getUrl(options), getBody(options), await getHeader(options));
                if (options.doNotUpdateEntities !== true) store.update(upsertEntities(data));
                return data;
            };

            const DELETE = async <DeleteReturn = T>(
                options: BaseRequest & { id: PropertyKey; updateEntities?: boolean }
            ): Promise<DeleteReturn> => {
                const { id: idToDelete } = options;
                const { data } = await axios.delete(getUrl(options, idToDelete), await getHeader(options));
                store.update(deleteEntitiesByPredicate((entity) => entity.id === idToDelete));
                if (options.updateEntities) store.update(upsertEntities(data));
                return data;
            };

            const getUrl = (options: BaseRequest, id?: PropertyKey) => {
                const { pathParams, pathPrefix, pathSuffix } = options;
                let url = resourcePath;
                if (pathPrefix) url += `/${pathPrefix}`;
                url = url.replace(TOKEN_REGEX, (match, token) => pathParams[token] || null);
                const entityId = pathParams?.id || id;
                if (entityId) url += `/${entityId}`;
                if (pathSuffix) url += `/${pathSuffix}`;
                return API_URL + url;
            };

            const getBody = (options: ContentRequest) => {
                return options.body ? JSON.stringify(options.body) : undefined;
            };

            const getHeader = async (options: BaseRequest) => {
                return {
                    params: options.params || {},
                    headers: {
                        Authorization: options.token ? options.token : await authenticatedUserService.getIdToken(),
                        'Content-Type': 'application/json'
                    }
                };
            };

            const clearEntities = () => {
                store.update(deleteAllEntities());
                loading$.next(false);
                loaded$.next(false);
            };

            return {
                activeEntity$,
                entities$,
                entitiesCount$,
                loaded$,
                loading$,
                // API methods
                fetch,
                get,
                create,
                update,
                delete: DELETE,
                // Entities manipulation
                getEntities: () => store.query(getAllEntities()),
                hasEntityId: (id: PropertyKey) => store.query(hasEntity(id)),
                removeAllEntities: clearEntities,
                removeEntities: (id: PropertyKey) => store.update(deleteEntities(id)),
                upsertEntities: (entities: T | T[]) => store.update(upsertEntities(entities)),
                updateEntity: (id: PropertyKey, update: Partial<T>) => store.update(updateEntities(id, update)),
                // Active entities
                clearActiveEntity: () => store.update(resetActiveId()),
                setActiveEntityId: (id: PropertyKey) => store.update(setActiveId(id))
            };
        })();
    };

    return {
        init,
        create
    };
})();
