import { Skeleton, SkeletonText, Stack } from '@chakra-ui/react';

export const SideBarSkeleton = () => {
    return (
        <Stack spacing={8}>
            <Skeleton height="60px" />
            <SkeletonText noOfLines={4} />
            <Skeleton height="200px" />
        </Stack>
    );
};

export const SideBarSectionSkeleton = () => {
    return (
        <Stack spacing={8}>
            <Skeleton height="200px" />
        </Stack>
    );
};
