import { useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import NP from 'number-precision';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlinePreview } from 'react-icons/md';
import {
    DocumentsField,
    SelectAccountsField,
    TextEditor,
    TextField,
    Wizard,
    WizardTabPanel
} from '../../../components';
import { correspondencesService } from '../../../services';
import { Account, DivisionMethod, DivisionVersionWithDivisions, SharedCosts } from '../../../types';
import { CorrespondenceWizardProps } from './CorrespondenceWizard';
import { CorrespondenceActionDispatcher } from './components/CorrespondenceActionDispatcher';
import { CorrespondenceForm, correspondenceSchema } from './components/Joi';
import { Templates } from './components/Templates';

const sharedCostsSchema = correspondenceSchema.keys({
    divisionVersionId: Joi.string().uuid()
});

type SharedCostsForm = CorrespondenceForm & {
    divisionVersionId: string | undefined;
};

interface CorrespondenceWizardForSharedCostsProps extends CorrespondenceWizardProps {
    divisionVersions: DivisionVersionWithDivisions[];
    onClose?: (correspondence?: SharedCosts) => void;
}

export const CorrespondenceWizardForSharedCosts = (props: CorrespondenceWizardForSharedCostsProps) => {
    const { accounts, building, disclosure, divisionVersions, properties, onClose } = props;

    const toast = useToast();

    const actionDispatcher = useMemo(() => new CorrespondenceActionDispatcher(), []);

    const divisionVersion = useMemo(
        () => divisionVersions.find((divisionVersion) => divisionVersion.active)!,
        [divisionVersions]
    );

    const propertiesCount = useMemo(() => properties.getActiveProperties().length, [properties]);

    if (!divisionVersion) throw new Error('Shared costs not configured');

    const form = useForm<SharedCostsForm>({
        defaultValues: {
            accountIds: accounts.getCurrentOwnerAccounts().map((account) => account.id),
            buildingId: building.id,
            body: new Templates().getSharedCostsTemplate(divisionVersion.divisionMethod),
            documents: [],
            subject: 'Information on how common repair costs are being shared'
        } as any,
        resolver: joiResolver(sharedCostsSchema),
        reValidateMode: 'onSubmit'
    });

    const save = async (formValues: any) => {
        return correspondencesService.create<SharedCosts>({
            pathParams: {
                buildingId: building.id
            },
            pathSuffix: 'shared-costs',
            body: formValues
        });
    };

    const previewCorrespondence = async (account: Account) => {
        const { body, subject } = form.getValues();

        const getReplacements = () => {
            if (divisionVersion.divisionMethod === 'EQUAL_SHARE') {
                return [
                    {
                        name: 'total_properties',
                        value: propertiesCount
                    },
                    {
                        name: 'percentage',
                        value: NP.times(NP.divide(1, propertiesCount), 100).toFixed(2) + '%'
                    }
                ];
            }

            const division = divisionVersion.divisions.find((division) => division.propertyId === account.propertyId);

            if (!division) {
                throw new Error(`Could not find shared cost value for property ${account.propertyId}`);
            }

            const propertyValue = division.value.toString();
            const propertyValueSum = divisionVersion.divisions
                .reduce((previousValue, currentValue) => previousValue + currentValue.value, 0)
                .toString();
            const percentage = NP.times(NP.divide(propertyValue, propertyValueSum), 100).toFixed(2) + '%';

            switch (divisionVersion.divisionMethod) {
                case DivisionMethod.FLOOR_AREA:
                    return [
                        {
                            name: 'floor_area',
                            value: `${propertyValue}m<sup>2</sup>`
                        },
                        {
                            name: 'total_floor_area',
                            value: `${propertyValueSum}m<sup>2</sup>`
                        },
                        {
                            name: 'percentage',
                            value: percentage
                        }
                    ];
                case DivisionMethod.FRACTION:
                    return [
                        {
                            name: 'numerator',
                            value: propertyValue
                        },
                        {
                            name: 'denominator',
                            value: propertyValueSum
                        },
                        {
                            name: 'percentage',
                            value: percentage
                        }
                    ];
                case DivisionMethod.RATEABLE_VALUE:
                    return [
                        {
                            name: 'rateable_value',
                            value: propertyValue
                        },
                        {
                            name: 'total_rateable_value',
                            value: propertyValueSum
                        },
                        {
                            name: 'percentage',
                            value: percentage
                        }
                    ];
                case DivisionMethod.PERCENTAGE:
                    return [
                        {
                            name: 'percentage',
                            value: percentage
                        }
                    ];
            }
        };

        await actionDispatcher.previewCorrespondence(
            { account },
            {
                body: body,
                subject: subject,
                replacements: getReplacements()
            },
            toast
        );
    };

    return (
        <Wizard<SharedCostsForm, SharedCosts>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    lg: '4xl'
                }
            }}
            modalContentProps={{
                height: '4xl'
            }}
            form={form}
            heading="Publish shared costs update"
            save={save}
            saveLabel={'Publish'}
            onSaveSuccess={onClose}
            steps={[
                {
                    heading: 'Letter subject / content',
                    fieldNames: ['subject', 'body']
                },
                {
                    heading: 'Select recipients',
                    fieldNames: ['accountIds']
                },
                {
                    heading: 'Attachments',
                    fieldNames: ['documents']
                }
            ]}
        >
            <WizardTabPanel>
                <TextField formLabel="Letter subject" name="subject" formHook={form} isRequired />
                <TextEditor formLabel="Letter content" name="body" formHook={form} isRequired />
            </WizardTabPanel>
            <WizardTabPanel>
                <SelectAccountsField
                    formLabel="Selected recipients"
                    name="accountIds"
                    formHook={form}
                    accounts={accounts}
                    properties={properties}
                    isRequired
                    additionalButton={{
                        buttonIcon: MdOutlinePreview,
                        onClick: (account) => previewCorrespondence(account),
                        tooltip: 'Preview document'
                    }}
                />
            </WizardTabPanel>
            <WizardTabPanel>
                <DocumentsField building={building} formHook={form} groupLabel="Attachments list" name="documents" />
            </WizardTabPanel>
        </Wizard>
    );
};
