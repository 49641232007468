import { Alert, AlertDescription, AlertIcon, Box, BoxProps, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { MdOutlineStarBorder } from 'react-icons/md';

interface CardHeaderWithIconProps {
    title: string;
    subtitle: string;
    icon: IconType;
    boxProps?: BoxProps;
}

export const CardHeaderWithIcon = (props: CardHeaderWithIconProps) => (
    <Box
        bg={'bg.surface'}
        boxShadow={'sm'}
        border={1}
        borderColor={'border.default'}
        borderRadius={'md'}
        borderStyle={'solid'}
        padding={2}
        {...props.boxProps}
    >
        <Flex alignItems={'center'}>
            <Icon as={props.icon} marginLeft={2} marginRight={4} />
            <Stack direction={'row'} spacing={4} justify={'space-between'}>
                <Stack spacing={1}>
                    <Text textStyle={'lg'} fontWeight={'medium'}>
                        {props.title}
                    </Text>
                    <Text textStyle={'sm'} color={'fg.muted'}>
                        {props.subtitle}
                    </Text>
                </Stack>
            </Stack>
        </Flex>
    </Box>
);

export const PremiumFeatureCard = () => {
    return (
        <CardHeaderWithIcon
            title={'Premium feature'}
            subtitle={'This is a premium feature requiring an active subscription'}
            icon={MdOutlineStarBorder}
            boxProps={{ mt: 2 }}
        />
    );
};

export const PremiumFeatureAlert = (props: { subtext?: string }) => {
    return (
        <Alert status={'info'} mb={2}>
            <AlertIcon />
            <AlertDescription fontSize={'sm'}>
                <Text mb={2}>This is a premium feature that requires an active subscription to use.</Text>
                <Text>You can start a free trial subscription from the Settings page.</Text>
                {props.subtext && <Text mt={2}>{props.subtext}</Text>}
            </AlertDescription>
        </Alert>
    );
};
