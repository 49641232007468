import { Link, Stack, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { EmailField, PasswordField, WeFactorSubmitButton } from '../../components';
import { authenticatedUserService } from '../../services';
import { AuthFlex } from './components/AuthFlex';
import { AuthHeader } from './components/AuthHeader';

export const SignInPage = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const form = useForm({
        defaultValues: {
            email: '',
            password: ''
        },
        resolver: joiResolver(
            Joi.object({
                email: Joi.string()
                    .trim()
                    .email({ tlds: { allow: false } })
                    .min(3)
                    .required(),
                password: Joi.string().trim().min(8).max(20).required()
            }).required()
        ),
        reValidateMode: 'onSubmit'
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting }
    } = form;

    const submit = async (formValues: any) => {
        const { email, password } = formValues;

        try {
            const confirmRequired = await authenticatedUserService.signIn(email, password);

            if (confirmRequired) {
                navigate('/confirm-account');
            }
        } catch (error: any) {
            const getErrorMessage = (code) => {
                switch (code) {
                    case 'TooManyFailedAttemptsException':
                    case 'TooManyRequestsException':
                        return 'Maximum number of attempts reached. Please try again later.';
                    case 'NotAuthorizedException':
                    case 'UserNotFoundException':
                        return "It looks like the email/username or password you entered isn't right. You can try again or reset your password.";
                    default:
                        return undefined;
                }
            };

            let errorMessage = getErrorMessage(error.code);

            if (!errorMessage && error.statusCode === 400 && error.message) {
                errorMessage = error.message;
            }

            toast({
                description: errorMessage || 'An unexpected error occurred - please contact us if the issue persists',
                status: 'error',
                duration: 6000,
                isClosable: true,
                position: 'top'
            });
        }
    };

    return (
        <AuthFlex>
            <AuthHeader>Sign in to account</AuthHeader>
            <form id="sign-in-form" noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
                <EmailField
                    name="email"
                    formLabel="Email Address"
                    formHook={form}
                    inputProps={{ autoComplete: 'username' }}
                    isRequired
                />
                <PasswordField
                    name="password"
                    formLabel="Password"
                    formHook={form}
                    inputProps={{ autoComplete: 'current-password' }}
                    isRequired
                />
                <WeFactorSubmitButton disabled={!isValid || isSubmitting} isLoading={isSubmitting} width="100%">
                    Sign In
                </WeFactorSubmitButton>
                <Stack mt={2}>
                    <Link
                        textAlign={'right'}
                        fontSize={'sm'}
                        onClick={() => {
                            navigate('/sign-up');
                        }}
                    >
                        Sign up now for free
                    </Link>
                </Stack>
                <Stack mt={2}>
                    <Link
                        textAlign={'right'}
                        fontSize={'sm'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/reset-password');
                        }}
                    >
                        Reset my password
                    </Link>
                </Stack>
            </form>
        </AuthFlex>
    );
};
