import { CommonRepairQuoteShare, Correspondence, CorrespondenceAccount, Document } from '../../../types';
import { entityServiceFactory } from '../entity-service-factory';

// Accounts

export const ownerCorrespondencesService = entityServiceFactory.create<Correspondence>(
    'accounts/{{accountId}}/correspondences'
);

// Building

export const correspondencesService = entityServiceFactory.create<Correspondence>(
    'buildings/{{buildingId}}/correspondences'
);

export const correspondenceAccountsService = entityServiceFactory.create<CorrespondenceAccount>(
    'buildings/{{buildingId}}/correspondences/{{correspondenceId}}/accounts'
);

export const correspondenceDocumentsService = entityServiceFactory.create<Document>(
    'buildings/{{buildingId}}/correspondences/{{correspondenceId}}/documents'
);

// Types: common-repair-quote

export const commonRepairQuoteSharesService = entityServiceFactory.create<CommonRepairQuoteShare>(
    'buildings/{{buildingId}}/correspondences/common-repair-quote/{{correspondenceId}}/shares'
);
