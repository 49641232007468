import { Box, Button, Collapse, Flex, Heading, Link, SpaceProps, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { useState } from 'react';
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import { MaintenanceAccountTransaction } from '../../types';
import { formatCurrency, formatDate } from '../../util';

interface TransactionDetailsProps {
    defaultIsOpen?: boolean;
    maintenanceAccountTransaction: MaintenanceAccountTransaction;
    spacing: SpaceProps;
}

export const TransactionDetails = (props: TransactionDetailsProps) => {
    const { defaultIsOpen, maintenanceAccountTransaction, spacing } = props;

    const [showTransaction, setShowTransaction] = useState<boolean>(!!defaultIsOpen);

    return (
        <Box {...spacing}>
            <Button
                leftIcon={showTransaction ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                onClick={() => setShowTransaction(!showTransaction)}
                as={Link}
                variant={'ghost'}
            >
                Show associated maintenance account transaction details
            </Button>
            <Collapse in={showTransaction} animateOpacity>
                <Box mt={2} pt={2} pb={2} pl={3} pr={3} color="white" bg="teal.500" rounded="md">
                    <Heading size={'md'} as={'h3'}>
                        Transaction details
                    </Heading>
                    <Flex mt={2} mb={2}>
                        <Box width={'200px'}>
                            <Stat>
                                <StatLabel>Transaction amount</StatLabel>
                                <StatNumber fontSize={'xl'}>
                                    {formatCurrency(maintenanceAccountTransaction.amount)}
                                </StatNumber>
                            </Stat>
                        </Box>
                        <Box width={'250px'}>
                            <Stat>
                                <StatLabel>Transaction date</StatLabel>
                                <StatNumber fontSize={'xl'}>
                                    {formatDate(maintenanceAccountTransaction.date)}
                                </StatNumber>
                            </Stat>
                        </Box>
                        <Box flex={1}>
                            <Stat>
                                <StatLabel>Transaction type</StatLabel>
                                <StatNumber fontSize={'xl'}>{maintenanceAccountTransaction.typeCode}</StatNumber>
                            </Stat>
                        </Box>
                    </Flex>
                    <Box>
                        <Stat>
                            <StatLabel>Transaction description</StatLabel>
                            <StatNumber fontSize={'xl'}>{maintenanceAccountTransaction.description}</StatNumber>
                        </Stat>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};
