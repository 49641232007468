import { DeleteIcon } from '@chakra-ui/icons';
import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';

export const DeleteDialog = (props: ConfirmDialogProps) => {
    const { detail, disclosure, title, onClose, onConfirm } = props;

    return (
        <ConfirmDialog
            confirmIcon={<DeleteIcon />}
            confirmText={'Delete'}
            detail={detail}
            disclosure={disclosure}
            title={title}
            onClose={onClose}
            onConfirm={onConfirm}
        />
    );
};
