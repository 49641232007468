import { Icon, Table, Tbody, Td, Text, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { SideBarSkeleton, TableMenu, TableMenuItem } from '../../../components';
import { correspondenceAccountsService, ownerCorrespondencesService, requestService } from '../../../services';
import { Account, Building, Correspondence } from '../../../types';
import { formatCorrespondenceType, formatDateShortMonth, genericToast, getCorrespondenceIcon } from '../../../util';
import { SendCorrespondanceToAccountModal } from '../../correspondence/dialogs/components/SendCorrespondanceModals';

interface CorrespondenceSectionProps {
    account: Account;
    building: Building;
}

export const CorrespondenceSection = (props: CorrespondenceSectionProps) => {
    const { account, building } = props;

    const sendCorrespondenceDisclosure = useDisclosure();

    const [disableButtons, setDisableButtons] = useState(false);

    const toast = useToast();

    const [selectedCorrespondence] = useObservable(ownerCorrespondencesService.activeEntity$);

    const [correspondences] = useObservable(ownerCorrespondencesService.entities$);
    const [correspondencesLoaded] = useObservable(ownerCorrespondencesService.loaded$);

    useEffect(() => {
        ownerCorrespondencesService.fetch({
            pathParams: {
                accountId: account.id
            }
        });

        return () => {
            ownerCorrespondencesService.removeAllEntities();
        };
    }, [account]);

    useEffect(() => {
        if (!selectedCorrespondence) return;

        correspondenceAccountsService.fetch({
            pathParams: {
                buildingId: building.id,
                correspondenceId: selectedCorrespondence.id
            }
        });
    }, [building, selectedCorrespondence]);

    if (!correspondences || !correspondencesLoaded) {
        return <SideBarSkeleton />;
    }

    const downloadCorrespondence = async (correspondence: Correspondence) => {
        setDisableButtons(true);

        await genericToast(
            toast,
            async () => {
                const result = await requestService.post<{ signedUrl: string }>(
                    'buildings/{{buildingId}}/correspondences/{{correspondenceId}}/download',
                    {
                        pathParams: {
                            buildingId: account.buildingId,
                            correspondenceId: correspondence.id
                        },
                        params: {
                            accountId: account.id
                        }
                    }
                );

                if (result) {
                    window.open(result.data.signedUrl);
                }
            },
            {
                duration: 4000,
                title: `Downloading correspondence`,
                onSuccessTitle: `Successfully downloaded correspondence`,
                onErrorTitle: `Failed to downloaded correspondence`
            }
        );

        setDisableButtons(false);
    };

    return (
        <>
            {correspondences.length > 0 ? (
                <Table size={'sm'}>
                    <Tbody>
                        {correspondences.map((correspondence) => {
                            const { id, createdDate } = correspondence;
                            return (
                                <Tr key={id}>
                                    <Td width={'14px'} margin={0} padding={0}>
                                        <Tooltip
                                            label={formatCorrespondenceType(correspondence.type)}
                                            placement="right"
                                        >
                                            <span>
                                                <Icon as={getCorrespondenceIcon(correspondence)} size={2} />
                                            </span>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Text mb={1}>{correspondence.subject}</Text>
                                        <Text fontSize={'sm'} fontWeight={200}>
                                            {formatDateShortMonth(createdDate)}
                                        </Text>
                                    </Td>
                                    <Td width={'40px'} margin={0} padding={0}>
                                        <TableMenu>
                                            <TableMenuItem
                                                menuItemProps={{
                                                    'aria-label': 'Download correspondence',
                                                    isDisabled: disableButtons,
                                                    onClick: (event: any) => {
                                                        event.preventDefault();
                                                        downloadCorrespondence(correspondence);
                                                    }
                                                }}
                                            >
                                                Download correspondence
                                            </TableMenuItem>
                                            <TableMenuItem
                                                menuItemProps={{
                                                    'aria-label': 'Send correspondence',
                                                    isDisabled: disableButtons,
                                                    onClick: (event: any) => {
                                                        event.preventDefault();
                                                        ownerCorrespondencesService.setActiveEntityId(id);
                                                        sendCorrespondenceDisclosure.onOpen();
                                                    }
                                                }}
                                            >
                                                Send correspondence
                                            </TableMenuItem>
                                        </TableMenu>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            ) : (
                <Text fontSize={'sm'}>No correspondence published for this account yet</Text>
            )}

            {sendCorrespondenceDisclosure.isOpen && selectedCorrespondence && (
                <SendCorrespondanceToAccountModal
                    account={account}
                    building={building}
                    correspondence={selectedCorrespondence}
                    disclosure={sendCorrespondenceDisclosure}
                />
            )}
        </>
    );
};
