import { Alert, AlertDescription, AlertIcon, UseDisclosureReturn } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DateField, EmailField, HiddenField, TextField, Wizard, WizardTabPanel } from '../../../components';
import { BaseSelect } from '../../../components/form/BaseSelect';
import { factorAccountsService } from '../../../services';
import { Account, Building, Properties } from '../../../types';
import { formatAddress } from '../../../util';

type AccountForm = {
    accountName: string;
    contactAddressLine1: string;
    contactAddressLine2: string;
    contactAddressLine3?: string;
    contactAddressLine4?: string;
    contactPostcode: string;
    buildingId: string;
    email?: string;
    propertyId: string;
    startDate: string;
};

interface AccountWizardProps {
    building: Building;
    defaultPropertyId?: string;
    disclosure: UseDisclosureReturn;
    properties: Properties;
    onClose?: () => void;
    onSaveSuccess?: (account: Account[]) => void;
}

export const AddAccountWizard = (props: AccountWizardProps) => {
    const { building, defaultPropertyId, disclosure, properties, onClose, onSaveSuccess } = props;

    const form = useForm<AccountForm>({
        defaultValues: {
            buildingId: building.id,
            propertyId: defaultPropertyId,
            accountName: '',
            contactAddressLine1: '',
            contactAddressLine2: '',
            contactAddressLine3: '',
            contactAddressLine4: '',
            contactPostcode: '',
            email: '',
            startDate: ''
        },
        resolver: joiResolver(
            Joi.object({
                buildingId: Joi.string().uuid().required(),
                propertyId: Joi.string()
                    .uuid()
                    .messages({
                        'any.required': '"Property" is required'
                    })
                    .required(),
                accountName: Joi.string().label('Full name').trim().min(3).max(100).required(),
                contactAddressLine1: Joi.string().label('Address line 1').trim().min(1).max(50).required(),
                contactAddressLine2: Joi.string().label('Address line 2').trim().min(1).max(50).required(),
                contactAddressLine3: Joi.string()
                    .label('Address line 3')
                    .trim()
                    .allow(null)
                    .allow('')
                    .empty()
                    .optional(),
                contactAddressLine4: Joi.string()
                    .label('Address line 4')
                    .trim()
                    .allow(null)
                    .allow('')
                    .empty()
                    .optional(),
                contactPostcode: Joi.string().label('Postcode').trim().max(10).required(),
                email: Joi.string()
                    .label('Email address')
                    .trim()
                    .email({ tlds: { allow: false } })
                    .allow(null, '')
                    .empty()
                    .max(250)
                    .optional(),
                startDate: Joi.date()
                    .label('Start date')
                    .messages({
                        'date.max': '"Start date" cannot be in the future'
                    })
                    .max('now')
                    .raw()
                    .required()
            })
        ),
        reValidateMode: 'onSubmit'
    });

    const propertyId = form.watch('propertyId');

    useEffect(() => {
        if (propertyId) {
            const address = properties.getActiveProperties().find((property) => property.id === propertyId)!;
            const lines = [
                address.subBuilding,
                address.buildingName,
                `${address.buildingNumber || ''} ${address.streetName}`.trim(),
                address.town
            ].filter((value) => value && value.trim().length > 0);
            if (lines[0]) form.setValue('contactAddressLine1', lines[0]);
            if (lines[1]) form.setValue('contactAddressLine2', lines[1]);
            if (lines[2]) form.setValue('contactAddressLine3', lines[2]);
            if (lines[3]) form.setValue('contactAddressLine4', lines[3]);
            form.setValue('contactPostcode', address.postcode);
        }
    }, [propertyId]);

    const save = async (formValues: AccountForm) => {
        return factorAccountsService.create<Account[]>({
            pathParams: {
                buildingId: building.id
            },
            body: formValues
        });
    };

    return (
        <Wizard<AccountForm, Account, Account[]>
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    md: 'md'
                }
            }}
            form={form}
            heading={'Add new account'}
            onClose={onClose}
            onSaveSuccess={onSaveSuccess}
            save={save}
            steps={[
                {
                    heading: defaultPropertyId ? 'Set owner details' : 'Select property and set owner details',
                    fieldNames: ['propertyId', 'accountName', 'email', 'startDate', 'buildingId']
                },
                {
                    heading: "Owner's contact address",
                    fieldNames: [
                        'contactAddressLine1',
                        'contactAddressLine2',
                        'contactAddressLine3',
                        'contactAddressLine4',
                        'contactPostcode'
                    ]
                }
            ]}
        >
            <WizardTabPanel>
                <BaseSelect
                    name="propertyId"
                    formLabel="Property"
                    formHook={form}
                    options={properties.getActiveProperties().map((property) => ({
                        label: formatAddress(property),
                        value: property.id
                    }))}
                    isDisabled={!!defaultPropertyId}
                    isRequired
                />
                <TextField name="accountName" formLabel="Full name" formHook={form} isRequired />
                <EmailField name="email" formLabel="Email address" formHook={form} />
                <DateField name="startDate" formLabel="Start date" formHook={form} isRequired />
                <Alert mb={5} status="info">
                    <AlertIcon />
                    <AlertDescription fontSize={'sm'}>
                        You should set the start date to be the date the property was purchased. If unknown it is
                        acceptable to use the current date.
                    </AlertDescription>
                </Alert>
                <HiddenField name="buildingId" formHook={form} />
            </WizardTabPanel>
            <WizardTabPanel>
                <TextField name="contactAddressLine1" formLabel="Address line 1" formHook={form} />
                <TextField name="contactAddressLine2" formLabel="Address line 2" formHook={form} />
                <TextField name="contactAddressLine3" formLabel="Address line 3" formHook={form} />
                <TextField name="contactAddressLine4" formLabel="Address line 4" formHook={form} />
                <TextField name="contactPostcode" formLabel="Postcode" formHook={form} />
            </WizardTabPanel>
        </Wizard>
    );
};
