import { TableCellProps, TableProps, Td, Th, Thead } from '@chakra-ui/react';

export const WeFactorThead = (props: TableProps) => {
    return <Thead backgroundColor="#F7FAFC">{props.children}</Thead>;
};

export const WeFactorMinTh = (props: TableCellProps) => {
    return (
        <Th paddingX={2} paddingY={2} {...props}>
            {props.children}
        </Th>
    );
};

// Cells

export const ThTableMenu = (props: TableCellProps) => {
    return (
        <Th key={'actions'} paddingX={'0'} paddingY={'0'} {...props}>
            &nbsp;
        </Th>
    );
};

export const TdButton = (props: TableCellProps) => {
    return (
        <Td key={'actions'} width={'40px'} paddingX={'10px'} {...props}>
            {props.children}
        </Td>
    );
};
