import { Box, Divider, Flex, IconButton, List, ListItem, Text, useToast } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import {
    MdOutlineAccountBalance,
    MdOutlineClose,
    MdOutlineEmail,
    MdOutlineMessage,
    MdOutlineReceiptLong,
    MdOutlineSavings
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { notificationsService } from '../../services';
import { Notification } from '../../types';
import { deleteToast, formatDate } from '../../util';
import { PageLoadingSkeleton } from '../skeletons';
import { ICON_SIZES } from '../skeletons/constants';

interface NotificationsProps {
    notifications: Notification[];
    onNotificationClick: () => any;
}

export const Notifications = (props: NotificationsProps) => {
    const { notifications, onNotificationClick } = props;
    const toast = useToast();
    const navigate = useNavigate();

    const [notificationsLoaded] = useObservable(notificationsService.loaded$);

    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        if (!notificationsService.loaded$.getValue()) return;
        setPageLoaded(true);
    }, [notificationsLoaded]);

    if (!pageLoaded) {
        return <PageLoadingSkeleton />;
    }

    const deleteNotification = async (notification: Notification) => {
        await deleteToast(toast, async () => {
            await notificationsService.delete({ id: notification.id });
        });
    };

    const handleNotificationClick = (notification: Notification) => {
        const { buildingId, discussionId, accountId, invoiceId, paymentId, correspondenceId } = notification.data;

        switch (notification.type) {
            case 'MAINTENANCE_ACCOUNT_RECONNECT_REQUIRED':
                navigate(`/buildings/${buildingId}/maintenance-account?fromNotification=true`);
                break;
            case 'NEW_DISCUSSION':
                navigate(`/accounts/${accountId}/discussions?fromNotification=${discussionId}`);
                break;
            case 'UPDATED_DISCUSSION':
                navigate(`/accounts/${accountId}/discussions?fromNotification=${discussionId}`);
                break;
            case 'NEW_INVOICE':
                navigate(`/accounts/${accountId}/accounting?fromNotification=${invoiceId}`);
                break;
            case 'UPDATED_INVOICE':
                navigate(`/accounts/${accountId}/accounting?fromNotification=${invoiceId}`);
                break;
            case 'NEW_PAYMENT':
                navigate(`/accounts/${accountId}/accounting?fromNotification=${paymentId}`);
                break;
            case 'UPDATED_PAYMENT':
                navigate(`/accounts/${accountId}/accounting?fromNotification=${paymentId}`);
                break;
            case 'NEW_CORRESPONDENCE':
                navigate(`/accounts/${accountId}/correspondence?fromNotification=${correspondenceId}`);
                break;
            default:
                break;
        }

        onNotificationClick();
    };

    const getIcon = (notification: Notification) => {
        switch (notification.type) {
            case 'MAINTENANCE_ACCOUNT_RECONNECT_REQUIRED':
                return <MdOutlineAccountBalance />;
            case 'NEW_DISCUSSION':
            case 'UPDATED_DISCUSSION':
                return <MdOutlineMessage />;
            case 'NEW_INVOICE':
            case 'UPDATED_INVOICE':
                return <MdOutlineReceiptLong />;
            case 'NEW_PAYMENT':
            case 'UPDATED_PAYMENT':
                return <MdOutlineSavings />;
            case 'NEW_CORRESPONDENCE':
                return <MdOutlineEmail />;
            default:
                break;
        }
    };

    return (
        <List padding={6}>
            {notifications
                .sort((a, b) => {
                    if (new Date(a.createdTimestamp) < new Date(b.createdTimestamp)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                .map((notification) => (
                    <Box key={notification.id}>
                        <ListItem
                            pb={4}
                            pt={4}
                            _hover={{ cursor: 'pointer' }}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <Flex alignItems="center">
                                {getIcon(notification)}
                                <Flex flexDirection="column" width="100%" ml={4}>
                                    <Flex lineHeight="22px" width="100%" alignItems="center">
                                        <Text fontWeight={400}>{notification.subject}</Text>
                                        <IconButton
                                            aria-label=""
                                            ml={'auto'}
                                            size="xs"
                                            variant={'ghost'}
                                            icon={<MdOutlineClose size={ICON_SIZES.LARGE} />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                deleteNotification(notification);
                                            }}
                                        />
                                    </Flex>
                                    <Flex mt={1} lineHeight="22px" width="100%" alignItems="center">
                                        <Text color="#84818A" fontSize="sm" alignSelf="flex-start">
                                            {formatDate(notification.createdTimestamp)}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </ListItem>
                        <Divider />
                    </Box>
                ))}
        </List>
    );
};
