import { Avatar, Box, Button, Divider, Flex, Text, useToast } from '@chakra-ui/react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { MdOutlineCamera } from 'react-icons/md';
import {
    AuthenticatedUser,
    authenticatedUserService,
    contentService,
    fileUploadService,
    usersService
} from '../../services';
import { genericToast, getContentUrl } from '../../util';
import { PasswordField, TextField } from '../form';
import { StandardModalProps } from '../modals';
import { COLOURS, DARK, DARK_4, ICON_SIZES } from '../skeletons/constants';
import { AddEditDialog } from './AddEditDialog';

interface EditProfileDialogProps extends StandardModalProps {
    authenticatedUser: AuthenticatedUser;
}
export const EditProfileDialog = (props: EditProfileDialogProps) => {
    const { authenticatedUser, disclosure, onClose } = props;
    const [isHovering, setIsHovering] = useState(false);

    const toast = useToast();

    // change email related form
    const [newEmail, setNewEmail] = useState(authenticatedUser.email);
    const [verificationCodeSent, setVerificationCodeSent] = useState(false);

    const changeEmailForm: UseFormReturn<any> = useForm({
        defaultValues: {
            email: authenticatedUser.email
        },
        resolver: joiResolver(
            Joi.object({
                email: Joi.string()
                    .email({ tlds: { allow: false } })
                    .optional(),
                code: Joi.any()
            })
        ),
        reValidateMode: 'onSubmit'
    });

    // change password related form
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const changePasswordForm: UseFormReturn = useForm({
        resolver: joiResolver(
            Joi.object({
                oldPassword: Joi.string()
                    .trim()
                    .min(8)
                    .max(20)
                    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
                    .required(),
                newPassword: Joi.string()
                    .trim()
                    .min(8)
                    .max(20)
                    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
                    .required()
            })
        )
    });

    const sendVerificationCode = async () => {
        if (!newEmail) return;

        await genericToast(
            toast,
            async () => {
                await authenticatedUserService.changeEmail(newEmail);
                setVerificationCodeSent(true);
            },
            {
                title: `Sending verification code to ${newEmail}`,
                onSuccessTitle: `Successfully sent verification code to ${newEmail}`,
                onErrorTitle: `Failed to send verification code to ${newEmail}`
            }
        );
    };

    const verifyEmailChange = async () => {
        await genericToast(
            toast,
            async () => {
                const code = changeEmailForm.getValues('code');
                await authenticatedUserService.verifyEmailChange(code);
                await authenticatedUserService.refreshSession();
            },
            {
                title: 'Updating email',
                onSuccessTitle: 'Successfully updated email',
                onErrorTitle: 'Failed to update email'
            }
        );
    };

    const changePassword = async () => {
        await genericToast(
            toast,
            async () => {
                const oldPassword = changePasswordForm.getValues('oldPassword');
                const newPassword = changePasswordForm.getValues('newPassword');
                await authenticatedUserService.changePassword(oldPassword, newPassword);
            },
            {
                title: 'Updating password',
                onSuccessTitle: 'Successfully updated password',
                onErrorTitle: 'Failed to update password'
            }
        );
    };

    const changeProfileImage = async (event) => {
        try {
            const fileForUpload: File = event.target.files[0];

            await genericToast(
                toast,
                async () => {
                    const result = await contentService.create({
                        body: { type: fileForUpload.type, prefix: 'profile-images' }
                    });

                    await fileUploadService.upload(result.signedUrl, fileForUpload);

                    const updatedProfile = await usersService.update({
                        pathParams: { id: authenticatedUser.username },
                        body: {
                            displayName: authenticatedUser.displayName,
                            email: authenticatedUser.email,
                            profileImageUrl: result.contentId
                        }
                    });

                    authenticatedUserService.updateUserDetails(updatedProfile);
                },
                {
                    title: 'Updating profile picture',
                    onSuccessTitle: 'Successfully updated profile picture',
                    onErrorTitle: 'Failed to update profile picture'
                }
            );
        } catch (error) {
            console.error('failed to upload file', error);
        }
    };

    return (
        <AddEditDialog
            disclosure={disclosure}
            onClose={onClose}
            header="Edit Profile"
            entity={{
                ...authenticatedUser,
                id: 'fake'
            }}
            fields={(form, disabled) => {
                return (
                    <>
                        <Flex alignItems={'center'} mb={6}>
                            <label htmlFor="imgupload">
                                <Avatar
                                    position={'relative'}
                                    _hover={{ cursor: 'pointer' }}
                                    name={authenticatedUser.displayName}
                                    src={getContentUrl(authenticatedUser.profileImageUrl)}
                                    size="lg"
                                    onMouseOver={() => {
                                        setIsHovering(true);
                                    }}
                                    onMouseLeave={() => {
                                        setIsHovering(false);
                                    }}
                                >
                                    {isHovering && (
                                        <Box position={'absolute'}>
                                            <MdOutlineCamera size={ICON_SIZES.LARGE} />
                                        </Box>
                                    )}
                                </Avatar>
                                <input type="file" hidden id="imgupload" onChange={changeProfileImage} />
                            </label>

                            <Text ml={4} fontWeight={500} textColor={DARK}>
                                Change your profile picture
                            </Text>
                        </Flex>

                        <TextField name="displayName" formHook={form} formLabel="Display Name" isRequired />

                        {verificationCodeSent === false && (
                            <TextField
                                name="email"
                                formHook={changeEmailForm}
                                formLabel="Email"
                                onValueChanged={setNewEmail}
                                isRequired
                            />
                        )}

                        {verificationCodeSent === false && newEmail !== authenticatedUser.email && (
                            <>
                                <Button
                                    display="block"
                                    ml={'auto'}
                                    size="sm"
                                    mb={6}
                                    backgroundColor={COLOURS.BLUE_2}
                                    _hover={{ backgroundColor: COLOURS.GREEN }}
                                    color={COLOURS.WHITE}
                                    disabled={!changeEmailForm.formState.isValid}
                                    onClick={sendVerificationCode}
                                >
                                    Send Verification Code
                                </Button>
                            </>
                        )}

                        {verificationCodeSent && (
                            <>
                                <TextField
                                    name="code"
                                    formHook={changeEmailForm}
                                    formLabel="Verification Code"
                                    isRequired
                                />

                                <Button
                                    display="block"
                                    ml={'auto'}
                                    size="sm"
                                    mb={6}
                                    backgroundColor={COLOURS.BLUE_2}
                                    _hover={{ backgroundColor: COLOURS.GREEN }}
                                    color={COLOURS.WHITE}
                                    onClick={verifyEmailChange}
                                >
                                    Confirm Email Change
                                </Button>
                            </>
                        )}

                        <Flex mt={4} alignItems="center">
                            <Text fontWeight={600} color={DARK}>
                                Password
                            </Text>
                            <Button
                                ml={'auto'}
                                size="sm"
                                onClick={() => {
                                    setShowPasswordReset(!showPasswordReset);
                                }}
                            >
                                {showPasswordReset ? 'Hide' : 'Reveal'}
                            </Button>
                        </Flex>

                        {showPasswordReset && (
                            <>
                                <Divider mt={2} mb={2} borderColor={DARK_4}></Divider>

                                <PasswordField
                                    name="oldPassword"
                                    formHook={changePasswordForm}
                                    formLabel="Old password"
                                    inputProps={{ autoComplete: 'none' }}
                                    isRequired
                                />

                                <PasswordField
                                    name="newPassword"
                                    formHook={changePasswordForm}
                                    formLabel="New password"
                                    inputProps={{ autoComplete: 'new-password' }}
                                    isRequired
                                />

                                {changePasswordForm.formState.isValid && (
                                    <Button
                                        display="block"
                                        ml={'auto'}
                                        size="sm"
                                        backgroundColor={COLOURS.BLUE_2}
                                        _hover={{ backgroundColor: COLOURS.GREEN }}
                                        color={COLOURS.WHITE}
                                        onClick={changePassword}
                                    >
                                        Confirm Password Change
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                );
            }}
            onUpdate={async (formValues) => {
                const updatedProfile = await usersService.update({
                    pathParams: { id: authenticatedUser.username },
                    body: {
                        displayName: formValues.displayName,
                        profileImageUrl: authenticatedUser.profileImageUrl,
                        email: authenticatedUser.email
                    }
                });

                await authenticatedUserService.updateUserDetails(updatedProfile);
            }}
            schema={Joi.object({
                displayName: Joi.string().max(150).required()
            })}
            setValues={(setValue) => {
                setValue('displayName', authenticatedUser.displayName);
            }}
        />
    );
};
