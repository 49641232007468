import { Box, Container, Flex, Skeleton, Stack, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { map } from 'rxjs';
import { Heading2, HelpKey, StandardDivider, StandardPage, WeFactorActionButton } from '../../components';
import {
    forumBoardService,
    forumBoardViewService,
    forumPostService,
    forumPostViewService,
    userBuildingsService
} from '../../services';
import { AccessReadBadge, AccessWriteBadge, ForumBreadcrumb, ForumPostBox, ForumPostWizard } from './components';

export const ForumBoardPage = () => {
    const { buildingId, forumBoardId } = useParams();

    const disclosure = useDisclosure();

    const navigate = useNavigate();

    const [building] = useObservable(userBuildingsService.activeEntity$);
    const [forumBoard] = useObservable(forumBoardService.activeEntity$);
    const [forumPosts] = useObservable(forumPostService.entities$);
    const [forumPostsLoaded] = useObservable(forumPostService.loaded$);
    const [forumPostViews] = useObservable(
        forumPostViewService.entities$.pipe(
            map((forumPostViews) =>
                forumPostViews.reduce(
                    (timestampMap, { forumPostId, updatedDate }) => timestampMap.set(forumPostId, updatedDate),
                    new Map()
                )
            )
        )
    );

    useEffect(() => {
        if (!buildingId || !forumBoardId) return;

        if (forumBoardService.hasEntityId(forumBoardId)) {
            // Use cached forums - but refresh them
            forumBoardService.setActiveEntityId(forumBoardId);
            forumBoardService.fetch({ pathParams: { buildingId: buildingId }, retainEntities: true });
        } else {
            forumBoardService
                .fetch({ pathParams: { buildingId: buildingId } })
                .then(() => forumBoardService.setActiveEntityId(forumBoardId));
        }

        forumPostService
            .fetch({
                pathParams: {
                    buildingId: buildingId,
                    forumBoardId: forumBoardId
                }
            })
            .then(() => {
                forumBoardViewService.update({
                    pathParams: {
                        buildingId: buildingId
                    },
                    params: {
                        forumBoardId: forumBoardId
                    }
                });
            });

        forumPostViewService.fetch({
            pathParams: {
                buildingId: buildingId,
                forumBoardId: forumBoardId
            }
        });
    }, [buildingId, forumBoardId]);

    const pageHeader = useBreakpointValue({
        base: 'Forums',
        md: `Forums: ${building?.name}`
    });

    return (
        <StandardPage
            action={
                forumBoard && forumBoard.hasWritePermission ? (
                    <WeFactorActionButton onClick={disclosure.onOpen}>New post</WeFactorActionButton>
                ) : undefined
            }
            helpKey={HelpKey.FORUMS}
            title={pageHeader}
        >
            <Container height={'100%'}>
                <Skeleton isLoaded={!!forumBoard} height={'100%'}>
                    {forumBoard && (
                        <>
                            <Heading2>{forumBoard.name}</Heading2>
                            <ForumBreadcrumb
                                items={[
                                    { text: 'Forums', link: `/buildings/${buildingId}/forums` },
                                    { text: forumBoard.name, isCurrentPage: true }
                                ]}
                            />
                            <Skeleton isLoaded={forumPostsLoaded} height={'100%'}>
                                <Box marginBottom={2}>
                                    <Flex alignItems={'center'} direction={'row'}>
                                        <AccessReadBadge forumBoard={forumBoard} badgeProps={{ marginRight: 2 }} />
                                        <AccessWriteBadge forumBoard={forumBoard} />
                                    </Flex>
                                </Box>
                                <Stack divider={<StandardDivider />} gap={1}>
                                    {forumPosts.length === 0 && <Text>There are no posts in this forum.</Text>}
                                    {forumPosts.map((forumPost) => (
                                        <ForumPostBox
                                            buildingId={buildingId!}
                                            forumBoard={forumBoard}
                                            forumPost={forumPost}
                                            forumPostViews={forumPostViews}
                                        />
                                    ))}
                                </Stack>
                                <ForumPostWizard
                                    buildingId={buildingId!}
                                    forumBoard={forumBoard}
                                    disclosure={disclosure}
                                    onSaveSuccess={(forumPost) =>
                                        navigate(
                                            `/buildings/${buildingId}/forums/${forumBoard.id}/posts/${forumPost.id}`
                                        )
                                    }
                                />
                            </Skeleton>
                        </>
                    )}
                </Skeleton>
            </Container>
        </StandardPage>
    );
};
