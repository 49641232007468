import { Text, TextProps, forwardRef } from '@chakra-ui/react';

interface AuthTextProps extends TextProps {
    children: any;
}

export const AuthText = forwardRef<AuthTextProps, 'p'>((props, ref) => {
    const { children } = props;

    return (
        <Text mb={4} textAlign={'justify'} {...props} ref={ref}>
            {children}
        </Text>
    );
});
