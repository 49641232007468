import { Container, Text } from '@chakra-ui/react';
import { EmptyBoxIcon } from '../icons';

interface PageBoxProps {
    children?: any;
    message?: string;
    textAlign?: any;
}

export const EmptyPageBox = (props: PageBoxProps) => {
    const { children, message, textAlign } = props;

    return (
        <Container height="100%" justifyContent="center" display="flex" flexDirection="column" alignItems="center">
            <EmptyBoxIcon />
            {message && (
                <Text mt={4} color="#2E2C34" textAlign={textAlign}>
                    {message}
                </Text>
            )}
            {children}
        </Container>
    );
};
