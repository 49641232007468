import { Icon } from '@chakra-ui/react';

export const LogoIcon = (props: { color?: string; onClick?: () => void }) => (
    <Icon viewBox="0 0 224 46" width="300" height="50" onClick={props?.onClick} _hover={{ cursor: 'pointer' }}>
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 933.79 608.82">
            <g id="Logo">
                <g>
                    <g>
                        <path
                            fill={'#fff'}
                            d="m57,581.01l36-121.2h17.6l36,121.2,38.6-122h17.8l-47.8,145.6h-17l-36.6-118.4-36.6,118.4h-17.2L0,459.01h18.2l38.8,122Z"
                        />
                        <path
                            fill={'#fff'}
                            d="m200.8,558.41c2.6,20.8,18.4,34.8,40,34.8,14.6,0,27.4-6.2,34.6-16.4l12.6,8.4c-9.4,13.4-27,22.2-47.2,22.2-32.4,0-56-24.2-56-55.2s22.6-55.4,53.6-55.4,53.4,21.4,53.4,52c0,3-.2,6.2-.4,9.6h-90.6Zm75.4-13c-.4-10.2-4-18.6-11-24.8-7-6.4-15.8-9.6-26.8-9.6-20,0-34.6,13.6-37.6,34.4h75.4Z"
                        />
                        <path fill={'#fff'} d="m407,457.61v31.4h-70.6v31.8h62.4v31.4h-62.4v53.8h-34.8v-148.4h105.4Z" />
                        <path
                            fill={'#fff'}
                            d="m524.39,497.21v108.8h-33.6v-13.4c-7.2,10.4-18.8,16.2-32.2,16.2-30.6,0-52.8-25-52.8-57.2s22.2-57.2,52.8-57.2c13.4,0,25,5.8,32.2,16.2v-13.4h33.6Zm-60.2,26.2c-15.8,0-26.4,12-26.4,28.2s10.6,28.2,26.4,28.2,26.4-12,26.4-28.2-10.6-28.2-26.4-28.2Z"
                        />
                        <path
                            fill={'#fff'}
                            d="m588.59,494.41c21.4,0,39.4,9.8,49.6,25.8l-27.6,15.2c-4.8-7.2-12.4-11.6-22-11.6-15.8,0-26.4,11.6-26.4,27.8s10.6,27.8,26.4,27.8c9.6,0,17.2-4.4,22-11.6l27.6,15.2c-10.2,16-28.2,25.8-49.6,25.8-33.2.6-59-25-58.4-57.2-.6-32.2,25.2-57.8,58.4-57.2Z"
                        />
                        <path
                            fill={'#fff'}
                            d="m735.99,602.41c-6.4,4-17.2,6.4-27.6,6.4-27.4,0-43-15.4-43-45.2v-37.2h-21.8v-29.2h21.8v-28.2h33.6v28.2h32.6v29.2h-32.6v37c0,10.6,5,15.8,15,15.8,5.6,0,10.6-1.2,15.2-3.4l6.8,26.6Z"
                        />
                        <path
                            fill={'#fff'}
                            d="m793.59,494.41c33.2-.6,59,25,58.4,57.2.6,32.2-25.2,57.8-58.4,57.2-33.2.6-59-25-58.4-57.2-.6-32.2,25.2-57.8,58.4-57.2Zm0,29.4c-15.8,0-26.4,11.6-26.4,27.8s10.6,27.8,26.4,27.8,26.4-11.6,26.4-27.8-10.6-27.8-26.4-27.8Z"
                        />
                        <path
                            fill={'#fff'}
                            d="m933.79,528.01c-3.8-1.2-8.2-1.8-13.6-1.8-16.2,0-27.4,10-27.4,26v53.8h-33.6v-108.8h33.6v16c5-11,15.8-18,28-18,5,0,9.4.6,13,2v30.8Z"
                        />
                    </g>
                    <g>
                        <g>
                            <circle fill="#008df6" cx="669.34" cy="115.07" r="42.96" />
                            <circle fill="#008df6" cx="265.37" cy="115.07" r="42.96" />
                            <polygon
                                fill="#008df6"
                                points="668.7 191.48 668.7 415.1 721.13 415.1 721.13 307.53 773.47 353.63 773.47 283.76 668.7 191.48"
                            />
                            <polygon
                                fill="#008df6"
                                points="161.24 353.63 213.58 307.53 213.58 415.1 266.01 415.1 266.01 191.48 161.24 283.76 161.24 353.63"
                            />
                            <polygon
                                fill="#17ebb1"
                                points="598.79 362.85 467.38 362.85 467.34 362.85 335.91 362.85 335.91 221.64 283.48 266.75 283.48 415.1 467.34 415.1 467.38 415.1 651.23 415.1 651.23 266.75 598.79 221.64 598.79 362.85"
                            />
                        </g>
                        <g>
                            <polygon
                                fill="#008df6"
                                points="455.35 .38 428.85 23.17 428.85 46.67 428.85 46.88 283.49 176.88 283.49 245.32 455.36 93.86 455.35 .38"
                            />
                            <polygon
                                fill="#008df6"
                                points="505.57 46.5 505.57 46.29 505.57 22.79 479.07 0 479.06 93.47 650.93 244.94 650.93 176.5 505.57 46.5"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </Icon>
);
