import { ColorModeScript } from '@chakra-ui/react';
import * as ReactDOM from 'react-dom/client';
import { App } from './App';
import { authenticatedUserService, entityServiceFactory, notificationsService } from './services';
import { configService } from './services/config';
import { requestServiceFactory } from './services/entities/request-service-factory';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

const renderApp = (rootComponent: any) => {
    root.render(
        <>
            <ColorModeScript />
            {rootComponent}
        </>
    );
};

let notificationsInterval;

configService
    .fetchConfig()
    .then(async (config) => {
        entityServiceFactory.init(config);

        requestServiceFactory.init(config);

        await authenticatedUserService.init(config);

        authenticatedUserService.authenticatedUser$.subscribe((user) => {
            if (user) {
                notificationsService.fetch();

                notificationsInterval = setInterval(() => {
                    notificationsService.fetch();
                }, 60000);
            } else {
                if (notificationsInterval) {
                    clearInterval(notificationsInterval);
                }
            }

            renderApp(<App />);
        });
    })
    .catch((err) => {
        return renderApp(<span>Failed to load application</span>);
    });
