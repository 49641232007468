import { Spinner, Text, TextProps } from '@chakra-ui/react';
import { formatCurrency } from '../../util';

interface CurrencyProps {
    amount: number | null | undefined;
    textProps?: TextProps;
}

export const Currency = (props: CurrencyProps) => {
    const { amount, textProps } = props;

    if (amount === null || amount === undefined) {
        return <Spinner size={'sm'} colorScheme={'blue'} />;
    }

    return <Text {...textProps}>{formatCurrency(amount)}</Text>;
};
