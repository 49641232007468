import { Alert, AlertDescription, AlertIcon, Skeleton, Spacer, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { StandardModal, StandardModalProps, WeFactorActionButton, WeFactorSecondaryButton } from '../../../components';
import { requestService } from '../../../services';
import { Building } from '../../../types';
import { formatDate, genericToast } from '../../../util';

interface TransferOwnershipDialogProps extends StandardModalProps {
    building: Building;
}

interface TransferRequest {
    id: string;
    expiryDate: string;
    hasExpired: boolean;
    requestDate: string;
    requestCode: string;
}

export const TransferOwnershipDialog = (props: TransferOwnershipDialogProps) => {
    const { building, disclosure, onClose } = props;

    const [isRequesting, setisRequesting] = useState(false);
    const [transferRequest, setTransferRequest] = useState<TransferRequest | 'ERROR' | 'LOADING' | 'NONE'>('LOADING');

    const toast = useToast();

    useEffect(() => {
        requestService
            .get<TransferRequest>(`buildings/{{buildingId}}/transfer-request`, {
                pathParams: {
                    buildingId: building.id
                }
            })
            .then(({ data }) => {
                if (data) {
                    setTransferRequest(data);
                } else {
                    setTransferRequest('NONE');
                }
            })
            .catch((error: any) => {
                setTransferRequest('ERROR');
            });
    }, [building.id]);

    const initiateTransfer = async () => {
        setisRequesting(true);

        await genericToast(
            toast,
            async () => {
                const response = await requestService.post<TransferRequest>(
                    `buildings/{{buildingId}}/transfer-request`,
                    {
                        pathParams: {
                            buildingId: building.id
                        }
                    }
                );

                setTransferRequest(response.data);
            },
            {
                title: 'Requesting transfer code',
                onSuccessTitle: 'Requested transfer code',
                onErrorTitle: 'Failed to request transfer code'
            }
        );

        setisRequesting(false);
    };

    const cancelTransfer = async () => {
        setisRequesting(true);

        await genericToast(
            toast,
            async () => {
                await requestService.delete<TransferRequest>(`buildings/{{buildingId}}/transfer-request`, {
                    pathParams: {
                        buildingId: building.id
                    }
                });

                disclosure.onClose();
            },
            {
                title: 'Cancelling transfer code',
                onSuccessTitle: 'Cancelled transfer code',
                onErrorTitle: 'Failed to cancel transfer code'
            }
        );

        setisRequesting(false);
    };

    const getContent = () => {
        if (transferRequest === 'LOADING') {
            return {
                content: (
                    <Skeleton isLoaded={false}>
                        <Alert status="error">
                            Failed to check existing status. Please close this dialog and try again.
                        </Alert>
                    </Skeleton>
                )
            };
        } else if (transferRequest === 'ERROR') {
            return {
                content: (
                    <Alert status="error">
                        Failed to check existing status. Please close this dialog and try again.
                    </Alert>
                )
            };
        } else if (transferRequest === 'NONE' || transferRequest.hasExpired) {
            return {
                content: (
                    <>
                        <Alert status="warning" mb={2}>
                            <AlertIcon />
                            <AlertDescription>
                                Transferring admin rights to someone else will transfer over to them this
                                building/estate and all associated accounting and correspondence information.
                            </AlertDescription>
                        </Alert>
                        <Text mb={2}>Click on 'Generate code' to generate a transfer code.</Text>
                        <Text mb={2}>Give the code to the person accepting the admin rights.</Text>
                        <Text>
                            They should create their own account and select "Transfer building/estate" from the accounts
                            menu in the top left side of the screen.
                        </Text>
                    </>
                ),
                footer: (
                    <>
                        <WeFactorSecondaryButton onClick={disclosure.onClose} isDisabled={isRequesting}>
                            Close
                        </WeFactorSecondaryButton>
                        <Spacer />
                        <WeFactorActionButton onClick={initiateTransfer} isDisabled={isRequesting}>
                            Generate code
                        </WeFactorActionButton>
                    </>
                )
            };
        } else {
            return {
                content: (
                    <>
                        <Alert status="warning" mb={2}>
                            <AlertIcon />
                            <AlertDescription>
                                Transferring admin rights to someone else will transfer over to them this
                                building/estate and all associated accounting and correspondence information.
                            </AlertDescription>
                        </Alert>
                        <Alert status="info" mb={2}>
                            Transfer code: {transferRequest.requestCode}
                        </Alert>
                        <Alert status="info" mb={2}>
                            Code expires on: {formatDate(transferRequest.expiryDate)}
                        </Alert>
                        <Text>
                            Provide the transfer code to the user you wish to transfer admin rights to. The user will
                            need to create their own WeFactor account and use the transfer code to move this building
                            and its information to their account.
                        </Text>
                    </>
                ),
                footer: (
                    <>
                        <WeFactorSecondaryButton onClick={disclosure.onClose} isDisabled={isRequesting}>
                            Close
                        </WeFactorSecondaryButton>
                        <Spacer />
                        <WeFactorActionButton onClick={cancelTransfer} isDisabled={isRequesting}>
                            Cancel code
                        </WeFactorActionButton>
                    </>
                )
            };
        }
    };

    const { content, footer } = getContent();

    return (
        <StandardModal
            disclosure={disclosure}
            modalProps={{
                size: {
                    base: 'full',
                    sm: 'md'
                }
            }}
            footer={footer}
            onClose={onClose}
            header={'Transfer admin rights'}
        >
            {content}
        </StandardModal>
    );
};
