import { FieldValues } from 'react-hook-form';
import { BaseInput, BaseInputProps } from './BaseInput';

export const PasswordField = <T extends FieldValues>(props: BaseInputProps<T>) => {
    return (
        <BaseInput
            {...props}
            inputProps={{
                type: 'password',
                ...props.inputProps
            }}
        />
    );
};
