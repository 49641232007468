import { ResponsiveValue, Table, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import * as CSS from 'csstype';
import React, { useEffect, useState } from 'react';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { IconType } from 'react-icons/lib';
import { MdOutlineDelete } from 'react-icons/md';
import { Account, Accounts, Properties, addressSorter } from '../../types';
import { formatStreetAddress } from '../../util';
import { WeFactorIconButton, WeFactorMinTh } from '../shared';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';
import { AccountOptionGroup, createAccountOptions } from './utilities/createAccountOptions';

type SelectAccountsFieldProps<T extends FieldValues> = BaseFormControlProps<T> & {
    accounts: Accounts;
    additionalButton?: {
        isDisabled?: boolean;
        tooltip: string;
        onClick: (account: Account) => void;
        buttonIcon: IconType;
    };
    additionalColumn?: {
        getHeading: () => string;
        getContent: (account: Account) => React.ReactElement;
    };
    defaultValues?: string[];
    properties: Properties;
};

export const SelectAccountsField = <T extends FieldValues>(props: SelectAccountsFieldProps<T>) => {
    const { accounts, additionalButton, additionalColumn, formHook, name, properties } = props;

    const [options, setOptions] = useState<AccountOptionGroup[]>([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [disableButtons, setDisableButtons] = useState(false);

    const selectedAccountIds: string[] = formHook.watch(name);

    useEffect(() => {
        setOptions(createAccountOptions(accounts, properties));
    }, [accounts, properties]);

    const addOwner = (option: { value: string }) => {
        const accountId = option.value;
        const account = accounts.getAccount(accountId);

        if (account) {
            const newValues = [...selectedAccountIds];
            newValues.push(accountId);
            formHook.setValue(name, newValues as any); // TODO
        }
    };

    const removeOwner = (value: string) => {
        const valueIndex = selectedAccountIds.indexOf(value);

        if (valueIndex !== -1) {
            const newValues = [...selectedAccountIds];
            newValues.splice(valueIndex, 1);
            formHook.setValue(name, newValues as any); // TODO
            setSelectedOption([]);
        }
    };

    const createTableRows = () => {
        if (selectedAccountIds.length === 0) {
            return (
                <Tr>
                    <Td>
                        <Text fontWeight={200}>No recipients have been selected</Text>
                    </Td>
                </Tr>
            );
        } else {
            const sorter = addressSorter();
            return selectedAccountIds
                .map((accountId) => {
                    const account = accounts.getAccount(accountId)!;
                    const property = properties.getProperty(account.propertyId)!;
                    return { account, property };
                })
                .sort((ap1, ap2) => sorter(ap1.property, ap2.property))
                .map(({ account, property }, index: number) => {
                    const props = {
                        margin: 0,
                        paddingY: 2,
                        paddingX: 0
                    };
                    const address = formatStreetAddress(property, true);
                    return (
                        <Tr key={account.id}>
                            <Td textAlign="left" {...props}>
                                <Text>{account.accountName}</Text>
                                <Text hideFrom={'sm'} mt={1}>
                                    {address}
                                </Text>
                            </Td>
                            <Td hideBelow={'sm'} {...props}>
                                <Text>{address}</Text>
                            </Td>
                            {additionalColumn && (
                                <Td textAlign="left" {...props}>
                                    {additionalColumn.getContent(account)}
                                </Td>
                            )}
                            <Td {...props}>
                                {additionalButton && (
                                    <WeFactorIconButton
                                        buttonIcon={additionalButton.buttonIcon}
                                        tooltipText={additionalButton.tooltip}
                                        iconButtonProps={{
                                            'aria-label': additionalButton.tooltip,
                                            isDisabled: disableButtons || additionalButton.isDisabled,
                                            marginRight: 2,
                                            onClick: async () => {
                                                try {
                                                    setDisableButtons(true);
                                                    await additionalButton.onClick(account);
                                                } catch (e) {
                                                    console.error(e);
                                                } finally {
                                                    setDisableButtons(false);
                                                }
                                            }
                                        }}
                                    />
                                )}
                                <WeFactorIconButton
                                    buttonIcon={MdOutlineDelete}
                                    tooltipText="Remove account"
                                    iconButtonProps={{
                                        'aria-label': 'Remove account',
                                        isDisabled: disableButtons,
                                        onClick: async () => {
                                            try {
                                                setDisableButtons(true);
                                                await removeOwner(account.id);
                                            } catch (e) {
                                                console.error(e);
                                            } finally {
                                                setDisableButtons(false);
                                            }
                                        }
                                    }}
                                />
                            </Td>
                        </Tr>
                    );
                });
        }
    };

    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        const textProps = {
            color: '#84818A',
            fontSize: 'sm',
            fontWeight: 600,
            letterSpacing: 'inherit',
            textAlign: 'left' as ResponsiveValue<CSS.Property.TextAlign>,
            textTransform: 'inherit' as ResponsiveValue<CSS.Property.TextTransform>
        };

        return (
            <>
                <Table size="sm">
                    <Thead fontFamily={'inherit'}>
                        <Tr>
                            <WeFactorMinTh {...textProps}>Account</WeFactorMinTh>
                            <WeFactorMinTh hideBelow={'sm'} {...textProps}>
                                Property
                            </WeFactorMinTh>
                            {additionalColumn && (
                                <WeFactorMinTh {...textProps}>{additionalColumn.getHeading()}</WeFactorMinTh>
                            )}
                            <WeFactorMinTh width={additionalButton ? '88px' : '40px'}>&nbsp;</WeFactorMinTh>
                        </Tr>
                    </Thead>
                    <Tbody>{createTableRows()}</Tbody>
                </Table>
                <Select
                    closeMenuOnSelect={true}
                    inputId={name}
                    isOptionDisabled={(o: any) => selectedAccountIds.includes(o.value)}
                    onChange={addOwner}
                    options={options}
                    placeholder="Select another account..."
                    ref={controller.field.ref}
                    value={selectedOption}
                />
            </>
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
