import { Text, UseDisclosureReturn } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { Observable } from 'rxjs';
import { PageLoadingModal } from '../../../components';
import { correspondencesService, factorDivisionVersionsService } from '../../../services';
import {
    Accounts,
    Building,
    Correspondence,
    CorrespondenceType,
    DivisionVersionWithDivisions,
    Properties
} from '../../../types';
import { CorrespondenceWizardForLetter } from './CorrespondenceWizardForLetter';
import { CorrespondenceWizardForQuote } from './CorrespondenceWizardForQuote';
import { CorrespondenceWizardForSharedCosts } from './CorrespondenceWizardForSharedCosts';
import { CorrespondenceTemplate } from './components/Templates';

export interface CorrespondenceWizardProps {
    accounts: Accounts;
    building: Building;
    properties: Properties;
    disclosure: UseDisclosureReturn;
}

export const CorrespondenceWizard = (
    props: CorrespondenceWizardProps & {
        correspondenceTemplate?: CorrespondenceTemplate;
        correspondenceType: CorrespondenceType;
    }
) => {
    const { accounts, building, correspondenceType, disclosure, correspondenceTemplate, properties } = props;

    const onClose = (correspondence?: Correspondence) => {
        if (correspondence) correspondencesService.upsertEntities(correspondence);
    };

    const [divisionVersions] = useObservable(
        factorDivisionVersionsService.entities$ as Observable<DivisionVersionWithDivisions[]>
    );
    const [divisionVersionsLoaded] = useObservable(factorDivisionVersionsService.loaded$);

    useEffect(() => {
        factorDivisionVersionsService.fetch({
            pathParams: {
                buildingId: building.id
            }
        });

        return () => {
            factorDivisionVersionsService.removeAllEntities();
        };
    }, [building.id]);

    if (!divisionVersionsLoaded || !divisionVersions.length) {
        return <PageLoadingModal />;
    }

    switch (correspondenceType) {
        case CorrespondenceType.COMMON_REPAIR_QUOTE: {
            return (
                <CorrespondenceWizardForQuote
                    accounts={accounts}
                    building={building}
                    disclosure={disclosure}
                    divisionVersions={divisionVersions}
                    properties={properties}
                    onClose={onClose}
                />
            );
        }
        case CorrespondenceType.LETTER: {
            return (
                <CorrespondenceWizardForLetter
                    accounts={accounts}
                    building={building}
                    disclosure={disclosure}
                    properties={properties}
                    correspondenceTemplate={correspondenceTemplate}
                    onClose={onClose}
                />
            );
        }
        case CorrespondenceType.SHARED_COSTS: {
            return (
                <CorrespondenceWizardForSharedCosts
                    accounts={accounts}
                    building={building}
                    disclosure={disclosure}
                    divisionVersions={divisionVersions}
                    properties={properties}
                    onClose={onClose}
                />
            );
        }
        default: {
            return <Text>Correspondence type not recognised</Text>;
        }
    }
};
