import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    IconButton,
    List,
    ListItem,
    Spacer,
    Text,
    useToast
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { FieldPath, FieldValues, UseControllerReturn } from 'react-hook-form';
import { MdOutlineDelete } from 'react-icons/md';
import { factorDocumentsService, fileUploadService } from '../../services';
import { Building, Document, SignedDocument } from '../../types';
import { genericToast } from '../../util';
import { BaseFormControl, BaseFormControlProps } from './BaseFormControl';

export interface DocumentsFieldProps<T extends FieldValues> extends BaseFormControlProps<T> {
    building: Building;
}

export const DocumentsField = <T extends FieldValues>(props: DocumentsFieldProps<T>) => {
    const { building } = props;

    const hasActiveSubscription = useMemo(() => building.subscriptionStatus.includes('ACTIVE'), [building]);

    const [isUploadingFile, setUploadingFile] = useState(false);

    const toast = useToast();

    const render = (controller: UseControllerReturn<T, FieldPath<T>>) => {
        const { onChange, value } = controller.field;
        const { isSubmitting, isValid } = controller.formState;

        const uploadButtonDisabled = isSubmitting || !isValid || !hasActiveSubscription;

        const uploadDocument = async (event: any) => {
            setUploadingFile(true);

            try {
                const fileForUpload: File = event.target.files[0];

                await genericToast(
                    toast,
                    async () => {
                        const result: SignedDocument = await factorDocumentsService.create({
                            pathParams: {
                                buildingId: building.id
                            },
                            body: {
                                fileName: fileForUpload.name,
                                fileSize: fileForUpload.size,
                                fileType: fileForUpload.type
                            }
                        });

                        const { document, signedUrl } = result;

                        await fileUploadService.upload(signedUrl, fileForUpload);

                        onChange([document, ...value]);
                    },
                    {
                        title: 'Uploading document',
                        onSuccessTitle: 'Successfully uploaded document',
                        onErrorTitle: 'Failed to upload document'
                    }
                );
            } catch (error) {
                console.error('failed to upload file', error);
            } finally {
                setUploadingFile(false);
            }
        };

        const viewDocument = async (document: Document) => {
            const result: SignedDocument = await factorDocumentsService.fetch({
                pathParams: {
                    buildingId: building.id,
                    id: document.id
                }
            });

            const { signedUrl } = result;

            if (result) {
                window.open(signedUrl);
            }
        };

        const removeDocument = async (document: Document) => {
            const newDocuments = [...value];
            const indexOf = newDocuments.indexOf(document);

            if (indexOf !== -1) {
                await factorDocumentsService.delete({
                    id: document.id,
                    pathParams: {
                        buildingId: building.id
                    }
                });
                newDocuments.splice(indexOf, 1);
                onChange(newDocuments);
            }
        };

        return (
            <Box mt={4}>
                {hasActiveSubscription ? (
                    <>
                        <Button isLoading={isUploadingFile} isDisabled={uploadButtonDisabled} mb={4}>
                            <label htmlFor="imgupload">
                                Upload attachment
                                <input
                                    id="imgupload"
                                    type="file"
                                    hidden
                                    disabled={uploadButtonDisabled}
                                    onChange={uploadDocument}
                                />
                            </label>
                        </Button>
                    </>
                ) : (
                    <Alert status="info" mb={2} maxWidth={'300px'}>
                        <AlertIcon />
                        <AlertDescription fontSize={'sm'}>
                            <Text mb={2}>
                                Uploading attachments is a premium feature requiring a subscription. Start a free trial
                                subscription from the Settings page.
                            </Text>
                        </AlertDescription>
                    </Alert>
                )}
                <List spacing={1}>
                    {value.map((document: Document) => (
                        <ListItem key={document.id}>
                            <Flex alignItems={'center'}>
                                <Text
                                    textDecoration={'underline'}
                                    _hover={{ cursor: 'pointer' }}
                                    onClick={() => viewDocument(document)}
                                >
                                    {document.fileName}
                                </Text>
                                <Spacer />
                                <IconButton
                                    aria-label="Remove attachment"
                                    icon={<MdOutlineDelete />}
                                    onClick={() => {
                                        removeDocument(document);
                                    }}
                                ></IconButton>
                            </Flex>
                        </ListItem>
                    ))}
                </List>
            </Box>
        );
    };

    return <BaseFormControl {...props} render={render} />;
};
