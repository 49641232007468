import { Box, Container, Flex, Link, Skeleton, Stack, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { MdOutlineArrowDropDownCircle } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Heading2, HelpKey, LINK_BLUE, StandardDivider, StandardPage, WeFactorActionButton } from '../../components';
import {
    forumBoardService,
    forumMessageService,
    forumPostService,
    forumPostViewService,
    userBuildingsService
} from '../../services';
import { CreateForumMessage, ForumBreadcrumb, ForumMessageBox } from './components';

export const ForumPostPage = () => {
    const { buildingId, forumBoardId, forumPostId } = useParams();

    const disclosure = useDisclosure();

    const [showReply, setShowReply] = useState(false);

    const [building] = useObservable(userBuildingsService.activeEntity$);
    const [forumBoard] = useObservable(forumBoardService.activeEntity$);
    const [forumPost] = useObservable(forumPostService.activeEntity$);

    const [forumMessages] = useObservable(forumMessageService.entities$);
    const [forumMessagesLoaded] = useObservable(forumMessageService.loaded$);

    useEffect(() => {
        if (!buildingId || !forumBoardId || !forumPostId) return;

        if (forumBoardService.hasEntityId(forumBoardId)) {
            forumBoardService.setActiveEntityId(forumBoardId);
            forumBoardService.fetch({ pathParams: { buildingId: buildingId }, retainEntities: true });
        } else {
            forumBoardService
                .fetch({ pathParams: { buildingId: buildingId } })
                .then(() => forumBoardService.setActiveEntityId(forumBoardId));
        }

        if (forumPostService.hasEntityId(forumPostId)) {
            forumPostService.setActiveEntityId(forumPostId);
            forumPostService.fetch({
                pathParams: { buildingId: buildingId, forumBoardId: forumBoardId },
                retainEntities: true
            });
        } else {
            forumPostService
                .fetch({ pathParams: { buildingId: buildingId, forumBoardId: forumBoardId } })
                .then(() => forumPostService.setActiveEntityId(forumPostId));
        }

        forumMessageService
            .fetch({
                pathParams: {
                    buildingId: buildingId,
                    forumPostId: forumPostId
                }
            })
            .then(() => {
                forumPostViewService.update({
                    pathParams: {
                        buildingId: buildingId,
                        forumBoardId: forumBoardId
                    },
                    params: {
                        forumPostId: forumPostId
                    }
                });
            });

        return forumPostService.clearActiveEntity;
    }, [buildingId, forumPostId, forumBoardId]);

    const pageHeader = useBreakpointValue({
        base: 'Forums',
        md: `Forums: ${building?.name}`
    });

    return (
        <StandardPage
            action={
                forumBoard && forumBoard.hasWritePermission && !forumPost ? (
                    <WeFactorActionButton onClick={disclosure.onOpen}>New post</WeFactorActionButton>
                ) : undefined
            }
            helpKey={HelpKey.FORUMS}
            title={pageHeader}
        >
            <Container height={'100%'}>
                <Skeleton isLoaded={!!forumBoard && !!forumPost}>
                    {forumBoard && forumPost && (
                        <>
                            <Heading2>{forumPost.subject}</Heading2>
                            <ForumBreadcrumb
                                items={[
                                    { text: 'Forums', link: `/buildings/${buildingId}/forums` },
                                    {
                                        text: forumBoard.name,
                                        link: `/buildings/${buildingId}/forums/${forumBoard.id}`
                                    }
                                ]}
                            />
                            {forumMessagesLoaded && (
                                <Skeleton isLoaded={forumMessagesLoaded}>
                                    <>
                                        <Stack divider={<StandardDivider />} gap={1}>
                                            {forumMessages.map((forumMessage) => (
                                                <ForumMessageBox
                                                    buildingId={buildingId!}
                                                    forumBoard={forumBoard}
                                                    forumMessage={forumMessage}
                                                    forumPost={forumPost}
                                                />
                                            ))}
                                        </Stack>
                                        <Box marginTop={4}>
                                            {showReply ? (
                                                <CreateForumMessage
                                                    buildingId={buildingId!}
                                                    forumBoard={forumBoard}
                                                    forumPost={forumPost}
                                                    onClose={() => setShowReply(false)}
                                                />
                                            ) : (
                                                <Link color={LINK_BLUE} onClick={() => setShowReply(true)}>
                                                    <Flex alignItems={'center'}>
                                                        <MdOutlineArrowDropDownCircle />
                                                        <Text marginLeft={1} paddingBottom={'4px'}>
                                                            write response
                                                        </Text>
                                                    </Flex>
                                                </Link>
                                            )}
                                        </Box>
                                    </>
                                </Skeleton>
                            )}
                        </>
                    )}
                </Skeleton>
            </Container>
        </StandardPage>
    );
};
