import { Icon, Table, Tbody, Td, Text, Th, Tooltip, Tr, useBreakpointValue } from '@chakra-ui/react';
import { TableMenu, TableMenuItem, TdButton, ThTableMenu, WeFactorThead } from '../../../components';
import { Correspondence } from '../../../types';
import { formatCorrespondenceType, formatDateShortMonth, getCorrespondenceIcon } from '../../../util';

interface CorrespondenceTableProps {
    onSendCorrespondence?: (correspondence: Correspondence) => void;
    onViewCorrespondence?: (correspondence: Correspondence) => void;
    correspondences: Correspondence[];
}

export const CorrespondenceTable = (props: CorrespondenceTableProps) => {
    const { correspondences, onSendCorrespondence, onViewCorrespondence } = props;

    const onSendCorrespondenceClick = (correspondence: Correspondence) => {
        if (onSendCorrespondence) {
            onSendCorrespondence(correspondence);
        }
    };

    const onViewCorrespondenceClick = (correspondence: Correspondence) => {
        if (onViewCorrespondence) {
            onViewCorrespondence(correspondence);
        }
    };

    const renderHeaderRow = useBreakpointValue(
        {
            base: () => (
                <>
                    <Th>Subject</Th>
                </>
            ),
            md: () => (
                <>
                    <Th width="110px">Date</Th>
                    <Th textAlign={'center'} width={'65px'}>
                        Type
                    </Th>
                    <Th>Subject</Th>
                </>
            )
        },
        { fallback: 'md', ssr: false }
    )!;

    const getBaseValues = (correspondence: Correspondence) => {
        return {
            clickProps: {
                _hover: { cursor: 'pointer' },
                onClick: () => onViewCorrespondenceClick(correspondence)
            },
            date: formatDateShortMonth(correspondence.createdDate)
        };
    };

    const getMediumValues = (correspondence: Correspondence) => {
        return {
            ...getBaseValues(correspondence),
            icon: getCorrespondenceIcon(correspondence),
            name: formatCorrespondenceType(correspondence.type)
        };
    };

    const renderCorrespondenceRow = useBreakpointValue(
        {
            base: (correspondence: Correspondence) => {
                const { clickProps, date } = getBaseValues(correspondence);
                return (
                    <>
                        <Td key={'subject'} {...clickProps}>
                            <Text mb={1.5}>{correspondence.subject}</Text>
                            <Text fontWeight={300}>{date}</Text>
                        </Td>
                    </>
                );
            },
            md: (correspondence: Correspondence) => {
                const { clickProps, date, icon, name } = getMediumValues(correspondence);
                return (
                    <>
                        <Td {...clickProps} key={'date'}>
                            {date}
                        </Td>
                        <Td {...clickProps} key={'icon'} textAlign={'center'}>
                            <Tooltip label={name}>
                                <span>
                                    <Icon as={icon} />
                                </span>
                            </Tooltip>
                        </Td>
                        <Td {...clickProps} key={'subject'} overflow={'hidden'} textOverflow={'ellipsis'}>
                            <Text mb={[1, 0]}>{correspondence.subject}</Text>
                        </Td>
                    </>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <Table size={'sm'}>
            <WeFactorThead>
                <Tr>
                    {renderHeaderRow()}
                    <ThTableMenu>&nbsp;</ThTableMenu>
                </Tr>
            </WeFactorThead>
            <Tbody>
                {correspondences.map((correspondence) => (
                    <Tr key={correspondence.id}>
                        {renderCorrespondenceRow(correspondence)}
                        <TdButton>
                            <TableMenu>
                                <TableMenuItem
                                    menuItemProps={{
                                        'aria-label': 'Send correspondence',
                                        onClick: () => onSendCorrespondenceClick(correspondence)
                                    }}
                                >
                                    Send correspondence
                                </TableMenuItem>
                            </TableMenu>
                        </TdButton>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
