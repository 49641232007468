import { Box, Button, Container, Icon, IconButton, Link, Stack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import {
    MdOutlineDownload,
    MdOutlineEdit,
    MdOutlineEmail,
    MdOutlineGroups,
    MdOutlineMenu,
    MdOutlinePersonAdd,
    MdOutlinePersonRemove
} from 'react-icons/md';
import { Heading2, PremiumFeatureCard, StandardDivider } from '../../shared';

enum Section {
    ADDING_NEW_ACCOUNTS,
    ADDING_NEW_PROPERTIES,
    DELETING_ACCOUNTS,
    DELETING_PROPERTIES,
    DOWNLOADING_CORRESPONDENCE,
    DOWNLOADING_STATEMENTS,
    EDITING_ACCOUNTS,
    GETTING_STARTED,
    SELECTING_ACCOUNTS,
    SENDING_CORRESPONDENCE,
    SENDING_STATEMENTS
}

export const PropertiesHelp = () => {
    const [section, setSection] = useState<Section>(Section.GETTING_STARTED);

    const getActiveSection = () => {
        switch (section) {
            case Section.ADDING_NEW_ACCOUNTS:
                return <AddingNewAccounts setSection={setSection} />;
            case Section.ADDING_NEW_PROPERTIES:
                return <AddingNewProperties setSection={setSection} />;
            case Section.DELETING_ACCOUNTS:
                return <DeletingAccounts setSection={setSection} />;
            case Section.DELETING_PROPERTIES:
                return <DeletingProperties setSection={setSection} />;
            case Section.EDITING_ACCOUNTS:
                return <EditingAccounts setSection={setSection} />;
            case Section.DOWNLOADING_CORRESPONDENCE:
                return <DownloadingCorrespondence setSection={setSection} />;
            case Section.DOWNLOADING_STATEMENTS:
                return <DownloadingStatements setSection={setSection} />;
            case Section.GETTING_STARTED:
                return <GettingStarted setSection={setSection} />;
            case Section.SELECTING_ACCOUNTS:
                return <AccessingPreviousAccounts setSection={setSection} />;
            case Section.SENDING_CORRESPONDENCE:
                return <SendingCorrespondence setSection={setSection} />;
            case Section.SENDING_STATEMENTS:
                return <SendingStatements setSection={setSection} />;
            default:
                return <></>;
        }
    };

    const indent = [4, 6];

    return (
        <Stack paddingX={[2, 0]} direction={['column', 'row']}>
            <Box borderRightWidth={[0, '1px']} paddingRight={indent} width={'fit-content'}>
                <Heading2>Help Contents</Heading2>
                <VStack alignItems={'normal'}>
                    <Link color="teal" onClick={() => setSection(Section.GETTING_STARTED)}>
                        Getting started
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.ADDING_NEW_ACCOUNTS)}>
                        Adding new accounts
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.SELECTING_ACCOUNTS)}>
                        Selecting accounts
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.EDITING_ACCOUNTS)}>
                        Editing accounts
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.DELETING_ACCOUNTS)}>
                        Deleting accounts
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.ADDING_NEW_PROPERTIES)}>
                        Adding new properties
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.DELETING_PROPERTIES)}>
                        Deleting properties
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.DOWNLOADING_STATEMENTS)}>
                        Downloading statements
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.SENDING_STATEMENTS)}>
                        Sending statements
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.DOWNLOADING_CORRESPONDENCE)}>
                        Downloading correspondence
                    </Link>
                    <Link color="teal" onClick={() => setSection(Section.SENDING_CORRESPONDENCE)}>
                        Sending correspondence
                    </Link>
                </VStack>
            </Box>
            <Container paddingX={0}>
                <Stack gap={2}>{getActiveSection()}</Stack>
            </Container>
        </Stack>
    );
};

type SectionProps = { setSection: (section: Section) => void };

const GettingStarted = (props: SectionProps) => {
    return (
        <>
            <Heading2>Getting started with properties</Heading2>
            <Text>This page is where you can manage properties and accounts associated with your building/estate.</Text>
        </>
    );
};

const AddingNewAccounts = (props: SectionProps) => {
    return (
        <>
            <Heading2>Adding new accounts</Heading2>
            <Text>
                When a property is sold it is necessary to add a new account for the new owner. New accounts are created
                with a starting balance of £0
                <sup>†</sup> and no items in their received correspondence list.
            </Text>
            <Text>
                To add a new account to a property select the property from the properties list and click on the "Add
                new account" button
                <sup>‡</sup> from the sidebar which opens up.
            </Text>
            <Text>
                The start date should be set to the purchase date because when you add common charges to accounts we use
                the start date to preselect accounts that were active on the charge date.
            </Text>
            <StandardDivider />
            <Text>
                <sup>†</sup> If starting with a non-zero balance remember to adjust the account balance from the
                Accounting tab
            </Text>
            <Text>
                <sup>‡</sup>{' '}
                <Button
                    as={IconButton}
                    icon={<Icon as={MdOutlinePersonAdd} />}
                    variant={'outline'}
                    margin={1}
                    padding={1}
                />
            </Text>
        </>
    );
};

const AccessingPreviousAccounts = (props: SectionProps) => {
    return (
        <>
            <Heading2>Selecting accounts</Heading2>
            <Text>
                To select an account select the appropriate property from the properties list. This will open the
                accounts sidebar for the selected property.
            </Text>
            <Text>
                By default the current account will be selected. To select an account of a previous owner click on the
                "Select other account" button <sup>†</sup>. This button will be disabled if there are no previous
                accounts.
            </Text>
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button
                    as={IconButton}
                    icon={<Icon as={MdOutlineGroups} />}
                    variant={'outline'}
                    margin={1}
                    padding={1}
                />
            </Text>
        </>
    );
};

const AddingNewProperties = (props: SectionProps) => {
    return (
        <>
            <Heading2>Adding more properties</Heading2>
            <Text>
                Generally you won't ever have to add more properties after the original set up but sometimes developers
                will divide properties and sell them as separate properties. In these circumstances you will need to add
                a new property for the newly formed property.
            </Text>
            <Text>
                To add new properties click on the "Actions" menu at the top of the page and select the "Add more
                properties" option.
            </Text>
            <Text>
                Note that if you have configured how costs are shared then this will have to be configured again to take
                into consideration the new number of properties.
            </Text>
        </>
    );
};

const EditingAccounts = (props: SectionProps) => {
    return (
        <>
            <Heading2>Editing accounts</Heading2>
            <Text>
                To edit an account{' '}
                <Link color="blue.500" onClick={() => props.setSection(Section.SELECTING_ACCOUNTS)}>
                    select the account
                </Link>{' '}
                and then click on the "Edit account" button
                <sup>†</sup> from the sidebar which opens up.
            </Text>
            <Text>
                You can set a different contact address from the property address if required. The contact address will
                appear at the top of all account statements and correspondence.
            </Text>
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button as={IconButton} icon={<Icon as={MdOutlineEdit} />} variant={'outline'} margin={1} padding={1} />{' '}
            </Text>
        </>
    );
};

const DeletingAccounts = (props: SectionProps) => {
    return (
        <>
            <Heading2>Deleting accounts</Heading2>
            <Text>
                We do not recommend that you delete accounts however we understand that accounts can created be by
                mistake.
            </Text>
            <Text>
                To delete an account{' '}
                <Link color="blue.500" onClick={() => props.setSection(Section.SELECTING_ACCOUNTS)}>
                    select the account
                </Link>{' '}
                and then click on the "Delete account" button
                <sup>†</sup> from the sidebar which opens up.
            </Text>
            <Text>
                Deleted accounts will no longer be available for selection when adding new accounting entries or
                correspondence.
            </Text>
            <Text>
                If deleted accounts have accounting entries or correspondence then the accounts will still appear
                against those entries or correspondence to maintain the integrity of the data in the system.
            </Text>
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button
                    as={IconButton}
                    icon={<Icon as={MdOutlinePersonRemove} />}
                    variant={'outline'}
                    margin={1}
                    padding={1}
                />
            </Text>
        </>
    );
};

const DeletingProperties = (props: SectionProps) => {
    return (
        <>
            <Heading2>Deleting properties</Heading2>
            <Text>
                We do not recommend that you delete properties however we understand that properties can added by
                mistake or be physically demolished in the real world.
            </Text>
            <Text>
                To delete a property click the menu button<sup>†</sup> against the property to delete in the properties
                list and then click on the "Delete property".
            </Text>
            <Text>
                Deleted properties and their associated accounts will no longer be available for selection when adding
                new accounting entries or correspondence.
            </Text>
            <Text>
                If deleted properties and their associated accounts have accounting entries or correspondence then the
                accounts will still appear against those entries or correspondence to maintain the integrity of the data
                in the system.
            </Text>
            <Text>
                Note that if you have configured how costs are shared then this will have to be configured again to take
                into consideration the new number of properties.
            </Text>
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button as={IconButton} icon={<Icon as={MdOutlineMenu} />} variant={'outline'} margin={1} padding={1} />{' '}
            </Text>
        </>
    );
};

const DownloadingStatements = (props: SectionProps) => {
    return (
        <>
            <Heading2>Downloading statements</Heading2>
            <Text>
                You can download account statements for accounts for any previous month. Statements contain details of
                charges taken and payments made for the selected month.
            </Text>
            <Text>
                To download an account statement{' '}
                <Link color="blue.500" onClick={() => props.setSection(Section.SELECTING_ACCOUNTS)}>
                    select the account
                </Link>{' '}
                and then select the "Accounting" tab from the sidebar which opens up before clicking on the "Download
                statement"<sup>†</sup> button to open the "Send statement" dialog window.
            </Text>
            <Text>
                Note that this button will not be available if there are no accounting entries for the selected account.
            </Text>
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button
                    as={IconButton}
                    icon={<Icon as={MdOutlineDownload} />}
                    variant={'outline'}
                    margin={1}
                    padding={1}
                />
            </Text>
        </>
    );
};

const SendingStatements = (props: SectionProps) => {
    return (
        <>
            <Heading2>Sending statements</Heading2>
            <Text>
                You can send account statements to accounts for any previous month. Statements contain details of
                charges taken and payments made for the selected month.
            </Text>
            <Text>
                To send an account statement{' '}
                <Link color="blue.500" onClick={() => props.setSection(Section.SELECTING_ACCOUNTS)}>
                    select the account
                </Link>{' '}
                and then select the "Accounting" tab from the sidebar which opens up before clicking on the "Send
                statement"<sup>†</sup> button to open the "Send statement" dialog window.
            </Text>
            <Text>
                Note that this button will not be available if there are no accounting entries for the selected account.
            </Text>
            <PremiumFeatureCard />
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button
                    as={IconButton}
                    icon={<Icon as={MdOutlineEmail} />}
                    variant={'outline'}
                    margin={1}
                    padding={1}
                />
            </Text>
        </>
    );
};

const DownloadingCorrespondence = (props: SectionProps) => {
    return (
        <>
            <Heading2>Downloading correspondence</Heading2>
            <Text>You can download previous correspondence for accounts.</Text>
            <Text>
                To download an item of correspondence{' '}
                <Link color="blue.500" onClick={() => props.setSection(Section.SELECTING_ACCOUNTS)}>
                    select the account
                </Link>{' '}
                and then select the "Correspondence" tab from the sidebar which opens up before clicking on the
                "Download correspondence"<sup>†</sup> button next to the item of correspondence to download.
            </Text>
            <Text>
                Note that this button will not be available if there are no items of correspondence for the selected
                account.
            </Text>
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button
                    as={IconButton}
                    icon={<Icon as={MdOutlineDownload} />}
                    variant={'outline'}
                    margin={1}
                    padding={1}
                />
            </Text>
        </>
    );
};

const SendingCorrespondence = (props: SectionProps) => {
    return (
        <>
            <Heading2>Sending correspondence</Heading2>
            <Text>You can send previous correspondence to accounts.</Text>
            <Text>
                To send an item of correspondence{' '}
                <Link color="blue.500" onClick={() => props.setSection(Section.SELECTING_ACCOUNTS)}>
                    select the account
                </Link>{' '}
                and then select the "Correspondence" tab from the sidebar which opens up before clicking on the "Send
                correspondence"<sup>†</sup> button next to the item of correspondence to send.
            </Text>
            <Text>
                Note that this button will not be available if there are no items of correspondence for the selected
                account.
            </Text>
            <PremiumFeatureCard />
            <StandardDivider />
            <Text>
                <sup>†</sup>{' '}
                <Button
                    as={IconButton}
                    icon={<Icon as={MdOutlineEmail} />}
                    variant={'outline'}
                    margin={1}
                    padding={1}
                />
            </Text>
        </>
    );
};
