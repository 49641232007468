import { Grid, GridItem, Skeleton, Td, Text, Th, useBreakpointValue, useToast } from '@chakra-ui/react';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useMemo, useState } from 'react';
import { NamedValue, StandardModalProps } from '../../../components';
import { commonRepairQuoteSharesService } from '../../../services';
import {
    Account,
    Accounts,
    Building,
    CommonRepairQuote,
    CorrespondenceAccount,
    DivisionMethod,
    DivisionVersion,
    Document,
    Properties,
    getDivisionMethodName
} from '../../../types';
import { formatCurrency, formatDate, updateToast } from '../../../util';
import { CorrespondenceModal } from './components/CorrespondenceModal';
import { CorrespondenceTableMenuForResponse } from './components/CorrespondenceTableMenu';
import { CorrespondenceRowValues } from './components/CorrespondenceTypes';
import { Documents } from './components/Documents';
import { PublishedDate } from './components/PublishedDate';
import { RecipientsTable } from './components/RecipientsTable';
import { ShareCalculator } from './components/ShareCalculator';
import { Subject } from './components/Subject';

export const CorrespondenceViewForQuote = (props: {
    accounts: Accounts;
    building: Building;
    correspondence: CommonRepairQuote;
    correspondenceAccounts: CorrespondenceAccount[];
    divisionVersions: DivisionVersion[];
    documents: Document[];
    properties: Properties;
    standardModalProps: StandardModalProps;
}) => {
    const {
        accounts,
        building,
        correspondence,
        correspondenceAccounts,
        divisionVersions,
        documents,
        properties,
        standardModalProps
    } = props;

    const divisionVersion = useMemo(
        () => divisionVersions.find((divVer) => divVer.id === correspondence.divisionVersionId)!,
        [correspondence, divisionVersions]
    );

    const [shares] = useObservable(commonRepairQuoteSharesService.entities$);
    const [sharesLoaded] = useObservable(commonRepairQuoteSharesService.loaded$);

    const [acceptedPercent, setAcceptedPercent] = useState<string>('');

    const toast = useToast();

    useEffect(() => {
        commonRepairQuoteSharesService.fetch({
            pathParams: {
                buildingId: building.id,
                correspondenceId: correspondence.id
            }
        });
    }, [building.id, correspondence.id]);

    useEffect(() => {
        if (shares && sharesLoaded) {
            const calculator = new ShareCalculator(
                accounts.getAccounts({
                    filters: [(account) => shares.some((share) => share.accountId === account.id)]
                })
            );
            setAcceptedPercent(calculator.calculateAcceptedByDivisionVersion(shares, divisionVersion));
        }
    }, [accounts, divisionVersion, shares, sharesLoaded]);

    const updateResponse = async (account: Account, response: boolean | null) => {
        const commonRepairQuoteShare = shares?.find((share) => share.accountId === account.id);

        if (!commonRepairQuoteShare) return;

        await updateToast(toast, async () => {
            await commonRepairQuoteSharesService.update({
                pathParams: {
                    buildingId: building.id,
                    correspondenceId: correspondence.id,
                    id: commonRepairQuoteShare.id
                },
                body: {
                    response: response
                }
            });
        });
    };

    const isSharedEqually = divisionVersion.divisionMethod !== DivisionMethod.EQUAL_SHARE;
    const quotedCost = formatCurrency(correspondence.amount);
    const responseDeadline = formatDate(correspondence.responseDeadline);
    const shareMethod = `By ${getDivisionMethodName(divisionVersion.divisionMethod).toLocaleLowerCase()}`;

    const renderHeaderRow = useBreakpointValue(
        {
            base: () => (
                <Th key={'approved'} paddingX={0} paddingY={0} textAlign={'center'} width={'40px'}>
                    App.
                </Th>
            ),
            md: () => (
                <>
                    <Th key={'share'} textAlign={'center'}>
                        Share
                    </Th>
                    <Th key={'approved'} paddingY={0} textAlign={'center'} width={'40px'}>
                        Approved
                    </Th>
                </>
            )
        },
        { fallback: 'md', ssr: false }
    )!;

    const renderCorrespondenceRow = useBreakpointValue(
        {
            base: (values: CorrespondenceRowValues) => {
                const share = shares?.find((share) => share.accountId === values.account.id);

                return (
                    <>
                        <Td key={'response'} paddingX={0} paddingY={0} textAlign={'center'} width={'40px'}>
                            <Skeleton isLoaded={!!share}>
                                <CorrespondenceTableMenuForResponse
                                    share={share}
                                    values={values}
                                    updateResponse={updateResponse}
                                />
                            </Skeleton>
                        </Td>
                    </>
                );
            },
            md: (values: CorrespondenceRowValues) => {
                const share = shares?.find((share) => share.accountId === values.account.id);

                return (
                    <>
                        <Td key={'share'} textAlign={'center'}>
                            {formatCurrency(share?.amount)}
                        </Td>
                        <Td key={'response'} paddingY={0} textAlign={'center'} width={'40px'}>
                            <Skeleton isLoaded={!!share}>
                                <CorrespondenceTableMenuForResponse
                                    share={share}
                                    values={values}
                                    updateResponse={updateResponse}
                                />
                            </Skeleton>
                        </Td>
                    </>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    const renderGrid = useBreakpointValue(
        {
            base: () => {
                return (
                    <Grid gap={4} marginBottom={4}>
                        <GridItem>
                            <Subject correspondence={correspondence} />
                        </GridItem>
                        <GridItem>
                            <PublishedDate correspondence={correspondence} />
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Response deadline'}>{responseDeadline}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Quoted cost'}>{quotedCost}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'How is cost shared?'}>{shareMethod}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Approved by'}>
                                {acceptedPercent}%<sup>*</sup> of owners
                            </NamedValue>
                            <Text marginTop={2}>
                                <sup>*</sup>{' '}
                                {isSharedEqually
                                    ? "each owner's response is weighted the same as their share of the cost"
                                    : "each owner's response is of equal value"}
                            </Text>
                        </GridItem>
                        {documents.length > 0 && (
                            <GridItem>
                                <Documents correspondence={correspondence} building={building} documents={documents} />
                            </GridItem>
                        )}
                    </Grid>
                );
            },
            md: () => {
                return (
                    <Grid gap={4} marginBottom={4} templateColumns="repeat(3, 1fr)">
                        <GridItem colSpan={2}>
                            <Subject correspondence={correspondence} />
                        </GridItem>
                        <GridItem rowSpan={2}>
                            <Documents correspondence={correspondence} building={building} documents={documents} />
                        </GridItem>
                        <GridItem>
                            <PublishedDate correspondence={correspondence} />
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Response deadline'}>{responseDeadline}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'Quoted cost'}>{quotedCost}</NamedValue>
                        </GridItem>
                        <GridItem>
                            <NamedValue label={'How is cost shared?'}>{shareMethod}</NamedValue>
                        </GridItem>
                        <GridItem colSpan={3}>
                            <NamedValue label={'Approved by'}>
                                {acceptedPercent}% of owners
                                {isSharedEqually
                                    ? " - each owner's response is weighted the same as their share of the cost"
                                    : " - each owner's response is of equal value"}
                            </NamedValue>
                        </GridItem>
                    </Grid>
                );
            }
        },
        { fallback: 'md', ssr: false }
    )!;

    return (
        <>
            <CorrespondenceModal {...standardModalProps}>
                {renderGrid()}
                <RecipientsTable
                    accounts={accounts}
                    building={building}
                    correspondence={correspondence}
                    correspondenceAccounts={correspondenceAccounts}
                    properties={properties}
                    renderHeaderRow={renderHeaderRow}
                    renderCorrespondenceRow={renderCorrespondenceRow}
                />
            </CorrespondenceModal>
        </>
    );
};
