import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { LINK_BLUE } from '../../../components';

export const ForumBreadcrumb = (props: {
    items: {
        link?: string;
        isCurrentPage?: boolean;
        text: string;
    }[];
}) => {
    const { items } = props;

    const navigate = useNavigate();

    return (
        <Breadcrumb marginBottom={2} spacing={1} separator={<ChevronRightIcon color="gray.500" />}>
            {items.map(({ link, isCurrentPage, text }, index) => {
                const onClick = link ? () => navigate(link) : undefined;
                return (
                    <BreadcrumbItem isCurrentPage={isCurrentPage} isLastChild={index === items.length - 1}>
                        <BreadcrumbLink color={link ? LINK_BLUE : undefined} onClick={onClick} href="#">
                            {text}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};
